import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=e5888bd8&scoped=true&lang=pug&"
import script from "./Form.vue?vue&type=script&lang=ts&"
export * from "./Form.vue?vue&type=script&lang=ts&"
import style0 from "./Form.vue?vue&type=style&index=0&id=e5888bd8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5888bd8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VBtn,VCol,VContainer,VDivider,VForm,VIcon,VRow})
