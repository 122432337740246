












import { Component } from 'vue-property-decorator';
import { EntityList, HeaderConfig } from '@/base';
import { store } from '../store';
import { OfficeWorkerJson } from '../json';
import { sortByFullName, getFullName } from '@/modules/user';

@Component<List>({
  filters: {
    fullName: (item: OfficeWorkerJson | null, fallbackToId?: boolean): string =>
    {
      return item ? getFullName(item, fallbackToId) : '';
    },
  },
  methods: {
    getFullName,
  },
  created()
  {
    this.init();
  },
})
export default class List extends EntityList<OfficeWorkerJson, typeof store>
{
  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'lastName, firstName' }];

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'lastName',
        text: this.$t('list.header.name').toString(),
        sort: (a, b) => sortByFullName(a, b, this.$i18n.locale),
      },
      {
        value: 'email',
        text: this.$t('list.header.email').toString(),
      },

    ];
  }

  protected getDeleteConfirmationMessage(item: OfficeWorkerJson): { title?: string; message: string }
  {
    const args = Object.assign({ fullName: getFullName(item, true) }, item);
    return {
      title: this.$t('list.delete.title', args).toString(),
      message: this.$t('list.delete.message', args).toString(),
    };
  }
}
