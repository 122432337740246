


























































































































import { Component } from 'vue-property-decorator';
import { HeaderConfig, EntityList, ListAction, SelectionMode } from '@/base';
import { store, Actions } from '../store';
import { ExternalDocumentJson } from '../json';
import { store as businessUnitStore } from '@/modules/businessunit';
import { Route, RawLocation } from 'vue-router';
import { UserPermission } from '@/modules/user';

@Component<List>({
  created()
  {
    this.init();
  },
})
export default class List extends EntityList<ExternalDocumentJson, typeof store>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_WRITE);
  }
  protected hasDeletePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_DELETE);
  }
  /**
   * The store to the items.
   */
  protected readonly store = store;
  private readonly businessUnitStore = businessUnitStore;

  public selectionMode = SelectionMode.MULTI;

  public sortState = [{ property: 'documentReference' }];

  private showDownloadFileZipCheck = false;

  private failedExternalDocumentsForDownloadZipCheck: ExternalDocumentJson[] = [];

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    documentReference: '',
    businessUnitId: null as number | null,
    documentValueFrom: null as number | null,
    documentValueTo: null as number | null,
    documentDateFrom: null as Date | null,
    documentDateUntil: null as Date | null,
    documentCreator: '',
    hasFilesAttached: null,
  };
  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<ExternalDocumentJson>[] {
    const actions = [ this.getActionEdit() ];
    if (this.hasDeletePermission()) {
      actions.push(this.getActionDelete());
    }
    return actions;
  }

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'documentReference',
        text: this.$t('list.header.documentReference').toString(),
        filterProperty: ['documentReference'],
      },
      {
        value: 'documentDate',
        text: this.$t('list.header.documentDate').toString(),
        filterProperty: ['documentDateFrom', 'documentDateUntil'],
      },
      {
        value: 'isIncome',
        text: this.$t('list.header.isIncome').toString(),
      },
      {
        value: 'documentValueNet',
        text: this.$t('list.header.documentValueNet').toString(),
        filterProperty: ['documentValueFrom', 'documentValueTo'],
      },
      {
        value: 'documentValueGross',
        text: this.$t('list.header.documentValueGross').toString(),
        filterProperty: ['documentValueFrom', 'documentValueTo'],
      },
      {
        value: 'documentCreator',
        text: this.$t('list.header.documentCreator').toString(),
        filterProperty: ['documentCreator'],
      },
      {
        value: 'businessUnit.name',
        text: this.$t('list.header.businessUnit').toString(),
        remoteSort: desc => [{ property: 'businessUnit.id', desc }],
        filterProperty: 'businessUnitId',
      },
      {
        value: 'isVatFull',
        text: this.$t('list.header.documentIsVatFull').toString(),
      },
      {
        value: 'isVatReduced',
        text: this.$t('list.header.documentIsVatReduced').toString(),
      },
      {
        value: 'isVatZero',
        text: this.$t('list.header.documentIsVatZero').toString(),
      },
      {
        value: 'hasFilesAttached',
        text: this.$t('list.header.downloadFiles').toString(),
        sortable: false,
        filterProperty: 'hasFilesAttached',
      },
    ];
  }

  protected getDeleteConfirmationMessage(item: ExternalDocumentJson): { title?: string; message: string }
  {
    return {
      title: this.$t('list.delete.title', item).toString(),
      message: this.$t('list.delete.message', item).toString(),
    };
  }

    private downloadFiles(item: ExternalDocumentJson): void {
      if (item) {
        store[Actions.DOWNLOAD_FILES_AS_ZIP]([item]);
      }
    }

    private async downloadAccumulative() {
      this. failedExternalDocumentsForDownloadZipCheck = [];
      const res = await store[Actions.DOWNLOAD_FILES_AS_ZIP_CHECK](this.selectedItems);
      if (res && res.length === 0)
      {
        await store[Actions.DOWNLOAD_FILES_AS_ZIP](this.selectedItems);
      } else {
        this. failedExternalDocumentsForDownloadZipCheck = res;
        this.showDownloadFileZipCheck = true;
      }
    }

    private downloadOtherFiles(): boolean {
      return this.failedExternalDocumentsForDownloadZipCheck && this.selectedItems && this.failedExternalDocumentsForDownloadZipCheck.length < this.selectedItems.length;
    }

    private async downloadAccumulativeForced() {
        this.showDownloadFileZipCheck = false;
        await store[Actions.DOWNLOAD_FILES_AS_ZIP](this.selectedItems);
    }
}
