import { ModuleBuilder, StoreBuilder } from 'vuex-typex';
import _ from 'lodash';
import { CancelToken } from 'axios';
import { SharedState } from '@/store';
import api from '@/plugins/api';
import { BaseEntity, NewEntityProperties } from './entity';
import createEntityStore, { EntityStoreState, EntityStore } from './entitystore';

export interface EntityPagingStoreState<T extends BaseEntity> extends EntityStoreState<T>
{
  /**
   * The current page.
   */
  page: Page<T>;
  /**
   * The arguments of the last request.
   */
  lastArgs?: string;
}

export enum EntityPagingStoreGetters
{
  PAGE = 'getPage',
}

export enum EntityPagingStoreActions
{
  LIST_ALL = 'dispatchListAll',
  READ_PAGE = 'dispatchReadPage',
  AUTOCOMPLETE_SEARCH = 'dispatchAutocompleteSearch',
}

export enum EntityPagingStoreMutations
{
  PAGE = 'commitPage',
}

/**
 * Filter information for LIST_ALL action.
 */
export interface ListInformation
{
  /**
   * Properties to sort.
   */
  sort?: SortInfo[];
  /**
   * Filtering parameters with values.
   */
  filter?: _.Dictionary<string | number | boolean | Date | null>;
}

/**
 * Paging information for READ_PAGE action.
 */
export interface PagingInformation extends ListInformation
{
  /**
   * The page to load (0-based).
   */
  page: number;
  /**
   * The size of a page.
   */
  pageSize: number;
  /**
   * Tries to return the local page if it has the same options.
   */
  initial?: boolean;
}

/**
 * Entity store with remote paging and sorting functionality.
 */
export interface EntityPagingStore<T extends BaseEntity> extends EntityStore<T>
{
  /**
   * Returns the current page.
   */
  readonly [EntityPagingStoreGetters.PAGE]: Page<T>;
  /**
   * Checks if the given search string is valid to perform an autocomplete search.
   */
  isValidAutocompleteSearchString(searchString: string): boolean;
  /**
   * Reads items of a page from the server using pagination and sorting.
   * Commits the current page with the result.
   */
  [EntityPagingStoreActions.READ_PAGE](paging: PagingInformation): Promise<Page<T>>;
  /**
   * Reads items of a page from the server using filtering.
   * Does not commit a page or store anything in the cache.
   */
  [EntityPagingStoreActions.LIST_ALL](payload: ListInformation): Promise<Page<T>>;
  /**
   * Reads items from the server using the given search string.
   */
  [EntityPagingStoreActions.AUTOCOMPLETE_SEARCH](payload: string | { searchString: string, cancelToken?: CancelToken }): Promise<T[]>;
}

function encodeListArguments(listInformation: ListInformation): string
{
  const { filter, sort } = listInformation;

  let args = (sort || []).map(({ property, desc }) => `&sort=${encodeURIComponent(property)},${desc ? 'desc' : 'asc'}`).join('');

  if (filter)
  {
    _.forEach(filter, (value, key) =>
      {
        if (value != null && value !== '')
        {
          if (value instanceof Date)
          {
            value = `${value.getFullYear()}-${(value.getMonth() + 1).toString().padStart(2, '0')}-${value.getDate().toString().padStart(2, '0')}`;
          }
          args += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      });
  }

  return args.substr(1); // strip first &
}

export function createEntityPagingStore<T extends BaseEntity, S extends EntityPagingStoreState<T>, U extends EntityPagingStore<T> = EntityPagingStore<T>>(
  moduleName: string,
  apiBaseUrl: string,
  createItem: () => NewEntityProperties<T>,
  initialState: Omit<S, keyof EntityPagingStoreState<T>>,
  configure?: (moduleBuilder: ModuleBuilder<S, SharedState>) => Omit<U, keyof EntityPagingStore<T>>,
  builder?: StoreBuilder<SharedState> | ModuleBuilder<any, SharedState>): U
{
  // store definition
  const store = createEntityStore<T, S, U>(
    moduleName,
    apiBaseUrl,
    createItem,
    Object.assign({}, initialState, {
      page: {
        content: [],
        empty: true,
        first: true,
        last: true,
        number: 0,
        numberOfElements: 0,
        pageable: {
          offset: 0,
          pageNumber: 0,
          pageSize: 0,
          paged: false,
          sort: {
            sorted: false,
            unsorted: true,
            empty: true,
          },
          unpaged: true,
        },
        size: 0,
        sort: {
          sorted: false,
          unsorted: true,
          empty: true,
        },
        totalElements: 0,
        totalPages: 0,
      } as Page<T>,
    }) as S,
    moduleBuilder =>
    {
      const getPage = moduleBuilder.read(state => state.page || [], EntityPagingStoreGetters.PAGE);
      const commitPage = moduleBuilder.commit((state, page: Page<T>) => state.page = page, EntityPagingStoreMutations.PAGE);

      const pagingStore: Omit<EntityPagingStore<T>, keyof EntityStore<T>> & Partial<EntityStore<T>> = {
        get [EntityPagingStoreGetters.PAGE](): Page<T>
        {
          return getPage();
        },

        [EntityPagingStoreActions.READ_PAGE]: moduleBuilder.dispatch(async ({ state }, paging: PagingInformation) =>
          {
            const { page, pageSize, initial } = paging;

            const args = `?page=${page}&size=${pageSize}&${encodeListArguments(paging)}`;

            // if items are appended/deleted and we are reloading, force server reload
            if (initial && !state.allLoaded && (state.items.size > state.page.totalElements || state.page.content.some(c => !state.items.has(c.id))))
            {
              delete state.lastArgs;
            }

            if (!initial || state.lastArgs !== args)
            {
              const result = await api.get<Page<T>>(`${apiBaseUrl}${(apiBaseUrl.endsWith('/') ? '' : '/')}list${args}`);
              state.lastArgs = args;

              if (!state.allLoaded && !result.empty)
              {
                state.items.set(...result.content);
              }

              commitPage(result);
            }

            return state.page;
          }, EntityPagingStoreActions.READ_PAGE),

        [EntityPagingStoreActions.LIST_ALL]: moduleBuilder.dispatch(async ({ state }, payload: ListInformation) =>
          {
            const args = `?page=0&size=10000&${encodeListArguments(payload)}`;

            return await api.get<Page<T>>(`${apiBaseUrl}${(apiBaseUrl.endsWith('/') ? '' : '/')}list${args}`);
          }, EntityPagingStoreActions.LIST_ALL),

        isValidAutocompleteSearchString(searchString: string): boolean
        {
          // By default check for a min length of 3 or an ID.
          return !!searchString && (searchString.length > 2 || !isNaN(parseInt(searchString, 10)));
        },

        [EntityPagingStoreActions.AUTOCOMPLETE_SEARCH]: moduleBuilder.dispatch(
          async ({ state }, payload: string | { searchString: string, cancelToken?: CancelToken }) =>
            {
              if (typeof payload === 'string')
              {
                payload = {
                  searchString: payload,
                };
              }

              const serverResult = await api.get<T[]>(`${apiBaseUrl}/search?searchString=${encodeURIComponent(payload.searchString)}`,
              {
                cancelToken: payload.cancelToken,
              });

              // Cache Items for autocomplete?
              // state.items.set(...serverResult);

              return serverResult;
            }, EntityPagingStoreActions.AUTOCOMPLETE_SEARCH),
        };

      // confiure more
      if (configure)
      {
        Object.defineProperties(pagingStore, Object.getOwnPropertyDescriptors(configure(moduleBuilder)));
      }

      return pagingStore as U;
    },
    builder);

  return store as U;
}

export default createEntityPagingStore;
