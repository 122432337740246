import { TaskJson, TaskState } from './json';
import { EntityPagingStoreState, EntityPagingStore, EntityStoreActions, createEntityPagingStore, EntityId, getId, commitEntity, FormData, NewEntityProperties } from '@/base';
import api from '@/plugins/api';
import { store as userStore } from '../user';

export enum Actions {
  SET_STATE = 'dispatchSetState',
}

export interface State extends EntityPagingStoreState<TaskJson> {}

export interface Store extends EntityPagingStore<TaskJson> {
  // hide actions
  [EntityStoreActions.CREATE_OR_UPDATE]: never;
  [EntityStoreActions.DELETE]: never;

  [Actions.SET_STATE](payload: { task: TaskJson; state: TaskState }): Promise<TaskJson>;
}

const taskBaseUrl = 'task';
function createItem(): NewEntityProperties<TaskJson> {
  return {
    taskState: TaskState.CREATED,
    taskName: '',
    taskDate: null,
    taskDescription: '',
    admins: [] = [],
    officeWorkers: [] = [],
  };
}

export const store = createEntityPagingStore<TaskJson, State, Store>('task', taskBaseUrl, createItem, {}, moduleBuilder => {
  return {
    [Actions.SET_STATE]: moduleBuilder.dispatch(async ({ state }, { task, state: taskState }: Parameters<Store[Actions.SET_STATE]>[0]) => {
      const result = await api.put<TaskJson>(`${taskBaseUrl}/${task.id}/setstate`, taskState);

      const committed = commitEntity(task, result);

      state.items.set(committed);

      return committed;
    }, Actions.SET_STATE),
  };
});

export default store;
