import _ from 'lodash';
import { BaseEntity } from '@/base';

/**
 * Returns the entity ids that are considered a duplicate by given key selectors.
 * Null keys will not be considered.
 */
export function getDuplicates<T extends BaseEntity>(entities: T[], keySelector: (entity: T) => string | string[] | null): number[]
{
  const entityIdByKey: _.Dictionary<number[]> = {};

  entities.forEach(entity =>
    {
      const key = keySelector(entity);
      if (key != null)
      {
        const keys = Array.isArray(key) ? key : [key];
        keys.forEach(k =>
          {
            entityIdByKey[k] = entityIdByKey[k] || [];
            entityIdByKey[k].push(entity.id);
          });
      }
    });

  return _.transform(entityIdByKey, (result, ids) => result.push(...(ids.length > 1 ? ids : [])), [] as number[]);
}
