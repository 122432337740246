










































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { EmailTemplateJson, EmailTemplatePlaceholder, EmailTemplateType } from '../json';
import { store as businessUnitStore } from '@/modules/businessunit';
import { EntityForm as FormBase, EntityStoreGetters, EntityStoreActions } from '@/base';
import QuillField from '@/components/QuillField';
import store from '../store';
import { filterPlaceholderTemplateType } from '../util';
import { UserPermission } from '@/modules/user';

@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends FormBase<EmailTemplateJson, typeof store>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.EMAIL_TEMPLATE_WRITE);
  }

  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'emailtemplate',
  };

  private readonly businessUnitStore = businessUnitStore;

  private get placeholders(): EmailTemplatePlaceholder[]
  {
    let placeholders = Object.keys(EmailTemplatePlaceholder) as EmailTemplatePlaceholder[];
    const activeItem = this.activeItem;
    if (activeItem && activeItem.emailTemplateType != null)
    {
      placeholders = placeholders.filter(ph => filterPlaceholderTemplateType(ph, activeItem.emailTemplateType!));
    }
    return placeholders;
  }

  /**
   * Inserts a placeholder to the current cursor position of the subject.
   */
  private insertPlaceholderToSubject(placeholder: EmailTemplatePlaceholder): void
  {
    const subjectEl = this.$refs.subject.$el;
    const activeItem = this.activeItem;
    if (subjectEl && activeItem)
    {
      const input = subjectEl.querySelector('input');
      if (input)
      {
        const start = input.selectionStart == null ? input.value.length : input.selectionStart;
        const end = input.selectionEnd == null ? input.value.length : input.selectionEnd;

        activeItem.subject = `${input.value.substr(0, start)}{{${placeholder}}}${input.value.substr(end)}`;

        this.$nextTick(() =>
        {
          input.setSelectionRange(start, start + placeholder.length + 4);
          input.focus();
        });
      }
    }
  }

  /**
   * Inserts a placeholder to the current cursor position of the body.
   */
  private insertPlaceholderToBody(placeholder: EmailTemplatePlaceholder): void
  {
    const body = this.$refs.body;
    if (body)
    {
      body.updateSelection([
        { insert: `{{${placeholder}}}` },
      ]);
    }
  }

  private readonly EmailTemplateType = EmailTemplateType;

  public readonly $refs!: {
    validator: InstanceType<typeof ValidationObserver>;
    subject: Vue;
    body: InstanceType<typeof QuillField>,
  };
}
