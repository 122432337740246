import i18n from '@/plugins/i18n';
import { EntityPagingStoreActions } from '@/base';
import { EventHandler, Event } from '@/calendar';
import store from './store';
import EventDetails from './components/EventDetails.vue';
import { CalendarObjectTypes } from '@/modules/calendar';
import { hasPermission } from '@/json';
import { UserPermission, UserType } from '@/modules/user';
import { store as mainStore, Getters } from '@/store';

export const eventHandler: EventHandler = {
  component: {
    extends: EventDetails,
  },

  async getEvents(from, until, businessUnitId, languageId, translatorId, customerId, calendarObjectType)
  {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;

    if (calendarObjectType && calendarObjectType !== CalendarObjectTypes.INVOICE || !hasPermission(UserPermission.ACCOUNTING_READ) || currentUserType === UserType.TRANSLATOR)
    {
      return [];
    }

    const result = await store[EntityPagingStoreActions.LIST_ALL]({
      filter: {
        timeForPaymentFrom: from,
        timeForPaymentUntil: until,
        businessUnitId,
        customerId,
      },
    });

    return result.content.map<Event>(i => ({
      start: i.timeForPayment,
      title: i18n.t('module.invoice.event.title', i).toString(),
      color: i.invoiceComment ? 'calendar_invoice_color darken-2' : 'calendar_invoice_color',
      route: `/invoice/${i.id}`,
      appointmentComment: i.invoiceComment,
      customerComment: i.customer.customerComment,
      componentBindings: {
        invoice: i,
      },
    }));
  },
};

export default eventHandler;
