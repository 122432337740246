






















































































































































































































































import { Component } from 'vue-property-decorator';
import { EntityList, ListAction, HeaderConfig, EntityPagingStoreActions, EntityStoreActions, EntityStoreGetters, SelectionMode } from '@/base';
import store, { Actions } from '../store';
import { CreditJson, CreditState } from '../json';
import { store as mainStore, Getters } from '@/store';
import { store as businessUnitStore } from '@/modules/businessunit';
import { TranslatorJson, store as translatorStore } from '@/modules/translator';
import { getFullName, UserJson, UserType, UserPermission } from '@/modules/user';
import { store as officeWorkerStore } from '@/modules/officeworker';
import { store as adminStore } from '@/modules/admin';
import { Route, RawLocation } from 'vue-router';
import { EmailTemplateJson, EmailTemplateType, store as emailTemplateStore } from '@/modules/emailtemplate';

@Component<List>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  filters: {
    fullName(item: UserJson | null, fallbackToId?: boolean): string {
      return item ? getFullName(item, fallbackToId) : '';
    },
  },
  created() {
    this.init();
  },
  methods: {
    getFullName,
  },
})
export default class List extends EntityList<CreditJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_WRITE);
  }

  protected hasDeletePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_DELETE);
  }

  protected readonly officeWorkerStore = officeWorkerStore;
  protected readonly adminStore = adminStore;

  public selectionMode = SelectionMode.MULTI;

  private showUsageDialog = false;

  private contentDialog = '';

  private showDownloadPdfZipCheck = false;

  private failedCreditsForDownloadPdfZipCheck: CreditJson[] = [];

  private handleIconClick(subject: string): void {
    this.contentDialog = subject;
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const contractNo = to.query.contractNo;
    if (contractNo) {
      this.loadDataForContractNo(to.query.contractNo as string);
    }
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;
  public sortState = [{ property: 'creditReference' }];

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    creditReference: '',
    contractNo: '',
    translatorId: null as number | null,
    businessUnitId: null as number | null,
    creditState: null as CreditState | null,
    creditDateFrom: null as Date | null,
    creditDateUntil: null as Date | null,
    isPaymentCompleted: null,
    pdfCreatorAdminUserId: null as number | null,
    pdfCreatorOfficeWorkerId: null as number | null,
    isCreatedPDF: null,
  };

  private readonly businessUnitStore = businessUnitStore;
  private readonly translatorStore = translatorStore;

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<CreditJson>[] {
    const actions = [ this.getListActionEdit() ];
    if (this.hasWritePermission()) {
      actions.push(this.getListActionSendEmailToTranslator());
    }
    if (this.hasDeletePermission()) {
      actions.push(this.getListActionDelete());

    }
    return actions;
  }

  private getListActionEdit(): ListAction<CreditJson> {
    return {
        icon: 'edit',
        handler: item => this.editItem(item),
        text: this.$root.$t('edit.label').toString(),
      };
  }

  private getListActionDelete(): ListAction<CreditJson> {
    return {
        icon: 'delete',
        handler: item => this.deleteItem(item),
        isVisible: item => item.creditState === CreditState.CREATED,
        text: this.$root.$t('delete.label').toString(),
        color: 'delete',
      };
  }

  private getListActionSendEmailToTranslator(): ListAction<CreditJson> {
    return {
        icon: 'sendEmail',
        handler: item => this.showSendEmailDialog(item, true),
        isVisible: item => this.isAllowForAdminsAndOfficeWorkers(),
        text: this.$t('list.actions.sendEmailToTranslator').toString(),
      };
  }
  private loadDataForContractNo(contractNo: string): Promise<void> {
    this.loading = true;

    let promise: Promise<any>;

    const { sortState, page, itemsPerPage } = this;
    const headers = this.$_.keyBy(this.headers as HeaderConfig[], h => h.value);

    const filter = { contractNo };
    const initial = true;

    promise = this.store[EntityPagingStoreActions.READ_PAGE]({
      page,
      pageSize: itemsPerPage,
      sort: (sortState as SortInfo[]).reduce((result, { property, desc }) => {
        const h = headers[property];

        if (h && h.remoteSort) {
          result.push(...h.remoteSort(!!desc));
        } else {
          result.push({ property, desc });
        }

        return result;
      }, [] as SortInfo[]),
      filter: filter || undefined,
      initial,
    }).then(() => (this.currentFilters = filter));

    return promise.finally(() => (this.loading = false));
  }

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[] {
    const creditReference: HeaderConfig = {
        value: 'creditReference',
        text: this.$t('list.header.creditReference').toString(),
        filterProperty: ['creditReference', 'contractNo'],
    };

    const creditState: HeaderConfig = {
        value: 'creditState',
        text: this.$t('list.header.creditState').toString(),
    };

    const creditDate: HeaderConfig = {
      value: 'creditDate',
      text: this.$t('list.header.creditDate').toString(),
      filterProperty: ['creditDateFrom', 'creditDateUntil'],
    };

    const translator: HeaderConfig = {
      value: 'translator',
      text: this.$t('list.header.translator').toString(),
      remoteSort: desc => [
        { property: 'translator.lastName', desc },
        { property: 'translator.firstName', desc },
      ],
      filterProperty: 'translatorId',
    };

    const businessUnit: HeaderConfig = {
      value: 'businessUnit.name',
      text: this.$t('list.header.businessUnit').toString(),
      remoteSort: desc => [{ property: 'businessUnit.id', desc }],
      filterProperty: 'businessUnitId',
    };

    const isPaymentCompleted: HeaderConfig = {
      value: 'isPaymentCompleted',
      text: this.$t('list.header.isPaymentCompleted').toString(),
      filterProperty: ['isPaymentCompleted'],
    };

    const creditComment: HeaderConfig = {
      value: 'creditComment',
      text: this.$t('list.header.creditComment').toString(),
      filterProperty: ['creditComment'],
    };

    const fileNameCreditPDF: HeaderConfig = {
      value: 'fileNameCreditPDF',
      text: this.$t('list.header.downloadPDF').toString(),
      remoteSort: desc => [{ property: 'fileEntityCreditPDF.fileName', desc }],
      filterProperty: ['pdfCreatorAdminUserId', 'pdfCreatorOfficeWorkerId', 'isCreatedPDF'],
    };

    if (this.isAllowForAdminsAndOfficeWorkers())
    {
      return [creditReference, creditState, creditDate, translator, businessUnit, isPaymentCompleted, creditComment, fileNameCreditPDF];
    }
    return [creditReference, creditState, creditDate, translator, businessUnit, isPaymentCompleted, fileNameCreditPDF];
  }

  private isAllowForAdminsAndOfficeWorkers(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.OFFICE_WORKER || currentUserType === UserType.ADMIN;
  }

  private canFulfillCreditByState(state: CreditState): boolean {
    return state === CreditState.CREATED && this.isAllowForAdminsAndOfficeWorkers();
  }

  private async fulfillCredit(item: CreditJson): Promise<void> {
    await store[Actions.SET_STATE]({
      credit: item,
      state: CreditState.APPROVED,
    });
  }

  // E-mails

  /**
   * Variable used to display the options in the email dialog.
   */
  protected emailTemplatesForCredit: {
    credit: CreditJson;
    emailTemplates: EmailTemplateJson[];
    isTranslator: boolean;
  } | null = null;

  protected showSendEmailDialog(item: CreditJson, isTranslator: boolean): void {
    emailTemplateStore[EntityStoreActions.READ_ALL](true);
    const res = emailTemplateStore[EntityStoreGetters.ITEMS].filter(t => t.emailTemplateType === EmailTemplateType.CREDIT);

    this.emailTemplatesForCredit = {
      credit: item,
      emailTemplates: res,
      isTranslator,
    };
  }

  protected sendEmail(item: CreditJson, emailTemplate: EmailTemplateJson, isTranslator: boolean): void {
    const emailPath = isTranslator ? 'email/translator' : 'email/customer';

    // forward to route
    this.$router
      .push({
        path: `${item.id}/${emailPath}/${emailTemplate.id}`,
        append: true,
      })
      // close dialog
      .then(() => null);
  }

  private readonly CreditState = CreditState;

  private showSetStateAdmin = false;

  private setStateAdmin(): void {
    this.showSetStateAdmin = true;
  }

  private async setNewState(arg: any) {
    const state = arg as CreditState;
    this.showSetStateAdmin = false;

    this.selectedItems.forEach(async item => {
      await store[Actions.SET_STATE]({
        credit: item,
        state,
      });
    });
  }

  private async refreshStateOfSelectedItems()
  {
    const readPromises: Promise<CreditJson>[] = [];
    this.selectedItems.forEach(i => {
      readPromises.push(store[EntityStoreActions.READ_ONE](i.id));
    });
    await Promise.all(readPromises).then(values => {
      values.forEach(i => this.selectedItems.filter(item => i.id === item.id).forEach(selectedItem => selectedItem.creditState = i.creditState));
    });
  }

  private showModalWarning = false;
  private contentModalWarning: string | null = null;

  private async cancelCreditBase()
  {
    if (this.selectedItems.length > 0)
    {
      await this.refreshStateOfSelectedItems();
      this.canceledCredits = '';
      if (this.selectedItems.filter(c => c.creditState !== CreditState.APPROVED).length > 0)
      {
        this.contentModalWarning = this.$t('dialog.warning.creditNotApproved') as string;
        this.showModalWarning = true;
      }
      else
      {
        this.canceledCredits = this.selectedItems.map(c => c.creditReference).join(', ');
        this.showCancelCredit = true;
      }
    }
  }

  private showCancelCredit = false;
  private canceledCredits = '';

  private async cancelCredit()
  {
    this.showCancelCredit = false;

    if (this.selectedItems.length > 0)
    {
      this.selectedItems.forEach(async item => {
        await store[Actions.CANCEL_CREDIT]({
          credit: item,
        }).then((OK) => this.$success(this.$t('message.reversalCreditCreated') as string));
      });
    }
  }

  private downloadPDF(item: CreditJson): void {
    if (item) {
      store[Actions.DOWNLOAD_CREDIT_PDF](item.id);
    }
  }

  private async downloadAccumulative() {
    this. failedCreditsForDownloadPdfZipCheck = [];
    const res = await store[Actions.DOWNLOAD_CREDIT_PDF_ZIP_CHECK](this.selectedItems);
    if (res && res.length === 0)
    {
      await store[Actions.DOWNLOAD_CREDIT_PDF_ZIP](this.selectedItems);
    } else {
      this. failedCreditsForDownloadPdfZipCheck = res;
      this.showDownloadPdfZipCheck = true;
    }
  }

  private downloadOtherPdfFiles(): boolean {
    return this.failedCreditsForDownloadPdfZipCheck && this.selectedItems && this.failedCreditsForDownloadPdfZipCheck.length < this.selectedItems.length;
  }

  private async downloadAccumulativeForced() {
      this.showDownloadPdfZipCheck = false;
      await store[Actions.DOWNLOAD_CREDIT_PDF_ZIP](this.selectedItems);
  }

  private isAllowForAdmins(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.ADMIN;
  }

}
