


































import { Component } from 'vue-property-decorator';
import { VatConfigurationJson, Vat } from '../json';
import FormBase from '@/base/EntityForm';
import { store, Actions } from '../store';
import { asFormData } from '../../../base';

@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends FormBase<VatConfigurationJson, typeof store>
{
  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'vatconfiguration',
  };

  private readonly Vat = Vat;

}
