





























































import { Component, Vue } from 'vue-property-decorator';
import { EntityForm } from '@/base';
import { TaskJson } from '../json';
import store, { Actions } from '../store';
import { store as officeWorkerStore } from '@/modules/officeworker';
import { store as adminStore } from '@/modules/admin';
import { ContactForm } from '@/modules/contact';
import PositionList from '../components/PositionList.vue';
import { getDescription as getContactDescription, ContactJson } from '@/modules/contact';
import { store as mainStore, Getters } from '@/store';
import { Route, RawLocation } from 'vue-router';
import { getFullName, UserJson, UserPermission } from '@/modules/user';

@Component<Form>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  components: {},
  methods: {
    getFullName,
  },
})
export default class Form extends EntityForm<TaskJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.APPOINTMENT_WRITE);
  }

  protected readonly store = store;
  protected readonly officeWorkerStore = officeWorkerStore;
  protected readonly adminStore = adminStore;

  private getCreatedBy(): string | null {
    if (this.activeItem && this.activeItem.user) {
      return this.$t('form.createdBy.label', [this.activeItem.user.lastName + ', ' + this.activeItem.user.firstName]) as string;
    }
    return null;
  }

  protected closeRoute = {};

  protected isFormDisabled(): boolean {
    return false;
  }

  private isAllowToEditAssignedUsers(creatorUser: UserJson | null): boolean {
    if (creatorUser)
    {
      const currentUserId = mainStore[Getters.CURRENT_USER_LOGIN]!.id;
      return creatorUser.id === currentUserId;
    }
    return true;
  }


  public handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    this.closeRoute = {
      name: from.name ? from.name : 'task',
    };
    super.handleBeforeRouteEnter(to, from, next);
  }
}
