













































































































































import { Component } from 'vue-property-decorator';
import { TranslatorJson, TranslatorOfferedService } from '../json';
import { EntityForm as FormBase, EntityStoreGetters, EntityStoreActions } from '@/base';
import { store, Actions } from '../store';
import { CostCenterJson, store as costCenterStore } from '@/modules/costcenter';
import { store as languageStore, LanguageJson, getLanguageItemComparator } from '@/modules/language';
import { UserPermission } from '@/modules/user';

@Component<Form>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  methods: {
    getLanguageItemComparator,
  },
})
export default class Form extends FormBase<TranslatorJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.TRANSLATOR_WRITE);
  }

  protected readonly store = store;

  /**
   * The store of available languages.
   */
  private readonly languageStore = languageStore;

  protected readonly closeRoute = {
    name: 'translator',
  };

  get offeredServiceItems(): any[] {
    const offeredServiceItems: any[] = [];
    Object.values(TranslatorOfferedService).forEach(s => {
      offeredServiceItems.push({
        typeOfService: s,
      });
    });

    return offeredServiceItems;
  }

  private sendActivationEmail(): void {
    if (this.activeItem) {
      store[Actions.SEND_ACTIVATION_EMAIL](this.activeItem)
        .then(itemRes => {
          this.$success(this.$root.$t('module.email.send.ok') as string);
        })
        .catch(err => {
          this.$error(this.$root.$t('module.email.send.failed') as string);
        });
    }
  }

  private readonly costCenterStore = costCenterStore;
}
