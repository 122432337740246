import { BaseEntity } from '@/base';
import { UserJson } from '../user';
import { OfficeWorkerJson } from '../officeworker';

export enum TaskState {
  CREATED = 'CREATED',
  DONE = 'DONE',
}

export interface TaskJson extends BaseEntity {
  taskState: TaskState;
  taskName: string;
  taskDate: Date | null;
  taskDescription: string | null;
  readonly user: UserJson | null;
  admins: UserJson[];
  officeWorkers: OfficeWorkerJson[];
}
