import { getStoreBuilder, ModuleBuilder, StoreBuilder } from 'vuex-typex';
import _ from 'lodash';
import { SharedState } from '@/store';
import api from '@/plugins/api';
import { FormData, EditFormData, asRawData, asFormData } from './formdata';
import { BaseEntity, NewEntity, NewEntityProperties, createEntity as baseCreateEntity } from './entity';
import { EntityPagingStoreActions, EntityPagingStore } from './entitypagingstore';
import { EntityCache } from '@/util';

export interface EntityStoreState<T extends BaseEntity>
{
  /**
   * The loaded list of items.
   */
  items: EntityCache<T>;

  /**
   * The currently active item that is edited.
   */
  activeItem: FormData<T> | null;

  /**
   * Whether the items are loaded at least once.
   */
  allLoaded: boolean;
}

export enum EntityStoreGetters
{
  ITEMS = 'getItems',
  ACTIVE_ITEM = 'getActiveItem',
}

export enum EntityStoreActions
{
  READ_ALL = 'dispatchReadAll',
  READ_ONE = 'dispatchReadOne',
  CREATE_OR_UPDATE = 'dispatchCreateOrUpdate',
  DELETE = 'dispatchDelete',
}

export enum EntityStoreMutations
{
  ITEMS = 'commitItems',
  APPEND = 'commitAppend',
  REMOVE = 'commitRemove',
  ACTIVE_ITEM = 'commitActiveItem',
}

/**
 * Store with basic CRUD functionality for entities.
 */
export interface EntityStore<T extends BaseEntity>
{
  /**
   * The namespace of the module of the store.
   */
  readonly namespace: string;
  /**
   * Returns the list of all items.
   */
  readonly [EntityStoreGetters.ITEMS]: T[];
  /**
   * Returns the currently edited/active item.
   */
  readonly [EntityStoreGetters.ACTIVE_ITEM]: FormData<T> | null;
  /**
   * Creates and returns a new entity.
   */
  createEntity(): NewEntity<T>;
  /**
   * Sets the currently edited/active item.
   */
  [EntityStoreMutations.ACTIVE_ITEM](item: T | NewEntity<T> | null | { item: T | NewEntity<T> | null, useExisting: boolean }): void;
  /**
   * Reads all items from the server.
   * @param initial True to skip loading if initial items are already loaded.
   */
  [EntityStoreActions.READ_ALL](initial?: boolean | { initial?: boolean }): Promise<T[]>;
  /**
   * Reads one item from the server.
   * @param id The id of the item. If an object with id and initial is provided and initial is true,
   * skips the loading if items are already loaded and the item is present.
   */
  [EntityStoreActions.READ_ONE](id: number | { id: number; initial?: boolean }): Promise<T>;
  /**
   * Saves an item on the server. Creates or updates it depending on its state.
   * The item gets populated with computed values from the server.
   */
  [EntityStoreActions.CREATE_OR_UPDATE](item: FormData<T>): Promise<EditFormData<T>>;
  /**
   * Deletes an item on the server.
   */
  [EntityStoreActions.DELETE](item: T): Promise<void>;
}

/**
 * Configures the itemstore getters, mutations and actions in the given module builder.
 */
function configureEntityStoreModule<T extends BaseEntity, S extends EntityStoreState<T>>(
  moduleBuilder: ModuleBuilder<S, SharedState>,
  apiBaseUrl: string,
  createEntity: () => NewEntityProperties<T>,
  initialState: Omit<S, keyof EntityStoreState<T>>): Omit<EntityStore<T>, 'namespace'>
{
  moduleBuilder.setInitialState(Object.assign({}, initialState, {
    items: new EntityCache<T>(),
    activeItem: null,
    allLoaded: false,
  }) as S);

  const getItems = moduleBuilder.read(state => state.items.list, EntityStoreGetters.ITEMS);
  const setItems = moduleBuilder.commit((state, items: T[]) => (state.items.clear(), state.items.set(...items)), EntityStoreMutations.ITEMS);
  const getActiveItem = moduleBuilder.read(state => state.activeItem, EntityStoreGetters.ACTIVE_ITEM);

  const appendItem = moduleBuilder.commit((state, item: T) => state.items.set(asRawData(item)), EntityStoreMutations.APPEND);
  const removeItem = moduleBuilder.commit((state, item: T) => state.items.delete(item.id), EntityStoreMutations.REMOVE);

  // store definition
  const store: Omit<EntityStore<T>, 'namespace'> = {
    get [EntityStoreGetters.ITEMS](): T[]
    {
      return getItems();
    },
    get [EntityStoreGetters.ACTIVE_ITEM](): FormData<T> | null
    {
      return getActiveItem();
    },

    createEntity()
    {
      return baseCreateEntity(createEntity());
    },

    [EntityStoreMutations.ACTIVE_ITEM]: moduleBuilder.commit((state, item: T | NewEntity<T> | null | { item: T | NewEntity<T> | null; useExisting: boolean; }) =>
      {
        let useExisting = true;
        if (item && 'item' in item && 'useExisting' in item)
        {
          useExisting = item.useExisting;
          item = item.item;
        }
        state.activeItem = item ? asFormData<T>(item, useExisting) : null;
      }, EntityStoreMutations.ACTIVE_ITEM),

    [EntityStoreActions.READ_ALL]: moduleBuilder.dispatch(async ({ state }, initial?: boolean | { initial?: boolean }) =>
      {
        if (initial && typeof initial === 'object' && 'initial' in initial)
        {
          initial = initial.initial;
        }

        if (!initial || !state.allLoaded)
        {
          const serverItems = await api.get<T[]>(apiBaseUrl);
          const addedItems = state.items.list.filter(t => t.id < 0);
          state.allLoaded = true;
          setItems(serverItems.concat(addedItems));
        }

        return state.items.list;
      }, EntityStoreActions.READ_ALL),

    [EntityStoreActions.READ_ONE]: moduleBuilder.dispatch(async ({ state }, id: number | { id: number; initial?: boolean }) =>
      {
        if (typeof id === 'number')
        {
          id = {
            id,
          };
        }

        let item: T | undefined;
        if (id.initial)
        {
          item = state.items.get(id.id);
        }

        if (!item)
        {
          item = await api.get<T>(`${apiBaseUrl}/${id.id}`);
          state.items.set(item);
        }

        return item;
      }, EntityStoreActions.READ_ONE),

    [EntityStoreActions.CREATE_OR_UPDATE]: moduleBuilder.dispatch(async ({ state }, item: FormData<T>) =>
      {
        let committedItem: EditFormData<T>;
        if (item.$isNew())
        {
          const result = await api.post<T>(apiBaseUrl, item.$raw);
          committedItem = item.$commit(result);
          appendItem(committedItem.$raw);
        }
        else
        {
          const result = await api.put<T>(`${apiBaseUrl}${(apiBaseUrl.endsWith('/') ? '' : '/')}${item.$original.id}`, item.$raw);
          committedItem = item.$commit(result);
        }

        state.items.set(committedItem.$raw);
        store[EntityStoreMutations.ACTIVE_ITEM]({ item: committedItem, useExisting: state.activeItem !== item });

        return committedItem;
      }, EntityStoreActions.CREATE_OR_UPDATE),

    [EntityStoreActions.DELETE]: moduleBuilder.dispatch(async ({ state }, item: T) =>
      {
        const { id } = item;
        if (id > 0)
        {
          await api.delete(`${apiBaseUrl}/${id}`);
        }
        removeItem(item);
      }, EntityStoreActions.DELETE),
  };

  return store;
}

export function createEntityStore<T extends BaseEntity, S extends EntityStoreState<T>, U extends EntityStore<T> = EntityStore<T>>(
  moduleName: string,
  apiBaseUrl: string,
  createItem: () => NewEntityProperties<T>,
  initialState: Omit<S, keyof EntityStoreState<T>>,
  configure?: (moduleBuilder: ModuleBuilder<S, SharedState>) => Omit<U, keyof EntityStore<T>>,
  builder?: StoreBuilder<SharedState> | ModuleBuilder<any, SharedState>): U
{
  const storeBuilder = getStoreBuilder<SharedState>();

  // module
  const moduleBuilder = (builder || storeBuilder).module<S>(moduleName);

  // store definition
  const store = configureEntityStoreModule(moduleBuilder, apiBaseUrl, createItem, initialState);

  // configure more
  if (configure)
  {
    Object.defineProperties(store, Object.getOwnPropertyDescriptors(configure(moduleBuilder)));
    // Object.assign(store, configure(moduleBuilder));
  }

  Object.defineProperty(store, 'namespace', {
    enumerable: true,
    value: moduleBuilder.namespace,
  });

  // register module
  if (!builder)
  {
    storeBuilder.registerModule(moduleName);
  }

  return store as U;
}

/**
 * Checks whether the given store is a paging store.
 */
export function isPagingStore<T extends BaseEntity>(store: EntityStore<T>): store is EntityPagingStore<T>
{
  return EntityPagingStoreActions.LIST_ALL in store;
}

export default createEntityStore;
