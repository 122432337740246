import axios, { AxiosAdapter, AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import _ from 'lodash';

declare module 'axios'
{
  interface AxiosInterceptorManager<V> {
    forEach(fn: (handler: { fulfilled?: (value: V) => V | Promise<V>, rejected?: (value: any) => any }) => void): void;
  }
}

const XSSI_PREFIX = /^\)\]\}',?\n/;
const DATE_RX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:[-+]\d{2}(?:\:?\d{2})?))$/;

const defaults: AxiosRequestConfig = {
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  responseType: 'json',
  withCredentials: true,
};

function getBaseUrl(): string
{
  const baseurl = window.location.origin;
  if (baseurl === 'http://localhost:4200')
  {
    return 'http://localhost:8080/rest/';
  }
  const re = /buerosoftware/gi;
  const apiUrl =  baseurl.replace(re, 'api');
  return apiUrl + '/rest/';
}

function defaultJsonReviever(key: any, value: any): any
{
  // Dates
  if (value && typeof value === 'string' && DATE_RX.test(value))
  {
    return new Date(value);
  }
  return value;
}

function apiJsonAdapter(adapter: AxiosAdapter): AxiosAdapter
{
  return request => adapter(request)
    .then(response =>
      {
        const originalBody = response.data;

        if (typeof originalBody === 'string')
        {
          const body = originalBody.replace(XSSI_PREFIX, '');
          try
          {
            response.data = body ? JSON.parse(body, (key, value) => defaultJsonReviever(key, value)) : body;
          }
          catch (error)
          {
            return Promise.reject(error);
          }
        }

        return response;
      });
}

/**
 * Injects the default JSON reviver into the api requests.
 */
function apiRequestInterceptor(request: AxiosRequestConfig): AxiosRequestConfig
{
  if (request.responseType === 'json')
  {
    request.responseType = 'text';
    request.adapter = apiJsonAdapter(request.adapter!);
  }
  return request;
}

export interface ApiAxiosInstance extends AxiosInstance
{
  /**
   * Raw request method that exposes the full response (not only the data)
   * and ignores message handling.
   */
  raw<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>>;
  // map response types to data types
  request<T = any>(config: AxiosRequestConfig): Promise<T>;
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  /**
   * Performs a request with file upload and uses a multipart form data object as request payload.
   */
  upload<T = any>(config: AxiosRequestConfig): Promise<T>;
  /**
   * Performs a download request and triggers the file download dialog in the browser.
   */
  download(config: AxiosRequestConfig): Promise<void>;
}

export interface IAxiosError<T = any> extends AxiosError<T>
{
  config: AxiosRequestConfig & { readonly $isRaw?: boolean; };
  statusCode?: number;
  isApiError: boolean;
}

/**
 * Use as transformRequest config to send a FormData object, e.g. for file uploads.
 */
export function transformRequestToFormData(data: any, headers?: any): any
{
  const formData = new FormData();

  if (data)
  {
    _.forEach(data, (val, key) =>
      {
        if (val instanceof FileList)
        {
          Array.prototype.slice.call(val).forEach(file => formData.append(key, file));
        }
        else if (_.isArray(val))
        {
          val.forEach(v => formData.append(key, v instanceof Blob ? v : JSON.stringify(v)));
        }
        else if (val instanceof Blob)
        {
          formData.append(key, val);
        }
        else
        {
          formData.append(key, JSON.stringify(val));
        }
      });
  }

  return formData;
}

const instance = axios.create(defaults);

/**
 * Used in all non-raw api calls to forward the data only to the caller.
 */
function forwardResponseData(response: AxiosResponse): any
{
  return response.data;
}

/**
 * Creates an axios instance that uses the whole response (not just the data) as result.
 */
function createRawInstance(): AxiosInstance
{
  const rawApi = axios.create(instance.defaults);

  // copy interceptors, ignore the response interceptor that forwards the reponse data only.
  instance.interceptors.request.forEach(h => rawApi.interceptors.request.use(h.fulfilled, h.rejected));
  instance.interceptors.response.forEach(h => rawApi.interceptors.response.use(h.fulfilled === forwardResponseData ? undefined : h.fulfilled, h.rejected));

  return rawApi;
}

/**
 * Public api instance.
 */
const api: ApiAxiosInstance = Object.assign(instance, {
  raw<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>>
  {
    const rawApi = createRawInstance();

    return rawApi.request({
      ...config,
      adapter: cfg => (config.adapter || instance.defaults.adapter!)(Object.assign(cfg, { $isRaw: true })),
    });
  },
  upload<T = any>(config: AxiosRequestConfig): Promise<T>
  {
    return api.request({
      ...config,
      transformRequest: _.castArray(config.transformRequest || []).concat(transformRequestToFormData),
    });
  },
  download(config: AxiosRequestConfig): Promise<void>
  {
    const rawApi = createRawInstance();

    return rawApi.request({
      method: 'GET',
      headers: {
        Accept: '*/*',
        ...config.headers,
      },
      ...config,
      responseType: 'blob',
    })
      .then(response =>
        {
          const contentDisposition: string = response.headers['content-disposition'];

          // extract the file name
          let fileName = '';
          if (contentDisposition)
          {
            const match = /filename\s*\=\s*\"([^\"]+)\"/i.exec(contentDisposition);
            if (match)
            {
              fileName = match[1];
            }
          }

          if (!fileName)
          {
            const contentType: string = response.headers['content-type'];
            if (contentType)
            {
              const slashPos = contentType.indexOf('/');
              fileName = `file.${(slashPos > -1 ? contentType.substr(slashPos + 1) : '')}`;
            }
          }

          // create an invisible link element and click it
          const el = document.createElement('a');
          el.style.display = 'none';
          el.href = URL.createObjectURL(response.data);
          el.download = fileName;
          document.body.appendChild(el);
          el.click();
          setTimeout(() => document.body.removeChild(el));
        });
  },
});

api.interceptors.request.use(apiRequestInterceptor);
api.interceptors.response.use(forwardResponseData, err => Promise.reject(parseError(err)));

export default api;

/**
 * Normalizes an ajax error.
 * @param error The error of the ajax call.
 * @param defaultMsg The default message if none is found.
 */
function parseError<T = any>(error: any, defaultMsg?: string): IAxiosError<T>
{
  if (!error)
  {
    return Object.assign(new Error(defaultMsg || 'Unknown error'), {
      config: {},
      isAxiosError: false,
      isApiError: true,
      toJSON: () => new Object('Unknown error'),
    });
  }
  else if (error.isApiError || axios.isCancel(error))
  {
    return error;
  }
  else if (error.isAxiosError)
  {
    return Object.assign(error, {
      statusCode: error.response ? error.response.status : undefined,
      message: error.message || (error.response ? error.response.statusText : undefined) || defaultMsg || 'Unknown error',
      isApiError: true,
    });
  }
  else if (error instanceof Error)
  {
    return Object.assign(error, {
      config: {},
      isAxiosError: false,
      message: error.message || defaultMsg || 'Unknown error',
      isApiError: true,
      toJSON: () => new Object(error.message || defaultMsg || 'Unknown error'),
    });
  }
  else
  {
    return Object.assign(new Error(error.toString() || defaultMsg || 'Unknown error'), {
      config: {},
      isAxiosError: false,
      isApiError: true,
      toJSON: () => new Object(error.toString() || defaultMsg || 'Unknown error'),
    });
  }
}
