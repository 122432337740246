import { ReportJson, ReportState, ReportTimeGroup } from './json';
import { NewEntityProperties, EntityPagingStore, createEntityPagingStore, EntityPagingStoreState, EntityId, getId } from '@/base';
import { api } from '@/plugins';

export enum Actions {
  DOWNLOAD_REPORT = 'dispatchDownloadReport',
}

export interface State extends EntityPagingStoreState<ReportJson> {}

export interface Store extends EntityPagingStore<ReportJson> {
  /**
   * Downloads a created Excel File .
   */
  [Actions.DOWNLOAD_REPORT](report: EntityId<ReportJson>): Promise<void>;
}

const apiBaseUrl = 'reports';

function createItem(): NewEntityProperties<ReportJson> {
  const fromDateInit = new Date();
  fromDateInit.setDate(1);
  fromDateInit.setMonth(fromDateInit.getMonth() - 1);

  const toDateInit = new Date();
  toDateInit.setDate(1);
  toDateInit.setDate(toDateInit.getDate() - 1);

  return {
    reportState: ReportState.CREATED,
    fromDate: fromDateInit,
    toDate: toDateInit,
    isGroupedByLanguage: true,
    isGroupedByBusinessUnit: true,
    isGroupedByContractType: false,
    isGroupedByContractNo: false,
    isGroupedByTranslator: false,
    isGroupedByCustomer: false,
    isGroupedByMoneyOrigin: false,
    isGroupedByAssignmentState: false,
    isGroupedByFinancialDocumentType: false,
    reportTimeGroup: ReportTimeGroup.MONTH,
  };
}

export default createEntityPagingStore<ReportJson, State, Store>('report', apiBaseUrl, createItem, {}, moduleBuilder => {
  return {
    [Actions.DOWNLOAD_REPORT]: moduleBuilder.dispatch(({ state }, report: EntityId<ReportJson>) => {
      return api.download({
        method: 'GET',
        url: `${apiBaseUrl}/${getId(report)}/reportxlsx`,
        headers: {
          Accept:
            'application/pdf application/vnd.ms-excel, application/vnd.ms-excel.addin.macroenabled.12, application/vnd.ms-excel.sheet.binary.macroenabled.12, application/vnd.ms-excel.template.macroenabled.12, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.template, application/vnd.ms-excel.sheet.macroenabled.12',
        },
      });
    }, Actions.DOWNLOAD_REPORT),
  };
});
