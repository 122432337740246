import { BaseEntity } from '@/base';
import { InvoiceState } from '../financialdocument/invoice';
import { ContractState, ContractType } from '../contract';
import { BankAccountTransactionAssignmentState } from '../bankaccounttransaction';

export enum ReportState {
  CREATED = 'CREATED',
  CREATION_FAILED_DATA_COLLECT = 'CREATION_FAILED_DATA_COLLECT',
  CREATION_FAILED_PDF_CREATION = 'CREATION_FAILED_PDF_CREATION',
  CREATION_FINISHED = 'CREATION_FINISHED',
}

export enum ReportTimeGroup {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum ReportMoneyOrigin {
  BANK = 'BANK',
  CASH = 'CASH',
}

export enum FinancialDocumentType {
  CREDIT = 'CREDIT',
  INVOICE = 'INVOICE',
  EXTERNAL_DOCUMENT = 'EXTERNAL_DOCUMENT',
}

export enum InvoicePaymentState {
  PAID,
  NOT_PAID,
}

export interface ReportJson extends BaseEntity {
  readonly reportState: ReportState;
  readonly fromDate: Date;
  readonly toDate: Date;

  // groupings
  readonly isGroupedByLanguage: boolean;
  readonly isGroupedByBusinessUnit: boolean;
  readonly isGroupedByContractType: boolean;
  readonly isGroupedByContractNo: boolean;
  readonly isGroupedByContractState: boolean;
  readonly isGroupedByTranslator: boolean;
  readonly isGroupedByCustomer: boolean;
  readonly isGroupedByMoneyOrigin: boolean;
  readonly isGroupedByAssignmentState: boolean;
  readonly isGroupedByFinancialDocumentType: boolean;
  readonly isGroupedByInvoiceState: boolean;
  readonly isGroupedByInvoicePaymentState: boolean;
  readonly isGroupedByOperationLocation: boolean;
  readonly reportTimeGroup: ReportTimeGroup;

  // filter;
  readonly reportMoneyOrigin: ReportMoneyOrigin | null;
  readonly bankAccountTransactionAssignmentState: BankAccountTransactionAssignmentState | null;
  readonly financialDocumentType: FinancialDocumentType  | null;
  readonly businessUnitId: number | null;
  readonly businessUnitName: string | null;
  readonly languageId: number | null;
  readonly languageName: string | null;
  readonly contractType: ContractType | null;
  readonly contractNo: number | null;
  readonly contractState: ContractState | null;
  readonly invoiceState: InvoiceState | null;
  readonly invoicePaymentState: InvoicePaymentState | null;
  readonly customerId: number | null;
  readonly customerName: string | null;
  readonly translatorId: number | null;
  readonly translatorName: string | null;
  readonly operationLocation: string | null;
}
