import { BaseEntity } from '@/base';

export enum SystemConfigurationKey
{
  INITIAL_LANGUAGE_GROUPS = 'INITIAL_LANGUAGE_GROUPS',
  REPORT_DISTRICT_COURT = 'REPORT_DISTRICT_COURT',
  REPORT_TAX_NUMBER = 'REPORT_TAX_NUMBER',
  REPORT_TAX_OFFICE = 'REPORT_TAX_OFFICE',
  REPORT_HOMEPAGE_URL = 'REPORT_HOMEPAGE_URL',
  REPORT_HEADQUARTER = 'REPORT_HEADQUARTER',
  REPORT_MANAGER = 'REPORT_MANAGER',
  REPORT_HOTLINE_24H = 'REPORT_HOTLINE_24H',
  EMAIL_SEND_ENABLED = 'EMAIL_SEND_ENABLED',
  EMAIL_SEND_ENABLED_FOR_AUTOMATIC_ACTIVATION = 'EMAIL_SEND_ENABLED_FOR_AUTOMATIC_ACTIVATION',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  EMAIL_USER_NAME = 'EMAIL_USER_NAME',
  EMAIL_PASSWORD = 'EMAIL_PASSWORD',
  EMAIL_SERVER_HOST = 'EMAIL_SERVER_HOST',
  EMAIL_SMTP_PORT = 'EMAIL_SMTP_PORT',
  PDF_LOGO = 'PDF_LOGO',
  SALES_TAX_ID = 'SALES_TAX_ID',
  WEBDAV_URL = 'WEBDAV_URL',
  WEBDAV_USERNAME = 'WEBDAV_USERNAME',
  WEBDAV_PASSWORD = 'WEBDAV_PASSWORD',
  BASE_URL = 'BASE_URL',
  DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS = 'DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS',
}

export interface SystemConfigurationJson extends BaseEntity
{
  key: SystemConfigurationKey;
  value: string;
  readonly description: string;
}
