
import { EntityList, EntityStore, EntityStoreActions, EntityStoreGetters, ListAction } from '../../../base';
import { EmailTemplateJson, EmailTemplateType, store as emailTemplateStore } from '../../emailtemplate';
import { UserType, UserPermission } from '../../user';
import { ContractJson, ContractState } from '../json';
import { TranslatorJson } from '../../translator';
import { EntitySelectCustomFilter } from '../../../components/EntitySelectCustomFilter';
import { InvoiceJson } from '../../financialdocument/invoice';
import { CreditJson } from '../../financialdocument/credit';
import { store as mainStore, Getters } from '@/store';
import { store as businessUnitStore } from '@/modules/businessunit';
import { store as languageStore, getLanguageItemComparator } from '@/modules/language';
import { store as customerStore, CustomerJson } from '@/modules/customer';
import { store as translatorStore } from '@/modules/translator';
import { store as invoiceStore, Actions as InvoiceActions } from '@/modules/financialdocument/invoice';
import { store as creditStore, Actions as CreditActions } from '@/modules/financialdocument/credit';

class TranslatorLanguageCustomFilter implements EntitySelectCustomFilter {
  private contract: ContractJson;

  constructor(contract: ContractJson) {
    this.contract = contract;
  }
  public filter(item: TranslatorJson, queryText: string, itemText: string): boolean {
    if (item.languages)
    {
      return item.languages.filter(l => this.contract.translatorLanguage && l.id === this.contract.translatorLanguage.id).length === 1;
    }
    return false;
  }
}

export default abstract class ContractList<T extends ContractJson, S extends EntityStore<T>> extends EntityList<T, S> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.CONTRACT_WRITE);
  }

  protected showUsageDialog = false;

  protected readonly businessUnitStore = businessUnitStore;
  protected readonly languageStore = languageStore;
  protected readonly customerStore = customerStore;
  protected readonly translatorStore = translatorStore;
  protected readonly invoiceStore = invoiceStore;
  protected readonly creditStore = creditStore;

  private readonly getLanguageItemComparator = getLanguageItemComparator;

  protected getTranslatorLanguageFilter(item: ContractJson): EntitySelectCustomFilter {
    return new TranslatorLanguageCustomFilter(item);
  }

  protected contentDialog = '';

  protected handleIconClick(subject: string): void {
    this.contentDialog = subject;
  }

  protected showSetStateAdmin = false;

  protected setStateAdmin(): void {
    this.showSetStateAdmin = true;
  }

  protected async setNewStateBase(arg: any) {
    const state = arg as ContractState;
    this.showSetStateAdmin = false;
    this.setNewState(state).then(res => this.refresh());
  }

  protected abstract setNewState(state: ContractState): Promise<void>;

  protected readonly ContractState = ContractState;

  protected isAllowForAdmins(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.ADMIN;
  }

  protected isAllowForAdminsAndOfficeWorkers(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.OFFICE_WORKER || currentUserType === UserType.ADMIN;
  }

  protected isAllowedForAdminsAndOfficeWorkersAndTranslators(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.TRANSLATOR || this.isAllowForAdminsAndOfficeWorkers();
  }

  protected isAllowedForAdminsAndOfficeWorkersAndCustomers(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.CUSTOMER || this.isAllowForAdminsAndOfficeWorkers();
  }

  protected getFullNameCredit(item: CreditJson): string
  {
    return item ? item.id + ' - ' + item.creditReference : '';
  }

  protected getFullNameInvoice(item: InvoiceJson): string
  {
    return item ? item.id + ' - ' + item.invoiceReference : '';
  }

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<T>[] {
    const res: ListAction<T>[] = [];
    res.push(this.getDialogActionEdit());

    if (this.hasPermission(UserPermission.ACCOUNTING_READ))
    {
      res.push(this.getDialogActionShowInvoice());
      res.push(this.getDialogActionShowCredit());
    }

    if (this.hasWritePermission())
    {
      res.push(this.getDialogActionSendEmailToTranslator());
      res.push(this.getDialogActionSendEmailToCustomer());
    }

    return res;
  }

  private getDialogActionEdit(): ListAction<T>{
    return {
        icon: 'edit',
        handler: item => this.editItem(item),
        isVisible: item => item.id > 0,
        text: this.$root.$t('edit.label').toString(),
      };
  }

  private getDialogActionShowInvoice(): ListAction<T>{
    return {
        icon: 'invoiceDetail',
        handler: item => this.showInvoice(item),
        isVisible: item => item && item.isInvoiceCreated && this.isAllowedForAdminsAndOfficeWorkersAndCustomers(),
        text: this.$t('list.actions.showInvoice').toString(),
      };
  }

  private getDialogActionShowCredit(): ListAction<T>{
    return {
        icon: 'creditDetail',
        handler: item => this.showCredit(item),
        isVisible: item => item && item.isCreditCreated && this.isAllowedForAdminsAndOfficeWorkersAndTranslators(),
        text: this.$t('list.actions.showCredit').toString(),
      };
  }

  private getDialogActionSendEmailToTranslator(): ListAction<T>{
    return {
        icon: 'sendEmail',
        handler: item => this.showSendEmailDialog(item, true),
        isVisible: item => this.isAllowForAdminsAndOfficeWorkers(),
        text: this.$t('list.actions.sendEmailToTranslator').toString(),
      };
  }

  private getDialogActionSendEmailToCustomer(): ListAction<T>{
    return {
        icon: 'sendEmailOutline',
        handler: item => this.showSendEmailDialog(item, false),
        isVisible: item => this.isAllowForAdminsAndOfficeWorkers(),
        text: this.$t('list.actions.sendEmailToCustomer').toString(),
      };
  }

  protected getContractStateDesc(item: ContractJson): string {
    const stateDesc = this.$t(`enums.ContractState.${item.contractState}`).toString();

    if (item.isInvoiceCreated && item.isCreditCreated) {
      return stateDesc + ' (' + this.$t('list.invoiceAndCreditCreated') + ')';
    }

    if (item.isInvoiceCreated) {
      return stateDesc + ' (' + this.$t('list.invoiceCreated') + ')';
    }

    if (item.isCreditCreated) {
      return stateDesc + ' (' + this.$t('list.creditCreated') + ')';
    }

    return stateDesc;
  }

  protected canFulfillContractByState(state: ContractState): boolean {
    return state === ContractState.TRANSLATOR_CONFIRMED && this.isAllowForAdminsAndOfficeWorkers();
  }

  protected canCancelContractByState(state: ContractState): boolean {
    return (
      (state === ContractState.CREATED || state === ContractState.TRANSLATOR_ASSIGNED || state === ContractState.TRANSLATOR_CONFIRMED) && this.isAllowForAdminsAndOfficeWorkers()
    );
  }

  protected canAssignTranslatorByState(state: ContractState): boolean {
    return (state === ContractState.CREATED || state === ContractState.TRANSLATOR_ASSIGNED) && this.isAllowForAdminsAndOfficeWorkers();
  }

  protected showInvoice(item: ContractJson): void {
    this.$router.push(
      {
        path: `${item.id}/invoice?contractNo=${item.contractNo}`,
        append: true,
      },
      this.$_.noop,
      this.$_.noop,
    );
  }

  protected showCredit(item: ContractJson): void {
    this.$router.push(
      {
        path: `${item.id}/credit?contractNo=${item.contractNo}`,
        append: true,
      },
      this.$_.noop,
      this.$_.noop,
    );
  }

protected refreshSelectedItems()
{
  const selectedIds = this.selectedItems.map(c => c.id);
  this.refresh(); // reload states
  this.selectedItems = this.items.filter(c => selectedIds.filter(id => id === c.id).length > 0);
}

private showModalWarning = false;
private contentModalWarning: string | null = null;
private showModalWarningCredit = false;
private showModalWarningInvoice = false;

/**
 * Creates Invoice for the selected contracts.
 */
private async createInvoiceBase()
{
  this.refreshSelectedItems();
  if (this.selectedItems.length > 0)
  {
    if (this.hasMoreThanOneCustomer)
    {
      this.contentModalWarning = this.$t('warningDialog.hasMoreThanOneCustomer') as string;
      this.showModalWarning = true;
    }
    else if (this.isInvoiceAlreadyCreated)
    {
      this.contentModalWarning = this.$t('warningDialog.isInvoiceAlreadyCreated') as string;
      this.showModalWarningInvoice = true;
    }
    else
    {
      this.createInvoice().then(res => this.refresh());
    }
  }
}

protected abstract createInvoice(): Promise<void>;

private closeModalWarningInvoice(forcedCreateInvoice: boolean) {
  this.showModalWarningInvoice = false;
  if (forcedCreateInvoice)
  {
    this.createInvoice();
    this.refresh(); // reload states of invoices (TODO: update state in action?)
  }
}

/**
 * Creates Credit for the selected contracts.
 */
private async createCreditBase()
{
  this.refreshSelectedItems();

  if (this.selectedItems.length > 0)
  {
    if (this.hasMoreThanOneTranslator)
    {
      this.contentModalWarning = this.$t('warningDialog.hasMoreThanOneTranslator') as string;
      this.showModalWarning = true;
    }
    else if (this.isCreditAlreadyCreated)
    {
      this.contentModalWarning = this.$t('warningDialog.isCreditAlreadyCreated') as string;
      this.showModalWarningCredit = true;
    }
    else
    {
      this.createCredit().then(res => this.refresh());
    }
  }
}

protected abstract createCredit(): Promise<void>;

private closeModalWarningCredit(forcedCreateCredit: boolean) {
  this.showModalWarningCredit = false;
  if (forcedCreateCredit)
  {
    this.createCredit();
    this.refresh(); // reload states of invoices (TODO: update state in action?)
  }
}

private get hasMoreThanOneCustomer(): boolean
{
  const selectedCustomerIds = this.selectedItems.map(c => c.customer).map(c => c.id);
  const selectedCustomerIdsSet = new Set(selectedCustomerIds);
  return selectedCustomerIdsSet.size > 1;
}

private get hasMoreThanOneTranslator(): boolean
{
  const selectedTranslatorIds = this.selectedItems.filter(c => c.translator).map(c => c.translator as TranslatorJson).map(t => t.id);
  const selectedTranslatorIdSet = new Set(selectedTranslatorIds);
  return selectedTranslatorIdSet.size > 1;
}

private get isCreditAlreadyCreated(): boolean { return this.selectedItems.filter(c => c.isCreditCreated).length > 0; }

private get isInvoiceAlreadyCreated(): boolean { return this.selectedItems.filter(c => c.isInvoiceCreated).length > 0; }

  // E-mails

  /**
   * Variable used to display the options in the email dialog.
   */
  protected emailTemplatesForContract: {
    contract: T;
    emailTemplates: EmailTemplateJson[];
    isTranslator: boolean;
  } | null = null;

  protected showSendEmailDialog(item: T, isTranslator: boolean): void {
    emailTemplateStore[EntityStoreActions.READ_ALL](true);
    const res = emailTemplateStore[EntityStoreGetters.ITEMS].filter(t => t.emailTemplateType === this.getEmailTemplateType());

    this.emailTemplatesForContract = {
      contract: item,
      emailTemplates: res,
      isTranslator,
    };
  }

  protected abstract getEmailTemplateType(): EmailTemplateType;

  protected sendEmail(item: T, emailTemplate: EmailTemplateJson, isTranslator: boolean): void {
    const emailPath = isTranslator ? 'email/translator' : 'email/customer';
    // forward to route
    this.$router
      .push({
        path: `${item.id}/${emailPath}/${emailTemplate.id}`,
        append: true,
      })
      // close dialog
      .then(() => (this.emailTemplatesForContract = null));
  }
}
