var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"list",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading ? 'secondary' : false,"loading-text":_vm.$root.$t('list.loading'),"custom-sort":_vm.sortItems,"must-sort":_vm.internalOptions.mustSort,"options":_vm.internalOptions,"hide-default-footer":!_vm.showFooter,"server-items-length":_vm.paginationInfo.hasPagination ? _vm.paginationInfo.totalItems : -1,"footer-props":{ disablePagination: !_vm.paginationInfo.hasPagination, showFirstLastPage: true, itemsPerPageOptions: [10, 20, 50, 100]},"show-select":_vm.selectionMode != 'NONE',"single-select":_vm.selectionMode == 'SINGLE'},on:{"update:options":function($event){_vm.internalOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.actions),function(action,index){return (!action.isVisible || action.isVisible(item))?_c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-sm-1 mr-1 pa-0",staticStyle:{"min-width":"0"},attrs:{"color":"primary","ripple":false,"small":"","text":true,"fab":false,"color":action.color === undefined ? 'primary' : action.color,"disabled":action.isDisabled && action.isDisabled(item)},on:{"click":function($event){return action.handler(item)}}},action.text ? on : undefined),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons."+_vm._s(action.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.text))])]):_vm._e()})}},{key:"item.assignedPriceNet",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('currency-field',{attrs:{"label":_vm.getLabel('edit.assignedPriceNet.label'),"single-line":"","nullable":false,"allowNegative":true},model:{value:(item.assignedPriceNet),callback:function ($$v) {_vm.$set(item, "assignedPriceNet", _vm._n($$v))},expression:"item.assignedPriceNet"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(_vm.$n(value, 'currency')))])]}},{key:"item.assignedPriceGross",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('currency-field',{attrs:{"label":_vm.getLabel('edit.assignedPriceGross.label'),"single-line":"","nullable":false,"allowNegative":true},model:{value:(item.assignedPriceGross),callback:function ($$v) {_vm.$set(item, "assignedPriceGross", _vm._n($$v))},expression:"item.assignedPriceGross"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(_vm.$n(value, 'currency')))])]}},{key:"item.entity.businessUnit",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(value.name))])]}},{key:"item.entity",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(true)?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('entity-select',{attrs:{"store":_vm.entityStore,"return-object":"","item-value":"id","item-text":_vm.getFullName,"label":_vm.getFinancialDocumentLabel(),"single-line":"","value":value},model:{value:(item.entity),callback:function ($$v) {_vm.$set(item, "entity", $$v)},expression:"item.entity"}})]},proxy:true}],null,true)},[(value)?[_c('span',[_vm._v(_vm._s(_vm.getFullName(value)))])]:[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_vm._v("$vuetify.icons.add")]),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t('list.edit.externalDocument.assign')))])]],2):[_c('span',[_vm._v(_vm._s(_vm._f("fullName")(value)))])]]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"add-row"},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('td',{attrs:{"colspan":"1"}}):_vm._e(),_c('td',{staticClass:"pt-2 pt-sm-0",class:{ 'v-data-table__mobile-row': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-edit-dialog',{on:{"save":function($event){return _vm.addItemIf()},"cancel":function($event){_vm.newEntity = null},"close":function($event){return _vm.addItemIf()},"open":function($event){_vm.newEntity = null}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('entity-select',{attrs:{"store":_vm.entityStore,"return-object":"","item-value":"id","item-text":_vm.getFullName,"label":_vm.getFinancialDocumentLabel(),"single-line":""},model:{value:(_vm.newEntity),callback:function ($$v) {_vm.newEntity=$$v},expression:"newEntity"}})]},proxy:true}])},[(_vm.hasWritePermission())?[_c('v-tooltip',{attrs:{"bottom":"","internal-activator":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-sm-1 mr-1 pa-0",staticStyle:{"min-width":"0"},attrs:{"color":"primary","ripple":false,"small":"","text":true,"fab":false}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.add")]),_vm._v(_vm._s(_vm.getLabel("edit.addBankTransactionPosition.label")))],1)]}}],null,false,1455177578)},[_c('span',[_vm._v(_vm._s(_vm.getLabel("edit.addBankTransactionPosition.label")))])])]:_vm._e()],2)],1),(!_vm.$vuetify.breakpoint.xsOnly)?_c('td',{attrs:{"colspan":"3"}}):_vm._e()])]},proxy:true}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})}
var staticRenderFns = []

export { render, staticRenderFns }