import { RouteConfig } from 'vue-router';
import Form from './views/Form.vue';
import List from './views/List.vue';

export default [
  // list
  {
    name: 'starmoneyimport',
    path: '',
    component: List,
  },
  // edit
  {
    path: ':id(new)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
] as RouteConfig[];
