import { CustomerLanguageGroupJson } from './json';

export function getCustomerLanguageGroupDescription(item: CustomerLanguageGroupJson, fallbackToId?: boolean): string
{
  const name = item.name || (fallbackToId ? `(ID: ${item.id})` : '');
  const languages = (item.languages || []).map(l => l.name);

  if (languages.length > 0)
  {
    return `${name} (${languages.join(', ')})`;
  }

  return name;
}
