





















































































































































import axios, { CancelTokenSource } from 'axios';
import { Component } from 'vue-property-decorator';
import { EntityList, ListAction, HeaderConfig, EntityStoreGetters, EntityStoreActions, SelectionMode } from '@/base';
import { ContractState, InvoiceFilter, CreditFilter } from '../../json';
import { VideoTranslationContractJson } from '../json';
import { TranslatorJson, store as translatorStore } from '@/modules/translator';
import { getFullName, UserJson, UserType } from '@/modules/user';
import store, { Actions } from '../store';
import { store as invoiceStore, Actions as InvoiceActions } from '@/modules/financialdocument/invoice';
import { store as mainStore, Getters } from '@/store';

@Component<List>({
  filters: {
    fullName(item: UserJson | null, fallbackToId?: boolean): string
    {
      return item ? getFullName(item, fallbackToId) : '';
    },
  },
  created()
  {
    this.init();
  },
  methods: {
    getFullName,
  },
})
export default class List extends EntityList<VideoTranslationContractJson, typeof store>
{
  private showUsageDialog = false;

  private contentDialog = '';

  private handleIconClick(subject: string): void {
    this.contentDialog = subject;
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;
  public sortState = [{ property: 'contractNo' }];
  public selectionMode = SelectionMode.MULTI;

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    contractNo: '',
    customerId: null as number | null,
    translatorId: null as number | null,
    businessUnitId: null as number | null,
    contractState: null as ContractState | null,
    contractDateFrom: null as Date | null,
    contractDateUntil: null as Date | null,
    translatorLanguageId: null as number | null,
    invoiceFilter: null as InvoiceFilter | null,
    creditFilter: null as CreditFilter | null,
    invoiceId: null as number | null,
    creditId: null as number | null,
  };

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    const contractNo: HeaderConfig = {
        value: 'contractNo',
        text: this.$t('list.header.contractNo').toString(),
      };

    const contractState: HeaderConfig =  {
        value: 'contractState',
        text: this.$t('list.header.contractState').toString(),
        filterProperty: ['contractState', 'invoiceFilter', 'creditFilter'],
      };

    const businessUnit: HeaderConfig = {
        value: 'businessUnit.name',
        text: this.$t('list.header.businessUnit').toString(),
        remoteSort: desc => [{ property: 'businessUnit.id', desc }],
        filterProperty: 'businessUnitId',
      };

    const customer: HeaderConfig = {
        value: 'customer',
        text: this.$t('list.header.customer').toString(),
        remoteSort: desc => [
          { property: 'customer.lastName', desc },
          { property: 'customer.firstName', desc },
        ],
        filterProperty: 'customerId',
      };

    const translator: HeaderConfig = {
        value: 'translator',
        text: this.$t('list.header.translator').toString(),
        remoteSort: desc => [
          { property: 'translator.lastName', desc },
          { property: 'translator.firstName', desc },
        ],
        filterProperty: 'translatorId',
      };

    const translatorLanguage: HeaderConfig = {
        value: 'translatorLanguage.name',
        text: this.$t('list.header.translatorLanguage').toString(),
        filterProperty: 'translatorLanguageId',
      };

    const contractDate: HeaderConfig = {
        value: 'contractDate',
        text: this.$t('list.header.contractDate').toString(),
        filterProperty: ['contractDateFrom', 'contractDateUntil'],
      };

    const contractComment: HeaderConfig = {
      value: 'contractComment',
      text: this.$t('list.header.contractComment').toString(),
      sortable: false,
      filterProperty: ['contractComment'],
    };

    if (this.isAllowForAdminsAndOfficeWorkers())
    {
      return [contractNo, contractState, businessUnit, customer, translator, translatorLanguage, contractDate, contractComment];
    }
    return [contractNo, contractState, businessUnit, customer, translator, translatorLanguage, contractDate];
  }

  private isAllowForAdminsAndOfficeWorkers(): boolean
  {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.OFFICE_WORKER || currentUserType === UserType.ADMIN;
  }

  /**
   * Creates an invoice or the selected contracts.
   */
  private async createInvoice()
  {
    if (this.selectedItems.length > 0)
    {
      const invoice = await invoiceStore[InvoiceActions.CREATE_VIDEO_TRANSLATION_CONTRACT]({data: this.selectedItems, module: this.$t('') as string });
    }
  }

  private canAssignTranslatorByState(state: ContractState): boolean
  {
    return (state === ContractState.CREATED || state === ContractState.TRANSLATOR_ASSIGNED) && this.isAllowForAdminsAndOfficeWorkers();
  }

  private async assignTranslator(item: VideoTranslationContractJson, translator: TranslatorJson): Promise<void>
  {
    await store[Actions.ASSIGN_TRANSLATOR]({
      contract: item,
      translator,
    });
  }

  private async confirmTranslator(item: VideoTranslationContractJson): Promise<void>
  {
    await store[Actions.SET_STATE]({
      contract: item,
      state: ContractState.TRANSLATOR_CONFIRMED,
    });
  }

  private canFulfillContractByState(state: ContractState): boolean
  {
    return state === ContractState.TRANSLATOR_CONFIRMED && this.isAllowForAdminsAndOfficeWorkers();
  }

  private async fulfillContract(item: VideoTranslationContractJson): Promise<void>
  {
    await store[Actions.SET_STATE]({
      contract: item,
      state: ContractState.CONTRACT_FULFILLED,
    });
  }

  private canCancelContractByState(state: ContractState): boolean
  {
    return (state === ContractState.CREATED || state === ContractState.TRANSLATOR_ASSIGNED || state === ContractState.TRANSLATOR_CONFIRMED)
    && this.isAllowForAdminsAndOfficeWorkers();
  }

  private async cancelContract(item: VideoTranslationContractJson): Promise<void>
  {
    const msg = await this.$msg(
      this.$t('list.edit.contractState.confirmCancel.title', item).toString(),
      this.$t('list.edit.contractState.confirmCancel.message', item).toString(),
      'no',
      {
        id: 'yes',
        color: 'delete',
        text: this.$t('list.edit.contractState.confirmCancel.yes.label', item).toString(),
      },
    );

    if (msg === 'yes')
    {
      await store[Actions.SET_STATE]({
        contract: item,
        state: ContractState.CANCELED,
      });
    }
  }

  private showInvoice(item: VideoTranslationContractJson): void
  {
    this.$router.push({
      path: `${item.id}/invoice?contractNo=${item.contractNo}`,
      append: true,
    }, this.$_.noop, this.$_.noop);
  }

  private readonly ContractState = ContractState;

  private readonly InvoiceFilter = InvoiceFilter;

  private readonly CreditFilter = CreditFilter;
}
