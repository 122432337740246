import { api } from '@/plugins';
import { BankAccountTransactionJson, BankAccountTransactionAssignmentState } from './json';
import { NewEntityProperties, EntityPagingStore, createEntityPagingStore, EntityPagingStoreState, commitEntity } from '@/base';

export enum Actions {
  ACCEPT_INCOMPLETE_ASSIGNMENT = 'dispatchAcceptIncompleteAssignment',
}

export interface State extends EntityPagingStoreState<BankAccountTransactionJson>
{

}

export interface Store extends EntityPagingStore<BankAccountTransactionJson>
{
  [Actions.ACCEPT_INCOMPLETE_ASSIGNMENT](payload: { bankAccountTransaction: BankAccountTransactionJson }): Promise<BankAccountTransactionJson>;
}

const apiBaseUrl = 'bankaccounttransactions';

function createItem(): NewEntityProperties<BankAccountTransactionJson>
{
  // should be never called
  return {
    btIdentity: null,
    btValue: null,
    amountOfBalance: null,
    btUsage: null,
    transactionDate: null,
    businessUnit: null,
    bankAccount: null,
    bankAccountTransactionInvoicePositions: [],
    bankAccountTransactionCreditPositions: [],
    bankAccountTransactionExternalDocumentPositions: [],
    bankAccountTransactionAssignmentState: BankAccountTransactionAssignmentState.NOT_ASSIGNED,
  };
}

export const store = createEntityPagingStore<BankAccountTransactionJson, State, Store>(
  'bankaccounttransaction',
  apiBaseUrl,
  createItem,
  {},
  (moduleBuilder) =>
  {
     return {
      [Actions.ACCEPT_INCOMPLETE_ASSIGNMENT]: moduleBuilder.dispatch(async ({ state }, { bankAccountTransaction }: Parameters<Store[Actions.ACCEPT_INCOMPLETE_ASSIGNMENT]>[0]) => {
        const result = await api.put<BankAccountTransactionJson>(`${apiBaseUrl}/${bankAccountTransaction.id}/acceptincompleteassignment`);
        const committed = commitEntity(bankAccountTransaction, result);
        state.items.set(committed);
        return committed;
      }, Actions.ACCEPT_INCOMPLETE_ASSIGNMENT),
     };
  });
