import { ExternalDocumentJson } from './json';
import { NewEntityProperties, EntityId, getId, FormData, EntityPagingStoreState, EntityPagingStore, createEntityPagingStore} from '@/base';
import { FileEntityCollectionJson, FileEntityJson } from '../../file';
import api from '@/plugins/api';

export enum Actions
{
  READ_FILE_ATTACHMENT_COLLECTION = 'dispatchReadFileAttachmentCollection',
  UPLOAD_FILE = 'dispatchUploadFile',
  DELETE_FILE = 'deleteUploadFile',
  DOWNLOAD_FILE = 'dispatchDownloadFile',
  DOWNLOAD_FILES_AS_ZIP_CHECK = 'dispatchDownloadFilesAsZipCheck',
  DOWNLOAD_FILES_AS_ZIP = 'dispatchDownloadFilesAsZip',
}

export enum Mutations
{
  FILE_ATTACHMENT_COLLECTION = 'commitFileAttachmentCollection',
}

export interface State extends EntityPagingStoreState<ExternalDocumentJson>
{
  fileEntityCollections: _.NumericDictionary<FileEntityCollectionJson>;
}

export interface Store extends EntityPagingStore<ExternalDocumentJson>
{
  [Actions.READ_FILE_ATTACHMENT_COLLECTION](
    // tslint:disable-next-line:max-line-length
    payload: EntityId<ExternalDocumentJson> | { externalDocumentJson: EntityId<ExternalDocumentJson>; }): Promise<FileEntityCollectionJson>;
  [Actions.UPLOAD_FILE](
    payload: {externalDocumentJson: FormData<ExternalDocumentJson>, file: File }): Promise<FileEntityCollectionJson>;
  [Actions.DELETE_FILE](
    payload: {externalDocumentJson: EntityId<ExternalDocumentJson>, fileEntity: FileEntityJson }): Promise<FileEntityCollectionJson>;
  [Actions.DOWNLOAD_FILE](
    payload: {externalDocumentJson: EntityId<ExternalDocumentJson>, fileEntity: FileEntityJson }): Promise<void>;
  /**
   * Returns ExternalDocumentJsons, which has no files attached
   */
  [Actions.DOWNLOAD_FILES_AS_ZIP_CHECK](externalDocuments: ExternalDocumentJson[]): Promise<ExternalDocumentJson[]>;
  /**
   * Downloads Files of external documents as ZIP archive.
   */
  [Actions.DOWNLOAD_FILES_AS_ZIP](externalDocuments: ExternalDocumentJson[]): Promise<void>;
}

const apiBaseUrl = 'externaldocuments';

function createItem(): NewEntityProperties<ExternalDocumentJson>
{
  // should be never called
  return {
    documentReference: null,
    documentDate: null,
    documentCreator: null,
    documentValueNet: null,
    documentValueGross: null,
    businessUnit: null,
    isVatFull: false,
    isVatReduced: false,
    isVatZero: false,
    isIncome: false,
  };
}

export const store =  createEntityPagingStore<ExternalDocumentJson, State, Store>(
  'uploadfinancialdocument',
  apiBaseUrl,
  createItem,
  {
    fileEntityCollections: {},
  },
  (moduleBuilder) =>
  {

    const setFileAttachmentCollection = moduleBuilder.commit(
      // tslint:disable-next-line:max-line-length
      (state, { externalDocumentJson, fileEntityCollection }: { externalDocumentJson: EntityId<ExternalDocumentJson>; fileEntityCollection: FileEntityCollectionJson }) =>
      {
        state.fileEntityCollections[getId(externalDocumentJson)] = fileEntityCollection;
      }, Mutations.FILE_ATTACHMENT_COLLECTION);

    return {
      [Actions.READ_FILE_ATTACHMENT_COLLECTION]: moduleBuilder.dispatch(
        async ({ state }, payload: EntityId<ExternalDocumentJson> | { externalDocumentJson: EntityId<ExternalDocumentJson>; }) =>
        {
          if (typeof payload === 'number' || !('externalDocumentJson' in payload))
          {
            payload = {
              externalDocumentJson: payload,
            };
          }

          const { externalDocumentJson } = payload;
          const externalDocumentJsonId = getId(externalDocumentJson);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${externalDocumentJsonId}/fileattachmentcollection`);
          setFileAttachmentCollection({ externalDocumentJson, fileEntityCollection });

          return state.fileEntityCollections[externalDocumentJsonId];
        }, Actions.READ_FILE_ATTACHMENT_COLLECTION),
      [Actions.UPLOAD_FILE]: moduleBuilder.dispatch(async ({ state }, { externalDocumentJson, file }: Parameters<Store[Actions.UPLOAD_FILE]>[0]) =>
        {
          const result = await api.upload<FileEntityCollectionJson>({
            data: {
              'user-file': file,
            },
            method: 'POST',
            url: `${apiBaseUrl}/${externalDocumentJson.id}/file`,
          });
          state.fileEntityCollections[getId(externalDocumentJson)] = result;
          return result;
        }, Actions.UPLOAD_FILE),
      [Actions.DELETE_FILE]: moduleBuilder.dispatch(async ({ state }, { externalDocumentJson, fileEntity }: Parameters<Store[Actions.DELETE_FILE]>[0]) =>
        {
          const externalDocumentJsonId = getId(externalDocumentJson);
          await api.delete(`${apiBaseUrl}/${externalDocumentJsonId}/file/${fileEntity.id}`);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${externalDocumentJsonId}/fileattachmentcollection`);
          setFileAttachmentCollection({ externalDocumentJson, fileEntityCollection });
          return state.fileEntityCollections[externalDocumentJsonId];
      }, Actions.DELETE_FILE),
      [Actions.DOWNLOAD_FILE]: moduleBuilder.dispatch(async ({ state }, { externalDocumentJson, fileEntity }: Parameters<Store[Actions.DOWNLOAD_FILE]>[0]) =>
        {
          const externalDocumentJsonId = getId(externalDocumentJson);
          return api.download({
            method: 'GET',
            url: `${apiBaseUrl}/${externalDocumentJsonId}/file/${fileEntity.id}`,
          });
      }, Actions.DOWNLOAD_FILE),
      [Actions.DOWNLOAD_FILES_AS_ZIP_CHECK]: moduleBuilder.dispatch(async ({ state }, externalDocuments: ExternalDocumentJson[]) => {
        const result = await api.post<ExternalDocumentJson[]>(`${apiBaseUrl}/documentfilezipcheck`, externalDocuments);
        return result;
      }, Actions.DOWNLOAD_FILES_AS_ZIP_CHECK),
      [Actions.DOWNLOAD_FILES_AS_ZIP]: moduleBuilder.dispatch(({ state }, externalDocuments: ExternalDocumentJson[]) => {
        return api.download({
          data: externalDocuments,
          method: 'POST',
          url: `${apiBaseUrl}/documentfilezip`,
          headers: {
            Accept: 'application/octet-stream',
          },
        });
      }, Actions.DOWNLOAD_FILES_AS_ZIP),
    };
  });

export default store;
