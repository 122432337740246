import { EmailTemplatePlaceholder, EmailTemplateType } from './json';

export function filterPlaceholderTemplateType(placeholder: EmailTemplatePlaceholder, templateType: EmailTemplateType): boolean {
  // First Switch goes through all TemplateTypes, second goes through all TemplateTypes
  switch (templateType) {
    case EmailTemplateType.CONTRACT_DOCUMENT:
      switch (placeholder) {
        case EmailTemplatePlaceholder.TRANSLATOR_NAME:
        case EmailTemplatePlaceholder.TRANSLATOR_NAME:
        case EmailTemplatePlaceholder.CUSTOMER_NAME:
        case EmailTemplatePlaceholder.COMPLETE_UNTIL:
        case EmailTemplatePlaceholder.LINES_OF_ORIGINAL_DOCUMENT:
        case EmailTemplatePlaceholder.LINES_OF_TRANSLATED_DOCUMENT:
        case EmailTemplatePlaceholder.CONTRACT_NO:
        case EmailTemplatePlaceholder.TRANSLATOR_TAXID:
        case EmailTemplatePlaceholder.TRANSLATOR_BANKACCOUNTHOLDER:
        case EmailTemplatePlaceholder.TRANSLATOR_BANKNAME:
        case EmailTemplatePlaceholder.TRANSLATOR_IBAN:
        case EmailTemplatePlaceholder.TRANSLATOR_BIC:
        case EmailTemplatePlaceholder.TRANSLATOR_TRANSLATORCOMMENT:
        case EmailTemplatePlaceholder.TRANSLATOR_ADDRESS:
        case EmailTemplatePlaceholder.TRANSLATOR_ADDRESS_NAME:
        case EmailTemplatePlaceholder.TRANSLATOR_FIRSTNAME:
        case EmailTemplatePlaceholder.TRANSLATOR_LASTNAME:
        case EmailTemplatePlaceholder.TRANSLATOR_EMAIL:
        case EmailTemplatePlaceholder.CUSTOMER_TAXID:
        case EmailTemplatePlaceholder.CUSTOMER_FIRSTNAME:
        case EmailTemplatePlaceholder.CUSTOMER_LASTNAME:
        case EmailTemplatePlaceholder.CUSTOMER_EMAIL:
          return true;
        default:
          return false;
      }
    case EmailTemplateType.CONTRACT_ON_SITE:
      switch (placeholder) {
        case EmailTemplatePlaceholder.TRANSLATOR_NAME:
        case EmailTemplatePlaceholder.CUSTOMER_NAME:
        case EmailTemplatePlaceholder.CONTRACT_NO:
        case EmailTemplatePlaceholder.DATE:
        case EmailTemplatePlaceholder.ADDRESS:
        case EmailTemplatePlaceholder.PLANNED_TRANSLATION_DURATION:
        case EmailTemplatePlaceholder.REAL_TRANSLATION_DURATION:
        case EmailTemplatePlaceholder.TRAVEL_TIME_TO_LOCATION:
        case EmailTemplatePlaceholder.TRAVEL_TIME_BACK:
        case EmailTemplatePlaceholder.TRAVEL_DISTANCE:
        case EmailTemplatePlaceholder.TRANSLATOR_TRAVEL_OPTION:
        case EmailTemplatePlaceholder.CUSTOMER_TRAVEL_OPTION:
        case EmailTemplatePlaceholder.TRAVEL_TICKET_COST:
        case EmailTemplatePlaceholder.ADDRESS_NAME:
          return true;
        default:
          return false;
      }
    case EmailTemplateType.CONTRACT_VIDEO:
      switch (placeholder) {
        // TODO
          // return true;
        default:
          return false;
      }
    case EmailTemplateType.TRANSLATOR:
      switch (placeholder) {
        case EmailTemplatePlaceholder.TRANSLATOR_TAXID:
        case EmailTemplatePlaceholder.TRANSLATOR_BANKACCOUNTHOLDER:
        case EmailTemplatePlaceholder.TRANSLATOR_BANKNAME:
        case EmailTemplatePlaceholder.TRANSLATOR_IBAN:
        case EmailTemplatePlaceholder.TRANSLATOR_BIC:
        case EmailTemplatePlaceholder.TRANSLATOR_TRANSLATORCOMMENT:
        case EmailTemplatePlaceholder.TRANSLATOR_ADDRESS:
        case EmailTemplatePlaceholder.TRANSLATOR_ADDRESS_NAME:
        case EmailTemplatePlaceholder.TRANSLATOR_FIRSTNAME:
        case EmailTemplatePlaceholder.TRANSLATOR_LASTNAME:
        case EmailTemplatePlaceholder.TRANSLATOR_EMAIL:
          return true;
        default:
          return false;
      }
    case EmailTemplateType.CUSTOMER:
      switch (placeholder) {
        case EmailTemplatePlaceholder.CUSTOMER_TAXID:
        case EmailTemplatePlaceholder.CUSTOMER_FIRSTNAME:
        case EmailTemplatePlaceholder.CUSTOMER_LASTNAME:
        case EmailTemplatePlaceholder.CUSTOMER_EMAIL:
        case EmailTemplatePlaceholder.CUSTOMER_TAXID:
        case EmailTemplatePlaceholder.COST_CENTER_NAME:
        case EmailTemplatePlaceholder.CUSTOMER_GROUP:
          return true;
        default:
          return false;
      }
    case EmailTemplateType.INVOICE:
      switch (placeholder) {
        case EmailTemplatePlaceholder.CUSTOMER_NAME:
        case EmailTemplatePlaceholder.INVOICE_REFERENCE:
        case EmailTemplatePlaceholder.INVOICE_DATE:
        case EmailTemplatePlaceholder.INVOICE_STATE:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_CITY:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_ZIPCODE:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_COMPANY:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_FIRST_NAME:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_LAST_NAME:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_STREET:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_COUNTRY:
        case EmailTemplatePlaceholder.SENDER_CONTACT_CITY:
        case EmailTemplatePlaceholder.SENDER_CONTACT_ZIPCODE:
        case EmailTemplatePlaceholder.SENDER_CONTACT_COMPANY:
        case EmailTemplatePlaceholder.SENDER_CONTACT_FIRST_NAME:
        case EmailTemplatePlaceholder.SENDER_CONTACT_LAST_NAME:
        case EmailTemplatePlaceholder.SENDER_CONTACT_STREET:
        case EmailTemplatePlaceholder.SENDER_CONTACT_COUNTRY:
        case EmailTemplatePlaceholder.BUSINESS_UNIT_NAME:
        case EmailTemplatePlaceholder.INVOICE_OPERATION_TIME:
        case EmailTemplatePlaceholder.INVOICE_OPERATION_LOCATION:
        case EmailTemplatePlaceholder.INVOICE_LANGUAGE:
        case EmailTemplatePlaceholder.INVOICE_NOTICE:
        case EmailTemplatePlaceholder.INVOICE_EXPLANATION:
        case EmailTemplatePlaceholder.INVOICE_DOCUMENT_VALUE_NET:
        case EmailTemplatePlaceholder.INVOICE_DOCUMENT_VALUE_GROSS:
        case EmailTemplatePlaceholder.COMMENT:
          return true;
        default:
          return false;
      }
    case EmailTemplateType.CREDIT:
      switch (placeholder) {
        case EmailTemplatePlaceholder.TRANSLATOR_NAME:
        case EmailTemplatePlaceholder.CREDIT_REFERENCE:
        case EmailTemplatePlaceholder.CREDIT_NO:
        case EmailTemplatePlaceholder.CREDIT_DATE:
        case EmailTemplatePlaceholder.CREDIT_DOCUMENT_VALUE_NET:
        case EmailTemplatePlaceholder.CREDIT_IBAN:
        case EmailTemplatePlaceholder.CREDIT_BIC:
        case EmailTemplatePlaceholder.CREDIT_ACCOUNTING_PERIOD_FROM:
        case EmailTemplatePlaceholder.CREDIT_ACCOUNTING_PERIOD_UNTIL:
        case EmailTemplatePlaceholder.CREDIT_BANK_ACCOUNT_HOLDER:
        case EmailTemplatePlaceholder.CREDIT_BANK_NAME:
        case EmailTemplatePlaceholder.COMMENT:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_CITY:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_ZIPCODE:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_COMPANY:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_FIRST_NAME:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_LAST_NAME:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_STREET:
        case EmailTemplatePlaceholder.RECEIVER_CONTACT_COUNTRY:
        case EmailTemplatePlaceholder.SENDER_CONTACT_CITY:
        case EmailTemplatePlaceholder.SENDER_CONTACT_ZIPCODE:
        case EmailTemplatePlaceholder.SENDER_CONTACT_COMPANY:
        case EmailTemplatePlaceholder.SENDER_CONTACT_FIRST_NAME:
        case EmailTemplatePlaceholder.SENDER_CONTACT_LAST_NAME:
        case EmailTemplatePlaceholder.SENDER_CONTACT_STREET:
        case EmailTemplatePlaceholder.SENDER_CONTACT_COUNTRY:
        case EmailTemplatePlaceholder.BUSINESS_UNIT_NAME:
          return true;
        default:
          return false;
      }
    default:
      return false;
  }
}
