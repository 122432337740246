
























import { Component } from 'vue-property-decorator';
import { StarMoneyImportJson } from '../json';
import FormBase from '@/base/EntityForm';
import { store, Actions } from '../store';
import { asFormData } from '../../../base';

const maxFileSize = parseInt(process.env.VUE_APP_UPLOAD_MAX_FILE_SIZE, 10) || 10485760;

@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends FormBase<StarMoneyImportJson, typeof store>
{
  private readonly maxFileSizeInKB = maxFileSize / 1024;

  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'starmoneyimport',
  };

  private uploadFileAttachment(file: globalThis.File): void {
    if (file.size < maxFileSize) {
      const activeItem = this.activeItem;
      if (activeItem) {
        store[Actions.UPLOAD_FILE]({file}).then(item =>
        {
            this.resetItem(activeItem);
            this.onClose();
        });
      }
    }
  }

  public async handleUnsavedChanges(): Promise<boolean> {
    return true;
  }
}
