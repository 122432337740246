import Vue, { VNode } from 'vue';
import Quill, { QuillOptionsStatic, RangeStatic, StringMap, DeltaOperation } from 'quill';
import { VTextField, VInput, VToolbar, VBtn, VIcon, VDivider, VMenu, VColorPicker, VList, VListItem, VListItemTitle, VTooltip } from 'vuetify/lib';
import { MessageBoxButton } from '@/plugins/msg';
import { formatFileSize } from '@/util';

import 'quill/dist/quill.core.css';
import './QuillField.scss';

const maxImageFileSize = 100 * 1024; // 100KB

const TextField = VTextField as (typeof Vue & { readonly options: Record<string, any> });
const Input = VInput as (typeof Vue & { readonly options: Record<string, any> });

// Must get the original style attributor class because there is an instanceof check that fails if we import Parchment manually.
const FontStyleAttributor = Quill.import('attributors/style/font');
const StyleAttributor = Object.getPrototypeOf(Object.getPrototypeOf(FontStyleAttributor)).constructor;

/**
 * Make Indents with style.
 */
class IndentStyleAttributor extends StyleAttributor
{
  private static valueParseRx = /^(\d+)([a-z]*)$/;
  private static increasementsPerUnit: StringMap = {
    px: 16,
    em: 3,
    mm: 10,
    pt: 10,
    ex: 3,
    ch: 3,
    rem: 3,
    // default is 1
  };

  public constructor()
  {
    super('indent', 'margin-left', {
      scope: 0b1110, // block
      whitelist: [1, 2, 3, 4, 5, 6, 7, 8] as any[],
    });
  }

  public add(node: HTMLElement, value: string): boolean
  {
    if (value === '+1' || value === '-1')
    {
      const indent = this.normalizeValue(this.value(node));

      const factor = IndentStyleAttributor.increasementsPerUnit[indent[1] || 'em'] || 1;
      const num = (value === '+1' ? (indent[0] + 1) : (indent[0] - 1)) * factor;

      value = num > 0 ? `${num}${(indent[1] || 'em')}` : '0';
    }

    if (value === '0')
    {
      this.remove(node);
      return true;
    }
    else
    {
      return super.add(node, value);
    }
  }

  public canAdd(node: HTMLElement, value: string): boolean
  {
    return super.canAdd(node, value) || super.canAdd(node, this.normalizeValue(value)[0]);
  }

  private normalizeValue(value: string): [number, string]
  {
    const match = IndentStyleAttributor.valueParseRx.exec(value || '');
    if (match)
    {
      const num = parseInt(match[1], 10);
      const factor = IndentStyleAttributor.increasementsPerUnit[match[2]] || 1;

      const result = Math.floor(num / factor);
      if (result >= 0)
      {
        return [result, match[2]];
      }
    }
    return [0, ''];
  }
}

/**
 * The default quill options.
 */
const defaultOptions: QuillOptionsStatic = {
  debug: process.env.NODE_ENV !== 'production' ? 'warn' : undefined,
  formats: [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'image',
  ],
};

/**
 * The default buttons of the toolbar.
 */
const defaultButtons = [
  ['font', 'header', 'size'],
  ['bold', 'italic', 'underline', 'strike'],
  ['align'],
  ['script'],
  ['color', 'background'],
  ['list', /*'blockquote', */'indent'],
  ['link', 'image'],
  // ['code', 'code-block'],
  ['clean'],
];

// Used to unset formats in a 0-length range on "clean"
const inlineFormats = [
  'font', 'size', 'bold', 'italic', 'underline', 'strike', 'script', 'color', 'background', 'link', 'code',
];

/**
 * The default arguments for specific formats.
 */
const defaultFormatArgs: StringMap = {
  font: [false, 'serif', 'monospace'],
  header: [1, 2, 3, 4, 5, 6, false],
  size: ['small', false, 'large', 'huge'],
  align: [false, 'center', 'right', 'justify'],
  script: ['sub', 'super'],
  list: ['ordered', 'bullet'],
  indent: ['indent', 'outdent'],
};

// Use style attribute formats instead of classes
Quill.register({
  'formats/align': Quill.import('attributors/style/align'),
  'formats/direction': Quill.import('attributors/style/direction'),
  'formats/background': Quill.import('attributors/style/background'),
  'formats/color': Quill.import('attributors/style/color'),
  'formats/font': Quill.import('attributors/style/font'),
  'formats/size': Quill.import('attributors/style/size'),
  'formats/indent': new IndentStyleAttributor(),
}, true);

/**
 * This is a VTextField extension that utilizes a quill wysiwyg editor.
 *
 * This is written in JS style to follow the vuetify component style. Vuetify doesn't work with templates and
 * instead generates the components in code.
 */
export default Vue.extend({
  extends: TextField,

  data(this: any)
  {
    return {
      _quill: null as (Quill | null),
      _content: this.lazyValue,
      formatStates: {} as _.Dictionary<any>,
      _linkRange: null as (RangeStatic | null),
    };
  },

  props: {
    /**
     * The available formats.
     */
    formats: {
      type: Array,
      default: null,
    },
    /**
     * The buttons to add to the toolbar. Use an empty array to hide the toolbar.
     */
    buttons: {
      type: Array,
      default: null,
    },
    /**
     * The fixed height of the container-
     */
    height: {
      type: [Number, String],
      default: 200,
    },
  },

  computed: {
    classes(): object {
      return {
        ...TextField.options.computed.classes.call(this),
        'quill-field': true,
      };
    },
  },

  watch: {
    readonly(this: any)
    {
      if (this._quill)
      {
        this._quill.enable(!this.isDisabled);
      }
    },
    disabled(this: any)
    {
      if (this._quill)
      {
        this._quill.enable(!this.isDisabled);
      }
    },
    placeholder(value)
    {
      if (this._quill)
      {
        value ? this._quill.root.setAttribute('data-placeholder', value) : this._quill.root.removeAttribute('data-placeholder');
      }
    },
    value(this: any, value)
    {
      if (this._quill && value !== this._content)
      {
        this._quill.setContents(this._quill.clipboard.convert(value || ''), 'silent');
      }
    },
  },

  mounted(this: any)
  {
    if (this.$el)
    {
      const options = this.$_.assign({}, defaultOptions, {
        placeholder: this.placeholder,
        formats: this.formats || defaultOptions.formats,
      });
      const quill = this._quill = new Quill(this.$refs.input as Element, options);

      quill.disable();

      quill.setContents(this._quill.clipboard.convert(this.lazyValue || ''), 'silent');

      quill.enable(!this.disabled);

      // Update the formats and focus state if the selection/cursor changes.
      quill.on('selection-change', range =>
        {
          if (range)
          {
            if (!this.isFocused)
            {
              this.onFocus();
            }
          }
          else if (this.isFocused)
          {
            this.onBlur();
          }
          this.updateFormatStates(range);
        });

      // Set the internal value to the html content of the field on change
      quill.on('text-change', (delta) =>
      {
        this.internalValue = this._content = this._quill.editor.isBlank() ? '' : quill.root.innerHTML.trimEnd();
        // If a format block is removed update the states
        if (delta.ops && delta.ops.length > 0)
        {
          const attributes = delta.ops[delta.ops.length - 1].attributes;
          if (attributes && this.$_.some(attributes, (v: any, k: string) => v === null && !this.$_.includes(inlineFormats, k)))
          {
            this.updateFormatStates(quill.getSelection());
          }
        }
      });
    }
  },

  beforeDestroy(this: any)
  {
    if (this._quill)
    {
      this._quill.emitter.removeAllListeners();
      this._quill = null;
    }
  },

  methods: {
    /**
     * Add the toolbar to the top.
     */
    genControl()
    {
      const node: VNode = TextField.options.methods.genControl.call(this);
      const toolbar = this.genToolbar();
      if (toolbar)
      {
        node.children!.unshift(toolbar);
      }
      return node;
    },
    /**
     * Generate the toolbar.
     */
    genToolbar()
    {
      const controls = this.genToolbarControls();
      return controls.length > 0 ? this.$createElement(VToolbar, {
        staticClass: 'quill-field--toolbar',
        props: {
          dense: true,
          flat: true,
          tile: true,
          color: 'transparent',
        },
        ref: 'toolbar',
      }, controls) : null;
    },
    genLabel()
    {
      const vLabel: VNode = TextField.options.methods.genLabel.call(this);
      // vLabel.data!.staticStyle = vLabel.data!.staticStyle || {};
      // vLabel.data!.staticStyle!.top = `calc(-${convertToUnit(this.height || 0)} + 100% - 12px)`;
      return vLabel;
    },
    /**
     * Generate the buttons of the toolbar.
     */
    genToolbarControls()
    {
      const buttons: (string | _.Dictionary<any> | (string | _.Dictionary<any>)[])[] = (this.buttons as any) || defaultButtons;
      const result: VNode[] = [];
      let needsDivider = false;

      buttons.forEach((btn: any) =>
        {
          if (Array.isArray(btn))
          {
            if (result.length > 0)
            {
              result.push(this.genDivider());
            }

            btn.forEach(b => result.push(...this.genToolbarControl(b)));
            needsDivider = true;
          }
          else
          {
            if (needsDivider)
            {
              result.push(this.genDivider());
            }
            needsDivider = false;
            result.push(...this.genToolbarControl(btn));
          }
        });
      return result;
    },
    /**
     * Generate the button/toolbar element of the specified type/format.
     */
    genToolbarControl(type: string | _.Dictionary<any>): VNode[]
    {
      let format: string;
      let args: any | undefined;
      if (typeof type === 'string')
      {
        format = type;
      }
      else
      {
        format = Object.keys(type)[0];
        args = type[format];
      }

      // Add the format to the states
      if (!(format in this.formatStates))
      {
        Vue.set(this.formatStates, format, false);
      }

      args = args || defaultFormatArgs[format];

      switch (format)
      {
        // font
        case 'font':
        case 'header':
        case 'size':
          return [this.genSelect(format, args)];

          // color
        case 'color':
        case 'background':
            const defaultColor = format === 'background' ? '#FFFFFF' : '#000000';

            return [
              this.$createElement(VTooltip, {
                props: {
                  bottom: true,
                },
                scopedSlots: {
                  activator: ({ on: tooltip }) =>
                    {
                      const vPicker = this.$createElement(VColorPicker, {
                        staticClass: 'quill-field--color-picker',
                        props: {
                          showSwatches: true,
                          hideInputs: true,
                          hideModeSwitch: true,
                          flat: true,
                          value: this.formatStates[format] || defaultColor,
                          mode: 'hexa',
                        },
                        on: {
                          input: (color: string) => this.handleFormat(format, color),
                        },
                      });

                      return this.$createElement(VMenu, {
                        scopedSlots: {
                          activator: ({ on: menu }) =>
                            {
                              const vIcon = this.$createElement(VIcon, {
                                props: {
                                  small: true,
                                },
                              }, `$vuetify.icons.quillColor${this.$_.upperFirst(format)}`);

                              const colorIcon = this.$createElement(VIcon, {
                                staticStyle: {
                                  position: 'absolute',
                                },
                                props: {
                                  small: true,
                                  color: this.formatStates[format] || defaultColor,
                                },
                              }, '$vuetify.icons.quillColorHelper');

                              return this.$createElement(VBtn, {
                                props: {
                                  icon: true,
                                  small: true,
                                },
                                on: {
                                  ...tooltip,
                                  ...menu,
                                },
                              }, [vIcon, colorIcon]);
                            },
                        },
                      }, [vPicker]);
                    },
                },
              }, this.$root.$t(`quill.${format}.tooltip`).toString())];

        // regular button
        default:
          // if default arguments available
          if (args)
          {
            return this.$_.castArray(args).map((a: any) => this.genButton(format, a, `${format}-${a || 'default'}`));
          }
          return [this.genButton(format, args)];
      }
    },
    genButton(format: string, args: any, suffix?: string): VNode
    {
      const vIcon = this.$createElement(VIcon, {
        props: {
          small: true,
        },
      }, `$vuetify.icons.quill${this.$_.upperFirst(this.$_.camelCase(suffix || format))}`);

      return this.$createElement(VTooltip, {
        props: {
          bottom: true,
        },
        scopedSlots: {
          activator: ({ on }) =>
            {
              return this.$createElement(VBtn, {
                props: {
                  icon: true,
                  small: true,
                  inputValue: this.isFormatActive(format, args),
                },
                on: {
                  ...on,
                  click: () => this.handleFormat(format, args),
                },
              }, [vIcon]);
            },
        },
      }, this.$root.$t(`quill.${format}.tooltip${args === false || (args && typeof args === 'string') ? ('.' + (args || 'default')) : ''}`).toString());
    },
    genSelect(format: string, args: any): VNode
    {
      const vList = this.$createElement(
        VList,
        {
          props: {
            dense: true,
          },
        },
        this.$_.castArray(args || [false])
          .map(a =>
            {
              return this.$createElement(VListItem, {
                on: {
                  click: () => this.handleFormat(format, a),
                },
              }, [
                this.$createElement(VListItemTitle, [this.$root.$t(`quill.${format}.${(a || 'default')}`).toString()]),
              ]);
            }));
      return this.$createElement(VTooltip, {
        props: {
          bottom: true,
        },
        scopedSlots: {
          activator: ({ on: tooltip }) =>
            {
              return this.$createElement(VMenu, {
                scopedSlots: {
                  activator: ({ on: menu }) =>
                    {
                      return this.$createElement(VBtn, {
                        props: {
                          text: true,
                          small: true,
                        },
                        on: {
                          ...tooltip,
                          ...menu,
                        },
                      }, [
                        this.$root.$t(`quill.${format}.${(this.formatStates[format] || 'default')}`).toString(),
                        this.$createElement(VIcon, {
                          props: {
                            xSmall: true,
                          },
                        }, '$vuetify.icons.dropdown'),
                      ]);
                    },
                },
              }, [vList]);
            },
        },
      }, this.$root.$t(`quill.${format}.tooltip`).toString());
    },
    genDivider()
    {
      return this.$createElement(VDivider, {
        staticClass: 'mx-1',
        props: {
          vertical: true,
          inset: true,
        },
      });
    },
    /**
     * Override the input with a simple div element. This gets utilized by the quill editor.
     */
    genInput()
    {
      return this.$createElement('div', {
        staticStyle: {

        },
        ref: 'input',
      });
    },
    /**
     * Override onFocus to handle the editor.
     */
    onFocus(this: any, e?: Event)
    {
      if (!this.$refs.input)
      {
        return;
      }

      if (this._quill && !this._quill.hasFocus())
      {
        this._quill.focus();
      }

      if (!this.isFocused)
      {
        this.isFocused = true;
        this.$emit('focus', e || this._quill);
      }
    },
    /**
     * Override onBlur to handle the editor.
     */
    onBlur(this: any, e?: Event)
    {
      if (this._quill && this._quill.hasFocus())
      {
        this._quill.blur();
      }
      if (this.isFocused)
      {
        this.isFocused = false;
        this.$emit('blur', e || this._quill);
      }
    },
    /**
     * Override onMouseDown to handle the editor.
     */
    onMouseDown(this: any, e: MouseEvent)
    {
      // We need to check for children of the input element.
      if (this.isFocused && e.target !== this.$refs.input && !this.$refs.input.contains(e.target))
      {
        e.stopPropagation();
        e.preventDefault();
      }

      Input.options.methods.onMouseDown.call(this, e);
    },
    /**
     * Ignore the onInput method.
     */
    onInput(e: Event)
    {
      // tslint:disable-next-line:no-empty
    },
    handleFormat(type: string, args?: any): void
    {
      if (this._quill)
      {
        const range = this._quill.getSelection();

        switch (type)
        {
          case 'clean':
            if (!range)
            {
              return;
            }
            else if (range.length === 0)
            {
              const format = this._quill.getFormat();
              inlineFormats.forEach(k =>
                {
                  if (k in format && format[k] !== false)
                  {
                    this._quill!.format(k, false, 'user');
                  }
                });
            }
            else
            {
              this._quill.removeFormat(range.index, range.length, 'user');
            }
            break;

          case 'size':
            // map sizes to style whitelist
            this._quill.format(type, args === 'small' ? '10px' : args === 'large' ? '18px' : args === 'huge' ? '32px' : false);
            break;

          case 'color':
            this._quill.format(type, args === '#000000' ? false : args, 'user');
            break;
          case 'background':
            this._quill.format(type, args === '#FFFFFF' ? false : args, 'user');
            break;

          case 'indent':
            this._quill.format(type, args === 'outdent' ? '-1' : '+1', 'user');
            break;

          case 'link':
            // show prompt dialog if no argument given
            if (args == null)
            {
              const currentLink = this.formatStates[type] || '';
              this.$prompt(
                this.$root.$t('quill.link.title').toString(),
                this.$root.$t('quill.link.label').toString(),
                currentLink,
                ...(currentLink ? [{
                  id: 'delete',
                  text: this.$root.$t('quill.link.delete.label').toString(),
                  color: 'delete',
                }] as MessageBoxButton[] : []).concat('cancel', 'ok'))
                .then(([result, value]) =>
                  {
                    if ((result === 'ok' && value) || result === 'delete')
                    {
                      this.handleFormat(type, value);
                    }
                  });
              return;
            }

            // else insert/remove link
            if (args === false && this._linkRange)
            {
              this._quill!.formatText(this._linkRange.index, this._linkRange.length, type, false, 'user');
            }
            else
            {
              this._quill.format(type, args, 'user');
            }
            break;

          case 'image':
            const fileInput = document.createElement('input');
            fileInput.setAttribute('type', 'file');
            fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
            fileInput.style.position = 'absolute';
            fileInput.style.visibility = 'hidden';
            fileInput.style.top = fileInput.style.left = '-1000px';

            fileInput.addEventListener('change', () =>
              {
                if (fileInput.files != null && fileInput.files[0] != null)
                {
                  if (fileInput.files[0].size > maxImageFileSize)
                  {
                    this.$error(this.$root.$t('quill.image.errorSize', { maxFileSize: formatFileSize(maxImageFileSize) }).toString(), 5000, true);
                  }
                  else
                  {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      this.updateSelection([{ insert: { image: reader.result } }]);
                      fileInput.parentNode!.removeChild(fileInput);
                    };
                    reader.readAsDataURL(fileInput.files[0]);
                  }
                }
              });

            document.body.appendChild(fileInput);
            fileInput.click();

            return;

          default:
            this._quill.format(type, args == null ? !this.isFormatActive(type, args) : args, 'user');
            break;
        }

        // Update states after applying format
        this.updateFormatStates(range);
      }
    },
    isFormatActive(type: string, args?: any): boolean
    {
      const format = this.formatStates[type];
      return args == null ? !!format : format === args;
    },
    updateFormatStates(range: RangeStatic | null): void
    {
      if (!this._quill)
      {
        return;
      }

      const formats = range == null ? {} : this._quill.getFormat(range);
      if (range)
      {
        this._linkRange = null;
      }

      this.$_.forEach(this.formatStates, (val, f) =>
        {
          val = formats[f];

          if (!val)
          {
            this.formatStates[f] = false;
          }
          else if ((f === 'color' || f === 'background') && Array.isArray(val))
          {
            // if multiple colors use just one
            this.formatStates[f] = val[0];
          }
          else if (f === 'size')
          {
            // map sizes back to names
            this.formatStates[f] = val === '10px' ? 'small' : val === '18px' ? 'large' : val === '32px' ? 'huge' : false;
          }
          else
          {
            // expand selection to whole link for remove function
            if (f === 'link' && range)
            {
              const [link, offset] = (this._quill!.scroll as any).descendant(Quill.import('formats/link'), range.index);
              if (link != null)
              {
                this._linkRange = {
                  index: range.index - offset,
                  length: link.length(),
                };
              }
            }
            this.formatStates[f] = val;
          }
        });
    },

    // public methods

    /**
     * Updates the content with the given operations.
     */
    updateContents(ops: DeltaOperation[]): void
    {
      if (this._quill && ops && ops.length)
      {
        // filter 0-length ops as they lead to errors.
        this._quill.updateContents(ops.filter(o => o.retain || o.delete || o.insert) as any, 'user');
      }
    },
    /**
     * Updates the current selection with the given operations.
     */
    updateSelection(ops: DeltaOperation[]): void
    {
      if (this._quill)
      {
        const r = this._quill.getSelection(true);
        ops = ops || [];
        ops.unshift({ retain: r.index }, { delete: r.length });

        const delta = this._quill.updateContents(ops.filter(o => o.retain || o.delete || o.insert) as any, 'user');

        const length = delta.reduce((p, op) =>
          {
            if (op.insert)
            {
              if (typeof op.insert === 'string')
              {
                return p + op.insert.length;
              }
              return p + 1;
            }
            return p;
          }, 0);

        this._quill.setSelection(r.index, length);
      }
    },
  },
});
