









































































































































































































































































import { Component } from 'vue-property-decorator';
import { EntityList, ListAction, HeaderConfig, EntityPagingStoreActions, EntityStore, EntityStoreActions, EntityStoreGetters, SelectionMode } from '@/base';
import store, { Actions } from '../store';
import { InvoiceJson, InvoiceState } from '../json';
import { store as mainStore, Getters } from '@/store';
import { store as customerStore } from '@/modules/customer';
import { store as translatorStore } from '@/modules/translator';
import { store as businessUnitStore } from '@/modules/businessunit';
import { store as invoiceStore, Actions as InvoiceActions } from '@/modules/financialdocument/invoice';
import { getFullName, UserJson, UserType, UserPermission } from '@/modules/user';
import { store as officeWorkerStore } from '@/modules/officeworker';
import { store as adminStore } from '@/modules/admin';
import { Route, RawLocation } from 'vue-router';
import { EmailTemplateJson, EmailTemplateType, store as emailTemplateStore } from '@/modules/emailtemplate';
import { ContractType } from '../../../contract';

@Component<List>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  filters: {
    fullName(item: UserJson | null, fallbackToId?: boolean): string {
      return item ? getFullName(item, fallbackToId) : '';
    },
  },
  created() {
    this.init();
  },
  methods: {
    getFullName,
  },
})
export default class List extends EntityList<InvoiceJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_WRITE);
  }
  protected hasDeletePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_DELETE);
  }
  protected readonly officeWorkerStore = officeWorkerStore;
  protected readonly adminStore = adminStore;

  public selectionMode = SelectionMode.MULTI;

  private showUsageDialog = false;

  private contentDialog = '';

  private showDownloadPdfZipCheck = false;

  private failedInvoicesForDownloadPdfZipCheck: InvoiceJson[] = [];

  private handleIconClick(subject: string): void {
    this.contentDialog = subject;
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const contractNo = to.query.contractNo;
    if (contractNo) {
      this.loadDataForContractNo(to.query.contractNo as string);
    }
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;
  public sortState = [{ property: 'invoiceReference' }];

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    invoiceReference: '',
    contractNo: '',
    customerId: null as number | null,
    translatorId: null as number | null,
    businessUnitId: null as number | null,
    invoiceState: null as InvoiceState | null,
    invoiceDateFrom: null as Date | null,
    invoiceDateUntil: null as Date | null,
    isPaymentCompleted: null,
    invoiceType: null as ContractType | null,
    pdfCreatorAdminUserId: null as number | null,
    pdfCreatorOfficeWorkerId: null as number | null,
    isCreatedPDF: null,
  };

  private readonly businessUnitStore = businessUnitStore;
  private readonly customerStore = customerStore;
  private readonly translatorStore = translatorStore;

  private readonly InvoiceType = ContractType;

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<InvoiceJson>[] {
    const actions = [ this.getListActionEdit() ];
    if (this.hasWritePermission()) {
      actions.push(this.getListActionSendEmail());
    }
    if (this.hasDeletePermission()) {
      actions.push(this.getListActionDelete());

    }
    return actions;
  }

  private getListActionEdit(): ListAction<InvoiceJson> {
    return {
        icon: 'edit',
        handler: item => this.editItem(item),
        text: this.$root.$t('edit.label').toString(),
      };
  }

  private getListActionDelete(): ListAction<InvoiceJson> {
    return {
        icon: 'delete',
        handler: item => this.deleteItem(item),
        isVisible: item => item.invoiceState === InvoiceState.CREATED,
        text: this.$root.$t('delete.label').toString(),
        color: 'delete',
      };
  }

  private getListActionSendEmail(): ListAction<InvoiceJson> {
    return {
        icon: 'sendEmail',
        handler: item => this.showSendEmailDialog(item, false),
        isVisible: item => this.isAllowForAdminsAndOfficeWorkers(),
        text: this.$t('list.actions.sendEmailToCustomer').toString(),
      };
  }

  private loadDataForContractNo(contractNo: string): Promise<void> {
    this.loading = true;

    let promise: Promise<any>;

    const { sortState, page, itemsPerPage } = this;
    const headers = this.$_.keyBy(this.headers as HeaderConfig[], h => h.value);

    const filter = { contractNo };
    const initial = true;

    promise = this.store[EntityPagingStoreActions.READ_PAGE]({
      page,
      pageSize: itemsPerPage,
      sort: (sortState as SortInfo[]).reduce((result, { property, desc }) => {
        const h = headers[property];

        if (h && h.remoteSort) {
          result.push(...h.remoteSort(!!desc));
        } else {
          result.push({ property, desc });
        }

        return result;
      }, [] as SortInfo[]),
      filter: filter || undefined,
      initial,
    }).then(() => (this.currentFilters = filter));

    return promise.finally(() => (this.loading = false));
  }

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[] {
    const invoiceReference: HeaderConfig = {
      value: 'invoiceReference',
      text: this.$t('list.header.invoiceReference').toString(),
      filterProperty: ['invoiceReference', 'contractNo'],
    };

    const invoiceState: HeaderConfig = {
      value: 'invoiceState',
      text: this.$t('list.header.invoiceState').toString(),
      filterProperty: ['invoiceState', 'invoiceType'],
    };

    const invoiceDate: HeaderConfig = {
      value: 'invoiceDate',
      text: this.$t('list.header.invoiceDate').toString(),
      filterProperty: ['invoiceDateFrom', 'invoiceDateUntil'],
    };

    const customer: HeaderConfig = {
      value: 'customer',
      text: this.$t('list.header.customer').toString(),
      remoteSort: desc => [
        { property: 'customer.lastName', desc },
        { property: 'customer.firstName', desc },
      ],
      filterProperty: ['customerId', 'translatorId'],
    };

    const businessUnit: HeaderConfig = {
      value: 'businessUnit.name',
      text: this.$t('list.header.businessUnit').toString(),
      remoteSort: desc => [{ property: 'businessUnit.id', desc }],
      filterProperty: 'businessUnitId',
    };

    const isPaymentCompleted: HeaderConfig = {
      value: 'isPaymentCompleted',
      text: this.$t('list.header.isPaymentCompleted').toString(),
      filterProperty: ['isPaymentCompleted'],
    };

    const invoiceComment: HeaderConfig = {
      value: 'invoiceComment',
      text: this.$t('list.header.invoiceComment').toString(),
      filterProperty: ['invoiceComment'],
    };

    const fileNameInvoicePDF: HeaderConfig = {
      value: 'fileNameInvoicePDF',
      text: this.$t('list.header.downloadPDF').toString(),
      remoteSort: desc => [{ property: 'fileEntityInvoicePDF.fileName', desc }],
      filterProperty: ['pdfCreatorAdminUserId', 'pdfCreatorOfficeWorkerId', 'isCreatedPDF'],
    };
    if (this.isAllowForAdminsAndOfficeWorkers())
    {
      return [invoiceReference, invoiceState, invoiceDate, customer, businessUnit, isPaymentCompleted, invoiceComment, fileNameInvoicePDF];
    }
    return [invoiceReference, invoiceState, invoiceDate, customer, businessUnit, isPaymentCompleted, fileNameInvoicePDF];
  }

  private readonly InvoiceState = InvoiceState;

  private showSetStateAdmin = false;

  private setStateAdmin(): void {
    this.showSetStateAdmin = true;
  }

  private async setNewState(arg: any) {
    const state = arg as InvoiceState;
    this.showSetStateAdmin = false;

    this.selectedItems.forEach(async item => {
      await store[Actions.SET_STATE]({
        invoice: item,
        state,
      });
    });
  }

  private isAllowForAdmins(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.ADMIN;
  }

  private isAllowForAdminsAndOfficeWorkers(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.OFFICE_WORKER || currentUserType === UserType.ADMIN;
  }

  private canFulfillInvoiceByState(state: InvoiceState): boolean {
    return state === InvoiceState.CREATED && this.isAllowForAdminsAndOfficeWorkers();
  }

  private async fulfillInvoice(item: InvoiceJson): Promise<void> {
    await store[Actions.SET_STATE]({
      invoice: item,
      state: InvoiceState.APPROVED,
    });
  }

  // E-mails

  /**
   * Variable used to display the options in the email dialog.
   */
  protected emailTemplatesForInvoice: {
    invoice: InvoiceJson;
    emailTemplates: EmailTemplateJson[];
    isTranslator: boolean;
  } | null = null;

  protected showSendEmailDialog(item: InvoiceJson, isTranslator: boolean): void {
    emailTemplateStore[EntityStoreActions.READ_ALL](true);
    const res = emailTemplateStore[EntityStoreGetters.ITEMS].filter(t => t.emailTemplateType === EmailTemplateType.INVOICE);

    this.emailTemplatesForInvoice = {
      invoice: item,
      emailTemplates: res,
      isTranslator,
    };
  }

  protected sendEmail(item: InvoiceJson, emailTemplate: EmailTemplateJson, isTranslator: boolean): void {
    const emailPath = isTranslator ? 'email/translator' : 'email/customer';

    // forward to route
    this.$router
      .push({
        path: `${item.id}/${emailPath}/${emailTemplate.id}`,
        append: true,
      })
      // close dialog
       .then(() => null);
  }

  private async refreshStateOfSelectedItems()
  {
    const readPromises: Promise<InvoiceJson>[] = [];
    this.selectedItems.forEach(i => {
      readPromises.push(store[EntityStoreActions.READ_ONE](i.id));
    });

    await Promise.all(readPromises).then(values => {
      values.forEach(i => this.selectedItems.filter(item => i.id === item.id).forEach(selectedItem => selectedItem.invoiceState = i.invoiceState));
    });
  }

  private showModalWarning = false;
  private contentModalWarning: string | null = null;

  private async cancelInvoiceBase()
  {
    if (this.selectedItems.length > 0)
    {
      await this.refreshStateOfSelectedItems();

      this.canceledInvoices = '';
      if (this.selectedItems.filter(c => c.invoiceState !== InvoiceState.APPROVED).length > 0)
      {
        this.contentModalWarning = this.$t('dialog.warning.invoiceNotApproved') as string;
        this.showModalWarning = true;
      }
      else
      {
        this.canceledInvoices = this.selectedItems.map(c => c.invoiceReference).join(', ');
        this.showCancelInvoice = true;
      }
    }
  }

  private showCancelInvoice = false;
  private canceledInvoices = '';

  private async cancelInvoice()
  {
    this.showCancelInvoice = false;

    if (this.selectedItems.length > 0)
    {
      this.selectedItems.forEach(async item => {
        await store[Actions.CANCEL_INVOICE]({
          invoice: item,
        }).then((OK) => this.$success(this.$t('message.reversalInvoiceCreated') as string));
      });
    }
  }

  private downloadPDF(item: InvoiceJson): void {
    if (item) {
      store[Actions.DOWNLOAD_INVOICE_PDF](item.id);
    }
  }

 private async downloadAccumulative() {
    this. failedInvoicesForDownloadPdfZipCheck = [];
    const res = await store[Actions.DOWNLOAD_INVOICE_PDF_ZIP_CHECK](this.selectedItems);
    if (res && res.length === 0)
    {
      await store[Actions.DOWNLOAD_INVOICE_PDF_ZIP](this.selectedItems);
    } else {
      this. failedInvoicesForDownloadPdfZipCheck = res;
      this.showDownloadPdfZipCheck = true;
    }
  }

  private downloadOtherPdfFiles(): boolean {
    return this.failedInvoicesForDownloadPdfZipCheck && this.selectedItems && this.failedInvoicesForDownloadPdfZipCheck.length < this.selectedItems.length;
  }

  private async downloadAccumulativeForced() {
      this.showDownloadPdfZipCheck = false;
      await store[Actions.DOWNLOAD_INVOICE_PDF_ZIP](this.selectedItems);
  }
}
