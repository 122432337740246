import { BusinessUnitJson } from './json';
import { EntityStore, createEntityStore, EntityStoreState, NewEntityProperties, FormData, EntityId, getId } from '@/base';
import { ContactJson } from '../contact';
import { BankAccountCollectionJson } from '../bankaccount';
import api from '@/plugins/api';


export interface State extends EntityStoreState<BusinessUnitJson>
{
  /**
   * The list of invoice contacts per business unit id.
   */
  invoiceContacts: _.NumericDictionary<ContactJson>;

  /**
   * The list of bank account collections per business unit id.
   */
  bankAccountCollections: _.NumericDictionary<BankAccountCollectionJson>;
}

export interface Store extends EntityStore<BusinessUnitJson>
{
  [Actions.READ_INVOICE_CONTACT](
    payload: EntityId<BusinessUnitJson> | { businessUnit: EntityId<BusinessUnitJson> }): Promise<ContactJson>;
  [Actions.SAVE_INVOICE_CONTACT](
    payload: { businessUnit: EntityId<BusinessUnitJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
  [Actions.READ_BANK_ACCOUNT_COLLECTION](
    payload: EntityId<BusinessUnitJson> | { businessUnit: EntityId<BusinessUnitJson> }): Promise<BankAccountCollectionJson>;
  [Actions.SAVE_BANK_ACCOUNT_COLLECTION](
    payload: { businessUnit: EntityId<BusinessUnitJson>; bankAccountCollection: FormData<BankAccountCollectionJson> }): Promise<BankAccountCollectionJson>;
}

export enum Actions
{
  READ_INVOICE_CONTACT = 'dispatchReadInvoiceContact',
  SAVE_INVOICE_CONTACT = 'dispatchSaveInvoiceContact',
  READ_BANK_ACCOUNT_COLLECTION = 'dispatchReadBankAccountCollection',
  SAVE_BANK_ACCOUNT_COLLECTION = 'dispatchSaveBankAccountCollection',
}

export enum Mutations
{
  INVOICE_CONTACT = 'commitInvoiceContact',
  BANK_ACCOUNT_COLLECTION = 'commitBankAccountCollection',
}

function createItem(): NewEntityProperties<BusinessUnitJson>
{
  return {
    name: '',
    invoiceNoPrefixDocumentContract: '',
    invoiceNoPrefixOnSiteContract: '',
    invoiceNoPrefixVideoTranslationContract: '',
    creditNoPrefixDocumentContract: '',
    creditNoPrefixOnSiteContract: '',
    creditNoPrefixVideoTranslationContract: '',
  };
}

const apiBaseUrl = 'businessunits';

export const store = createEntityStore<BusinessUnitJson, State, Store>(
  'businessunit',
  apiBaseUrl,
  createItem,
  {
    invoiceContacts: {},
    bankAccountCollections: {},
  },

  moduleBuilder =>
  {
    const setInvoiceContact = moduleBuilder.commit(
      (state, { businessUnit, contact }: { businessUnit: EntityId<BusinessUnitJson>; contact: ContactJson }) =>
      {
        state.invoiceContacts[getId(businessUnit)] = contact;
      }, Mutations.INVOICE_CONTACT);

    const setBankAccountCollection = moduleBuilder.commit(
      (state, { businessUnit, bankAccountCollection }: { businessUnit: EntityId<BusinessUnitJson>; bankAccountCollection: BankAccountCollectionJson }) =>
      {
        state.bankAccountCollections[getId(businessUnit)] = bankAccountCollection;
      }, Mutations.BANK_ACCOUNT_COLLECTION);

    return {
      [Actions.READ_INVOICE_CONTACT]: moduleBuilder.dispatch(
          async ({ state }, payload: EntityId<BusinessUnitJson> | { businessUnit: EntityId<BusinessUnitJson> }) =>
          {
            if (typeof payload === 'number' || !('businessUnit' in payload))
            {
              payload = {
                businessUnit: payload,
              };
            }

            const { businessUnit } = payload;
            const businessUnitId = getId(businessUnit);

            const contact = await api.get<ContactJson>(`${apiBaseUrl}/${businessUnitId}/invoicecontact`);
            setInvoiceContact({ businessUnit, contact });

            return state.invoiceContacts[businessUnitId];
          }, Actions.READ_INVOICE_CONTACT),

        [Actions.SAVE_INVOICE_CONTACT]: moduleBuilder.dispatch(
          async (ctx, { businessUnit, contact }: { businessUnit: EntityId<BusinessUnitJson>; contact: FormData<ContactJson> }) =>
          {
            const businessUnitId = getId(businessUnit);
            const serverResult = await api.put<ContactJson>(`${apiBaseUrl}/${businessUnitId}/invoicecontact`, contact);
            const committed = contact.$commit(serverResult);
            setInvoiceContact({ businessUnit, contact: committed.$raw });
            return committed;
          }, Actions.SAVE_INVOICE_CONTACT),

        [Actions.READ_BANK_ACCOUNT_COLLECTION]: moduleBuilder.dispatch(
          async ({ state }, payload: EntityId<BusinessUnitJson> | { businessUnit: EntityId<BusinessUnitJson> }) =>
          {
            if (typeof payload === 'number' || !('businessUnit' in payload))
            {
              payload = {
                businessUnit: payload,
              };
            }

            const { businessUnit } = payload;
            const businessUnitId = getId(businessUnit);

            const bankAccountCollection = await api.get<BankAccountCollectionJson>(`${apiBaseUrl}/${businessUnitId}/bankaccountcollection`);
            setBankAccountCollection({ businessUnit, bankAccountCollection });

            return state.bankAccountCollections[businessUnitId];
          }, Actions.READ_BANK_ACCOUNT_COLLECTION),

        [Actions.SAVE_BANK_ACCOUNT_COLLECTION]: moduleBuilder.dispatch(
          async (ctx, { businessUnit, bankAccountCollection }: { businessUnit: EntityId<BusinessUnitJson>; bankAccountCollection: FormData<BankAccountCollectionJson> }) =>
          {
            const businessUnitId = getId(businessUnit);
            const serverResult = await api.put<BankAccountCollectionJson>(`${apiBaseUrl}/${businessUnitId}/bankaccountcollection`, bankAccountCollection);
            const committed = bankAccountCollection.$commit(serverResult);
            setBankAccountCollection({ businessUnit, bankAccountCollection: committed.$raw });
            return committed;
          }, Actions.SAVE_BANK_ACCOUNT_COLLECTION),
      };
});

export default store;

