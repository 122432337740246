

























































































import { Component } from 'vue-property-decorator';
import { CustomerJson } from '../json';
import { EntityForm as FormBase, EntityStoreGetters, EntityStoreActions } from '@/base';
import { store, Actions } from '../store';
import { CustomerGroupJson, store as customerGroupStore } from '@/modules/customer/customergroup';
import { CostCenterJson, store as costCenterStore } from '@/modules/costcenter';
import { UserPermission } from '../../user';

@Component<Form>({
  beforeRouteEnter(to, from, next): void {
    Form.setBackTo(null);
    if (to.query.backTo)
    {
      Form.setBackTo(to.query.backTo as string);
    }

    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends FormBase<CustomerJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.CUSTOMER_WRITE);
  }

  protected readonly store = store;

  public static setBackTo(value: string | null)
  {
    this.backTo = value;
  }

  private static backTo: string | null =  null;

  protected readonly closeRoute = {
    name: 'customer',
  };

  protected onClose(): void {
    if (Form.backTo)
    {
      this.$router.push(Form.backTo);
    }
    else
    {
      this.$router.push(this.closeRoute);
    }
  }

  private sendActivationEmail(): void {
    if (this.activeItem) {
      store[Actions.SEND_ACTIVATION_EMAIL](this.activeItem)
        .then(itemRes => {
          this.$success(this.$root.$t('module.email.send.ok') as string);
        })
        .catch(err => {
          this.$error(this.$root.$t('module.email.send.failed') as string);
        });
    }
  }

  private readonly customerGroupStore = customerGroupStore;
  private readonly costCenterStore = costCenterStore;
}
