


















































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { getDateString, firstDayOfWeek } from '@/util';

type DateParts = 'day' | 'month' | 'year' | 'hour' | 'minute' | 'second';

@Component<DateField>({
  inheritAttrs: false,
  created(): void
  {
    this.__updateDateTimeFormatter();
    this.__localeSubscriber = this.$i18n.vm.$watch('locale', () => this.__updateOnLocaleChange());
    this.model = this.value ? this.dateTimeFormat[0].format(this.value) : '';
    this.modelAsDateString = this.value ? getDateString(this.value) : '';
  },
  destroyed()
  {
    if (this.__localeSubscriber)
    {
      this.__localeSubscriber();
    }
  },
})
export default class DateField extends Vue
{
  /**
   * The (initial) value of the field.
   */
  @Prop({ default: null })
  public readonly value: Date | null;

  /**
   * Binding property to the open state of the menu.
   */
  public isOpen = false;

  /**
   * The value of the field.
   */
  private model: string = '';
  /**
   * The model value for the date picker.
   */
  private modelAsDateString: string = '';
  /**
   * The current value as date.
   */
  private valueAsDate = this.value || null;

  /**
   * The first day of the week.
   */
  private readonly firstDayOfWeek = firstDayOfWeek;

  /**
   * The currently visible picker.
   */
  private pickerType: 'date' | 'time' = 'date';

  /**
   * Returns the datetime format options to use.
   */
  protected getDateTimeFormatOptions(): Intl.DateTimeFormatOptions
  {
    return this.$i18n.getDateTimeFormat(this.$i18n.locale).short;
  }

  /**
   * Set the isFocused state on focus.
   */
  private onFocus(): void
  {
    this.__updateValue();
    try
    {
      this.$nextTick(() => ((this.$refs.field as any).$refs.input as HTMLInputElement).select());
    }
    catch (e)
    {
      // ignore
    }
  }

  /**
   * Set the isFocused state on blur.
   */
  private onBlur(): void
  {
    if (!this.isOpen)
    {
      this.__updateValue();
    }
  }

  /**
   * Updates the value on every key strike or input event.
   */
  private onInput(): void
  {
    this.__updateValue(true);
  }

  /**
   * Updates the value on selection of a date inside the picker.
   */
  private onDateSelect(dateString: string): void
  {
    this.__updateValue(false, this.__parseValue(this.dateTimeFormat[3] ? `${dateString}T00:00:00` : dateString));

    if (this.dateTimeFormat[3])
    {
      this.pickerType = 'time';
    }
    else
    {
      this.isOpen = false;
    }
  }

  private selectedHour: string;

  private onTimeSelectHour(hour: number): void
  {
    if (hour < 10)
    {
      this.selectedHour = '0' + hour + '';
    } else {
      this.selectedHour = hour + '';
    }
  }

  /**
   * Updates the value on selection of a date inside the picker.
   */
  private onTimeSelectMinute(minute: number): void
  {
    let selectedMinute = '';
    if (minute < 10)
    {
      selectedMinute = '0' + minute + '';
    } else {
      selectedMinute = minute + '';
    }

    const timeString = this.selectedHour + ':' + selectedMinute;

    this.__updateValue(false, this.__parseValue(`${getDateString(this.valueAsDate || new Date())}T${timeString}${(timeString.length < 6 ? ':00' : '')}`));
    this.isOpen = false;
    this.pickerType = 'date';
  }

  /**
   * Opens the date picker.
   */
  private openDatePicker(): void
  {
    this.pickerType = 'date';
    this.isOpen = true;
  }

  /**
   * Opens the time picker.
   */
  private openTimePicker(): void
  {
    if (this.dateTimeFormat[3])
    {
      this.pickerType = 'time';
      this.isOpen = true;
    }
  }

  /**
   * Updates the value based on the current model.
   */
  private __updateValue(onInput?: boolean, value?: Date | null): void
  {
    const isFocused = !!(this.$refs.field as any).isFocused;
    if (value === undefined)
    {
      value = this.__parseValue(this.model);
    }

    // ignore invalid inputs as long as we are typing
    if (value == null && onInput && !!this.model)
    {
      return;
    }

    this.valueAsDate = value;

    if (!isFocused || !onInput)
    {
      this.model = value == null ? '' : this.dateTimeFormat[0].format(value);
    }

    this.modelAsDateString = value == null ? '' : getDateString(value);
  }

  /**
   * Called when the isOpen property changes.
   */
  @Watch('isOpen')
  private __onMenuToggle(isOpen: boolean | null): void
  {
    // emits dateSelected event when menu is closed and value selected
    if (!isOpen && this.valueAsDate) {
      this.$emit('dateSelected', this.valueAsDate);
    }
  }

  /**
   * Called when the valueAsDate property changes.
   */
  @Watch('valueAsDate')
  @Emit('input')
  private __onDateValueChange(value: Date | null): void
  {
    // just emit the value to sync the bound model
  }

  /**
   * Called when the value changed from outside.
   */
  @Watch('value')
  private __onValueChange(value: any): void
  {
    // ignore circular updates
    if (value === this.model)
    {
      return;
    }

    // check for value updates from outside
    const date = this.__parseValue(value);
    if (date !== this.valueAsDate)
    {
      this.__updateValue(false, date);
    }
  }

  /**
   * Updates the datetime formatter.
   */
  private __updateDateTimeFormatter(): void
  {
    let resolvedOptions = this.dateTimeFormat[0].resolvedOptions();
    if (resolvedOptions.locale !== this.$i18n.locale || (resolvedOptions.year === '2-digit' && !resolvedOptions.month))
    {
      const dateTimeFormat = new Intl.DateTimeFormat(this.$i18n.locale, this.getDateTimeFormatOptions());
      resolvedOptions = dateTimeFormat.resolvedOptions();

      const formattedNumber = dateTimeFormat.formatToParts(new Date(9, 7, 2017, 9, 8, 5));
      const containsSecond = !!resolvedOptions.second;
      const containsMinute = containsSecond || !!resolvedOptions.hour || !!resolvedOptions.minute;

      let rx = '';
      const parts: DateParts[] = [];
      formattedNumber.forEach(part =>
        {
          if (part.type === 'year' || part.type === 'month' || part.type === 'day'
            || (containsMinute && (part.type === 'hour' || part.type === 'minute'))
            || (containsSecond && part.type === 'second'))
          {
            rx += `(\\d{${part.value.length},${Math.max(part.value.length, 2)}})`;
            parts.push(part.type);
          }
          else
          {
            rx += this.$_.escapeRegExp(part.value);
          }
        });

      this.dateTimeFormat = [
        dateTimeFormat,
        new RegExp(`^${rx}$`),
        parts,
        containsSecond ? 's' : containsMinute ? 'm' : false,
      ];
    }
  }
  /**
   * Parses the given value as number.
   */
  private __parseValue(value: string | Date): Date | null
  {
    if (!value)
    {
      return null;
    }

    if (!(value instanceof Date))
    {
      const match = this.dateTimeFormat[1].exec(value);
      const parts: Partial<{ [part in DateParts]: number }> = {};
      let current = this.valueAsDate;

      if (!current)
      {
        current = new Date();
        if (!this.dateTimeFormat[3])
        {
          current.setHours(0, 0, 0, 0);
        }
      }

      if (match)
      {
        this.$_.tail(match).forEach((str, idx) => parts[this.dateTimeFormat[2][idx]] = parseInt(str, 10));
      }
      else
      {
        const date = new Date(value);
        if (isNaN(date.getTime()))
        {
          return null;
        }

        parts.year = date.getFullYear();
        parts.month = date.getMonth() + 1;
        parts.day = date.getDate();
        if (this.dateTimeFormat[3])
        {
          parts.hour = date.getHours();
          parts.minute = date.getMinutes();
          if (this.dateTimeFormat[3] === 's')
          {
            parts.second = date.getSeconds();
          }
        }
      }

      return new Date(
        parts.year || current.getFullYear(),
        (parts.month || (current.getMonth() + 1)) - 1,
        parts.day || current.getDate(),
        parts.hour !== undefined ? parts.hour : current.getHours(),
        parts.minute !== undefined ? parts.minute : current.getMinutes(),
        parts.second !== undefined ? parts.second : current.getSeconds(),
        current.getMilliseconds());
    }

    return value;
  }

  /**
   * Updates the field output on locale change.
   */
  private __updateOnLocaleChange(): void
  {
    const value = this.__parseValue(this.model);

    this.__updateDateTimeFormatter();

    this.__updateValue(false, value);
  }

  /**
   * Function used to merge the listeners on the text field
   */
  // tslint:disable-next-line:ban-types
  private mergeListeners(objValue: Function | Function[], srcValue: Function | Function[], key: string)
  {
    // Do not apply input listeners. Those are handled separately.
    if (key === 'input')
    {
      return objValue;
    }

    // Ensure an array so nothing is overridden.
    if (!this.$_.isArray(objValue))
    {
      objValue = objValue == null ? [] : [objValue];
    }

    return objValue.concat(srcValue);
  }

  /**
   * Number format options. This is an initial value.
   */
  private dateTimeFormat: [Intl.DateTimeFormat, RegExp, DateParts[], 'm' | 's' | false] = [new Intl.DateTimeFormat(undefined, { year: '2-digit' }), /^$/, [], false];
  private __localeSubscriber: () => void;
}

