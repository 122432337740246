import Vue from 'vue';
import Vuex from 'vuex';
import { getStoreBuilder } from 'vuex-typex';
import _ from 'lodash';
import { WritableKeys } from 'ts-essentials';
import { AxiosRequestConfig } from 'axios';
import api from './plugins/api';
import { UserLoginResponse, UserLoginRequest, UserPasswordRequestJson, UserPasswordResetJson } from './json';

Vue.use(Vuex);

const storage = self.localStorage;

/**
 * The alert object.
 */
export interface Alert
{
  /**
   * The text of the message.
   */
  text: string;
  /**
   * The message type.
   */
  type?: 'info' | 'success' | 'warning' | 'error';
  /**
   * False to make the message unclosable. The message must then be removed wie REMOVE_MESSAGE mutation.
   */
  closable?: boolean;
  /**
   * Milliseconds to auto hide messages.
   * Defaults to 5000 for success messages if closable is not set.
   */
  autoHide?: number;

  module: string | null;
}

export interface SharedState
{
  currentUserLogin: UserLoginResponse | null;
  messages: Alert[];
}

export enum Getters
{
  CURRENT_USER_LOGIN = 'getCurrentUserLogin',
  IS_LOGGED_IN = 'getIsLoggedIn',
  ALERTS = 'getAlerts',
}

export enum Actions
{
  LOGIN = 'dispatchLogin',
  LOGOUT = 'dispatchLogout',
  REFRESH_LOGIN = 'dispatchRefreshLogin',
  CREATE_ENTITY = 'dispatchCreateEntity',
  REQUEST_PASSWORD = 'dispatchRequestPassword',
  RESET_PASSWORD = 'dispatchResetPassword',
}

export enum Mutations
{
  CURRENT_USER_LOGIN = 'commitCurrentUserLogin',
  APPEND_ALERT = 'commitAppendAlert',
  REMOVE_ALERT = 'commitRemoveAlert',
}

enum SessionKeys
{
  CURRENT_USER = 'currentUser',
  TOKEN = 'token',
}

/**
 * Sets or removes the authorization token to the default api request headers based on the current session store value.
 */
function applyAuthorizationHeader()
{
  const token = storage.getItem(SessionKeys.TOKEN);

  if (token)
  {
    api.defaults.headers = Object.assign({}, api.defaults.headers || {}, {
      Authorization: token,
    });
  }
  else if (api.defaults.headers)
  {
    delete api.defaults.headers.Authorization;
  }
}

function doLogin(config: AxiosRequestConfig): Promise<UserLoginResponse>
{
  return api.raw<UserLoginResponse>(config)
    .then(response => {
      const user = response.data;

      storage.setItem(SessionKeys.CURRENT_USER, JSON.stringify(user));

      // Set the authorization header as default.
      if (response.headers && response.headers.authorization)
      {
        storage.setItem(SessionKeys.TOKEN, response.headers.authorization);

        applyAuthorizationHeader();
      }

      setCurrentUser(user);

      return user;
    });
}

// apply token initially
applyAuthorizationHeader();

const currentUser = storage.getItem(SessionKeys.CURRENT_USER);

const storeBuilder = getStoreBuilder<SharedState>();
const isLoggedIn = storeBuilder.read(state => !!state.currentUserLogin && !!storage.getItem(SessionKeys.TOKEN), Getters.IS_LOGGED_IN);
const getCurrentUserLogin = storeBuilder.read(state => state.currentUserLogin, Getters.CURRENT_USER_LOGIN);
const setCurrentUser = storeBuilder.commit((state, user: UserLoginResponse | null) => state.currentUserLogin = user, Mutations.CURRENT_USER_LOGIN);
const getAlerts = storeBuilder.read(state => state.messages || [], Getters.ALERTS);
const commitRemoveMessage = storeBuilder.commit((state, message: Alert) => _.pull(state.messages, message), Mutations.REMOVE_ALERT);

export interface ModuleApiData<T>
{
  module: string;
  data: T;
}


// store
export const store = {
  // getters
  get [Getters.CURRENT_USER_LOGIN](): UserLoginResponse | null
  {
    return getCurrentUserLogin();
  },

  get [Getters.IS_LOGGED_IN](): boolean
  {
    return isLoggedIn();
  },

  get [Getters.ALERTS](): Alert[]
  {
    return getAlerts();
  },

  // mutations
  [Mutations.APPEND_ALERT]: storeBuilder.commit((state, alert: Alert) =>
    {
      if (alert.autoHide && alert.autoHide > 0)
      {
        self.setTimeout(() => commitRemoveMessage(alert), alert.autoHide);
      }

      const text = alert.text + alert.module;
      const texts = state.messages.map(elem => alert.text + alert.module);
      if (!texts.includes(text))
      {
        state.messages.push(alert);
      }
    }, Mutations.APPEND_ALERT),
  [Mutations.REMOVE_ALERT]: commitRemoveMessage,

  // actions

  /**
   * Performs login with the given email and password.
   *
   * Do manual error handling!
   */
  [Actions.LOGIN]: storeBuilder.dispatch((ctx, { email, password }: UserLoginRequest): Promise<UserLoginResponse> =>
    {
      return doLogin({
        url: 'login',
        method: 'POST',
        data: { email, password },
      });
    }, Actions.LOGIN),

  /**
   * Performs logout of the current user.
   */
  [Actions.LOGOUT]: storeBuilder.dispatch((): void =>
    {
      setCurrentUser(null);

      storage.removeItem(SessionKeys.CURRENT_USER);
      storage.removeItem(SessionKeys.TOKEN);

      applyAuthorizationHeader();
    }, Actions.LOGOUT),

  /**
   * Refreshes the login token of the current user.
   *
   * Do manual error handling!
   */
  [Actions.REFRESH_LOGIN]: storeBuilder.dispatch((): Promise<boolean> =>
    {
      return doLogin({
        url: 'login/refreshtoken',
        method: 'POST',
      })
        .then(
          () => true,
          error =>
          {
            store[Actions.LOGOUT]();

            if (error && (error.statusCode === 401 || error.statusCode === 404))
            {
              return Promise.resolve(false);
            }

            return Promise.reject(error);
          });
      }, Actions.REFRESH_LOGIN),

  /**
   * Requests a new password for the user with the given email.
   *
   * Do manual error handling!
   */
  [Actions.REQUEST_PASSWORD]: storeBuilder.dispatch((ctx, { email }: Pick<UserPasswordRequestJson, WritableKeys<UserPasswordRequestJson>>) =>
    {
      return api.raw<UserPasswordRequestJson>({
        url: 'login/requestpassword',
        method: 'POST',
        data: { email },
      });
    }, Actions.REQUEST_PASSWORD),

  /**
   * Resets the password using the given id.
   */
  [Actions.RESET_PASSWORD]: storeBuilder.dispatch((ctx, payload: UserPasswordResetJson ) =>
    {
      return api.post<void>('login/resetpassword', payload);
    }, Actions.RESET_PASSWORD),
};

// Try to parse the current user from the storage
let currentUserLogin: UserLoginResponse | null = null;
try
{
  if (currentUser)
  {
    currentUserLogin = JSON.parse(currentUser);
  }
}
catch (e)
{
  currentUserLogin = null;
}

// export created store to register in main.ts
export default storeBuilder.vuexStore({
  // strict: process.env.NODE_ENV !== 'production',
  state: {
    currentUserLogin,
    messages: [],
  },
});
