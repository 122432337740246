import { OnSiteTranslationContractJson, TravelOption } from './json';
import { EntityPagingStore, EntityPagingStoreState, NewEntityProperties, createEntityPagingStore, commitEntity, EntityId, FormData, getId } from '@/base';
import { ReceiverOption, ContractState } from '../json';
import { TranslatorJson } from '@/modules/translator';
import { api } from '@/plugins';
import { ContactJson } from '@/modules/contact';
import { EmailTemplateJson } from '@/modules/emailtemplate';
import { EmailJson } from '@/modules/email';
import { FileEntityCollectionJson, FileEntityJson } from '@/modules/file';

export enum Actions {
  ASSIGN_TRANSLATOR = 'dispatchAssignTranslator',
  SET_STATE = 'dispatchSetState',
  READ_INVOICE_CONTACT = 'dispatchReadInvoiceContact',
  SAVE_INVOICE_CONTACT = 'dispatchSaveInvoiceContact',
  READ_CREDIT_CONTACT = 'dispatchReadCreditContact',
  SAVE_CREDIT_CONTACT = 'dispatchSaveCreditContact',
  READ_OPERATION_CONTACT = 'dispatchReadOperationContact',
  SAVE_OPERATION_CONTACT = 'dispatchSaveOperationContact',
  READ_EMAIL = 'dispatchReadEmail',
  READ_FILE_ATTACHMENT_COLLECTION = 'dispatchReadFileAttachmentCollection',
  UPLOAD_FILE = 'dispatchUploadFile',
  DELETE_FILE = 'dispatchDeleteFile',
  DOWNLOAD_FILE = 'dispatchDownloadFile',
}

export enum Mutations {
  INVOICE_CONTACT = 'commitInvoiceContact',
  CREDIT_CONTACT = 'commitCreditContact',
  OPERATION_CONTACT = 'commitOperationContact',
  FILE_ATTACHMENT_COLLECTION = 'commitFileAttachmentCollection',
}

export interface State extends EntityPagingStoreState<OnSiteTranslationContractJson> {
  /**
   * The list of invoice contacts per contract id.
   */
  invoiceContacts: _.NumericDictionary<ContactJson>;
  /**
   * The list of credit contacts per contract id.
   */
  creditContacts: _.NumericDictionary<ContactJson>;
  /**
   * The list of operation contacts per contract id.
   */
  operationContacts: _.NumericDictionary<ContactJson>;

  fileEntityCollections: _.NumericDictionary<FileEntityCollectionJson>;
}

export interface Store extends EntityPagingStore<OnSiteTranslationContractJson> {
  /**
   * Assigns the given translator to the given contract.
   */
  [Actions.ASSIGN_TRANSLATOR](payload: { contract: OnSiteTranslationContractJson; translator: TranslatorJson }): Promise<OnSiteTranslationContractJson>;
  /**
   * Sets the contract to the given state.
   */
  [Actions.SET_STATE](payload: { contract: OnSiteTranslationContractJson; state: ContractState }): Promise<OnSiteTranslationContractJson>;

  [Actions.READ_INVOICE_CONTACT](payload: EntityId<OnSiteTranslationContractJson> | { contract: EntityId<OnSiteTranslationContractJson> }): Promise<ContactJson>;
  [Actions.SAVE_INVOICE_CONTACT](payload: { contract: EntityId<OnSiteTranslationContractJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
  [Actions.READ_CREDIT_CONTACT](payload: EntityId<OnSiteTranslationContractJson> | { contract: EntityId<OnSiteTranslationContractJson> }): Promise<ContactJson>;
  [Actions.SAVE_CREDIT_CONTACT](payload: { contract: EntityId<OnSiteTranslationContractJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
  [Actions.READ_OPERATION_CONTACT](
    payload: EntityId<OnSiteTranslationContractJson> | { contract: EntityId<OnSiteTranslationContractJson> },
  ): Promise<ContactJson>;
  [Actions.SAVE_OPERATION_CONTACT](payload: { contract: EntityId<OnSiteTranslationContractJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
  [Actions.READ_EMAIL](payload: { contract: EntityId<OnSiteTranslationContractJson>; emailTemplate: EntityId<EmailTemplateJson>; isTranslator: boolean }): Promise<EmailJson>;
  [Actions.UPLOAD_FILE](payload: { onSiteTranslationContractJson: FormData<OnSiteTranslationContractJson>; file: File }): Promise<FileEntityCollectionJson>;
  [Actions.DELETE_FILE](payload: { onSiteTranslationContractJson: EntityId<OnSiteTranslationContractJson>; fileEntity: FileEntityJson }): Promise<FileEntityCollectionJson>;
  [Actions.DOWNLOAD_FILE](payload: { onSiteTranslationContractJson: EntityId<OnSiteTranslationContractJson>; fileEntity: FileEntityJson }): Promise<void>;
  [Actions.READ_FILE_ATTACHMENT_COLLECTION](
    payload:
      | EntityId<OnSiteTranslationContractJson>
      | {
          onSiteTranslationContractJson: EntityId<OnSiteTranslationContractJson>;
        },
  ): Promise<FileEntityCollectionJson>;
}

function createItem(): NewEntityProperties<OnSiteTranslationContractJson> {
  return {
    businessUnit: null,
    contractDate: new Date(),
    customerLanguage: null,
    translatorLanguage: null,
    invoiceReceiverOption: ReceiverOption.CONTACT_ADDRESS,
    creditReceiverOption: ReceiverOption.CONTACT_ADDRESS,
    customer: null,
    beginDateTime: null,
    plannedTranslationDuration: 60,
    realTranslationStart: null,
    realTranslationEnd: null,
    travelTimeToLocationStart: null,
    travelTimeToLocationEnd: null,
    travelTimeBackStart: null,
    travelTimeBackEnd: null,
    waitTimeAtLocation: null,
    travelCosts: [{
      travelOption: TravelOption.FIX_RATE,
      travelAmount: null,
      isTravelCostCustomer: false,
      isTravelCostTranslator: false,
    }, {
      travelOption: TravelOption.COST_PER_KM,
      travelAmount: null,
      isTravelCostCustomer: false,
      isTravelCostTranslator: false,
    }, {
      travelOption: TravelOption.PUBLIC_TRANSIT_TICKET,
      travelAmount: null,
      isTravelCostCustomer: false,
      isTravelCostTranslator: false,
    }],
    contractComment: null,
    operationContact: null,
  };
}

const apiBaseUrl = 'onsitetranslationcontract';

export default createEntityPagingStore<OnSiteTranslationContractJson, State, Store>(
  'onsitetranslation',
  apiBaseUrl,
  createItem,
  {
    invoiceContacts: {},
    creditContacts: {},
    operationContacts: {},
    fileEntityCollections: {},
  },
  moduleBuilder => {
    const setInvoiceContact = moduleBuilder.commit((state, { contract, contact }: { contract: EntityId<OnSiteTranslationContractJson>; contact: ContactJson }) => {
      state.invoiceContacts[getId(contract)] = contact;
    }, Mutations.INVOICE_CONTACT);

    const setCreditContact = moduleBuilder.commit((state, { contract, contact }: { contract: EntityId<OnSiteTranslationContractJson>; contact: ContactJson }) => {
      state.creditContacts[getId(contract)] = contact;
    }, Mutations.CREDIT_CONTACT);

    const setOperationContact = moduleBuilder.commit((state, { contract, contact }: { contract: EntityId<OnSiteTranslationContractJson>; contact: ContactJson }) => {
      state.operationContacts[getId(contract)] = contact;
    }, Mutations.OPERATION_CONTACT);

    const setFileAttachmentCollection = moduleBuilder.commit(
      (
        state,
        {
          onSiteTranslationContractJson,
          fileEntityCollection,
        }: {
          onSiteTranslationContractJson: EntityId<OnSiteTranslationContractJson>;
          fileEntityCollection: FileEntityCollectionJson;
        },
      ) => {
        state.fileEntityCollections[getId(onSiteTranslationContractJson)] = fileEntityCollection;
      },
      Mutations.FILE_ATTACHMENT_COLLECTION,
    );

    function generateReadContactAction(
      action: Actions.READ_INVOICE_CONTACT | Actions.READ_CREDIT_CONTACT | Actions.READ_OPERATION_CONTACT,
      type: 'invoice' | 'credit' | 'operation',
      mutation: typeof setInvoiceContact,
    ) {
      return moduleBuilder.dispatch(
        async ({ state }, payload: EntityId<OnSiteTranslationContractJson> | { contract: EntityId<OnSiteTranslationContractJson> }) => {
          if (typeof payload === 'number' || !('contract' in payload)) {
            payload = {
              contract: payload,
            };
          }

          const { contract } = payload;
          const contractId = getId(contract);
          const contacts = state[`${type}Contacts` as 'invoiceContacts' | 'creditContacts' | 'operationContacts'];

          const contact = await api.get<ContactJson>(`${apiBaseUrl}/${contractId}/${type}contact`);
          mutation({ contract, contact });

          return contacts[contractId];
        },
        action,
      );
    }

    function generateSaveContactAction(
      action: Actions.SAVE_INVOICE_CONTACT | Actions.SAVE_CREDIT_CONTACT | Actions.SAVE_OPERATION_CONTACT,
      type: 'invoice' | 'credit' | 'operation',
      mutation: typeof setInvoiceContact,
    ) {
      return moduleBuilder.dispatch(async ({ state }, { contract, contact }: { contract: EntityId<OnSiteTranslationContractJson>; contact: FormData<ContactJson> }) => {
        const contractId = getId(contract);
        const serverResult = await api.put<ContactJson>(`${apiBaseUrl}/${contractId}/${type}contact`, contact);
        const committed = contact.$commit(serverResult);
        mutation({ contract, contact: committed.$raw });
        return committed;
      }, action);
    }

    return {
      [Actions.ASSIGN_TRANSLATOR]: moduleBuilder.dispatch(async ({ state }, { contract, translator }: Parameters<Store[Actions.ASSIGN_TRANSLATOR]>[0]) => {
        const result = await api.put<OnSiteTranslationContractJson>(`${apiBaseUrl}/${contract.id}/assigntranslator`, translator);

        const committed = commitEntity(contract, result);

        state.items.set(committed);

        return committed;
      }, Actions.ASSIGN_TRANSLATOR),

      [Actions.SET_STATE]: moduleBuilder.dispatch(async ({ state }, { contract, state: contractState }: Parameters<Store[Actions.SET_STATE]>[0]) => {
        const result = await api.put<OnSiteTranslationContractJson>(`${apiBaseUrl}/${contract.id}/setstate`, contractState);

        const committed = commitEntity(contract, result);

        state.items.set(committed);

        return committed;
      }, Actions.SET_STATE),

      [Actions.READ_INVOICE_CONTACT]: generateReadContactAction(Actions.READ_INVOICE_CONTACT, 'invoice', setInvoiceContact),
      [Actions.SAVE_INVOICE_CONTACT]: generateSaveContactAction(Actions.SAVE_INVOICE_CONTACT, 'invoice', setInvoiceContact),

      [Actions.READ_CREDIT_CONTACT]: generateReadContactAction(Actions.READ_CREDIT_CONTACT, 'credit', setCreditContact),
      [Actions.SAVE_CREDIT_CONTACT]: generateSaveContactAction(Actions.SAVE_CREDIT_CONTACT, 'credit', setCreditContact),

      [Actions.READ_OPERATION_CONTACT]: generateReadContactAction(Actions.READ_OPERATION_CONTACT, 'operation', setOperationContact),
      [Actions.SAVE_OPERATION_CONTACT]: generateSaveContactAction(Actions.SAVE_OPERATION_CONTACT, 'operation', setOperationContact),

      [Actions.READ_EMAIL]: moduleBuilder.dispatch((ctx, { contract, emailTemplate, isTranslator }: Parameters<Store[Actions.READ_EMAIL]>[0]) => {
        const emailPath = isTranslator ? 'email/translator' : 'email/customer';
        return api.get<EmailJson>(`${apiBaseUrl}/${getId(contract)}/${emailPath}/${getId(emailTemplate)}`);
      }, Actions.READ_EMAIL),

      [Actions.READ_FILE_ATTACHMENT_COLLECTION]: moduleBuilder.dispatch(
        async (
          { state },
          payload:
            | EntityId<OnSiteTranslationContractJson>
            | {
                onSiteTranslationContractJson: EntityId<OnSiteTranslationContractJson>;
              },
        ) => {
          if (typeof payload === 'number' || !('onSiteTranslationContractJson' in payload)) {
            payload = {
              onSiteTranslationContractJson: payload,
            };
          }
          const { onSiteTranslationContractJson } = payload;
          const onSiteTranslationContractJsonId = getId(onSiteTranslationContractJson);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${onSiteTranslationContractJsonId}/fileattachmentcollection`);
          setFileAttachmentCollection({
            onSiteTranslationContractJson,
            fileEntityCollection,
          });

          return state.fileEntityCollections[onSiteTranslationContractJsonId];
        },
        Actions.READ_FILE_ATTACHMENT_COLLECTION,
      ),
      [Actions.UPLOAD_FILE]: moduleBuilder.dispatch(async ({ state }, { onSiteTranslationContractJson, file }: Parameters<Store[Actions.UPLOAD_FILE]>[0]) => {
        const result = await api.upload<FileEntityCollectionJson>({
          data: {
            'user-file': file,
          },
          method: 'POST',
          url: `${apiBaseUrl}/${onSiteTranslationContractJson.id}/file`,
        });
        state.fileEntityCollections[getId(onSiteTranslationContractJson)] = result;
        return result;
      }, Actions.UPLOAD_FILE),

      [Actions.DELETE_FILE]: moduleBuilder.dispatch(async ({ state }, { onSiteTranslationContractJson, fileEntity }: Parameters<Store[Actions.DELETE_FILE]>[0]) => {
        const onSiteTranslationContractJsonId = getId(onSiteTranslationContractJson);
        await api.delete(`${apiBaseUrl}/${onSiteTranslationContractJsonId}/file/${fileEntity.id}`);
        const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${onSiteTranslationContractJsonId}/fileattachmentcollection`);
        setFileAttachmentCollection({
          onSiteTranslationContractJson,
          fileEntityCollection,
        });
        return state.fileEntityCollections[onSiteTranslationContractJsonId];
      }, Actions.DELETE_FILE),
      [Actions.DOWNLOAD_FILE]: moduleBuilder.dispatch(async ({ state }, { onSiteTranslationContractJson, fileEntity }: Parameters<Store[Actions.DOWNLOAD_FILE]>[0]) => {
        const onSiteTranslationContractJsonId = getId(onSiteTranslationContractJson);
        return api.download({
          method: 'GET',
          url: `${apiBaseUrl}/${onSiteTranslationContractJsonId}/file/${fileEntity.id}`,
        });
      }, Actions.DOWNLOAD_FILE),
    };
  },
);
