











import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';
import { MessageBoxButton, MessageBoxButtonConfig, MessageBoxResult, DefaultButtons } from '@/plugins/msg';

@Component<MessageBox>({
  mounted()
  {
    this.handleOnMounted();
  },
})
export default class MessageBox extends Vue
{
  public visible = false;

  @Prop()
  public readonly title: string;

  @Prop()
  public readonly message: string;

  @Prop({ type: Boolean })
  public readonly modal: boolean;

  @Prop()
  public readonly buttons: MessageBoxButton[];

  @Prop()
  public readonly width: string | undefined;

  @Ref()
  public readonly dialog: Vue;

  public get computedButtons(): MessageBoxButtonConfig[]
  {
    return (this.buttons || []).map(btn =>
      {
        if (typeof btn === 'string')
        {
          return {
            id: btn,
            text: this.$root.$t(`${btn}.label`).toString(),
            color: this.__isDefaultPrimaryButton(btn) ? 'primary' : undefined,
          };
        }

        return btn;
      });
  }

  @Emit('result')
  public commit(btn: MessageBoxResult): MessageBoxResult
  {
    return btn;
  }

  private handleOnMounted(): void
  {
    this.dialog.$on('click:outside', () =>
    {
      if (!this.modal)
      {
        // there is a 0-timeout in click-outside directive, so don't destroy too early
        setTimeout(() => this.commit('overlay'), 1);
      }
    });
    this.visible = true;
  }

  private __isDefaultPrimaryButton(btn: DefaultButtons): boolean
  {
    const buttons = this.buttons || [];

    if (buttons.every(b => b === 'yes' || b === 'no' || b === 'cancel'))
    {
      return btn === 'yes';
    }
    else if (buttons.every(b => b === 'cancel' || b === 'ok'))
    {
      return btn === 'ok';
    }
    else
    {
      return buttons.every(b => b === btn);
    }
  }

  public __visible = false;
}
