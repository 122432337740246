import Vue from 'vue';

/**
 * This Plugin provides auto focus functionality to input fields with the v-focus directive.
 */
Vue.use(vue =>
  {
    vue.directive('focus', {
      bind: (el) => {
        const input = el.tagName === 'INPUT' ? el : el.querySelector('input');
        if (input)
        {
          vue.nextTick(() => input.focus());
        }
      },
    });
  });
