import Vue, { VNode } from 'vue';
import MessageBox from '../components/MessageBox.vue';
import { VTextField } from 'vuetify/lib';

declare module 'vue/types/vue'
{
  interface Vue
  {
    /**
     * Shows a message box.
     */
    $msg(title: string, message: string, ...buttons: MessageBoxButton[]): Promise<MessageBoxResult>;
    $msg(title: string, message: string, modal: boolean, ...buttons: MessageBoxButton[]): Promise<MessageBoxResult>;
    /**
     * Shows a prompt dialog.
     */
    $prompt(title: string, label: string, value: string, ...buttons: MessageBoxButton[]): Promise<[MessageBoxResult, string]>;
    $prompt(title: string, label: string, value: string, modal: boolean, ...buttons: MessageBoxButton[]): Promise<[MessageBoxResult, string]>;
  }
}

/**
 * Default button results.
 */
export type DefaultButtons = 'yes' | 'no' | 'cancel' | 'close' | 'ok';

export type MessageBoxResult = DefaultButtons | 'overlay' | string;

export interface MessageBoxButtonConfig
{
  id: MessageBoxResult;
  text: string;
  color?: string;
}

export type MessageBoxButton = DefaultButtons | MessageBoxButtonConfig;

// tslint:disable-next-line:max-line-length
function showMessageBox(this: Vue, title: string, content: string | VNode | VNode[] | ((msgBox: MessageBox) => VNode | VNode[]), modal: boolean, buttons: MessageBoxButton[], width?: string): Promise<MessageBoxResult>
{
  return new Promise<MessageBoxResult>((resolve, reject) =>
    {
      const mboxClass = Vue.extend(MessageBox);
      const mboxComponent = new mboxClass({
        propsData: {
          title,
          message: typeof content === 'string' ? content : undefined,
          modal,
          buttons,
          width,
        },
        parent: this.$root, // make all the plugins available (vuetify, i18n, ...)
      });

      if (typeof content !== 'string')
      {
        if (typeof content === 'function')
        {
          content = content(mboxComponent as any);
        }
        mboxComponent.$slots.default = this.$_.castArray(content);
      }

      mboxComponent.$mount();

      mboxComponent.$once('result', (value: MessageBoxResult) =>
        {
          mboxComponent.$destroy();

          resolve(value);
        });

      document.getElementById('app')!.appendChild(mboxComponent.$el);
    });
}

Vue.use(vue =>
  {
    vue.prototype.$msg = function(this: Vue, title: string, message: string, modalOrButton: MessageBoxButton | boolean, ...buttons: MessageBoxButton[]): Promise<MessageBoxResult>
    {
      let modal = true;

      if (modalOrButton === true || modalOrButton === false)
      {
        modal = modalOrButton;
      }
      else if (modalOrButton)
      {
        buttons.unshift(modalOrButton);
      }

      if (buttons.length === 0)
      {
        buttons.push('ok');
      }

      return showMessageBox.call(this, title, message, modal, buttons);
    };

    // tslint:disable-next-line:max-line-length
    vue.prototype.$prompt = function(this: Vue, title: string, label: string, value: string, modalOrButton: MessageBoxButton | boolean, ...buttons: MessageBoxButton[]): Promise<[MessageBoxResult, string]>
    {
      let modal = true;

      if (modalOrButton === true || modalOrButton === false)
      {
        modal = modalOrButton;
      }
      else if (modalOrButton)
      {
        buttons.unshift(modalOrButton);
      }

      if (buttons.length === 0)
      {
        buttons.push('cancel', 'ok');
      }

      let model = value || '';

      return showMessageBox.call(
        this,
        title,
        mbox => this.$createElement(VTextField, {
          props: {
            label,
            value: model,
          },
          directives: [
            {
              name: 'focus',
              value: true,
            },
          ],
          on: {
            input: (v: string) => model = v,
            keypress: (e: KeyboardEvent) => e.keyCode === 13 && (mbox as any).commit('ok'),
          },
        }),
        modal,
        buttons,
        '600px')
          .then(result => [result, model]);
    };
  });
