import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Load plugins
import i18n, { initI18n } from './plugins/i18n';
import vuetify from './plugins/vuetify';
import './plugins/lodash';
import './plugins/focus';
import './plugins/module';
import './plugins/validate';
import './plugins/msg';
import './plugins/alert';

// Load filters
import './filters';
import { IAxiosError } from './plugins/api';

// Load global components
import './components';

Vue.config.productionTip = false;
Vue.config.errorHandler = (err) =>
  {
    // ignore api errors as they are handled by the message output
    if (err && (err as IAxiosError).isApiError)
    {
      return;
    }
    // forward to default handling
    throw err;
  };

initI18n()
  .then(() =>
  {
    const app = new Vue({
      el: '#app',
      vuetify,
      router,
      store,
      i18n,
      render: (h) => h(App),
    });
  });
