


































































































import { Component } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { extend, validate } from 'vee-validate';
import { EntityStoreActions, Form as FormBase, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity, FormData, asFormData, WritableEntity } from '@/base';
import { getDuplicates } from '@/util';
import { TranslatorJson } from '../json';
import store, { Actions } from '../store';
// tslint:disable-next-line:max-line-length
import { store as costConfigurationStore, getCycleTypeDescription as getCostConfigurationCycleTypeDescription, CostConfigurationCycleTypeJson } from '@/modules/costconfiguration';
import {
  TranslatorCostConfigurationCycleJson,
  TranslatorCostConfigurationJson,
  getCycleDescription as getCostConfigurationCycleDescription,
} from '@/modules/costconfiguration/translator';
import { store as customerGroupStore, CustomerGroupJson } from '@/modules/customer/customergroup';
import { UserPermission } from '@/modules/user';

/**
 * The type of the active form item.
 */
interface ActiveItem {
  translator: TranslatorJson;
  costConfiguration: FormData<TranslatorCostConfigurationJson>;
}

@Component<CostConfiguration>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  methods: {
    getCostConfigurationCycleTypeDescription,
    getCostConfigurationCycleDescription,
  },
})
export default class CostConfiguration extends FormBase<ActiveItem> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.TRANSLATOR_WRITE);
  }

  /**
   * Returns the currently active cost configuration item.
   */
  public activeItem: ActiveItem | null = null;

  /**
   * Returns the cost configuration of the active item.
   */
  public get activeCostConfiguration(): FormData<TranslatorCostConfigurationJson> | null {
    return this.activeItem ? this.activeItem.costConfiguration : null;
  }

  /**
   * The store of customer groups.
   */
  private readonly customerGroupStore = customerGroupStore;

  /**
   * The store of cost configuration cycle types.
   */
  private readonly costConfigurationCycleTypeStore = costConfigurationStore.cycleType;


  private getWarningTitle(item: TranslatorCostConfigurationCycleJson): string | null
  {
    if (this.isDuplicate(item))
    {
      return this.$t('costConfiguration.costConfigurationCycles.translatorCostConfigurationCycleDuplicates') as string;
    }
    return null;
  }

  private removeEntity(entity: NewEntity<TranslatorCostConfigurationCycleJson> | WritableEntity<TranslatorCostConfigurationCycleJson>): void
  {
    if (this.activeCostConfiguration)
    {
      this.$_.pull(this.activeCostConfiguration.costConfigurationCycles, entity);
    }
    this.updateDuplicates();
  }

  private isDuplicate(item: TranslatorCostConfigurationCycleJson): boolean
  {
    return this.currentDuplicates.filter(id => item.id === id).length > 0;
  }

  private hasDuplicates(): boolean {
    return this.currentDuplicates.length > 0;
  }

  private currentDuplicates: number[] = [];

  private getCurrentDuplicates(): number[] {
    return this.currentDuplicates;
  }

  private getDuplicateKeys(customerGroups: NewEntity<CustomerGroupJson>[], costConfigurationCycleTypes: NewEntity<CostConfigurationCycleTypeJson>[]): string[] {
    const res: string[] = [];
    customerGroups.forEach(group => costConfigurationCycleTypes.forEach(type => res.push(`${group.id}_${type.id}`)));
    return res;
  }

  private updateDuplicates(): void
  {
    if (this.activeCostConfiguration && this.activeCostConfiguration.costConfigurationCycles)
    {
      const duplicates = getDuplicates(this.activeCostConfiguration.costConfigurationCycles, c =>
        c && c.customerGroups && c.costConfigurationCycleTypes ? this.getDuplicateKeys(c.customerGroups, c.costConfigurationCycleTypes) : null,
      );

      this.currentDuplicates = duplicates;
    }
    else {
      this.currentDuplicates = [];
    }
  }

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: ActiveItem | null): void {
    const activeTranslator = store[EntityStoreGetters.ACTIVE_ITEM];
    const activeItem = this.activeItem;

    // set the translator active
    store[EntityStoreMutations.ACTIVE_ITEM](item ? item.translator : null);
    this.activeItem = item;

    if (activeTranslator && (!item || item.translator !== activeTranslator.$raw)) {
      activeTranslator.$revoke();
    }

    if (activeItem && (!item || item.costConfiguration !== activeItem.costConfiguration)) {
      activeItem.costConfiguration.$revoke();
    }
  }

  private createCostConfigurationCycle(): NewEntity<TranslatorCostConfigurationCycleJson> {
    return createEntity<TranslatorCostConfigurationCycleJson>({
      firstCycleDuration: 0,
      firstCycleCostNet: 0,
      followingCycleDuration: 0,
      followingCycleCostNet: 0,
      costConfigurationCycleTypes: [],
      customerGroups: [],
    });
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: ActiveItem): boolean {
    return item.costConfiguration.$isDirty();
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void {
    this.$router.push({ name: 'translator' });
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: ActiveItem): Promise<void> {
    const result = await store[Actions.SAVE_COST_CONFIGURATION](item);
    // renew active item to trigger fresh changes
    this.changeActiveItem({
      translator: item.translator,
      costConfiguration: asFormData(result, false),
    });
    this.$success(this.$root.$t('form.save.success').toString());
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: ActiveItem): void {
    item.costConfiguration.$reset();
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const proceed = (translator: TranslatorJson, item: TranslatorCostConfigurationJson | NewEntity<TranslatorCostConfigurationJson>): Promise<void> => {
      return this.open(() => ({
        translator,
        costConfiguration: asFormData<TranslatorCostConfigurationJson>(item),
      })).then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    };

    const translatorId = parseInt(to.params.id, 10);

    store[EntityStoreActions.READ_ONE]({ id: translatorId, initial: true })
      .then(translator => {
        // read this separately to handle the 404 error
        return store[Actions.READ_COST_CONFIGURATION]({ translator })
          .then(item => proceed(translator, item))
          .catch(error => {
            // if not found, create a new one
            if (error.statusCode === 404) {
              return proceed(
                translator,
                createEntity<TranslatorCostConfigurationJson>({
                  fixedRateNet: 0,
                  costConfigurationCycles: [],
                }),
              );
            }
            return Promise.reject(error);
          });
      })
      .catch(() => next(from.fullPath === '/' && !from.name ? { name: 'translator', replace: true } : { path: from.fullPath, replace: true }));
  }
}
