

































import { Component, Prop, Vue } from 'vue-property-decorator';
import Details, { Property } from '@/components/Details.vue';
import { UserJson, getFullName } from '@/modules/user';
import { DocumentTranslationContractJson } from '../json';

@Component<EventDetails>({
  module: 'documenttranslation', // override because it is used in calendar
  filters: {
    fullName(item: UserJson | null, fallbackToId?: boolean): string
    {
      return item ? getFullName(item, fallbackToId) : '';
    },
  },
  methods: {
    getFullName,
  },
  components: {
    Details,
  },
})
export default class EventDetails extends Vue
{
  private showCustomerCommentDialog = false;
  private showTranslatorCommentDialog = false;

  @Prop({ required: true })
  public readonly contract: DocumentTranslationContractJson;

  private get properties(): Property[]
  {
    return [
      {
        value: 'contractState',
        title: this.$t('event.details.contractState').toString(),
      },
      {
        value: 'businessUnit.name',
        title: this.$t('event.details.businessUnit').toString(),
      },
      {
        value: 'customer',
        title: this.$t('event.details.customer').toString(),
      },
      {
        value: 'translator',
        title: this.$t('event.details.translator').toString(),
      },
      {
        value: 'contractDate',
        title: this.$t('event.details.contractDate').toString(),
      },
      {
        value: 'completeUntil',
        title: this.$t('event.details.completeUntil').toString(),
      },
    ];
  }
}

