import { api } from '@/plugins';
import { CashAccountTransactionJson } from './json';
import { NewEntityProperties, EntityPagingStore, createEntityPagingStore, EntityPagingStoreState, commitEntity } from '@/base';

export enum Actions {
  ACCEPT_INCOMPLETE_ASSIGNMENT = 'dispatchAcceptIncompleteAssignment',
}

export interface State extends EntityPagingStoreState<CashAccountTransactionJson>
{

}

export interface Store extends EntityPagingStore<CashAccountTransactionJson>
{
  [Actions.ACCEPT_INCOMPLETE_ASSIGNMENT](payload: { cashAccountTransaction: CashAccountTransactionJson }): Promise<CashAccountTransactionJson>;
}

const apiBaseUrl = 'cashaccounttransactions';

function createItem(): NewEntityProperties<CashAccountTransactionJson>
{
  // should be never called
  return {
    ctValue: null,
    ctUsage: null,
    transactionDate: null,
    businessUnit: null,
    cashAccountTransactionInvoicePositions: [],
    cashAccountTransactionCreditPositions: [],
    cashAccountTransactionExternalDocumentPositions: [],
  };
}

export const store = createEntityPagingStore<CashAccountTransactionJson, State, Store>(
  'cashaccounttransaction',
  apiBaseUrl,
  createItem,
  {},
  (moduleBuilder) =>
  {
     return {
      [Actions.ACCEPT_INCOMPLETE_ASSIGNMENT]: moduleBuilder.dispatch(async ({ state }, { cashAccountTransaction }: Parameters<Store[Actions.ACCEPT_INCOMPLETE_ASSIGNMENT]>[0]) => {
        const result = await api.put<CashAccountTransactionJson>(`${apiBaseUrl}/${cashAccountTransaction.id}/acceptincompleteassignment`);
        const committed = commitEntity(cashAccountTransaction, result);
        state.items.set(committed);
        return committed;
      }, Actions.ACCEPT_INCOMPLETE_ASSIGNMENT),
     };
  });
