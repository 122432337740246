import { ContractJson } from '../json';

export enum CommunicationSystem
{
  JITSI = 'JITSI',
  BRAVIS = 'BRAVIS',
}

export interface VideoTranslationContractJson extends ContractJson
{
  beginDateTime: Date;
  plannedTranslationDuration: number;
  communicationSystem: CommunicationSystem;
}
