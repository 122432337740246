import { BaseEntity } from '@/base';
import { BusinessUnitJson } from '../businessunit';
import { ExternalDocumentJson } from '../financialdocument/externaldocument';
import { CreditJson } from '../financialdocument/credit';
import { InvoiceJson } from '../financialdocument/invoice';



export interface CashAccountTransactionJson extends BaseEntity
{
  ctValue: number;
  ctUsage: number;
  transactionDate: Date;
  businessUnit: BusinessUnitJson;
  cashAccountTransactionInvoicePositions: CashAccountTransactionPositionJson<InvoiceJson>[];
  cashAccountTransactionCreditPositions: CashAccountTransactionPositionJson<CreditJson>[];
  cashAccountTransactionExternalDocumentPositions: CashAccountTransactionPositionJson<ExternalDocumentJson>[];
}

export interface CashAccountTransactionPositionJson<T extends DocumentValueHolder> extends BaseEntity
{
  assignedPriceNet: number;
  assignedPriceGross: number;
  entity: T | null;
}

export interface DocumentValueHolder extends BaseEntity
{
  businessUnit: BusinessUnitJson;
  documentValueNet: number;
  documentValueGross: number;
}

export enum CashAccountTransactionAssignmentState {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNMENT_INCOMPLETE_MANUAL = 'ASSIGNMENT_INCOMPLETE_MANUAL',
  ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED = 'ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED',
  ASSIGNMENT_COMPLETE_MANUAL = 'ASSIGNMENT_COMPLETE_MANUAL',
}

