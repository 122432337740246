















import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';

const valueToModelRx = /^(\d+)(?:(\.)(\d+))?$/;

@Component<NumberField>({
  inheritAttrs: false,
  created(): void
  {
    this.__updateNumberFormatter();
    this.__localeSubscriber = this.$i18n.vm.$watch('locale', () => this.__updateOnLocaleChange());
    this.model = this.value == null ? this.nullable ? '' : '0' : this.numberFormat[0].format(this.value);
  },
  destroyed()
  {
    if (this.__localeSubscriber)
    {
      this.__localeSubscriber();
    }
  },
})
export default class NumberField extends Vue
{
  /**
   * Whether negatives are allowed.
   */
  @Prop({ default: false, type: Boolean })
  public readonly allowNegative: boolean;

  /**
   * Whether null values are allowed. Otherwise 0 is used as fallback value. Defaults to true.
   */
  @Prop({ default: true, type: Boolean })
  public readonly nullable: boolean;

  /**
   * Whether decimals/fraction are allowed. Defaults to true.
   */
  @Prop({ default: true, type: Boolean })
  public readonly allowDecimals: boolean;

  /**
   * The number style to use. Must be one of i18n.$numberFormats or a Intl.NumberFormat options object.
   */
  @Prop({ required: true })
  public readonly format: string | Intl.NumberFormatOptions;

  /**
   * The (initial) value of the field.
   */
  @Prop({ default: null })
  public readonly value: number | null;

  /**
   * The value of the field.
   */
  private model: string = this.value == null ? this.nullable ? '' : '0' : `${this.value}`;

  /**
   * The current value as number.
   */
  private valueAsNumber: number | null = this.value == null ? this.nullable ? null : 0 : this.value;

  /**
   * Set the isFocused state on focus.
   */
  private onFocus(): void
  {
    this.__updateValue();
    try
    {
      this.$nextTick(() => ((this.$refs.field as any).$refs.input as HTMLInputElement).select());
    }
    catch (e)
    {
      // ignore
    }
  }

  /**
   * Set the isFocused state on blur.
   */
  private onBlur(): void
  {
    this.__updateValue();
  }

  /**
   * Updates the value on every key strike or input event.
   */
  private onInput(): void
  {
    this.__updateValue(true);
  }

  /**
   * Updates the value based on the current model.
   */
  private __updateValue(onInput?: boolean, value?: number | null): void
  {
    const isFocused = !!(this.$refs.field as any).isFocused;
    if (value === undefined)
    {
      value = !this.model ? null : this.__parseValue(this.model);
    }

    this.valueAsNumber = value == null && !this.nullable ? 0 : value;

    if (!isFocused)
    {
      this.model = value == null ? this.nullable ? '' : '0' : this.numberFormat[0].format(value);
    }
    else if (!onInput)
    {
      this.model = value == null ? '' : value.toString().replace(valueToModelRx, ($$, $1, $2, $3) => `${$1}${(this.numberFormat[3] > 0 || $3 ? this.numberFormat[1] : '')}${String($3 || '').padEnd(this.numberFormat[3], '0')}`);
    }
  }

  /**
   * Called when the valueAsNumber property changes.
   */
  @Watch('valueAsNumber')
  @Emit('input')
  private __onNumberValueChange(value: number | null): void
  {
    // just emit the value to sync the bound model
  }

  /**
   * Called when the value changed from outside.
   */
  @Watch('value')
  private __onValueChange(value: any): void
  {
    // undefined as null
    if (value == null)
    {
      value = this.nullable ? null : 0;
    }

    // ignore circular updates
    if (value === this.model)
    {
      return;
    }

    // check for value updates from outside
    const num = this.__parseValue(value);
    if (num !== this.valueAsNumber)
    {
      this.__updateValue(false, num);
    }
  }

  /**
   * Updates the number formatter.
   */
  private __updateNumberFormatter(): void
  {
    if (!this.numberFormat[1] || this.numberFormat[0].resolvedOptions().locale !== this.$i18n.locale)
    {
      const options = typeof this.format === 'string'
        ? this.$i18n.getNumberFormat(this.$i18n.locale)[this.format] as Intl.NumberFormatOptions
        : this.format;
      const numberFormat = new Intl.NumberFormat(this.$i18n.locale, options);
      const resolvedOptions = numberFormat.resolvedOptions();

      const formattedNumber = numberFormat.formatToParts(1234.5678);
      const decimal = formattedNumber.find(g => g.type === 'decimal');
      const group = formattedNumber.find(g => g.type === 'group');
      const fraction = formattedNumber.find(g => g.type === 'fraction');

      const numberOnlyRegexp = new RegExp(`^\\d+(?:[\\.\\${(decimal ? this.$_.escapeRegExp(decimal.value) : '.')}]\\d+)?$`);
      const trimRegexp = new RegExp(`[\\s${formattedNumber.filter(
        g => g.type === 'currency' || g.type === 'group' || g.type === 'percentSign').map(g => this.$_.escapeRegExp(g.value)).join('') || ''}]+`, 'g');

      this.numberFormat = [
        numberFormat,
        decimal ? (decimal.value || '.') : '.',
        group ? group.value : '',
        resolvedOptions.minimumFractionDigits,
        resolvedOptions.maximumFractionDigits,
        numberOnlyRegexp,
        trimRegexp,
      ];
    }
  }

  /**
   * Parses the given value as number.
   */
  private __parseValue(value: any): number | null
  {
    if (value == null)
    {
      if (this.nullable)
      {
        return null;
      }

      value = 0;
    }

    if (typeof value !== 'number')
    {
      value = String(value);
      // allow a number to be pasted from "everywhere"
      if (!this.numberFormat[5].test(value))
      {
        value = value.split(this.numberFormat[6]).join('');
      }

      if (this.numberFormat[1] !== '.')
      {
        value = value.replace(this.numberFormat[1], '.');
      }
    }

    let num = this.allowDecimals ? parseFloat(value as any) : parseInt(value as any, 10);

    if (isNaN(num))
    {
      return null;
    }

    if (!this.allowNegative && num < 0)
    {
      num = 0;
    }

    const rounding = Math.pow(10, this.numberFormat[4]);
    return Math.round(num * rounding) / rounding;
  }

  /**
   * Updates the field output on locale change.
   */
  private __updateOnLocaleChange(): void
  {
    const value = this.__parseValue(this.model);

    this.__updateNumberFormatter();

    this.__updateValue(false, value);
  }

  /**
   * Number format options. This is an initial value.
   */
  private numberFormat: [Intl.NumberFormat, string, string, number, number, RegExp, RegExp] = [new Intl.NumberFormat(undefined), '', '', 0, 0, /^$/, /^$/];
  private __localeSubscriber: () => void;
}

