import i18n from './i18n';
import { configure, extend } from 'vee-validate';
import { required, email, numeric, length, confirmed, size } from 'vee-validate/dist/rules';

configure({
  mode: 'eager',
  useConstraintAttrs: false,
  defaultMessage(field, values)
  {
    return i18n.t(`$validation.${values!._rule_}`, values).toString();
  },
});

for (const [key, value] of Object.entries({ required, email, numeric, length, confirmed, size }))
{
  extend(key, value);
}
