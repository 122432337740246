// This method is just to avoid some type difficulties.

/**
 * Returns an enumeration by a given name if present, otherwise undefined.
 * @param enumType The enum type.
 * @param name The name of an enum field.
 */
export function getEnumByName<T extends Enum<T>>(enumType: T, name: string): T[keyof T] | undefined
{
  const value: T[keyof T] | undefined = enumType[name as keyof T] as T[keyof T] | undefined;
  if (value === name)
  {
    return value;
  }

  return undefined;
}

/**
 * Returns the fields of an enum type.
 */
export function getEnumFields<T extends Enum<T>>(enumType: T): T[keyof T][]
{
  // filter numeric keys. Numeric enumerations have its values used as keys as well for reverse access
  return (Object.keys(enumType) as T[keyof T][])
    .filter(key => isNaN(key as any));
}
