import api from '@/plugins/api';
import { InvoiceJson, InvoiceState } from './json';
import { EntityPagingStoreState, EntityPagingStore, EntityStoreActions, createEntityPagingStore, EntityId, getId, commitEntity, FormData } from '@/base';
import { DocumentTranslationContractJson } from '@/modules/contract/documenttranslation';
import { OnSiteTranslationContractJson } from '@/modules/contract/onsitetranslation';
import { VideoTranslationContractJson } from '@/modules/contract/videotranslation';
import { ModuleApiData } from '@/store';
import { EmailTemplateJson } from '@/modules/emailtemplate';
import { EmailJson } from '@/modules/email';

export enum Actions {
  READ_DOCUMENT_TRANSLATION_CONTRACT = 'dispatchReadDocumentTranslationContract',
  CREATE_DOCUMENT_TRANSLATION_CONTRACT = 'dispatchCreateDocumentTranslationContract',
  READ_ON_SITE_TRANSLATION_CONTRACT = 'dispatchReadOnSiteTranslationContract',
  CREATE_ON_SITE_TRANSLATION_CONTRACT = 'dispatchCreateOnSiteTranslationContract',
  READ_VIDEO_TRANSLATION_CONTRACT = 'dispatchReadVideoTranslationContract',
  CREATE_VIDEO_TRANSLATION_CONTRACT = 'dispatchCreateVideoTranslationContract',
  CREATE_INVOICE_PDF = 'dispatchCreateInvoicePdf',
  DOWNLOAD_INVOICE_PDF = 'dispatchDownloadInvoicePdf',
  DOWNLOAD_INVOICE_PDF_ZIP_CHECK = 'dispatchDownloadInvoicePdfZipCheck',
  DOWNLOAD_INVOICE_PDF_ZIP = 'dispatchDownloadInvoicePdfZip',
  SET_STATE = 'dispatchSetState',
  READ_EMAIL = 'dispatchReadEmail',
  CANCEL_INVOICE = 'dispatchCancelInvoice',
}

export interface State extends EntityPagingStoreState<InvoiceJson> {}

export interface Store extends EntityPagingStore<InvoiceJson> {
  // hide actions
  [EntityStoreActions.CREATE_OR_UPDATE]: never;
  [EntityStoreActions.DELETE]: never;
  /**
   * Reads the invoice for a document translation contract.
   */
  [Actions.READ_DOCUMENT_TRANSLATION_CONTRACT](contract: EntityId<DocumentTranslationContractJson>): Promise<InvoiceJson[]>;
  /**
   * Creates an invoice for document translation contracts.
   */
  [Actions.CREATE_DOCUMENT_TRANSLATION_CONTRACT](contracts: ModuleApiData<DocumentTranslationContractJson[]>): Promise<InvoiceJson>;
  /**
   * Reads the invoice for a onsite translation contract.
   */
  [Actions.READ_ON_SITE_TRANSLATION_CONTRACT](contract: EntityId<OnSiteTranslationContractJson>): Promise<InvoiceJson[]>;
  /**
   * Creates an invoice for onsite translation contracts.
   */
  [Actions.CREATE_ON_SITE_TRANSLATION_CONTRACT](contracts: ModuleApiData<OnSiteTranslationContractJson[]>): Promise<InvoiceJson>;
  /**
   * Reads the invoice for a video translation contract.
   */
  [Actions.READ_VIDEO_TRANSLATION_CONTRACT](contract: EntityId<VideoTranslationContractJson>): Promise<InvoiceJson[]>;
  /**
   * Creates an invoice for video translation contracts.
   */
  [Actions.CREATE_VIDEO_TRANSLATION_CONTRACT](contracts: ModuleApiData<VideoTranslationContractJson[]>): Promise<InvoiceJson>;
  /**
   * Creates an invoice PDF.
   */
  [Actions.CREATE_INVOICE_PDF](invoice: FormData<InvoiceJson>): Promise<InvoiceJson>;
  /**
   * Downloads a created invoice PDF.
   */
  [Actions.DOWNLOAD_INVOICE_PDF](invoice: EntityId<InvoiceJson>): Promise<void>;
  /**
   * Returns InvoiceJsons, which has no PDF-File.
   */
  [Actions.DOWNLOAD_INVOICE_PDF_ZIP_CHECK](invoices: InvoiceJson[]): Promise<InvoiceJson[]>;
  /**
   * Downloads created invoice PDF-Files as ZIP archive.
   */
  [Actions.DOWNLOAD_INVOICE_PDF_ZIP](invoices: InvoiceJson[]): Promise<void>;
  /**
   * Sets the invoice to the given state.
   */
  [Actions.SET_STATE](payload: { invoice: InvoiceJson; state: InvoiceState }): Promise<InvoiceJson>;

  /**
   * Sets the invoice to the cancel state.
   */
  [Actions.CANCEL_INVOICE](payload: { invoice: InvoiceJson; }): Promise<InvoiceJson>;

  [Actions.READ_EMAIL](payload: { invoice: EntityId<InvoiceJson>; emailTemplate: EntityId<EmailTemplateJson>; isTranslator: boolean }): Promise<EmailJson>;
}

const invoiceBaseUrl = 'invoices';

export const store = createEntityPagingStore<InvoiceJson, State, Store>(
  'invoice',
  invoiceBaseUrl,
  () => {
    throw new Error('Creating invoices is not supported.');
  },
  {},
  moduleBuilder => {
    return {
      [Actions.READ_DOCUMENT_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contract: EntityId<DocumentTranslationContractJson>) => {
        const result = await api.get<InvoiceJson[]>(`${invoiceBaseUrl}/documenttranslationcontract/${getId(contract)}`);

        state.items.set(...result);

        return result;
      }, Actions.READ_DOCUMENT_TRANSLATION_CONTRACT),

      [Actions.CREATE_DOCUMENT_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contracts: ModuleApiData<DocumentTranslationContractJson[]>) => {
        const result = await api.post<InvoiceJson>(`${invoiceBaseUrl}/documenttranslationcontract`, contracts.data, { params: { module: contracts.module } });
        state.items.set(result);

        return result;
      }, Actions.CREATE_DOCUMENT_TRANSLATION_CONTRACT),
      [Actions.READ_ON_SITE_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contract: EntityId<OnSiteTranslationContractJson>) => {
        const result = await api.get<InvoiceJson[]>(`${invoiceBaseUrl}/onsitetranslationcontract/${getId(contract)}`);

        state.items.set(...result);

        return result;
      }, Actions.READ_ON_SITE_TRANSLATION_CONTRACT),

      [Actions.CREATE_ON_SITE_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contracts: ModuleApiData<OnSiteTranslationContractJson[]>) => {
        const result = await api.post<InvoiceJson>(`${invoiceBaseUrl}/onsitetranslationcontract`, contracts.data, { params: { module: contracts.module } });

        state.items.set(result);

        return result;
      }, Actions.CREATE_ON_SITE_TRANSLATION_CONTRACT),
      [Actions.READ_VIDEO_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contract: EntityId<VideoTranslationContractJson>) => {
        const result = await api.get<InvoiceJson[]>(`${invoiceBaseUrl}/videotranslationcontract/${getId(contract)}`);

        state.items.set(...result);

        return result;
      }, Actions.READ_VIDEO_TRANSLATION_CONTRACT),

      [Actions.CREATE_VIDEO_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contracts: ModuleApiData<VideoTranslationContractJson[]>) => {
        const result = await api.post<InvoiceJson>(`${invoiceBaseUrl}/videotranslationcontract`, contracts.data, { params: { module: contracts.module } });

        state.items.set(result);

        return result;
      }, Actions.CREATE_VIDEO_TRANSLATION_CONTRACT),

      [Actions.CREATE_INVOICE_PDF]: moduleBuilder.dispatch(async ({ state }, invoice: FormData<InvoiceJson>) => {
        const result = await api.post<InvoiceJson>(`${invoiceBaseUrl}/${invoice.id}/invoicepdf`);
        return invoice.$commit(result);
      }, Actions.CREATE_INVOICE_PDF),

      [Actions.DOWNLOAD_INVOICE_PDF]: moduleBuilder.dispatch(({ state }, invoice: EntityId<InvoiceJson>) => {
        return api.download({
          method: 'GET',
          url: `${invoiceBaseUrl}/${getId(invoice)}/invoicepdf`,
          headers: {
            Accept: 'application/pdf',
          },
        });
      }, Actions.DOWNLOAD_INVOICE_PDF),
      [Actions.DOWNLOAD_INVOICE_PDF_ZIP_CHECK]: moduleBuilder.dispatch(async ({ state }, invoices: InvoiceJson[]) => {
        const result = await api.post<InvoiceJson[]>(`${invoiceBaseUrl}/invoicepdfzipcheck`, invoices);
        return result;
      }, Actions.DOWNLOAD_INVOICE_PDF_ZIP_CHECK),
      [Actions.DOWNLOAD_INVOICE_PDF_ZIP]: moduleBuilder.dispatch(({ state }, invoices: InvoiceJson[]) => {
        return api.download({
          data: invoices,
          method: 'POST',
          url: `${invoiceBaseUrl}/invoicepdfzip`,
          headers: {
            Accept: 'application/pdf',
          },
        });
      }, Actions.DOWNLOAD_INVOICE_PDF_ZIP),
      [Actions.SET_STATE]: moduleBuilder.dispatch(async ({ state }, { invoice, state: invoiceState }: Parameters<Store[Actions.SET_STATE]>[0]) => {
        const result = await api.put<InvoiceJson>(`${invoiceBaseUrl}/${invoice.id}/setstate`, invoiceState);

        const committed = commitEntity(invoice, result);

        state.items.set(committed);

        return committed;
      }, Actions.SET_STATE),
      [Actions.CANCEL_INVOICE]: moduleBuilder.dispatch(async ({ state }, { invoice }: Parameters<Store[Actions.CANCEL_INVOICE]>[0]) => {
        const result = await api.put<InvoiceJson>(`${invoiceBaseUrl}/${invoice.id}/cancelinvoice`);

        const committed = commitEntity(invoice, result);

        state.items.set(result);

        return committed;
      }, Actions.CANCEL_INVOICE),
      [Actions.READ_EMAIL]: moduleBuilder.dispatch((ctx, { invoice, emailTemplate, isTranslator }: Parameters<Store[Actions.READ_EMAIL]>[0]) => {
        const emailPath = isTranslator ? 'email/translator' : 'email/customer';
        return api.get<EmailJson>(`${invoiceBaseUrl}/${getId(invoice)}/${emailPath}/${getId(emailTemplate)}`);
      }, Actions.READ_EMAIL),
    };
  },
);

export default store;
