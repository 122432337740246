import i18n from '@/plugins/i18n';
import { EntityPagingStoreActions } from '@/base';
import { EventHandler, Event } from '@/calendar';
import store from './store';
import EventDetails from './components/EventDetails.vue';
import { TaskState } from './json';
import { CalendarObjectTypes } from '../calendar';
import { hasPermission } from '@/json';
import { UserPermission } from '../user';

export const eventHandler: EventHandler = {
  component: {
    extends: EventDetails,
  },

  async getEvents(from, until, businessUnitId, languageId, translatorId, customerId, calendarObjectType)
  {
    if (calendarObjectType && calendarObjectType !== CalendarObjectTypes.TASK || !hasPermission(UserPermission.APPOINTMENT_READ))
    {
      return [];
    }
    // hide Tasks if any other filter is set.
    if (businessUnitId || languageId || translatorId || customerId)
    {
      return [];
    }
    const result = await store[EntityPagingStoreActions.LIST_ALL]({
      filter: {
        taskDateFrom: from,
        taskDateTo: until,
        taskDate: !null,
        taskState: TaskState.CREATED,
      },
    });

    if (!result)
    {
      return [];
    }

    return result.content.map<Event>(t => ({
      start:  t.taskDate!,
      title: `${t.taskName}`,
      color: t.taskDescription ? 'calendar_task_color darken-2' : 'calendar_task_color',
      route: `/task/${t.id}`,
      appointmentComment: t.taskDescription,
      componentBindings: {
        task: t,
      },
    }));
  },
};

export default eventHandler;
