




































































































import { Component } from 'vue-property-decorator';
import { EntityList, HeaderConfig, EntityStoreActions, EntityStoreGetters, ListAction } from '@/base';
import store from '../store';
import { store as customerStore } from '@/modules/customer';
import { CustomerJson } from '../json';
import { sortByFullName, getFullName, UserPermission } from '@/modules/user';
import { store as costCenterStore } from '@/modules/costcenter';
import { store as customerGroupStore } from '@/modules/customer/customergroup';
import { EmailTemplateJson, EmailTemplateType, store as emailTemplateStore } from '@/modules/emailtemplate';

@Component<List>({
  filters: {
    fullName: (item: CustomerJson | null, fallbackToId?: boolean): string => {
      return item ? getFullName(item, fallbackToId) : '';
    },
  },
  methods: {
    getFullName,
  },
  created() {
    this.init();
  },
})
export default class List extends EntityList<CustomerJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.CUSTOMER_WRITE);
  }

  private showCommentDialog = false;

  private contentDialog = '';

  private handleIconClick(subject: string): void {
    this.contentDialog = subject;
  }
  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'lastName, firstName' }];

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    firstName: '',
    lastName: '',
    email: '',
    customerGroupId: null as number | null,
    costCenterId: null as number | null,
  };

  private readonly costCenterStore = costCenterStore;
  private readonly customerGroupStore = customerGroupStore;
  private readonly customerStore = customerStore;

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[] {
    return [
      {
        value: 'lastName',
        text: this.$t('list.header.name').toString(),
        sort: (a, b) => sortByFullName(a, b, this.$i18n.locale),
        filterProperty: ['firstName', 'lastName'],
      },
      {
        value: 'email',
        text: this.$t('list.header.email').toString(),
      },
      {
        value: 'customerGroup.name',
        text: this.$t('list.header.customerGroup').toString(),
        filterProperty: 'customerGroupId',
      },
      {
        value: 'costCenter.name',
        text: this.$t('list.header.costCenter').toString(),
        filterProperty: 'costCenterId',
      },
      {
        value: 'customerComment',
        text: this.$t('list.header.customerComment').toString(),
        sortable: false,
        filterProperty: ['customerComment'],
      },
    ];
  }

  protected getDeleteConfirmationMessage(item: CustomerJson): { title?: string; message: string } {
    const args = Object.assign({ fullName: getFullName(item, true) }, item);
    return {
      title: this.$t('list.delete.title', args).toString(),
      message: this.$t('list.delete.message', args).toString(),
    };
  }

  protected getActions(): ListAction<CustomerJson>[] {
    if (this.hasWritePermission()) {
      return [
        {
          icon: 'edit',
          handler: item => this.editItem(item),
          text: this.$root.$t('edit.label').toString(),
        },
        {
          icon: 'delete',
          handler: item => this.deleteItem(item),
          isVisible: item => item.id > 0,
          text: this.$root.$t('delete.label').toString(),
          color: 'delete',
        },
        {
          icon: 'sendEmail',
          handler: item => this.showSendEmailDialog(item, false),
          // isVisible: item => this.isAllowForAdminsAndOfficeWorkers(),
          text: this.$t('list.actions.sendEmailToCustomer').toString(),
        },
      ];
    }
    return [
      {
        icon: 'edit',
        handler: item => this.editItem(item),
        text: this.$root.$t('edit.label').toString(),
      },
    ];
  }

  // E-mails

  /**
   * Variable used to display the options in the email dialog.
   */
  protected emailTemplatesForCustomer: {
    customer: CustomerJson;
    emailTemplates: EmailTemplateJson[];
    isTranslator: boolean;
  } | null = null;

  protected showSendEmailDialog(item: CustomerJson, isTranslator: boolean): void {
    emailTemplateStore[EntityStoreActions.READ_ALL](true);
    const res = emailTemplateStore[EntityStoreGetters.ITEMS].filter(t => t.emailTemplateType === EmailTemplateType.CUSTOMER);

    this.emailTemplatesForCustomer = {
      customer: item,
      emailTemplates: res,
      isTranslator,
    };
  }

  protected sendEmail(item: CustomerJson, emailTemplate: EmailTemplateJson, isTranslator: boolean): void {
    const emailPath = isTranslator ? 'email/translator' : 'email/customer';

    // forward to route
    this.$router
      .push({
        path: `${item.id}/${emailPath}/${emailTemplate.id}`,
        append: true,
      })
      // close dialog
      .then(() => null);
  }
}
