import i18n from '@/plugins/i18n';

const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

export function formatFileSize(value: number, locale?: string): string
{
  if (value == null || isNaN(value))
  {
    return '';
  }

  if (value === 1)
  {
    return '1\u00a0Byte';
  }

  let i = 0;
  while (value > 1000 && i + 1 < units.length)
  {
    value /= 1024;
    i++;
  }

  return `${i18n.n(parseFloat(value.toPrecision(14)), i === 0 ? 'integer' : 'fileSize')}\u00a0${units[i]}`;
}
