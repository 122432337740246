



















import { Component, Vue, Prop } from 'vue-property-decorator';
import Alerts from './Alerts.vue';

// tslint:disable-next-line:no-var-requires
const companyLogoSrc = process.env.VUE_APP_COMPANY_LOGO ? require(`@/${process.env.VUE_APP_COMPANY_LOGO}`) : null;

/**
 * Component to place content as dialog with the company logo as background (Login dialog, password reset dialog, etc.).
 */
@Component({
  components: {
    Alerts,
  },
})
export default class DialogContent extends Vue
{
  /**
   * The title to display.
   */
  @Prop({ required: true })
  public readonly title: string;

  /**
   * The background-image value for the company logo.
   */
  private readonly backgroundImage = companyLogoSrc ? `url(${companyLogoSrc})` : 'none';
}
