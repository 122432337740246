import { CostConfigurationCycleTypeJson } from './json';

export function getCycleTypeDescription(item: CostConfigurationCycleTypeJson, fallbackToId?: boolean): string
{
  if (item.costType)
  {
    if (item.costUnit)
    {
      return `${item.costType}, (${item.costUnit})`;
    }
    return item.costType;
  }

  return fallbackToId ? `(ID: ${item.id})` : '';
}
