import { SystemConfigurationJson, SystemConfigurationKey } from './json';
import { FormData, EditFormData, asFormData, WritableEntity, NewEntity} from '@/base';
import { SharedState } from '@/store';
import { getStoreBuilder } from 'vuex-typex';
import api from '@/plugins/api';

export enum Getters
{
  ACTIVE_ITEM = 'getActiveItem',
}

export enum Actions
{
  READ_ONE = 'dispatchReadOne',
  UPDATE = 'dispatchUpdate',
}

export enum Mutations
{
  ACTIVE_ITEM = 'commitActiveItem',
}

export interface State
{
  activeItem: EditFormData<SystemConfigurationJson> | null;
}

export interface Store
{
  readonly [Getters.ACTIVE_ITEM]: EditFormData<SystemConfigurationJson> | null;
  [Actions.READ_ONE](key: SystemConfigurationKey): Promise<SystemConfigurationJson>;
  [Actions.UPDATE](item: FormData<SystemConfigurationJson>): Promise<EditFormData<SystemConfigurationJson>>;
  [Mutations.ACTIVE_ITEM](item: SystemConfigurationJson | null | { item: SystemConfigurationJson | null; useExisting?: boolean }): void;
}

const apiBaseUrl = 'systemconfiguration';
const moduleBuilder = getStoreBuilder<SharedState>().module<State>('systemconfiguration', {
  activeItem: null,
});
const getActiveItem = moduleBuilder.read(state => state.activeItem, Getters.ACTIVE_ITEM);

export const store: Store = {
  get [Getters.ACTIVE_ITEM]()
    {
      return getActiveItem();
    },

  [Actions.READ_ONE]: moduleBuilder.dispatch(async ({state}, key: SystemConfigurationKey) =>
    {
      return await api.get<SystemConfigurationJson>(`${apiBaseUrl}/${key}`);
    }, Actions.READ_ONE),

  [Actions.UPDATE]: moduleBuilder.dispatch(async ({ state }, item: FormData<SystemConfigurationJson>) =>
    {
      const result = await api.put<SystemConfigurationJson>(`${apiBaseUrl}${(apiBaseUrl.endsWith('/') ? '' : '/')}${item.$original.key}`, item.value);
      const committedItem = item.$commit(result);

      store[Mutations.ACTIVE_ITEM]({item: committedItem, useExisting: state.activeItem !== item});

      return committedItem;
    }, Actions.UPDATE),

  [Mutations.ACTIVE_ITEM]: moduleBuilder.commit((state, item: SystemConfigurationJson | null | { item: SystemConfigurationJson | null; useExisting: boolean; }) =>
    {
      let useExisting = true;
      if (item && 'item' in item && 'useExisting' in item)
      {
        useExisting = item.useExisting;
        item = item.item;
      }
      state.activeItem = item ? asFormData(item, useExisting) : null;
    }, Mutations.ACTIVE_ITEM),
};

getStoreBuilder<SharedState>().registerModule('systemconfiguration');

export default store;
