import { CostCenterJson } from './json';
import { EntityStore, createEntityStore, EntityStoreState, NewEntityProperties, EntityId, FormData, getId } from '@/base';
import { ContactJson } from '../contact';
import { api } from '@/plugins';

export interface State extends EntityStoreState<CostCenterJson>
{
  /**
   * The list of invoice contacts per cost center id.
   */
  invoiceContacts: _.NumericDictionary<ContactJson>;
}

export enum Actions
{
  READ_INVOICE_CONTACT = 'dispatchReadInvoiceContact',
  SAVE_INVOICE_CONTACT = 'dispatchSaveInvoiceContact',
}

export enum Mutations
{
  INVOICE_CONTACT = 'commitInvoiceContact',
}

export interface Store extends EntityStore<CostCenterJson>
{
  [Actions.READ_INVOICE_CONTACT](
    payload: EntityId<CostCenterJson> | { costCenter: EntityId<CostCenterJson> }): Promise<ContactJson>;
  [Actions.SAVE_INVOICE_CONTACT](
    payload: { costCenter: EntityId<CostCenterJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
}

const apiBaseUrl = 'costcenters';

function createItem(): NewEntityProperties<CostCenterJson>
{
  return {
    name: '',
  };
}

export default createEntityStore<CostCenterJson, State, Store>(
  'costcenter',
  apiBaseUrl,
  createItem,
  {
    invoiceContacts: {},
  },
  moduleBuilder =>
    {
      const setInvoiceContact = moduleBuilder.commit(
        (state, { costCenter, contact }: { costCenter: EntityId<CostCenterJson>; contact: ContactJson }) =>
        {
          state.invoiceContacts[getId(costCenter)] = contact;
        }, Mutations.INVOICE_CONTACT);

      return {
        [Actions.READ_INVOICE_CONTACT]: moduleBuilder.dispatch(
          async ({ state }, payload: EntityId<CostCenterJson> | { costCenter: EntityId<CostCenterJson> }) =>
          {
            if (typeof payload === 'number' || !('costCenter' in payload))
            {
              payload = {
                costCenter: payload,
              };
            }

            const { costCenter } = payload;
            const costCenterId = getId(costCenter);

            const contact = await api.get<ContactJson>(`${apiBaseUrl}/${costCenterId}/invoicecontact`);
            setInvoiceContact({ costCenter, contact });

            return state.invoiceContacts[costCenterId];
          }, Actions.READ_INVOICE_CONTACT),

        [Actions.SAVE_INVOICE_CONTACT]: moduleBuilder.dispatch(
          async (ctx, { costCenter, contact }: { costCenter: EntityId<CostCenterJson>; contact: FormData<ContactJson> }) =>
          {
            const costCenterId = getId(costCenter);
            const serverResult = await api.put<ContactJson>(`${apiBaseUrl}/${costCenterId}/invoicecontact`, contact);
            const committed = contact.$commit(serverResult);
            setInvoiceContact({ costCenter, contact: committed.$raw });
            return committed;
          }, Actions.SAVE_INVOICE_CONTACT),
      };
    });
