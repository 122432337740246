import { Vue, Watch } from 'vue-property-decorator';
import { RawLocation, Route } from 'vue-router';
import { getModule } from '@/router';
import { ValidationObserver } from 'vee-validate';
import { UserPermission } from '@/modules/user';
import { Getters } from '@/store';
import { hasPermission as hasPermissionBase } from '@/json';

import './Form.scss';

export abstract class Form<T> extends Vue {

  protected isSubmitButtonAvailable(): boolean {
    return this.hasWritePermission();
  }

  protected hasWritePermission(): boolean {
    return true;
  }

  protected hasPermission(userPermission: UserPermission): boolean {
    return hasPermissionBase(userPermission);
  }

  /**
   * Returns or sets the currently active item.
   */
  public abstract get activeItem(): T | null;

  /**
   * Checks if the given item has unsaved changes.
   */
  protected abstract hasUnsavedChanges(item: T): boolean;

  /**
   * Changes the active item.
   */
  protected abstract changeActiveItem(item: T | null): void;

  /**
   * Saves the changes of the given item on the server.
   */
  protected abstract saveItem(item: T): Promise<unknown>;

  /**
   * Resets the given item.
   */
  protected abstract resetItem(item: T): void;

  /**
   * Whether the given item is considered new.
   */
  protected isNew(item: T): boolean {
    return false;
  }

  /**
   * Called when the form is opened for the given item.
   */
  protected onOpen(item: T): void {
    // tslint:disable-next-line:no-empty
  }

  /**
   * Called when the form is closed.
   */
  protected onClose(): void {
    // tslint:disable-next-line:no-empty
  }

  /**
   * A function to determine whether the active item should be kept when changing the route.
   * Defaults to true if the module stays the same and the item is not new.
   */
  protected shouldKeepActiveItemOnRouteLeave(item: T, to: Route, from: Route): boolean {
    return !this.isNew(item) && getModule(to) === getModule(from);
  }

  /**
   * Returns the message for the unsaved changes confirmation dialog for the given item.
   */
  protected getUnsavedChangesMessage(item: T): { title?: string; message: string } {
    return {
      title: this.$root.$t('unsavedChanges.title').toString(),
      message: this.$root.$t('unsavedChanges.msg').toString(),
    };
  }

  /**
   * Checks for unsaved changes and asks to save them.
   */
  public async handleUnsavedChanges(): Promise<boolean> {
    const activeItem = this.activeItem;
    if (activeItem && (this.isNew(activeItem) || this.hasUnsavedChanges(activeItem))) {
      const { title, message } = this.getUnsavedChangesMessage(activeItem);
      const result = await this.$msg(title || '', message, 'cancel', 'no', 'yes');

      switch (result) {
        case 'yes':
          return await this.save();
        case 'no':
          this.resetItem(activeItem);
          return true;
        default:
          return false;
      }
    }

    return true;
  }

  /**
   * Submits the form.
   */
  public submit(): void {
    this.save().then(saved => {
      if (saved) {
        // this.unsetActiveItem('close');
        this.afterSave();
      }
    });
  }

  /**
   * Called after Sucessfull save, here to be overwritten
   */
  protected afterSave(): void {
    // tslint:disable-next-line:no-empty
  }

  /**
   * Resets the form.
   */
  public reset(): void {
    const activeItem = this.activeItem;
    if (activeItem) {
      this.resetItem(activeItem);
      this.resetValidation();
    }
  }

  /**
   * Saves the changes of the current item on the server.
   */
  private async save(): Promise<boolean> {
    const activeItem = this.activeItem;

    if (activeItem) {
      const isValid = await this.isValid();

      if (isValid) {
        await this.saveItem(activeItem);
        return true;
      }

      return false;
    }

    return Promise.reject(new Error('No active item found'));
  }

  /**
   * Opens the form for the given item.
   */
  public open(factory: () => T): Promise<boolean> {
    return this.handleUnsavedChanges().then(handled => {
      if (handled) {
        const item = factory();

        this.changeActiveItem(item);
        this.onOpen(item);
      }
      return handled;
    });
  }

  /**
   * Closes the form.
   */
  public close(): Promise<boolean> {
    return this.handleUnsavedChanges().then(handled => {
      if (handled) {
        this.changeActiveItem(null);
        this.onClose();
      }
      return handled;
    });
  }

  /**
   * Validates the field and returns whether it is valid.
   */
  public async isValid(silent?: boolean): Promise<boolean> {
    return this.$refs.validator.validate({ silent });
  }

  /**
   * Resets the validation state.
   */
  public resetValidation(): void {
    this.$refs.validator.reset();
  }

  /**
   * Default handler for the beforeRouteLeave navigation guard.
   */
  protected handleBeforeRouteLeave(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const isLoggedIn = this.$store.getters[Getters.IS_LOGGED_IN];
    // Bypass unsaved changes handling when logging out.
    return (!isLoggedIn ? Promise.resolve(true) : this.handleUnsavedChanges()).then(handled => {
      if (handled) {
        const activeItem = this.activeItem;
        if (activeItem && !this.shouldKeepActiveItemOnRouteLeave(activeItem, to, from)) {
          this.changeActiveItem(null);
        }
        next();
      } else {
        next(false);
      }
    });
  }

  /**
   * Pauses validation if there is no active item (to prevent runtime errors).
   */
  @Watch('activeItem')
  protected onActiveItemChanged(value: T | null): void {
    if (value) {
      this.resetValidation();
    }
  }

  public readonly $refs!: {
    validator: InstanceType<typeof ValidationObserver>;
  };
}

export default Form;
