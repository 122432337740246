
import { Component, Prop } from 'vue-property-decorator';
import NumberField from './NumberField.vue';

@Component
export default class DecimalField extends NumberField
{
  /**
   * The number style to use. Must be one of i18n.$numberFormats or a Intl.NumberFormat options object.
   */
  @Prop({ default: 'decimal' })
  public readonly format: string | Intl.NumberFormatOptions;
}
