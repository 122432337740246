var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('AjaxLoader'),_c('v-navigation-drawer',{ref:"drawer",attrs:{"value":_vm.drawer,"fixed":"","app":"","clipped":"","width":"300"}},[(_vm.isLoggedIn)?_c('Menu',{staticClass:"pt-0"}):_vm._e()],1),_c('v-app-bar',{attrs:{"dark":"","app":"","clipped-left":"","color":"primary"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-toolbar-title',_vm._g({directives:[{name:"t",rawName:"v-t",value:({ path: 'title', args: { company: _vm.company } }),expression:"{ path: 'title', args: { company } }"}],staticClass:"white--text"},on),[_vm._v("Title")])]}}])},[_c('span',[_vm._v("Version: "+_vm._s(_vm.currentVersion))])]),_c('v-spacer'),(_vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","to":"/user"}},on),[_c('v-icon',[_vm._v("$vuetify.icons.user")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(_vm.currentUser.firstName)+" "+_vm._s(_vm.currentUser.lastName)+" ("+_vm._s(_vm.getCurrentUserType())+")")])],1)]}}],null,false,2147717574)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('module.user.title'),expression:"'module.user.title'"}]},[_vm._v("User account")])]):_vm._e(),(_vm.isLoggedIn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.logout()}}},on),[_c('v-icon',[_vm._v("$vuetify.icons.logout")])],1)]}}],null,false,2147567123)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('logout.tooltip'),expression:"'logout.tooltip'"}]},[_vm._v("Logout")])]):_vm._e()],1),_c('v-content',[_c('keep-alive',[_c('router-view')],1)],1),_c('v-footer',{attrs:{"dark":"","app":"","color":"primary"}},[_c('v-spacer'),(_vm.availableLocales.length > 1)?_c('v-menu',{attrs:{"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":""}},on),[_vm._v(_vm._s(_vm._f("upper")(_vm.currentLocale)))])]}}],null,false,425837976)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.availableLocales),function(locale){return _c('v-list-item',{key:locale,on:{"click":function($event){return _vm.changeLocale(locale)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("upper")(locale)))])],1)}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }