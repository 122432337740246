











































import { Component, Prop } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { WritableEntity, EntityStoreActions, Form as FormBase, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity } from '@/base';
import { FormData, asFormData, EditFormData } from '@/base';
import { EmailTemplateJson } from '../json';
import store, { Actions } from '../store';
import { FileEntityCollectionJson, FileEntityJson } from '@/modules/file';
import EmailTemplate from '../EmailTemplate.vue';
import { UserPermission } from '@/modules/user';

const maxFileSize = parseInt(process.env.VUE_APP_UPLOAD_MAX_FILE_SIZE, 10) || 10485760;

/**
 * The type of the active form item.
 */
interface ActiveItem {
  emailTemplate: EmailTemplateJson;
  fileAttachmentCollection: FormData<FileEntityCollectionJson>;
}

@Component<FileAttachmentCollection>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created(): void {
    // HACK: Dirty is not evaluated if inner values change.
    // this.$watch('activeContact', () => this.$forceUpdate(), { deep: true });
  },
})
export default class FileAttachmentCollection extends FormBase<ActiveItem> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.EMAIL_TEMPLATE_WRITE);
  }

  private readonly maxFileSizeInKB = maxFileSize / 1024;
  private readonly maxFileSizeInKBPermitted = 7 * 1024;

  /**
   * Returns file attachment collection of the active item.
   */
  public get formData(): FormData<FileEntityCollectionJson> | null {
    return this.activeItem ? this.activeItem.fileAttachmentCollection : null;
  }

  private createFileEntity(): NewEntity<FileEntityJson> {
    return createEntity<FileEntityJson>({
      uuid: '',
      fileName: '',
    });
  }

  private removeFileEntity(fileEntity: FileEntityJson): void {
    this.deleteFileAttachment(fileEntity);
  }

  private downloadFileEntity(fileEntity: FileEntityJson): void {
    this.downloadFileAttachment(fileEntity);
  }

  private downloadFileAttachment(fileEntity: FileEntityJson): void {
    if (this.activeItem) {
      store[Actions.DOWNLOAD_FILE]({
        emailTemplate: asFormData(this.activeItem.emailTemplate),
        fileEntity,
      });
    }
  }

  private isAttachmentToLarge(): boolean {
    return this.maxFileSizeInKBPermitted < this.getCompleteFileSize();
  }

  private getCompleteFileSize() {
    const activeItem = this.activeItem;
    let aggregated_size = 0;
    if (activeItem) {
      (activeItem.fileAttachmentCollection as EditFormData<FileEntityCollectionJson>).fileEntities.forEach(element => {
        if (element && element.size) {
          aggregated_size += element.size;
        }
      });
    }
    return this.roundToTwoDigits(aggregated_size);
  }

  private roundToTwoDigits(value: number) {
    return Math.round((value / 1024) * 100) / 100;
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: ActiveItem): Promise<void> {
    return Promise.resolve();
  }

  private getDescriptionWithSize(item: FileEntityJson): string {
    if (item && item.fileName) {
      // The Math.round rounds to 2 digits after ','. We Get the size in bytes, so we have to convert it
      return this.$root.$t('module.email.fileform.file') + ': ' + item.fileName + ' (' + this.roundToTwoDigits(item.size) + ' KB)';
    }
    return this.$root.$t('module.email.fileform.uploadRequest') as string;
  }

  /**
   * Returns the cost configuration of the active item.
   */
  public get activeFileAttachmentCollection(): FormData<FileEntityCollectionJson> | null {
    return this.activeItem ? this.activeItem.fileAttachmentCollection : null;
  }

  private uploadFileAttachment(file: globalThis.File): void {
    if (file.size < maxFileSize) {
      if (this.activeItem) {
        store[Actions.UPLOAD_FILE]({
          emailTemplate: asFormData(this.activeItem.emailTemplate),
          file,
        }).then(item =>
          this.changeActiveItem(
            this.activeItem
              ? {
                  emailTemplate: this.activeItem.emailTemplate,
                  fileAttachmentCollection: asFormData(item),
                }
              : null,
          ),
        );
      }
    }
  }

  private deleteFileAttachment(fileEntity: FileEntityJson): void {
    if (this.activeItem) {
      store[Actions.DELETE_FILE]({
        emailTemplate: asFormData(this.activeItem.emailTemplate),
        fileEntity,
      }).then(item =>
        this.changeActiveItem(
          this.activeItem
            ? {
                emailTemplate: this.activeItem.emailTemplate,
                fileAttachmentCollection: asFormData(item),
              }
            : null,
        ),
      );
    }
  }

  /**
   * Returns the currently active item.
   */
  public activeItem: ActiveItem | null = null;

  /**
   * Returns the bank account collection of the active item.
   */
  public get activeContact(): FormData<FileEntityCollectionJson> | null {
    return this.activeItem ? this.activeItem.fileAttachmentCollection : null;
  }

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: ActiveItem | null): void {
    const activeBusinessUnit = store[EntityStoreGetters.ACTIVE_ITEM];
    const activeItem = this.activeItem;

    // set the email template
    store[EntityStoreMutations.ACTIVE_ITEM](item ? item.emailTemplate : null);
    this.activeItem = item;

    if (activeBusinessUnit && (!item || item.emailTemplate !== activeBusinessUnit.$raw)) {
      activeBusinessUnit.$revoke();
    }

    if (activeItem && (!item || item.fileAttachmentCollection !== activeItem.fileAttachmentCollection)) {
      activeItem.fileAttachmentCollection.$revoke();
    }
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: ActiveItem): boolean {
    if (item) {
      return item.fileAttachmentCollection.$isDirty();
    }
    return false;
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void {
    this.$router.push({ name: 'emailtemplate' });
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: ActiveItem): void {
    item.fileAttachmentCollection.$reset();
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const proceed = (emailTemplate: EmailTemplateJson, item: FileEntityCollectionJson | NewEntity<FileEntityCollectionJson>): Promise<void> => {
      return this.open(() => ({
        emailTemplate,
        fileAttachmentCollection: asFormData<FileEntityCollectionJson>(item),
      })).then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    };

    const emailTemplateId = parseInt(to.params.id, 10);

    store[EntityStoreActions.READ_ONE]({ id: emailTemplateId, initial: true })
      .then(emailTemplate => {
        // read this separately to handle the 404 error
        return store[Actions.READ_FILE_ATTACHMENT_COLLECTION]({ emailTemplate })
          .then(item => proceed(emailTemplate, item))
          .catch(error => {
            // if not found, create a new one
            if (error.statusCode === 404) {
              return proceed(
                emailTemplate,
                createEntity<FileEntityCollectionJson>({
                  fileEntities: [],
                }),
              );
            }
            return Promise.reject(error);
          });
      })
      .catch(() => next(from.fullPath === '/' && !from.name ? { name: 'emailTemplate', replace: true } : { path: from.fullPath, replace: true }));
  }
}
