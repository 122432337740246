import api from '@/plugins/api';
import { VatConfigurationJson, Vat } from './json';
import { EntityPagingStore, createEntityPagingStore, EntityPagingStoreState, NewEntityProperties} from '@/base';

export enum Actions
{
  READ_VAT_CONFIGURATIONS_VALID_FROM = 'dispatchReadVatConfigurationsValidFrom',
}

export interface State extends EntityPagingStoreState<VatConfigurationJson>
{
  vatconfigurations: _.NumericDictionary<VatConfigurationJson>;
}

export interface Store extends EntityPagingStore<VatConfigurationJson>
{
  /**
   * Reads all VAT configurations for a given valid from date.
   */
   [Actions.READ_VAT_CONFIGURATIONS_VALID_FROM](validFrom: Date): Promise<VatConfigurationJson[]>;

}

function createItem(): NewEntityProperties<VatConfigurationJson>
{
  return {
    validFrom: new Date(),
    vatRate: 0.00,
    vat: Vat.ZERO,
  };
}

const baseUrl = 'vatconfigurations';

export const store = createEntityPagingStore<VatConfigurationJson, State, Store>(
  'vatconfiguration',
  baseUrl,
  createItem,
  {
    vatconfigurations: {},
  },
  moduleBuilder => {
    return {
      [Actions.READ_VAT_CONFIGURATIONS_VALID_FROM]: moduleBuilder.dispatch(async ({ state }, validFrom: Date) => {
        const args = `?vatValidFrom=${validFrom.toISOString()}`;
        const result = await api.get<VatConfigurationJson[]>(`${baseUrl}/fordate${args}`);

        state.items.set(...result);

        return result;
      }, Actions.READ_VAT_CONFIGURATIONS_VALID_FROM),
    };
  },
);
