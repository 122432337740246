




import { Component } from 'vue-property-decorator';
import { HeaderConfig, EntityList } from '@/base';
import store from '../store';
import { BusinessUnitJson } from '../json';
import { UserPermission, UserType } from '@/modules/user';
import { store as mainStore, Getters } from '@/store';

@Component<List>({
  created()
  {
    this.init();
  },
})
export default class List extends EntityList<BusinessUnitJson, typeof store>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.MASTER_DATA_WRITE) && this.isAllowForAdmins();
  }

  protected isAllowForAdmins(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.ADMIN;
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'name' }];

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'name',
        text: this.$t('list.header.name').toString(),
      },
    ];
  }

  protected getDeleteConfirmationMessage(item: BusinessUnitJson): { title?: string; message: string }
  {
    return {
      title: this.$t('list.delete.title', item).toString(),
      message: this.$t('list.delete.message', item).toString(),
    };
  }
}
