import Vue from 'vue';
import { store, Alert, Mutations } from '../store';

declare module 'vue/types/vue'
{
  interface Vue
  {
    /**
     * Shows an alert message.
     * Returns a function to remove the message.
     */
    $alert(message: Alert): () => void;
    $alert(text: Alert['text'], type?: Alert['type'], autoHide?: Alert['autoHide'], closable?: Alert['closable'], module?: Alert['module']): () => void;
    /**
     * Shows a success alert message.
     * AutoHide for success is 500ms.
     * Returns a function to remove the message.
     */
    $success(text: Alert['text'], autoHide?: Alert['autoHide'], closable?: Alert['closable'], module?: Alert['module']): () => void;
    /**
     * Shows a warning alert message.
     * Returns a function to remove the message.
     */
    $warning(text: Alert['text'], autoHide?: Alert['autoHide'], closable?: Alert['closable'], module?: Alert['module']): () => void;
    /**
     * Shows a error alert message.
     * Returns a function to remove the message.
     */
    $error(text: Alert['text'], autoHide?: Alert['autoHide'], closable?: Alert['closable'], module?: Alert['module']): () => void;
  }
}

Vue.use(vue =>
  {
    vue.prototype.$alert = function(this: Vue, message: Alert | Alert['text'], type?: Alert['type'], autoHide?: Alert['autoHide'], closable?: Alert['closable'], module?: Alert['module']): () => void
    {
      if (typeof message === 'string')
      {
        message = {
          module: module ? this.$root.$t(module + 'title') as string : null,
          text: message,
          type,
          autoHide,
          closable,
        };
      }

      store[Mutations.APPEND_ALERT](message);

      return () => {
        store[Mutations.REMOVE_ALERT](message as Alert);
      };
    };

    ['success', 'warning', 'error'].forEach(method =>
      {
        vue.prototype[`$${method}`] = function(this: Vue, text: Alert['text'], autoHide?: Alert['autoHide'], closable?: Alert['closable'], module?: Alert['module']): () => void
        {
          if (method === 'success' && typeof autoHide !== 'number' && typeof closable !== 'boolean')
          {
            autoHide = 5000;
          }
          return this.$alert(text, method as Alert['type'], autoHide, closable, module);
        };
      });
  });
