









































import { Component } from 'vue-property-decorator';
import { AdminJson } from '../json';
import FormBase from '@/base/EntityForm';
import { store, Actions } from '../store';


@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends FormBase<AdminJson, typeof store>
{
  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'admin',
  };

  private sendActivationEmail(): void
  {
    if (this.activeItem)
    {
      store[Actions.SEND_ACTIVATION_EMAIL](this.activeItem).then(itemRes => {
         this.$success(this.$root.$t('module.email.send.ok') as string);
      }).catch(err => {
         this.$error(this.$root.$t('module.email.send.failed') as string);
      });
    }
  }
}
