
import { Component } from 'vue-property-decorator';
import DateField from './DateField.vue';

@Component
export default class DateTimeField extends DateField
{
  /**
   * Returns the datetime format options to use.
   */
  protected getDateTimeFormatOptions(): Intl.DateTimeFormatOptions
  {
    return this.$i18n.getDateTimeFormat(this.$i18n.locale).dateTime;
  }
}
