

































import { Component, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { store, Getters } from '../store';
import { routes } from '../router';
import { UserType } from '@/modules/user';

export interface IMenuItem
{
  title: string;
  icon?: string | null;
  route: string;
}

interface IMainMenuItem extends IMenuItem
{
  children?: IMenuItem[];
  noRoute?: boolean;
  collapsed?: boolean;
}

@Component
export default class Menu extends Vue
{
  private get isLoggedIn(): typeof store[Getters.IS_LOGGED_IN]
  {
    return store[Getters.IS_LOGGED_IN];
  }

  /**
   * Returns the menu items for the navigation drawer.
   */
  public get menuItems(): IMainMenuItem[]
  {
    const menuItems: IMainMenuItem[] = [];

    routes.forEach(route =>
    {
      const meta = route.meta || {};

      // filter
      if (!this.__filterRoute(route))
      {
        return;
      }

      const item: IMainMenuItem = this.__createMenuItem(route);

      // children
      if (route.children)
      {
        item.children = [];
        if (meta.collapsedMenu === true)
        {
          item.collapsed = true;
        }

        if (meta.noMenuLink === true)
        {
          item.noRoute = true;
        }

        route.children.forEach(child => {
          if (!this.__filterRoute(child))
          {
            return;
          }

          item.children!.push(this.__createMenuItem(child));
        });
      }

      // create menu item
      menuItems.push(item);
    });

    return menuItems;
  }

  private __filterRoute(route: RouteConfig): boolean
  {
    const meta = route.meta;
    if (meta && meta.hideMenu === true)
    {
      return false;
    }

    if (!this.isLoggedIn && (!meta || meta.anonymous !== true))
    {
      return false;
    }

    const currentUserLogin = store[Getters.CURRENT_USER_LOGIN];
    if (meta && meta.userType && currentUserLogin)
    {
      const currentUserType = currentUserLogin.userType;
      if (!this.$_.castArray(meta.userType).some(ut => ut === currentUserType))
      {
        return false;
      }

      if (currentUserType === UserType.OFFICE_WORKER && meta.userPermission) {
        if (!this.$_.castArray(meta.userPermission).some(up => currentUserLogin.userPermissions.some(upUser => up === upUser)))
        {
          return false;
        }
      }
    }

    return true;
  }

  private __createMenuItem(route: RouteConfig): IMenuItem
  {
    const meta = route.meta || {};

    const name = meta.module || meta.name || route.name;

    return {
      route: this.$router.resolve(route).href,
      title: this.$root.$t(meta.title || `module.${name}.title`).toString(),
      icon: meta.icon === false ? null : (meta.icon || name ? `$vuetify.icons.${(meta.icon || name).replace(/\./g, '_')}` : null),
    };
  }
}
