































import { Vue, Prop, Component, Watch, Emit } from 'vue-property-decorator';
import { VSelect, VAutocomplete } from 'vuetify/lib';
import axios, { CancelTokenSource } from 'axios';
import { BaseEntity, EntityStore, EntityPagingStore, isPagingStore, EntityStoreGetters, EntityStoreActions, EntityPagingStoreActions, isBaseEntity } from '@/base';
import { EntitySelectCustomFilter } from './EntitySelectCustomFilter';
import { EntitySelectItemComparator } from './EntitySelectItemComparator';

function getSearchHandler<T extends BaseEntity>(
  store: EntityPagingStore<T>,
  resultCallback: (customers: T[]) => void,
  beforeSearch?: () => void,
  afterSearch?: () => void,
  ): (searchString: string) => void
{
  let cancelTokenSource: CancelTokenSource | null = null;

  function searchItems(searchString: string)
  {
    // filter invalid search strings
    if (!searchString || !store.isValidAutocompleteSearchString(searchString))
    {
      return;
    }

    if (beforeSearch)
    {
      beforeSearch();
    }

    if (cancelTokenSource)
    {
      cancelTokenSource.cancel();
    }
    cancelTokenSource = axios.CancelToken.source();
    store[EntityPagingStoreActions.AUTOCOMPLETE_SEARCH](
      {
        searchString,
        cancelToken: cancelTokenSource.token,
      })
      .then(
        (items) =>
        {
          cancelTokenSource = null;
          resultCallback(items);
          if (afterSearch)
          {
            afterSearch();
          }
        },
        (err) =>
          {
              if (!axios.isCancel(err))
              {
                if (afterSearch)
                {
                  afterSearch();
                }
                throw err;
              }
          });
  }

  return (newSearchString: string) => searchItems(newSearchString);
}


/**
 * A wrapper around a select box or auto complete field for entities, depending on the type of store.
 */
@Component<Field<BaseEntity, EntityStore<BaseEntity>>>({
  inheritAttrs: false,
  destroyed()
  {
    if (this.__watchHandler)
    {
      this.__watchHandler();
    }
  },
})
export default class Field<T extends BaseEntity, S extends EntityStore<T>> extends Vue
{
  // Pass model through
  @Prop()
  public readonly value: any;
  private model = this.value == null ? null : this.value;
  @Watch('model')
  @Emit('input')
  private onModelChange(): void
  {
    this.__wasSelect = true;
    // just emit the event
  }
  @Watch('value')
  private onValueChange(value: any): void
  {
    if (this.model !== value && !(isBaseEntity(this.model) && isBaseEntity(value) && this.model.id === value.id))
    {
      this.model = value;
    }
  }

  /**
   * The entitystore of the properties.
   */
  @Prop({ required: true })
  public readonly store: S;

  /**
   * comparator to sort displayed items.
   */
  @Prop({ required: false })
  public readonly itemComparator: EntitySelectItemComparator<T>;

  @Watch('store', { immediate: true })
  private onStoreChange(): void
  {
    if (this.__watchHandler)
    {
      this.__watchHandler();
    }

    this.isLoading = false;
    this.autoCompleteItems.length = 0;

    const store = this.store;
    if (store && isPagingStore(store))
    {
      const debouncedHandler = this.$_.debounce(
        getSearchHandler(store, items => this.autoCompleteItems = items, () => this.isLoading = true, () => this.isLoading = false),
        250);

      this.__watchHandler = this.$watch('searchString', searchString =>
      {
        if (!this.__wasSelect)
        {
          debouncedHandler(searchString);
        }
        this.__wasSelect = false;
      });
    }
  }

  /**
   * Checks whether the store is a paging store.
   */
  private get isPagingStore(): boolean
  {
    return isPagingStore(this.store);
  }

  /**
   * The list of items to display in the field.
   */
  private get items(): T[]
  {
    if (!isPagingStore(this.store))
    {
      // if we have a value, load all items initially
      if (this.value)
      {
        this.store[EntityStoreActions.READ_ALL](true);
      }

      return this.sortOnDemand(this.store[EntityStoreGetters.ITEMS]);
    }
    else
    {
      if (this.value && this.autoCompleteItems.filter(acItem => acItem.id === this.value.id).length === 0)
      {
        this.autoCompleteItems.push(this.value);
      }
      return this.sortOnDemand(this.autoCompleteItems);
    }
  }

  private sortOnDemand(unsortedItems: T[]): T[] {
    if (this.itemComparator) {
      unsortedItems.sort((a, b) => this.itemComparator.sort(a, b));
    }
    return unsortedItems;
  }

  /**
   * Read all items on first focus of a select field.
   */
  private onFirstSelectFocus(): void
  {
    if (!isPagingStore(this.store))
    {
      this.isLoading = true;
      this.store[EntityStoreActions.READ_ALL](true)
        .finally(() => this.isLoading = false);
    }
  }

  // properties for autocomplete
  private autoCompleteItems: T[] = [];
  private searchString = '';
  private isLoading = false;
  private __wasSelect = false;
  private __watchHandler: (() => void) | null = null;

  @Prop({ required: false })
  public readonly customFilter: EntitySelectCustomFilter;

  protected customFilterInternal(item: any, queryText: string, itemText: string): boolean {
    return this.customFilter.filter(item, queryText, itemText);
  }

  private isNoFilter(): boolean {
    return this.customFilter == null;
  }
}

