import { BaseEntity, WritableEntity, NewEntity } from './entity';
import _ from 'lodash';
import Vue from 'vue';

/**
 * A key used to indicate form data objects.
 */
const formDataKey = Symbol();

/**
 * Checks equality and considers 1 level of arrays.
 */
function equals<T>(a: T, b: T): boolean
{
  return _.isEqual(a, b);
}

interface FormDataProperties<T extends BaseEntity, W extends WritableEntity<T> | NewEntity<T>, R extends T | NewEntity<T>>
{
  /**
   * For changed properties returns the original values.
   */
  readonly $modified: Readonly<Partial<W>>;
  /**
   * Returns all original values.
   */
  readonly $original: Readonly<R>;
  /**
   * The raw data object.
   */
  readonly $raw: R;
  /**
   * True if the item is new (has a non positive ID).
   */
  $isNew(): this is NewFormData<T>;
  /**
   * True if the item is not new and exists on the server (has a positive ID).
   * Use this method instead of !$isNew() if you need a strongly typed item, because EditFormData<T> is assignable to NewFormData<T>.
   */
  $isCreated(): this is EditFormData<T>;
  /**
   * Detect the dirty state of properties. Omit the argument to detect any dirty state.
   * A property is dirty if its value has been changed and does not equal the original value.
   */
  $isDirty(prop?: keyof W): boolean;
  /**
   * Detect the touched state of properties. Omit the argument to detect any touched state.
   * A property is dirty if its value has been changed no matter of its equality to the original value.
   */
  $isTouched(prop?: keyof W): boolean;
  /**
   * Resets all changes made and all states.
   */
  $reset(): void;
  /**
   * Resets all states and populates the item with the given data (e.g. from server).
   * Returns this as strongly typed item.
   */
  $commit(result: T): EditFormData<T>;
  /**
   * Disables the proxy and returns the original data object.
   */
  $revoke(): R;
}

interface ArrayFormDataProperties<T extends BaseEntity>
{
  /**
   * Detect the dirty state of any item. The array is considered dirty if any value is added or removed as well.
   * A property is dirty if its value has been changed and does not equal the original value.
   */
  $isDirty(): boolean;
  /**
   * Detect the touched state of any item. The array is considered touched if any value is added or removed as well.
   * A property is dirty if its value has been changed no matter of its equality to the original value.
   */
  $isTouched(): boolean;
  /**
   * Resets all changes made and all states.
   */
  $reset(): void;
  /**
   * Resets all states and populates the items with the given data (e.g. from server).
   * Returns this as strongly typed item.
   */
  $commit(data: T[]): this;
  /**
   * Disables the proxy and returns the original data objects.
   */
  $revoke(): (T | WritableEntity<T> | NewEntity<T>)[];
}

/**
 * Type of a form data object for existing items.
 */
export type EditFormData<T extends BaseEntity> = T & FormDataProperties<T, WritableEntity<T>, T>;

/**
 * Type of a form data object for new items.
 */
export type NewFormData<T extends BaseEntity> = NewEntity<T> & FormDataProperties<T, NewEntity<T>, NewEntity<T>>;

/**
 * Union type for either of both form data types.
 */
export type FormData<T extends BaseEntity> = EditFormData<T> | NewFormData<T>;

/**
 * Form data with additional information around an array of data objects.
 */
// This is always of "any" form data, because one can add a NewFormData<T> to an array of EditFormData<T>.
export type ArrayFormData<T extends BaseEntity> = FormData<T>[] & ArrayFormDataProperties<T>;

// tslint:disable:forin
class FormDataProxy<T extends BaseEntity> implements ProxyHandler<T>
{
  public constructor(data: T, private readonly __revoke: () => void)
  {
    const modified: Partial<T> = {};

    this.__modified = new Proxy<Partial<T>>(modified, {
      has<K extends keyof T>(target: Partial<T>, prop: K): boolean
      {
        return target.hasOwnProperty(prop);
      },
    });

    this.__initVm(data);

    this.__touchedProxy = (prop?: keyof T): boolean =>
      {
        if (prop === undefined)
        {
          for (const k in modified)
          {
            return true;
          }
          return false;
        }
        else if (prop in data)
        {
          return prop in modified;
        }

        return false;
      };
    this.__dirtyProxy = (prop?: keyof T): boolean =>
      {
        // HACK: Trigger change detections.
        // This is used to prevent deep watchers. Otherwise changes in child components
        // will not trigger a re-call of the $isDirty() method.
        // Seems that we just have to use the data object so that vue is not able to optimize it away
        if (this.__triggerChangeDetection === 0)
        {
          JSON.stringify(data);
          this.__triggerChangeDetection++;
        }
        if (prop === undefined)
        {
          for (const k in modified)
          {
            if (!equals(modified[k], data[k]))
            {
              return true;
            }
          }
          this.__triggerChangeDetection = 0;
          return false;
        }
        else if (prop in data)
        {
          return prop in modified && !equals(modified[prop], data[prop]);
        }

        this.__triggerChangeDetection = 0;
        return false;
      };

    this.__originalProxy = new Proxy<Readonly<T>>({} as T, {
      get<K extends keyof T>(target: Readonly<T>, prop: K): T[K]
      {
        return prop in modified ? (modified as Partial<T>)[prop]! : data[prop];
      },
      has<K extends keyof T>(target: Readonly<T>, prop: K): boolean
      {
        return data.hasOwnProperty(prop);
      },
      set(): boolean
      {
        return false;
      },
      deleteProperty(): boolean
      {
        return false;
      },
    });
  }

  public get<K extends keyof T>(target: T, prop: K | keyof FormDataProperties<T, WritableEntity<T>, T>, receiver: T): any
  {
    switch (prop)
    {
      case '$raw':
        return target;
      case '$modified':
        return this.__modified;
      case '$original':
        return this.__originalProxy;
      case '$isDirty':
        return this.__dirtyProxy;
      case '$isTouched':
        return this.__touchedProxy;
      case '$isNew':
        return () => !(target.id > 0);
      case '$isCreated':
        return () => target.id > 0;
      case '$reset':
        return this.__untouch.bind(this, undefined, target, this.__modified);
      case '$commit':
        return this.__untouch.bind(this, receiver/* pass the proxy through since we don't want to change the object.*/, target);
      case '$revoke':
        return () => (this.__vm.$destroy(), this.__revoke());
      default:
        return target[prop];
    }
  }

  public has(target: T, prop: any): boolean
  {
    return prop === formDataKey || prop in target;
  }

  // public set<K extends keyof T>(target: T, prop: K, value: T[K]): boolean
  // {
  //   this.__touch(target, prop);
  //   target[prop] = value;
  //   return true;
  // }

  // public deleteProperty<K extends keyof T>(target: T, prop: K): boolean
  // {
  //   this.__touch(target, prop);
  //   delete target[prop];
  //   return true;
  // }

  // private __touch<K extends keyof T>(target: T, prop: K): void
  // {
  //   if (!(prop in this.__modified))
  //   {
  //     this.__modified[prop] = target[prop];
  //   }
  // }

  private __untouch(returnValue: T | undefined, target: T, result: Partial<T>): T | void
  {
    if (result)
    {
      for (const k in result)
      {
        target[k] = result[k]!;
      }

      this.__initVm(target);
    }

    for (const k in this.__modified)
    {
      delete this.__modified[k];
    }

    // this.__triggerChangeDetection = 0;
    return returnValue;
  }

  private __initVm(data: T): void
  {
    if (this.__vm)
    {
      this.__vm.$destroy();
    }

    const me = this;
    const modified = this.__modified;

    this.__vm = new Vue({
      data: {
        data,
      },
      watch: _.mapKeys(_.mapValues(data, (val, prop) =>
        {
          const clone = _.cloneDeep(val);
          return {
            handler(value: any)
            {
              if (!(prop in modified))
              {
                modified[prop as keyof T] = clone;
              }

              if (_.isArray(value) || _.isPlainObject(value) || _.isArray(clone) || _.isPlainObject(clone))
              {
                me.__triggerChangeDetection = 0;
              }
            },
            deep: true,
            sync: true, // undocumented, leads to immediate handler call and is not queued. Needed to have the right state for every other watcher
          };
        }), (val, prop) => `data.${prop}`),
    });
  }

  private readonly __touchedProxy: (prop?: keyof T) => boolean;
  private readonly __dirtyProxy: (prop?: keyof T) => boolean;
  private readonly __originalProxy: Readonly<T>;
  private readonly __modified: Partial<T>;
  private __vm: Vue;
  private __triggerChangeDetection = 0;
}

// tslint:disable:max-classes-per-file
class ArrayFormDataProxy<T extends BaseEntity> implements ProxyHandler<FormData<T>[]>
{
  public constructor(data: FormData<T>[], private readonly __revoke: () => void)
  {
    const original = data.slice();

    function arrayDiffers(): boolean
    {
      return data.length !== original.length || data.some((t, i) => original[i] !== t);
    }

    this.__data = original;
    this.__touchedProxy = (): boolean => arrayDiffers() || data.some(d => d.$isTouched());
    this.__dirtyProxy = (): boolean => arrayDiffers() || data.some(d => d.$isDirty());
  }

  public get<K extends keyof FormData<T>[]>(target: FormData<T>[], prop: K | keyof ArrayFormDataProperties<T>, receiver: any): any
  {
    switch (prop)
    {
      case '$isDirty':
        return this.__dirtyProxy;
      case '$isTouched':
        return this.__touchedProxy;
      case '$reset':
        return () => (target.length = 0, this.__data.forEach((t, i) => (t.$reset(), target[i] = t)));
      case '$commit':
        return (result: T[]) => (target.forEach((t, i) => t.$commit(result[i])), receiver);
      case '$revoke':
        return () => (_.union(target, this.__data).forEach(t => t.$revoke()), this.__revoke());
      default:
        return target[prop];
    }
  }
  public has(target: FormData<T>[], prop: any): boolean
  {
    return prop === formDataKey || prop in target;
  }

  private readonly __data: FormData<T>[];
  private readonly __touchedProxy: () => boolean;
  private readonly __dirtyProxy: () => boolean;
}

// Helper type to infer the array type of a form data array.
type InferArrayType<T> = T[] extends (infer U)[] ? U : T;

/**
 * Creates a wrapper around a data object that provides form state functionality.
 * @param data The data object.
 * @param useExisting (=true) True to use data if it is already a form data item, false to always create a new.
 */
export function asFormData<T extends BaseEntity>(data: T, useExisting?: boolean): EditFormData<T>;
export function asFormData<T extends BaseEntity>(data: NewEntity<T>, useExisting?: boolean): NewFormData<T>;
export function asFormData<T extends BaseEntity>(data: T | NewEntity<T>, useExisting?: boolean): FormData<T>;
export function asFormData<T extends BaseEntity, A extends InferArrayType<T>>(data: A[], useExisting?: boolean): ArrayFormData<T>;
export function asFormData<T extends BaseEntity, A extends InferArrayType<NewEntity<T>>>(data: A[], useExisting?: boolean): ArrayFormData<T>;
export function asFormData<T extends BaseEntity>(
  data: T | NewEntity<T> | (T | NewEntity<T>)[],
  useExisting?: boolean): FormData<T> | ArrayFormData<T>
{
  if (Array.isArray(data))
  {
    if (useExisting === false || !isFormData<T>(data))
    {
      data = asRawData(data);
      let revokeFn: () => void;
      const formDataArray = data.map(d => asFormData<T>(d as T));
      const { proxy, revoke } = Proxy.revocable(formDataArray, new ArrayFormDataProxy<T>(formDataArray, () =>
      {
        revokeFn();
        return data;
      }));
      revokeFn = revoke;
      return proxy as ArrayFormData<T>;
    }
    return data;
  }
  else
  {
    if (useExisting === false || !isFormData<T>(data))
    {
      data = asRawData<T>(data as T);
      let revokeFn: () => void;
      const { proxy, revoke } = Proxy.revocable(data, new FormDataProxy<T>(data as T, () =>
      {
        revokeFn();
        return data;
      }));
      revokeFn = revoke;
      return proxy as FormData<T>;
    }
    return data;
  }
}

/**
 * Checks if data is a form data object.
 */
function isFormData<T extends BaseEntity>(data: T | WritableEntity<T>| NewEntity<T>): data is FormData<T>;
function isFormData<T extends BaseEntity>(data: (T | WritableEntity<T> | NewEntity<T>)[]): data is ArrayFormData<T>;
function isFormData<T extends BaseEntity>(data: T | WritableEntity<T> | NewEntity<T> | (T | WritableEntity<T> | NewEntity<T>)[]): boolean
{
  return formDataKey in data;
}

/**
 * Ensures to return the raw data object if it is a form data object.
 */
export function asRawData<T extends BaseEntity>(data: T): T;
export function asRawData<T extends BaseEntity>(data: WritableEntity<T>): WritableEntity<T>;
export function asRawData<T extends BaseEntity>(data: NewEntity<T>): NewEntity<T>;
export function asRawData<T extends BaseEntity, A extends InferArrayType<T>>(data: A[]): A[];
export function asRawData<T extends BaseEntity, A extends InferArrayType<WritableEntity<T>>>(data: A[]): A[];
export function asRawData<T extends BaseEntity, A extends InferArrayType<NewEntity<T>>>(data: A[]): A[];
export function asRawData<T extends BaseEntity>(
  data: T | WritableEntity<T> | NewEntity<T> | (T | WritableEntity<T> | NewEntity<T>)[]): T | WritableEntity<T> | NewEntity<T> | (T | WritableEntity<T> | NewEntity<T>)[]
{
  if (Array.isArray(data))
  {
    return isFormData<T>(data) ? data.map(d => d.$raw) : data;
  }
  else
  {
    return isFormData<T>(data) ? data.$raw : data;
  }
}

/**
 * Commits the specified item with the given result. Uses a temporary FormData if item isn't already one.
 */
export function commitEntity<T extends BaseEntity>(item: T, result: T): T
{
  const formData = asFormData(item);
  const committed = formData.$commit(result).$raw;

  if (formData !== item)
  {
    formData.$revoke();
  }

  return committed;
}
