




import { Component, Prop } from 'vue-property-decorator';
import Sortable from 'sortablejs';
import { List as ListBase, ListAction, HeaderConfig, createEntity, NewEntity } from '@/base';
// import store, { Actions } from '../store';
import { CashAccountTransactionPositionJson } from '../json';
import { ExternalDocumentJson, store as externalDocumentStore } from '@/modules/financialdocument/externaldocument';
import CashAccountTransactionPositionList from '../base/CashAccountTransactionPositionList.vue';


@Component<PositionListExternalDocument>({
  props: {
    items: {
      required: true,
    },
  },
  mounted()
  {
    this.sortable = new Sortable(this.$el.querySelector('tbody')!, {
      draggable: 'tr:not(.add-row)',
      handle: '.drag-handle:not(.v-icon--disabled)',
    });
  },
  destroyed()
  {
    if (this.sortable)
    {
      this.sortable.destroy();
    }
  },
})
export default class PositionListExternalDocument extends CashAccountTransactionPositionList<ExternalDocumentJson>
{

  public readonly items: NewEntity<CashAccountTransactionPositionJson<ExternalDocumentJson>>[];

  private entityStore = externalDocumentStore;

  protected getFinancialDocumentLabel(): string
  {
    return this.getLabel('header.externalDocument');
  }

  protected getFullName(item: ExternalDocumentJson): string
  {
    return item ? item.id + ' - ' + item.documentReference : '';
  }

  /**
   * Adds a new item if the newDescription model has a value.
   */
  protected addItemIf(): void
  {
    if (this.newEntity)
    {
      this.items.push(createEntity({
        assignedPriceNet: this.newEntity.isIncome ? this.newEntity.documentValueNet : -this.newEntity.documentValueNet,
        assignedPriceGross: this.newEntity.isIncome ? this.newEntity.documentValueGross : -this.newEntity.documentValueGross,
        entity: this.newEntity,
      }));
      this.newEntity = null;
    }
  }
}
