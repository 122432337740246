









































































import { Component } from 'vue-property-decorator';
import { OfficeWorkerJson } from '../json';
import { store, Actions } from '../store';
import { store as businessUnitStore } from '@/modules/businessunit';
import { UserPermission } from '@/modules/user';
import { EntityForm as FormBase, EntityStoreGetters, EntityStoreActions } from '@/base';

@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends FormBase<OfficeWorkerJson, typeof store>
{
  protected readonly store = store;

  private readonly UserPermission = UserPermission;

  private getUserPermissionGroups(): Set<string> {
    return new Set(Object.values(UserPermission).map(permission => {
      return permission.substr(0, permission.lastIndexOf('_'));
    }));
  }

  private getUserPermissionsForGroup(group: string): Array<string> {
    return Object.values(UserPermission).filter(permission => {
      return permission.indexOf(group) !== -1;
    });
  }

  private presetReadWrite(): void {
    if (this.activeItem) {
      this.activeItem.userPermissions = Object.values(UserPermission);
    }
  }

  private presetReadOnly(): void {
    if (this.activeItem) {
      this.activeItem.userPermissions = Object.values(UserPermission).filter(up => up.includes('_READ'));
    }
  }

  private presetNone(): void {
    if (this.activeItem) {
      this.activeItem.userPermissions = [];
    }
  }

  protected readonly closeRoute = {
    name: 'officeworker',
  };

  private sendActivationEmail(): void
  {
    if (this.activeItem)
    {
      store[Actions.SEND_ACTIVATION_EMAIL](this.activeItem).then(itemRes => {
         this.$success(this.$root.$t('module.email.send.ok') as string);
      }).catch(err => {
         this.$error(this.$root.$t('module.email.send.failed') as string);
      });
    }
  }

  private readonly businessUnitStore = businessUnitStore;
}
