import api from '@/plugins/api';
import { AdminJson } from './json';
import { UserType, injectResetMutation } from '../user';
import { EntityStore, createEntityStore, EntityStoreState, NewEntityProperties, FormData } from '@/base';

export enum Actions
{
  SEND_ACTIVATION_EMAIL = 'dispatchSemdActivationEmail',
}

export interface State extends EntityStoreState<AdminJson>
{
}

export interface Store extends EntityStore<AdminJson>
{
  [Actions.SEND_ACTIVATION_EMAIL](admin: FormData<AdminJson>): Promise<AdminJson>;
}

function createItem(): NewEntityProperties<AdminJson>
{
  return {
    userType: UserType.ADMIN,
    lastName: '',
    firstName: '',
    email: '',
    isLocked: false,
    userPermissions: [],
  };
}

const baseUrl = 'admins';

export const store = createEntityStore<AdminJson, State, Store>(
  'admin',
  baseUrl,
  createItem,
  {},
  moduleBuilder =>
  {
    injectResetMutation(moduleBuilder);
    return {
      [Actions.SEND_ACTIVATION_EMAIL]: moduleBuilder.dispatch(async ({ state }, admin: FormData<AdminJson>) =>
      {
        const result = await api.post<AdminJson>(`${baseUrl}/activationemail`, admin.$raw);
        return admin.$commit(result);
      }, Actions.SEND_ACTIVATION_EMAIL),
    };
  });
