import { RouteConfig } from 'vue-router';

import List from './views/List.vue';
import Form from './views/Form.vue';
import Contact from './views/Contact.vue';

export default [
  // list
  {
    name: 'videotranslation',
    path: '',
    component: List,
  },
  // edit
  {
    path: ':id(new|\\d+)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // invoice or credit contact
  {
    path: ':id(\\d+)/:type(invoice|credit)contact',
    component: Contact,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // invoice
  {
    path: ':id(\\d+)/invoice',
    redirect: to => `/invoice/videotranslationcontract/${to.params.id}`,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
] as RouteConfig[];
