var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Details',{attrs:{"item":_vm.invoice,"properties":_vm.properties},scopedSlots:_vm._u([{key:"item.invoiceState",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(("enums.InvoiceState." + value))))]}},{key:"item.customer",fn:function(ref){
var value = ref.value;
return [(value.customerComment)?_c('v-dialog',{attrs:{"persistent":"","max-width":"90%","width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("$vuetify.icons.show ")])]}}],null,true),model:{value:(_vm.showCustomerCommentDialog),callback:function ($$v) {_vm.showCustomerCommentDialog=$$v},expression:"showCustomerCommentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('customerCommentDialog.content')))]),_c('v-card-text',[_vm._v(_vm._s(value.customerComment))]),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.showCustomerCommentDialog = false}}},[_vm._v(_vm._s(_vm.$t('customerCommentDialog.close'))+" ")])],1)],1)],1):_vm._e(),_vm._v(" "+_vm._s(_vm._f("fullName")(value)))]}},{key:"item.invoiceDate",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.$d(value, 'short')))]}},{key:"item.timeForPayment",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.$d(value, 'short')))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }