import Vue from 'vue';
import { Path } from 'vue-i18n';
import { getModule } from '@/router';

declare module 'vue/types/vue'
{
  interface Vue
  {
    /**
     * Returns the name of the current module.
     */
    readonly $module: string | undefined;
  }
}

declare module 'vue/types/options'
{
  interface ComponentOptions<V extends Vue>
  {
    /**
     * Specifies the module name for components if not possible to get from route.
     */
    module?: string;
  }
}

// Use a mixin to apply the module to the underlying instance.
Vue.mixin({
  beforeCreate()
  {
    Object.defineProperty(this, '$module', {
      enumerable: true,
      value: this.$options.module || (this.$route ? getModule(this.$route) : undefined),
    });
  },
});

/**
 * This Plugin provides the name of the current module and modifies the I18n functions to expect a locale path in the current module.
 * Use $root to skip this behavior.
 */
Vue.use(vue =>
  {
    ['$t', '$tc', '$te'].forEach(method => {
      vue.prototype[method] = ((orig: (this: Vue, key: Path, ...args: any[]) => any) =>
      {
        return function(this: Vue, key: Path, ...args: any[]): any
        {
          let path = key;
          // not in root
          if (this.$parent)
          {
            const currentModule = this.$module;
            if (currentModule)
            {
              path = `module.${currentModule}.${key}`;
            }

            // // check key in current module
            // if (!this.$i18n.te(path))
            // {
            //   // else check for a module that starts with the first key segment
            //   path = `module.${key}`;

            //   // else use as is from root
            //   if (!this.$i18n.te(path))
            //   {
            //     path = key;
            //   }
            // }
          }

          return orig.call(this, path, ...args);
        };
      })(vue.prototype[method]);
    });

  });
