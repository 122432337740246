


















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ContactJson, ContactEmailJson, ContactPhoneNumberJson, EmailType, PhoneNumberType } from '../json';
import { getEmailDescription, getPhoneNumberDescription } from '../util';
import { Form as FormBase, NewEntity, WritableEntity, createEntity } from '@/base';

@Component<ContactForm>({
  methods: {
    getEmailDescription,
    getPhoneNumberDescription,
  },
})
export default class ContactForm extends Vue
{
  /**
   * The contact form json.
   */
  @Prop({ required: true })
  public formData: WritableEntity<ContactJson> | NewEntity<ContactJson>;

  @Prop({ required: false })
  public isFormDisabled: false;

  /**
   * Returns the label with the given key. Tries to find it in the current module and falls back
   * to the contact module locale.
   */
  private getLabel(key: string): string
  {
    if (this.$te(key))
    {
      return this.$t(key).toString();
    }

    return this.$root.$t(`module.contact.${key}`).toString();
  }

  private createContactEmail(): NewEntity<ContactEmailJson>
  {
    return createEntity<ContactEmailJson>({
      email: null,
      emailType: EmailType.BUSINESS,
    });
  }

  private createContactPhoneNumber(): NewEntity<ContactPhoneNumberJson>
  {
    return createEntity<ContactPhoneNumberJson>({
      phoneNumber: null,
      phoneNumberType: PhoneNumberType.BUSINESS,
    });
  }

  private onEnter(): void
  {
    this.$emit('enter');
  }

  private readonly EmailType = EmailType;
  private readonly PhoneNumberType = PhoneNumberType;
}
