























































































import { Component } from 'vue-property-decorator';
import { ExternalDocumentJson } from '../json';
import FormBase from '@/base/EntityForm';
import store from '../store';
import { store as businessUnitStore } from '@/modules/businessunit';
import { UserPermission } from '@/modules/user';

@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    Form.setBackTo(null);
    if (to.query.backTo)
    {
      Form.setBackTo(to.query.backTo as string);
    }
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends FormBase<ExternalDocumentJson, typeof store>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_WRITE);
  }

  private isFormDisabled(): boolean {
    return !this.hasWritePermission();
  }

  protected readonly store = store;
  private readonly businessUnitStore = businessUnitStore;

  public static setBackTo(value: string | null)
  {
    this.backTo = value;
  }

  private static backTo: string | null =  null;

  protected readonly closeRoute = {
    name: 'externaldocument',
  };

  protected onClose(): void {
  if (Form.backTo)
  {
    this.$router.push(Form.backTo);
  }
  else
  {
    this.$router.push(this.closeRoute);
  }
}

  private getNoticeText(): string {
    if (this.activeItem && this.activeItem.documentValueGross) {
      if (this.activeItem.isIncome &&  this.activeItem.documentValueGross >= 0)
      {
        const docValue = this.$n(this.activeItem.documentValueGross, 'currency');
        return this.$t('form.noticeTextIncome', [docValue]) as string;
      }
      if (this.activeItem.isIncome &&  this.activeItem.documentValueGross < 0)
      {
        const docValue = this.$n(-this.activeItem.documentValueGross, 'currency');
        return this.$t('form.noticeTextSpending', [docValue]) as string;
      }

      if (!this.activeItem.isIncome &&  this.activeItem.documentValueGross >= 0)
      {
        const docValue = this.$n(this.activeItem.documentValueGross, 'currency');
        return this.$t('form.noticeTextSpending', [docValue]) as string;
      }
      if (!this.activeItem.isIncome &&  this.activeItem.documentValueGross < 0)
      {
        const docValue = this.$n(-this.activeItem.documentValueGross, 'currency');
        return this.$t('form.noticeTextIncome', [docValue]) as string;
      }
    }
    return '';
  }
}
