import { UserType, UserJson, UserPermission } from './modules/user';
import { store as mainStore, Getters } from '@/store';

/**
 * Data used to submit the login form.
 */
export interface UserLoginRequest
{
  email: string;
  password: string;
}

/**
 * User account information provided with login.
 */
export interface UserLoginResponse extends UserJson
{
  rights: string[];
}

export interface UserPasswordRequestJson
{
  email: string;
  readonly passwordRequestExpirationTime: Date;
}

export interface UserPasswordResetJson
{
  passwordRequestUid: string;
  passwordNew: string;
}

export function hasPermission(userPermission: UserPermission): boolean {
  const currentUser = mainStore[Getters.CURRENT_USER_LOGIN];

  // permissions will be only checked for office worker
  if (currentUser && currentUser.userType !== UserType.OFFICE_WORKER) {
    return true;
  }

  if (currentUser && currentUser.userPermissions && currentUser.userPermissions.some(up => up === userPermission)) {
    return true;
  }
  return false;
}
