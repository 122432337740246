import _ from 'lodash';
import { CancelToken } from 'axios';
import { CustomerJson } from './json';
import { UserType, injectResetMutation } from '../user';
import { EntityPagingStore, EntityPagingStoreState, createEntityPagingStore, FormData, ArrayFormData, NewEntityProperties, EntityId, getId } from '@/base';
import api from '@/plugins/api';
import { CustomerCostConfigurationJson } from '../costconfiguration/customer';
import { CustomerLanguageGroupsJson } from './customerlanguagegroup';
import { ContactJson } from '../contact';
import { EmailTemplateJson } from '../emailtemplate';
import { EmailJson } from '../email';

export interface State extends EntityPagingStoreState<CustomerJson> {
  /**
   * The list of language groups per customer id.
   */
  languageGroups: _.NumericDictionary<CustomerLanguageGroupsJson>;
  /**
   * The list of cost configurations per customer id.
   */
  costConfigurations: _.NumericDictionary<CustomerCostConfigurationJson>;
  /**
   * The list of invoice contacts per customer id.
   */
  invoiceContacts: _.NumericDictionary<ContactJson>;
}

export enum Actions {
  READ_LANGUAGE_GROUPS = 'dispatchReadLanguageGroups',
  SAVE_LANGUAGE_GROUPS = 'dispatchSaveLanguageGroups',
  READ_COST_CONFIGURATION = 'dispatchReadCostConfiguration',
  SAVE_COST_CONFIGURATION = 'dispatchSaveCostConfiguration',
  READ_INVOICE_CONTACT = 'dispatchReadInvoiceContact',
  SAVE_INVOICE_CONTACT = 'dispatchSaveInvoiceContact',
  SEND_ACTIVATION_EMAIL = 'dispatchSendActivationEmail',
  READ_EMAIL = 'dispatchReadEmail',
}

export enum Mutations {
  LANGUAGE_GROUPS = 'commitLanguageGroups',
  COST_CONFIGURATION = 'commitCostConfiguration',
  INVOICE_CONTACT = 'commitInvoiceContact',
}

export interface Store extends EntityPagingStore<CustomerJson> {
  [Actions.READ_LANGUAGE_GROUPS](payload: EntityId<CustomerJson> | { customer: EntityId<CustomerJson> }): Promise<CustomerLanguageGroupsJson>;
  [Actions.SAVE_LANGUAGE_GROUPS](payload: { customer: EntityId<CustomerJson>; languageGroups: FormData<CustomerLanguageGroupsJson> }): Promise<CustomerLanguageGroupsJson>;
  [Actions.READ_COST_CONFIGURATION](payload: EntityId<CustomerJson> | { customer: EntityId<CustomerJson> }): Promise<CustomerCostConfigurationJson>;
  [Actions.SAVE_COST_CONFIGURATION](payload: {
    customer: EntityId<CustomerJson>;
    costConfiguration: FormData<CustomerCostConfigurationJson>;
  }): Promise<CustomerCostConfigurationJson>;
  [Actions.READ_INVOICE_CONTACT](payload: EntityId<CustomerJson> | { customer: EntityId<CustomerJson> }): Promise<ContactJson>;
  [Actions.SAVE_INVOICE_CONTACT](payload: { customer: EntityId<CustomerJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
  [Actions.SEND_ACTIVATION_EMAIL](customer: FormData<CustomerJson>): Promise<CustomerJson>;
  [Actions.READ_EMAIL](payload: { customer: EntityId<CustomerJson>; emailTemplate: EntityId<EmailTemplateJson>; isTranslator: boolean }): Promise<EmailJson>;
}

const apiBaseUrl = 'customers';

function createItem(): NewEntityProperties<CustomerJson> {
  return {
    userType: UserType.CUSTOMER,
    lastName: '',
    firstName: '',
    email: '',
    taxId: '',
    vatExempt: false,
    customerComment: '',
    customerGroup: null,
    costCenter: null,
    isLocked: false,
    userPermissions: [],
  };
}

export const store = createEntityPagingStore<CustomerJson, State, Store>(
  'customer',
  apiBaseUrl,
  createItem,
  {
    languageGroups: {},
    costConfigurations: {},
    invoiceContacts: {},
  },
  moduleBuilder => {
    injectResetMutation(moduleBuilder);

    const setLanguageGroups = moduleBuilder.commit((state, { customer, languageGroups }: { customer: EntityId<CustomerJson>; languageGroups: CustomerLanguageGroupsJson }) => {
      state.languageGroups[getId(customer)] = languageGroups;
    }, Mutations.LANGUAGE_GROUPS);

    const setCostConfiguration = moduleBuilder.commit(
      (state, { customer, costConfiguration }: { customer: EntityId<CustomerJson>; costConfiguration: CustomerCostConfigurationJson }) => {
        state.costConfigurations[getId(customer)] = costConfiguration;
      },
      Mutations.COST_CONFIGURATION,
    );

    const setInvoiceContact = moduleBuilder.commit((state, { customer, contact }: { customer: EntityId<CustomerJson>; contact: ContactJson }) => {
      state.invoiceContacts[getId(customer)] = contact;
    }, Mutations.INVOICE_CONTACT);

    return {
      [Actions.READ_LANGUAGE_GROUPS]: moduleBuilder.dispatch(async ({ state }, payload: EntityId<CustomerJson> | { customer: EntityId<CustomerJson> }) => {
        if (typeof payload === 'number' || !('customer' in payload)) {
          payload = {
            customer: payload,
          };
        }

        const { customer } = payload;
        const customerId = getId(customer);

        const languageGroups = await api.get<CustomerLanguageGroupsJson>(`${apiBaseUrl}/${customerId}/languagegroups`);
        setLanguageGroups({ customer, languageGroups });

        return state.languageGroups[customerId];
      }, Actions.READ_LANGUAGE_GROUPS),

      [Actions.SAVE_LANGUAGE_GROUPS]: moduleBuilder.dispatch(
        async (ctx, { customer, languageGroups }: { customer: EntityId<CustomerJson>; languageGroups: FormData<CustomerLanguageGroupsJson> }) => {
          const customerId = getId(customer);
          const serverResult = await api.put<CustomerLanguageGroupsJson>(`${apiBaseUrl}/${customerId}/languagegroups`, languageGroups);
          const committed = languageGroups.$commit(serverResult);
          setLanguageGroups({ customer, languageGroups: committed });
          return committed;
        },
        Actions.SAVE_LANGUAGE_GROUPS,
      ),

      [Actions.READ_COST_CONFIGURATION]: moduleBuilder.dispatch(async ({ state }, payload: EntityId<CustomerJson> | { customer: EntityId<CustomerJson> }) => {
        if (typeof payload === 'number' || !('customer' in payload)) {
          payload = {
            customer: payload,
          };
        }

        const { customer } = payload;
        const customerId = getId(customer);

        const costConfiguration = await api.get<CustomerCostConfigurationJson>(`${apiBaseUrl}/${customerId}/costconfiguration`);
        setCostConfiguration({ customer, costConfiguration });

        return state.costConfigurations[customerId];
      }, Actions.READ_COST_CONFIGURATION),

      [Actions.SAVE_COST_CONFIGURATION]: moduleBuilder.dispatch(
        async (ctx, { customer, costConfiguration }: { customer: EntityId<CustomerJson>; costConfiguration: FormData<CustomerCostConfigurationJson> }) => {
          const customerId = getId(customer);
          const serverResult = await api.put<CustomerCostConfigurationJson>(`${apiBaseUrl}/${customerId}/costconfiguration`, costConfiguration);
          const committed = costConfiguration.$commit(serverResult);
          setCostConfiguration({ customer, costConfiguration: committed.$raw });
          return committed;
        },
        Actions.SAVE_COST_CONFIGURATION,
      ),

      [Actions.READ_INVOICE_CONTACT]: moduleBuilder.dispatch(async ({ state }, payload: EntityId<CustomerJson> | { customer: EntityId<CustomerJson> }) => {
        if (typeof payload === 'number' || !('customer' in payload)) {
          payload = {
            customer: payload,
          };
        }

        const { customer } = payload;
        const customerId = getId(customer);

        const contact = await api.get<ContactJson>(`${apiBaseUrl}/${customerId}/invoicecontact`);
        setInvoiceContact({ customer, contact });

        return state.invoiceContacts[customerId];
      }, Actions.READ_INVOICE_CONTACT),

      [Actions.SAVE_INVOICE_CONTACT]: moduleBuilder.dispatch(async (ctx, { customer, contact }: { customer: EntityId<CustomerJson>; contact: FormData<ContactJson> }) => {
        const customerId = getId(customer);
        const serverResult = await api.put<ContactJson>(`${apiBaseUrl}/${customerId}/invoicecontact`, contact);
        const committed = contact.$commit(serverResult);
        setInvoiceContact({ customer, contact: committed.$raw });
        return committed;
      }, Actions.SAVE_INVOICE_CONTACT),

      [Actions.SEND_ACTIVATION_EMAIL]: moduleBuilder.dispatch(async ({ state }, customer: FormData<CustomerJson>) => {
        const result = await api.post<CustomerJson>(`${apiBaseUrl}/activationemail`, customer.$raw);
        return customer.$commit(result);
      }, Actions.SEND_ACTIVATION_EMAIL),

      [Actions.READ_EMAIL]: moduleBuilder.dispatch((ctx, { customer, emailTemplate, isTranslator }: Parameters<Store[Actions.READ_EMAIL]>[0]) => {
        const emailPath = isTranslator ? 'email/translator' : 'email/customer';
        return api.get<EmailJson>(`${apiBaseUrl}/${getId(customer)}/${emailPath}/${getId(emailTemplate)}`);
      }, Actions.READ_EMAIL),
    };
  },
);

export default store;
