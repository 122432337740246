import Vue from 'vue';
import _ from 'lodash';
import { mdiFormatIndentIncrease } from '@mdi/js';

declare module 'vue/types/vue'
{
  interface Vue
  {
    /**
     * Returns the lodash utils.
     */
    $_: typeof _;
  }
}

/**
 * This Plugin provides the lodash utils in every Vue instance.
 */
Vue.use(vue =>
  {
    Object.defineProperty(vue.prototype, '$_', {
      enumerable: true,
      value: _,
    });
  });

// Fix methods so they use the reactive methods
_.reverse = array => array == null ? array : Array.prototype.reverse.call(array) as any;
_.pull = (array: any[], ...values: any[]) => basePullAll(array, values);
_.pullAll = (array: any[], values: any[]) => basePullAll(array, values);
_.pullAllBy = (array: any[], values: any[], iteratee: any) => basePullAll(array, values, iteratee);
_.pullAllWith = (array: any[], values: any[], comparator: any) => basePullAll(array, values, undefined, comparator);
_.pullAt = (array: any[], ...indices: _.Many<number>[]) => _.sortedUniq(_.flatten(indices).sort((a, b) => b - a))
  .reduce((res, idx) => (res.push(...array.splice(idx, 1)), res), [] as any[]); // simplified version

function basePullAll(array: any[], values: ReadonlyArray<any>, iteratee?: (v: any) => any, comparator?: _.Comparator<any>)
{
  if (array == null || !array.length || values == null || !values.length)
  {
    return array;
  }

  const length = values.length;

  let index = -1;
  let seen = array;

  if (array === values)
  {
    values = values.slice();
  }

  if (iteratee)
  {
    seen = array.map(v => iteratee(v));
  }

  while (++index < length)
  {
    let fromIndex = 0;
    const value = values[index];
    const computed = iteratee ? iteratee(value) : value;

    const indexOf = comparator ? (arr: any[], val: any, fi?: number, cp?: _.Comparator<any>) => _.findIndex(arr, v => cp!(v, val), fi) : _.indexOf;

    // tslint:disable-next-line:no-conditional-assignment
    while ((fromIndex = indexOf(seen, computed, fromIndex, comparator)) > -1)
    {
      if (seen !== array)
      {
        seen.splice(fromIndex, 1);
      }
      array.splice(fromIndex, 1);
    }
  }
  return array;
}
