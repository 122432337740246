
























































import { Component } from 'vue-property-decorator';
import { BusinessUnitJson } from '../json';
import FormBase from '@/base/EntityForm';
import store from '../store';
import { UserPermission } from '@/modules/user';

@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends FormBase<BusinessUnitJson, typeof store>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.MASTER_DATA_WRITE);
  }

  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'businessunit',
  };
}
