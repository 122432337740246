import api from '@/plugins/api';
import { CashBookJson, CashBookResultJson } from './json';
import { EntityStore, createEntityStore, EntityStoreState, NewEntityProperties, EntityId, getId, FormData} from '@/base';

export enum Actions
{
  CREATE_CASHBOOK = 'dispatchCreateCashBook',
  GET_CURRENT_BALANCES = 'dispatchGetCurrentBalances',
}

export interface State extends EntityStoreState<CashBookJson>
{

}

export interface Store extends EntityStore<CashBookJson>
{
  [Actions.CREATE_CASHBOOK](payload: { cashBookJson: FormData<CashBookJson> }): Promise<void>;
  [Actions.GET_CURRENT_BALANCES](payload: {}): Promise<CashBookResultJson[]>;
}

function createItem(): NewEntityProperties<CashBookJson>
{
  return {
    toDate: null,
    fromDate: null,
    businessUnit: null,
  };
}


function formatDatetime(date: Date, format: string) {
  const _padStart = (value: number): string => value.toString().padStart(2, '0');
  return format
    .replace(/yyyy/g, _padStart(date.getFullYear()))
    .replace(/dd/g, _padStart(date.getDate()))
    .replace(/mm/g, _padStart(date.getMonth() + 1))
    .replace(/hh/g, _padStart(date.getHours()))
    .replace(/ii/g, _padStart(date.getMinutes()))
    .replace(/ss/g, _padStart(date.getSeconds()));
  }

const baseUrl = 'cashbooks';

export const store = createEntityStore<CashBookJson, State, Store>(
  'cashbook',
  baseUrl,
  createItem,
  {  },
  moduleBuilder =>
  {
      return ({
        [Actions.CREATE_CASHBOOK]: moduleBuilder.dispatch(async ({  }, { cashBookJson }: Parameters<Store[Actions.CREATE_CASHBOOK]>[0]) =>
          {
            const buId = cashBookJson.businessUnit ? cashBookJson.businessUnit.id : 0;
            const fromDate = cashBookJson.fromDate ? formatDatetime(cashBookJson.fromDate, 'yyyy-mm-dd') : '2000-01-01';
            const toDate = cashBookJson.toDate ? formatDatetime(cashBookJson.toDate, 'yyyy-mm-dd') : '2000-01-01';
            return api.download({
              method: 'GET',
              url: `${baseUrl}/${buId}/${fromDate}/${toDate}`,
            });

          }, Actions.CREATE_CASHBOOK),

        [Actions.GET_CURRENT_BALANCES]: moduleBuilder.dispatch(async () =>
          {
            return await api.get<CashBookResultJson[]>(baseUrl + '/currentbalances');
          }, Actions.GET_CURRENT_BALANCES),
      });
    },
);
