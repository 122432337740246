
















































import { Vue, Prop, Component, Watch, Emit } from 'vue-property-decorator';
import Sortable from 'sortablejs';
import { BaseEntity, NewEntity, WritableEntity } from '../base';
import './FormItemGroup.scss';

@Component<FormItemGroup<BaseEntity>>({
  mounted()
  {
    this.sortablejs = new Sortable(this.$el.querySelector('.v-expansion-panels') as HTMLElement, {
      draggable: '.v-expansion-panel',
      handle: '.drag-handle:not(.v-icon--disabled)',
      disabled: !this.sortable,
      onEnd: e => this.moveItem(e.oldIndex!, e.newIndex!),
    });
  },
  destroyed()
  {
    if (this.sortablejs)
    {
      this.sortablejs.destroy();
    }
  },
})
export default class FormItemGroup<T extends BaseEntity> extends Vue
{
  /**
   * The item array.
   */
  @Prop({ required: true, type: Array })
  public readonly items: (NewEntity<T> | WritableEntity<T>)[];

  /**
   * The title of the component.
   */
  @Prop()
  public readonly title?: string;

  @Prop({ required: false })
  public disabled: false;

  /**
   * A function to create a new entity.
   */
  @Prop()
  public readonly createEntity?: () => NewEntity<T>;

  /**
   * A function to remove a new entity.
   */
  @Prop()
  public readonly removeEntity?: (entity: NewEntity<T> | WritableEntity<T>) => void;

  /**
   * A function to download a new entity.
   */
  @Prop()
  public readonly downloadEntity?: (entity: NewEntity<T> | WritableEntity<T>) => void;

  /**
   * A function that returns the title of an item.
   */
  @Prop()
  public readonly itemTitle?: (item: T) => string;

  /**
   * A function that returns the warningtitle of an item.
   */
  @Prop()
  public readonly warningTitle?: (item: T) => string;

  /**
   * Whether the elements can be reordered.
   */
  @Prop({ default: false, type: Boolean })
  public readonly sortable: boolean;

  private addEntity(): void
  {
    if (this.createEntity)
    {
      this.items.unshift(this.createEntity());
      // for some reason even if prepended this is the last index
      this.expandedItem = this.items.length - 1;
    }
  }

  private removeEntityInternal(entity: NewEntity<T> | WritableEntity<T>): void
  {
    if (this.removeEntity)
    {
      this.removeEntity(entity);
    }
    this.$_.pull(this.items, entity);
  }

  private downloadEntityInternal(entity: NewEntity<T> | WritableEntity<T>): void
  {
    if (this.downloadEntity)
    {
      this.downloadEntity(entity);
    }
  }

  /**
   * Called at the end of dragging and moves the element from the old index to the new index.
   */
  private moveItem(oldIndex: number, newIndex: number): void
  {
    if (oldIndex !== newIndex && oldIndex >= 0 && oldIndex < this.items.length && newIndex >= 0 && newIndex < this.items.length)
    {
      this.items.splice(newIndex, 0, ...this.items.splice(oldIndex, 1));
    }
  }

  private moveItemUp(item: NewEntity<T> | WritableEntity<T>): void
  {
    const index = this.items.indexOf(item);
    this.moveItem(index, index - 1);
  }

  private moveItemDown(item: NewEntity<T> | WritableEntity<T>): void
  {
    const index = this.items.indexOf(item);
    this.moveItem(index, index + 1);
  }

  @Watch('sortable')
  private onSortableChange(newValue: boolean): void
  {
    if (this.sortablejs)
    {
      this.sortablejs.option('disabled', !newValue);
    }
  }

  /**
   * The index of the expanded item.
   */
  private expandedItem = -1;

  private sortablejs: Sortable;
}
