import { ReadonlyKeys, WritableKeys } from 'ts-essentials';

/**
 * Base entity interface.
 */
export interface BaseEntity
{
  readonly id: number;
  readonly createDate: Date;
  readonly modifyDate: Date;
}

/**
 * Helper type for type inference.
 */
export type ArrayOfBaseEntity<T extends BaseEntity> = T[];

/**
 * Helper type for type inference. Returns the type of one entity in an entity array.
 */
export type ArrayOfBaseEntityType<T extends BaseEntity> = T[] extends (infer U)[] ? U : T;

/**
 * Type of an entity with its writable properties be nullable.
 */
export type WritableEntity<T extends BaseEntity> = NewEntity<T> & Pick<T, ReadonlyKeys<T>>;

/**
 * The type to create a new entity.
 */
export type NewEntityProperties<T extends BaseEntity> = {
  [P in WritableKeys<T>]: T[P] extends ArrayOfBaseEntity<infer U>
    ? NewEntity<U>[]
    : T[P] extends BaseEntity
      ? NewEntity<T[P]> | null
      : T[P] | null;
};

/**
 * Type of an entity with its writable only properties.
 */
export type NewEntity<T extends BaseEntity> = BaseEntity & NewEntityProperties<T> & {
  readonly [P in Exclude<ReadonlyKeys<T>, keyof BaseEntity>]: undefined;
};

let entitySeed = 0;
/**
 * Creates a new entity of any type.
 * @param entity The properties of the new entity.
 */
export function createEntity<T extends BaseEntity>(entity: NewEntityProperties<T>): NewEntity<T>
{
  return Object.assign({
    id: --entitySeed,
    createDate: new Date(),
    modifyDate: new Date(),
  } as any, entity) as NewEntity<T>;
}

/**
 * Checks whether the given item is a base entity.
 */
export function isBaseEntity(item: any): item is BaseEntity
{
  return item && typeof item.id === 'number' && item.createDate instanceof Date && item.modifyDate instanceof Date;
}

/**
 * Type of an entity or its id.
 */
export type EntityId<T extends BaseEntity> = T | number;

/**
 * Returns the id of an entity if it is not already an id.
 */
export function getId<T extends BaseEntity>(entity: EntityId<T>): number
{
  return typeof entity === 'number' ? entity : entity.id;
}
