import api from '@/plugins/api';
import { StarMoneyImportJson, StarMoneyImportState } from './json';
import { EntityPagingStore, createEntityPagingStore, EntityPagingStoreState, NewEntityProperties, EntityId, getId, FormData} from '@/base';

export enum Actions
{
  UPLOAD_FILE = 'dispatchUploadFile',
}

export interface State extends EntityPagingStoreState<StarMoneyImportJson>
{
  starMoneyImports: _.NumericDictionary<StarMoneyImportJson>;
}

export interface Store extends EntityPagingStore<StarMoneyImportJson>
{
  [Actions.UPLOAD_FILE](
    payload: { file: File }): Promise<StarMoneyImportJson>;
}

function createItem(): NewEntityProperties<StarMoneyImportJson>
{
  return {
    importState: StarMoneyImportState.READY_FOR_IMPORT,
    user: null,
    fileName: null,
  };
}

const baseUrl = 'starmoneyimports';

export const store = createEntityPagingStore<StarMoneyImportJson, State, Store>(
  'starmoneyimport',
  baseUrl,
  createItem,
  {
    starMoneyImports: {},
  },
  moduleBuilder =>
  {
      return ({
        [Actions.UPLOAD_FILE]: moduleBuilder.dispatch(async ({ state }, { file }: Parameters<Store[Actions.UPLOAD_FILE]>[0]) =>
        {
          const result: StarMoneyImportJson = await api.upload<StarMoneyImportJson>({
            data: {
              'user-file': file,
            },
            method: 'POST',
            url: `${baseUrl}`,
          });
          state.starMoneyImports[getId(result)] = result;
          return result;
        }, Actions.UPLOAD_FILE),
      });
    },
);
