













































import { Component, Vue } from 'vue-property-decorator';

import AjaxLoader from './components/AjaxLoader.vue';
import Menu from './components/Menu.vue';
import { store, Getters, Actions, Mutations, Alert } from './store';
import { logoutAndRedirectToLogin } from './router';
import { availableLocales, loadLanguageAsync } from './plugins/i18n';
import { store as userStore, CurrentUserGetters, UserJson } from '@/modules/user';

@Component<App>({
  components: {
    AjaxLoader,
    Menu,
  },
  created()
  {
    this.init();
  },
  destroyed()
  {
    if (this.__storeSubscription)
    {
      this.__storeSubscription();
    }
  },
})
export default class App extends Vue
{
  public get isLoggedIn(): typeof store[Getters.IS_LOGGED_IN]
  {
    return store[Getters.IS_LOGGED_IN];
  }
  public get currentUser(): UserJson | null
  {
    return store[Getters.CURRENT_USER_LOGIN];
  }

  public get currentVersion(): string
  {
     const pjson = require('./../package.json');
     return pjson.version;
  }

  public getCurrentUserType(): string
  {
    const currentUser = this.currentUser;

    if (currentUser && currentUser.userType)
    {
      return this.$root.$t('module.user.enums.UserType.' + currentUser.userType) as string;
    }
    return this.$root.$t('module.user.enums.userType.UNKNOWN') as string;
  }

  /**
   * Whether the drawer is open.
   */
  public drawer = false;

  /**
   * Returns the name of the company.
   */
  public get company(): string
  {
    return process.env.VUE_APP_COMPANY || '<unknown>';
  }

  /**
   * The current locale for the footer button.
   */
  public get currentLocale(): string
  {
    return this.$i18n.locale;
  }

  /**
   * The available locales for the footer button menu.
   */
  public get availableLocales(): string[]
  {
    return availableLocales;
  }

  private init(): void
  {
    this.drawer = this.isLoggedIn;

    // open/close drawer on login/logout
    this.__storeSubscription = this.$store.subscribe(mutation =>
    {
      if (mutation.type === Mutations.CURRENT_USER_LOGIN)
      {
        // do not force to open on mobile devices
        if (!!mutation.payload && (this.$refs.drawer as any).isMobile)
        {
          return;
        }

        this.drawer = !!mutation.payload;
      }
      else if (mutation.type === Mutations.APPEND_ALERT && this.isLoggedIn)
      {
        this.$vuetify.goTo(0);
      }
  });

    // check if user is really logged in
    if (this.isLoggedIn)
    {
      store[Actions.REFRESH_LOGIN]()
        .then(
          loggedIn =>
          {
            if (!loggedIn)
            {
              logoutAndRedirectToLogin();
            }
          },
          err => logoutAndRedirectToLogin());
    }
  }

  /**
   * Action for the logout button.
   */
  public logout(): void
  {
    store[Actions.LOGOUT]()
      .then(() =>
      {
        this.$router.push('/');
        // reload browser to clear the app
        self.location.reload();
      });
  }

  /**
   * Changes the locale by clicking the corresponding menu item.
   */
  public changeLocale(locale: string): void
  {
    loadLanguageAsync(locale)
      .then(
        () => this.$forceUpdate(/* rerender v-t directives */),
        err => this.$error(err.message || err.toString(), 5000));
  }

  private __storeSubscription: (() => void) | null;
}
