










import { Component, Prop, Vue } from 'vue-property-decorator';

export interface Property
{
  /**
   * The property value/path.
   */
  value: string;
  /**
   * The title of the propery.
   */
  title: string;
}

/**
 * A component to display a list of details of an item.
 */
@Component
export default class Details<T> extends Vue
{
  /**
   * The item to display the details for.
   */
  @Prop({ required: true })
  public readonly item: T;

  /**
   * The list of properties to display.
   */
  @Prop({ required: true })
  public readonly properties: Property[];
}

