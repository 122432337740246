












import { Component, Prop, Vue } from 'vue-property-decorator';
import Details, { Property } from '@/components/Details.vue';
import { TaskJson } from '../json';

@Component<EventDetails>({
  module: 'task', // override because it is used in calendar
  components: {
    Details,
  },
})
export default class EventDetails extends Vue {
  private showCustomerCommentDialog = false;

  @Prop({ required: true })
  public readonly task: TaskJson;

  private get properties(): Property[] {
    return [
      {
        value: 'taskName',
        title: this.$t('event.details.taskName').toString(),
      },
      {
        value: 'taskDate',
        title: this.$t('event.details.taskDate').toString(),
      },
    ];
  }
}
