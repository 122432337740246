















import { Component } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { SystemConfigurationJson, SystemConfigurationKey } from '../json';
import { Form as FormBase, EditFormData, asFormData } from '@/base';
import { getEnumByName } from '@/util';
import store, { Getters, Actions, Mutations } from '../store';

@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends FormBase<EditFormData<SystemConfigurationJson>>
{
  /**
   * Returns the currently active item.
   */
  public get activeItem(): EditFormData<SystemConfigurationJson> | null
  {
    return store[Getters.ACTIVE_ITEM];
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: EditFormData<SystemConfigurationJson>): boolean
  {
    return item.$isDirty();
  }

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: EditFormData<SystemConfigurationJson> | null): void
  {
    const activeItem = this.activeItem;
    store[Mutations.ACTIVE_ITEM](item);
    if (activeItem && activeItem !== item)
    {
      activeItem.$revoke();
    }
  }

  /**
   * Called when the form is closed.
   */
  protected onClose(): void
  {
    this.$router.push({ name: 'systemconfiguration' });
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: EditFormData<SystemConfigurationJson>): Promise<void>
  {
    await store[Actions.UPDATE](item);
    this.$success(this.$root.$t('form.save.success').toString());
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: EditFormData<SystemConfigurationJson>): void
  {
    item.$reset();
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  protected handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void)
  {
    const key = getEnumByName(SystemConfigurationKey, to.params.key);

    if (key)
    {
      store[Actions.READ_ONE](key)
        .then(item => this.open(() => asFormData<SystemConfigurationJson>(item)))
        .then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    }
    else
    {
      next(from.fullPath === '/' && !from.name ? { name: 'systemconfiguration', replace: true } : { path: from.fullPath, replace: true });
    }
  }
}
