import { ContractJson } from '../json';
import { BaseEntity } from '@/base';

export enum TravelOption
{
  FIX_RATE = 'FIX_RATE',
  COST_PER_KM = 'COST_PER_KM',
  PUBLIC_TRANSIT_TICKET = 'PUBLIC_TRANSIT_TICKET',
  NO_REFUND = 'NO_REFUND',
}
export interface TravelCostJson
{
  isTravelCostCustomer: boolean;
  isTravelCostTranslator: boolean;
  travelAmount: number | null;
  travelOption: TravelOption;
}

export interface OnSiteTranslationContractJson extends ContractJson
{
  beginDateTime: Date;
  plannedTranslationDuration: number;
  realTranslationStart: number | null;
  realTranslationEnd: number | null;
  travelTimeToLocationStart: number | null;
  travelTimeToLocationEnd: number | null;
  travelTimeBackStart: number | null;
  travelTimeBackEnd: number | null;
  waitTimeAtLocation: number | null;
  travelCosts: TravelCostJson[];
  operationContact: ContractJson | null;
}
