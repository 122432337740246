import _ from 'lodash';
import { EmailJson } from './json';
import { EntityStore, createEntityStore, EntityStoreState, NewEntityProperties, EntityId, getId, FormData} from '@/base';
import { FileEntityCollectionJson, FileEntityJson } from '../file';
import api from '@/plugins/api';
import { createCommonHandlers } from 'vee-validate/dist/types/components/common';
import { EmailTemplateJson } from '../emailtemplate';


export enum Actions
{
  READ_FILE_ATTACHMENT_COLLECTION = 'dispatchReadFileAttachmentCollection',
  UPLOAD_FILE = 'dispatchUploadFile',
  DELETE_FILE = 'dispatchDeleteFile',
  SEND_EMAIL = 'dispatchSendEmail',
}

export enum Mutations
{
  FILE_ATTACHMENT_COLLECTION = 'commitFileAttachmentCollection',
}

export interface State extends EntityStoreState<EmailJson>
{
  /**
   * The list of file entity collections per email template id.
   */
  fileEntityCollections: _.NumericDictionary<FileEntityCollectionJson>;
}

export interface Store extends EntityStore<EmailJson>
{
  [Actions.UPLOAD_FILE](
    payload: {email: FormData<EmailJson>, file: File }): Promise<FileEntityCollectionJson>;
  [Actions.DELETE_FILE](
    payload: {email: EntityId<EmailJson>, fileEntity: FileEntityJson }): Promise<FileEntityCollectionJson>;
  [Actions.SEND_EMAIL](
    payload: {email: FormData<EmailJson> }): Promise<EmailJson>;
}

function createItem(): NewEntityProperties<EmailJson>
{
  return {
    emailTo: '',
    emailCc: '',
    subject: '',
    body: '',
    fileAttachmentCollection: null,
  };
}

const apiBaseUrl = 'emails';

export const store = createEntityStore<EmailJson, State, Store>(
  'email',
  apiBaseUrl,
  createItem,
  {
    fileEntityCollections: {},
  },
  moduleBuilder =>
  {

    const setFileAttachmentCollection = moduleBuilder.commit(
      (state, { email, fileEntityCollection }: { email: EntityId<EmailJson>; fileEntityCollection: FileEntityCollectionJson }) =>
      {
        state.fileEntityCollections[getId(email)] = fileEntityCollection;
      }, Mutations.FILE_ATTACHMENT_COLLECTION);

    return {
      [Actions.UPLOAD_FILE]: moduleBuilder.dispatch(async ({ state }, { email, file }: Parameters<Store[Actions.UPLOAD_FILE]>[0]) =>
        {
          const result = await api.upload<FileEntityCollectionJson>({
            data: {
              'user-file': file,
            },
            method: 'POST',
            url: `${apiBaseUrl}/${email.id}/file`,
          });
          state.fileEntityCollections[getId(email)] = result;
          return result;
        }, Actions.UPLOAD_FILE),
      [Actions.DELETE_FILE]: moduleBuilder.dispatch(async ({ state }, { email, fileEntity }: Parameters<Store[Actions.DELETE_FILE]>[0]) =>
        {
          const emailId = getId(email);
          await api.delete(`${apiBaseUrl}/${emailId}/file/${fileEntity.id}`);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${emailId}/fileattachmentcollection`);
          setFileAttachmentCollection({ email, fileEntityCollection });
          return state.fileEntityCollections[emailId];
        }, Actions.DELETE_FILE),

      [Actions.SEND_EMAIL]: moduleBuilder.dispatch(async ({ state }, { email }: Parameters<Store[Actions.SEND_EMAIL]>[0]) =>
        {
          const emailId = getId(email);
          const emailSend = await api.post<EmailJson>(`${apiBaseUrl}/send/${emailId}`, email);
          return emailSend;
        }, Actions.SEND_EMAIL),
    };
  },
);
