




















































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { BankAccountTransactionJson } from '../json';
import { store as businessUnitStore } from '@/modules/businessunit';
import PositionListInvoice from '../components/PositionListInvoice.vue';
import PositionListCredit from '../components/PositionListCredit.vue';
import PositionListExternalDocument from '../components/PositionListExternalDocument.vue';
import { EntityForm as FormBase, EntityStoreGetters, EntityStoreActions } from '@/base';
import { store } from '../store';
import { UserPermission } from '@/modules/user';


@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
  components: {
    PositionListInvoice,
    PositionListCredit,
    PositionListExternalDocument,
    },
})
export default class Form extends FormBase<BankAccountTransactionJson, typeof store>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_WRITE);
  }

  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'bankaccounttransaction',
  };

  private readonly businessUnitStore = businessUnitStore;

  protected createExternalDocument()
  {
    this.$router.push('/externaldocument/new?backTo=' + this.$route.fullPath);
  }

  private getBusiunitCheckMsg(): string {
    if (this.activeItem && this.activeItem.businessUnit)
    {
      const buId = this.activeItem.businessUnit.id;
      const assignmentInvoiceOK = this.activeItem.bankAccountTransactionInvoicePositions.every(pos => pos.entity != null && pos.entity.businessUnit.id === buId);
      const assignmentCreditOK = this.activeItem.bankAccountTransactionCreditPositions.every(pos => pos.entity != null && pos.entity.businessUnit.id === buId);
      const assignmentExternalDocumentOK = this.activeItem.bankAccountTransactionExternalDocumentPositions.every(pos => pos.entity != null && pos.entity.businessUnit.id === buId);

      if (!assignmentInvoiceOK || !assignmentCreditOK || !assignmentExternalDocumentOK) {
        return this.$t('form.notAllBusinessUnitAssignmentsValid', [this.activeItem.businessUnit.name]) as string;
      }
    }
    return '';
  }
}
