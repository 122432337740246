import i18n from '@/plugins/i18n';
import { EntityPagingStoreActions } from '@/base';
import { EventHandler, Event } from '@/calendar';
import store from './store';
import EventDetails from './components/EventDetails.vue';
import { CalendarObjectTypes } from '@/modules/calendar';
import { hasPermission } from '@/json';
import { UserPermission } from '@/modules/user';

export const eventHandler: EventHandler = {
  component: {
    extends: EventDetails,
  },

  async getEvents(from, until, businessUnitId, languageId, translatorId, customerId, calendarObjectType)
  {
    if (calendarObjectType && calendarObjectType !== CalendarObjectTypes.VIDEO_CONTRACT || !hasPermission(UserPermission.CONTRACT_READ))
    {
      return [];
    }

    const result = await store[EntityPagingStoreActions.LIST_ALL]({
      filter: {
        beginDateTimeFrom: from,
        beginDateTimeUntil: until,
        businessUnitId,
        translatorLanguageId: languageId,
        translatorId,
        customerId,
      },
    });

    return result.content.map<Event>(c => ({
      start: c.beginDateTime,
      end: new Date(c.beginDateTime.getTime() + c.plannedTranslationDuration * 60000),
      title: `${c.beginDateTime.getHours()}:${(c.beginDateTime.getMinutes() < 10 ? '0' : '') + c.beginDateTime.getMinutes()},
              ${c.translatorLanguage.isoCode}, ${c.translator ? (c.translator.lastName + ' ' + c.translator.firstName.slice(0, 1) + '.') : '-' }, ${c.customer.firstName} ${c.customer.lastName}`,
      color: c.contractComment ? 'calendar_video_translation_contract_color darken-2' : 'calendar_video_translation_contract_color',
      route: `/videotranslation/${c.id}`,
      appointmentComment: c.contractComment,
      customerComment: c.customer.customerComment,
      translatorComment: c.translator ? c.translator.translatorComment : null,
      componentBindings: {
        contract: c,
      },
    }));
  },
};

export default eventHandler;
