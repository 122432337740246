import { RouteConfig } from 'vue-router';

import Account from './views/Account.vue';
import ChangePassword from './views/ChangePassword.vue';
import Contact from './views/Contact.vue';

export default [
  {
    name: 'user',
    path: '',
    component: Account,
  },
  {
    name: 'changePassword',
    path: 'changepassword',
    component: ChangePassword,
    meta: {
      icon: 'userChangePassword',
      hideMenu: true,
    },
  },
  {
    path: 'contact',
    component: Contact,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
] as RouteConfig[];
