











































import { Component, Vue } from 'vue-property-decorator';
import { Form, FormData } from '@/base';
import api from '@/plugins/api';
import store, { CurrentUserActions } from '../store';
import { UserLoginResponse } from '@/json';
import { UserPasswordChangeJson } from '../json';

interface PasswordData extends UserPasswordChangeJson
{
  passwordNewConfirm: string;
}

@Component
export default class ChangePassword extends Form<PasswordData>
{
  /**
   * Returns the currently active item.
   */
  public readonly activeItem: PasswordData = {
    passwordOld: '',
    passwordNew: '',
    passwordNewConfirm: '',
  };

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: PasswordData): boolean
  {
    return !!item.passwordOld || !!item.passwordNew || !!item.passwordNewConfirm;
  }

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: PasswordData | null): void
  {
    // do nothing
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: PasswordData): Promise<void>
  {
    await store.currentUser[CurrentUserActions.CHANGE_PASSWORD]({
      passwordOld: item.passwordOld,
      passwordNew: item.passwordNew,
    });
    this.reset();
    this.$success(this.$t('changePassword.success').toString());
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: PasswordData): void
  {
    item.passwordOld = item.passwordNew = item.passwordNewConfirm = '';
  }

  /**
   * Whether to show the password in the password fields.
   */
  public showPassword = false;
}
