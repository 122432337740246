











import { Component, Vue } from 'vue-property-decorator';
import { List as ListBase, ListAction } from '@/base';
import store from '../store';
import { SystemConfigurationJson, SystemConfigurationKey } from '../json';
import { getEnumFields } from '@/util';

interface SystemConfigurationListItem
{
  key: SystemConfigurationKey;
}

@Component
export default class List extends ListBase<SystemConfigurationListItem>
{
  public sortState = [{ property: 'key' }];

  /**
   * Returns the columns of the table (excluding actions).
   */
  protected getColumns(): VueDataTableHeaderConfig[]
  {
    return [
      {
        text: this.$t('list.header.key').toString(),
        value: 'key',
      },
    ];
  }

  /**
   * Returns the items of the list.
   */
  public readonly items: SystemConfigurationListItem[] = getEnumFields(SystemConfigurationKey).map(key => ({ key }));

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<SystemConfigurationListItem>[]
  {
    return [
      {
        icon: 'edit',
        handler: item => this.editItem(item.key),
        text: this.$root.$t('edit.label').toString(),
      },
    ];
  }

  /**
   * Starts the editing of the given item.
   */
  public editItem(key: SystemConfigurationKey): void
  {
    this.$router.push({ path: key, append: true });
  }
}
