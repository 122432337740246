































































import { Component, Prop } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { WritableEntity, EntityStoreActions, Form as FormBase, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity, FormData, asFormData } from '@/base';
import { BusinessUnitJson } from '../json';
import store, { Actions } from '../store';
import { BankAccountCollectionJson, BankAccountJson } from '@/modules/bankaccount';
import { UserPermission } from '@/modules/user';

/**
 * The type of the active form item.
 */
interface ActiveItem
{
  businessUnit: BusinessUnitJson;
  bankAccountCollection: FormData<BankAccountCollectionJson>;
}

@Component<BankAccountCollection>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created(): void
  {
    // HACK: Dirty is not evaluated if inner values change.
    // this.$watch('activeContact', () => this.$forceUpdate(), { deep: true });
  },
})
export default class BankAccountCollection extends FormBase<ActiveItem>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.MASTER_DATA_WRITE);
  }

  private createBankAccount(): NewEntity<BankAccountJson>
  {
    return createEntity<BankAccountJson>({
      bankName: '',
      IBAN: '',
      BIC: '',
      isActive: true,
    });
  }

  /**
   * Returns the label with the given key. Tries to find it in the current module and falls back
   * to the contact module locale.
   */
  private getLabel(key: string): string
  {
    if (this.$te(key))
    {
      return this.$t(key).toString();
    }

    return this.$root.$t(`module.BankAccountCollection.{key}`).toString();
  }

  private getDescription(item: BankAccountJson): string
  {
    if (item.isActive)
    {
      return item.bankName + ' - IBAN: ' + item.IBAN + ' - (aktiv)';
    }
    return item.bankName + ' - IBAN: ' + item.IBAN;
  }

  /**
   * Returns the cost configuration of the active item.
   */
  public get activeBankAccountCollection(): FormData<BankAccountCollectionJson> | null
  {
    return this.activeItem ? this.activeItem.bankAccountCollection : null;
  }


  /**
   * Returns the currently active item.
   */
  public activeItem: ActiveItem | null = null;

  /**
   * Returns the bank account collection of the active item.
   */
  public get activeContact(): FormData<BankAccountCollectionJson> | null
  {
    return this.activeItem ? this.activeItem.bankAccountCollection : null;
  }

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: ActiveItem | null): void
  {
    const activeBusinessUnit = store[EntityStoreGetters.ACTIVE_ITEM];
    const activeItem = this.activeItem;

    // set the businessUnit active
    store[EntityStoreMutations.ACTIVE_ITEM](item ? item.businessUnit : null);
    this.activeItem = item;

    if (activeBusinessUnit && (!item || item.businessUnit !== activeBusinessUnit.$raw))
    {
      activeBusinessUnit.$revoke();
    }

    if (activeItem && (!item || item.bankAccountCollection !== activeItem.bankAccountCollection))
    {
      activeItem.bankAccountCollection.$revoke();
    }
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: ActiveItem): boolean
  {
    if (item)
    {
    return item.bankAccountCollection.$isDirty();
    }
    return false;
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void
  {
    this.$router.push({ name: 'businessunit' });
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: ActiveItem): Promise<void>
  {
    const result = await store[Actions.SAVE_BANK_ACCOUNT_COLLECTION](item);
    this.changeActiveItem({
      businessUnit: item.businessUnit,
      bankAccountCollection: asFormData(result, false),
    });
    this.$success(this.$root.$t('form.save.success').toString());
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: ActiveItem): void
  {
    item.bankAccountCollection.$reset();
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void)
  {
    const proceed = (businessUnit: BusinessUnitJson, item: BankAccountCollectionJson | NewEntity<BankAccountCollectionJson>): Promise<void> =>
    {
      return this.open(() => ({
        businessUnit,
        bankAccountCollection: asFormData<BankAccountCollectionJson>(item),
      }))
        .then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    };

    const businessUnitId = parseInt(to.params.id, 10);

    store[EntityStoreActions.READ_ONE]({ id: businessUnitId, initial: true })
      .then(businessUnit =>
        {
          // read this separately to handle the 404 error
          return store[Actions.READ_BANK_ACCOUNT_COLLECTION]({ businessUnit })
            .then(item => proceed(businessUnit, item))
            .catch(error =>
            {
              // if not found, create a new one
              if (error.statusCode === 404)
              {
                return proceed(businessUnit, createEntity<BankAccountCollectionJson>({
                    bankAccounts: [],
                }));
              }
              return Promise.reject(error);
            });
        })
        .catch(() => next(from.fullPath === '/' && !from.name ? { name: 'businessUnit', replace: true } : { path: from.fullPath, replace: true }));
  }
}
