import Vue from 'vue';
import VueI18n, { Locale, LocaleMessageObject, Path, IVueI18n } from 'vue-i18n';

Vue.use(VueI18n);

declare module 'vue-i18n'
{
  interface IVueI18n
  {
    /**
     * The Vue instance the i18n instance is using to store the locale.
     */
    readonly vm: Vue;

    /**
     * Formats the number as ordinal (eg. 1st, 2nd, 1., 2., ...)
     */
    nth(n: number): string;
  }
}

/**
 * The list of available locales.
 */
export const availableLocales = process.env.VUE_APP_LOCALES ? process.env.VUE_APP_LOCALES.split(/[^\w-]/) : ['de', 'en'];

const defaultLocale = availableLocales[0];

const i18n = new VueI18n({
  fallbackLocale: defaultLocale,
});
// default impl
(i18n as any).nth = (n: number) => String(n);

const loadedLanguages: string[] = [];

function setI18nLanguage(lang: string)
{
  i18n.locale = lang;
  document.querySelector('html')!.setAttribute('lang', lang);
  self.localStorage.setItem('locale', lang);
  return lang;
}

export function loadLanguageAsync(lang: string)
{
  if (i18n.locale !== lang)
  {
    if (!loadedLanguages.includes(lang))
    {
      return Promise.all([
        import(/* webpackChunkName: "lang-[request]" */ `@/locale/${lang}`),
        import(/* webpackChunkName: "lang-[request]-vv" */ `vee-validate/dist/locale/${lang}.json`),
      ])
        .then(([msgs, validateMessages]) =>
        {
          const { $dateTimeFormats, $numberFormats, $nth, ...messages } = msgs.default;
          i18n.setLocaleMessage(lang, messages);
          if ($dateTimeFormats)
          {
            i18n.setDateTimeFormat(lang, $dateTimeFormats);
          }
          if ($numberFormats)
          {
            i18n.setNumberFormat(lang, $numberFormats);
          }

          if ($nth)
          {
            (i18n as any).nth = $nth;
          }

          if (validateMessages.default)
          {
            i18n.mergeLocaleMessage(lang, {
              $validation: validateMessages.default.messages,
            });
          }

          loadedLanguages.push(lang);
          return setI18nLanguage(lang);
        });
    }
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
}

/**
 * Initializes the locale based on the previous stored locale or browser language.
 */
export function initI18n(): Promise<string>
{
  let locale = self.localStorage.getItem('locale') || navigator.language || defaultLocale;

  const dashPos = locale.indexOf('-');
  if (dashPos > -1)
  {
    locale = locale.substring(0, dashPos);
  }

  locale = locale.toLowerCase();

  if (!availableLocales.includes(locale))
  {
    locale = defaultLocale;
  }

  return loadLanguageAsync(locale);
}

export default i18n;

// override: allow placeholders in link placeholders
const VueI18nProto = VueI18n.prototype as any;
VueI18nProto._interpolate = ((orig: any) =>
{
  return function(this: any, locale: Locale, message: LocaleMessageObject, key: Path, host: any, interpolateMode: string, values: any, visitedLinkStack: string[]): any
  {
    let ret = orig.apply(this, arguments);
    // check again for links if they contained placeholders
    if (ret && (ret.indexOf('@:') >= 0 || ret.indexOf('@.') >= 0))
    {
      ret = this._link(locale, message, ret, host, 'string', undefined, []);
    }
    return ret;
  };
})(VueI18nProto._interpolate);

