










































































































import { Component } from 'vue-property-decorator';
import { EntityList, ListAction, HeaderConfig, EntityPagingStoreActions, EntityStore, EntityStoreActions, EntityStoreGetters } from '@/base';
import store, { Actions } from '../store';
import { TaskJson, TaskState } from '../json';
import { store as mainStore, Getters } from '@/store';
import { store as officeWorkerStore } from '@/modules/officeworker';
import { store as adminStore } from '@/modules/admin';
import { Route, RawLocation } from 'vue-router';
import { UserType, store as userStore, getFullName, UserPermission } from '@/modules/user';
import Task from '../Task.vue';

@Component<List>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },

  created() {
    this.init();
  },
  methods: {
    getFullName,
  },
})
export default class List extends EntityList<TaskJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.APPOINTMENT_WRITE);
  }

  protected readonly officeWorkerStore = officeWorkerStore;
  protected readonly adminStore = adminStore;

  public sortState = [{ property: 'taskDate' }];

  private showUsageDialog = false;

  private contentDialog = '';

  private handleIconClick(subject: string): void {
    this.contentDialog = subject;
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  // tslint:disable-next-line:no-empty
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {}

  /**
   * The store to the items.
   */
  protected readonly store = store;

  protected readonly filters = {
    taskName: '',
    taskState: null as TaskState | null,
    taskDateFrom: null as Date | null,
    taskDateTo: null as Date | null,
    taskDescription: '',
    adminUserId: null as number | null,
    officeWorkerId: null as number | null,
  };

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<TaskJson>[] {
    if (this.hasWritePermission()) {
      return [
        {
          icon: 'edit',
          handler: item => this.editItem(item),
          text: this.$root.$t('edit.label').toString(),
        },
        {
          icon: 'delete',
          handler: item => this.deleteItem(item),
          text: this.$root.$t('delete.label').toString(),
          color: 'delete',
        },
      ];
    }
    return [
      {
        icon: 'edit',
        handler: item => this.editItem(item),
        text: this.$root.$t('edit.label').toString(),
      },
    ];
  }

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[] {
    return [
      {
        value: 'taskName',
        text: this.$t('list.header.taskName').toString(),
      },
      {
        value: 'taskState',
        text: this.$t('list.header.taskState').toString(),
      },
      {
        value: 'taskDate',
        text: this.$t('list.header.taskDate').toString(),
        filterProperty: ['taskDateFrom', 'taskDateTo'],
      },
      {
        value: 'taskDescription',
        text: this.$t('list.header.taskDescription').toString(),
        filterProperty: 'taskDescription',
      },
      {
        value: 'user',
        text: this.$t('list.header.createdBy').toString(),
      },
      {
        value: 'admins',
        text: this.$t('list.header.assignedAdmins').toString(),
        filterProperty: 'adminUserId',
      },
      {
        value: 'officeWorkers',
        text: this.$t('list.header.assignedOfficeWorkers').toString(),
        filterProperty: 'officeWorkerId',
      },
    ];
  }
  private readonly TaskState = TaskState;

  private isAllowForAdminsAndOfficeWorkers(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.OFFICE_WORKER || currentUserType === UserType.ADMIN;
  }

  private canTaskSetToDone(state: TaskState): boolean {
    return state === TaskState.CREATED;
  }

  private async setTaskToDone(item: TaskJson): Promise<void> {
    await store[Actions.SET_STATE]({
      task: item,
      state: TaskState.DONE,
    });
  }

  private canTaskSetToCreated(state: TaskState): boolean {
    return state === TaskState.DONE;
  }

  private async setTaskToCreated(item: TaskJson): Promise<void> {
    await store[Actions.SET_STATE]({
      task: item,
      state: TaskState.CREATED,
    });
  }
}
