import { VideoTranslationContractJson, CommunicationSystem } from './json';
import { EntityPagingStore, EntityPagingStoreState, NewEntityProperties, createEntityPagingStore, commitEntity, EntityId, FormData, getId } from '@/base';
import { ReceiverOption, ContractState } from '../json';
import { TranslatorJson } from '@/modules/translator';
import { api } from '@/plugins';
import { ContactJson } from '@/modules/contact';

export enum Actions
{
  ASSIGN_TRANSLATOR = 'dispatchAssignTranslator',
  SET_STATE = 'dispatchSetState',
  READ_INVOICE_CONTACT = 'dispatchReadInvoiceContact',
  SAVE_INVOICE_CONTACT = 'dispatchSaveInvoiceContact',
  READ_CREDIT_CONTACT = 'dispatchReadCreditContact',
  SAVE_CREDIT_CONTACT = 'dispatchSaveCreditContact',
}

export enum Mutations
{
  INVOICE_CONTACT = 'commitInvoiceContact',
  CREDIT_CONTACT = 'commitCreditContact',
}

export interface State extends EntityPagingStoreState<VideoTranslationContractJson>
{
  /**
   * The list of credit contacts per contract id.
   */
  invoiceContacts: _.NumericDictionary<ContactJson>;
  /**
   * The list of credit contacts per contract id.
   */
  creditContacts: _.NumericDictionary<ContactJson>;
}

export interface Store extends EntityPagingStore<VideoTranslationContractJson>
{
  /**
   * Assigns the given translator to the given contract.
   */
  [Actions.ASSIGN_TRANSLATOR](payload: { contract: VideoTranslationContractJson, translator: TranslatorJson }): Promise<VideoTranslationContractJson>;
  /**
   * Sets the contract to the given state.
   */
  [Actions.SET_STATE](payload: { contract: VideoTranslationContractJson, state: ContractState }): Promise<VideoTranslationContractJson>;

  [Actions.READ_INVOICE_CONTACT](
    payload: EntityId<VideoTranslationContractJson> | { contract: EntityId<VideoTranslationContractJson> }): Promise<ContactJson>;
  [Actions.SAVE_INVOICE_CONTACT](
    payload: { contract: EntityId<VideoTranslationContractJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
  [Actions.READ_CREDIT_CONTACT](
    payload: EntityId<VideoTranslationContractJson> | { contract: EntityId<VideoTranslationContractJson> }): Promise<ContactJson>;
  [Actions.SAVE_CREDIT_CONTACT](
    payload: { contract: EntityId<VideoTranslationContractJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
}

function createItem(): NewEntityProperties<VideoTranslationContractJson>
{
  return {
    businessUnit: null,
    contractDate: new Date(),
    customerLanguage: null,
    translatorLanguage: null,
    invoiceReceiverOption: ReceiverOption.CONTACT_ADDRESS,
    creditReceiverOption: ReceiverOption.CONTACT_ADDRESS,
    customer: null,
    beginDateTime: null,
    plannedTranslationDuration: 1,
    communicationSystem: CommunicationSystem.JITSI,
    contractComment: null,
  };
}

const apiBaseUrl = 'videotranslationcontract';

export default createEntityPagingStore<VideoTranslationContractJson, State, Store>(
  'videotranslation',
  apiBaseUrl,
  createItem,
  {
    invoiceContacts: {},
    creditContacts: {},
  },
  moduleBuilder =>
    {
      const setInvoiceContact = moduleBuilder.commit(
        (state, { contract, contact }: { contract: EntityId<VideoTranslationContractJson>; contact: ContactJson }) =>
        {
          state.invoiceContacts[getId(contract)] = contact;
        }, Mutations.INVOICE_CONTACT);

      const setCreditContact = moduleBuilder.commit(
        (state, { contract, contact }: { contract: EntityId<VideoTranslationContractJson>; contact: ContactJson }) =>
        {
          state.creditContacts[getId(contract)] = contact;
        }, Mutations.CREDIT_CONTACT);

      function generateReadContactAction(action: Actions.READ_INVOICE_CONTACT | Actions.READ_CREDIT_CONTACT, type: 'invoice' | 'credit', mutation: typeof setInvoiceContact)
      {
        return moduleBuilder.dispatch(
          async ({ state }, payload: EntityId<VideoTranslationContractJson> | { contract: EntityId<VideoTranslationContractJson> }) =>
          {
            if (typeof payload === 'number' || !('contract' in payload))
            {
              payload = {
                contract: payload,
              };
            }

            const { contract } = payload;
            const contractId = getId(contract);
            const contacts = state[`${type}Contacts` as 'invoiceContacts' | 'creditContacts'];

            const contact = await api.get<ContactJson>(`${apiBaseUrl}/${contractId}/${type}contact`);
            mutation({ contract, contact });

            return contacts[contractId];
          }, action);
      }

      function generateSaveContactAction(action: Actions.SAVE_INVOICE_CONTACT | Actions.SAVE_CREDIT_CONTACT, type: 'invoice' | 'credit', mutation: typeof setInvoiceContact)
      {
        return moduleBuilder.dispatch(
          async ({ state }, { contract, contact }: { contract: EntityId<VideoTranslationContractJson>; contact: FormData<ContactJson> }) =>
          {
            const contractId = getId(contract);
            const serverResult = await api.put<ContactJson>(`${apiBaseUrl}/${contractId}/${type}contact`, contact);
            const committed = contact.$commit(serverResult);
            mutation({ contract, contact: committed.$raw });
            return committed;
          }, action);
      }

      return {
        [Actions.ASSIGN_TRANSLATOR]: moduleBuilder.dispatch(async ({ state }, { contract, translator }: Parameters<Store[Actions.ASSIGN_TRANSLATOR]>[0]) =>
          {
            const result = await api.put<VideoTranslationContractJson>(`${apiBaseUrl}/${contract.id}/assigntranslator`, translator);

            const committed = commitEntity(contract, result);

            state.items.set(committed);

            return committed;
          }, Actions.ASSIGN_TRANSLATOR),

        [Actions.SET_STATE]: moduleBuilder.dispatch(async ({ state }, { contract, state: contractState }: Parameters<Store[Actions.SET_STATE]>[0]) =>
          {
            const result = await api.put<VideoTranslationContractJson>(`${apiBaseUrl}/${contract.id}/setstate`, contractState);

            const committed = commitEntity(contract, result);

            state.items.set(committed);

            return committed;
          }, Actions.SET_STATE),

        [Actions.READ_INVOICE_CONTACT]: generateReadContactAction(Actions.READ_INVOICE_CONTACT, 'invoice', setInvoiceContact),
        [Actions.SAVE_INVOICE_CONTACT]: generateSaveContactAction(Actions.SAVE_INVOICE_CONTACT, 'invoice', setInvoiceContact),

        [Actions.READ_CREDIT_CONTACT]: generateReadContactAction(Actions.READ_CREDIT_CONTACT, 'credit', setCreditContact),
        [Actions.SAVE_CREDIT_CONTACT]: generateSaveContactAction(Actions.SAVE_CREDIT_CONTACT, 'credit', setCreditContact),
      };
    });
