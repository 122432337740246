
import { Component } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { Form as FormBase, EntityStoreActions, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity, asFormData, FormData } from '@/base';
import { EmailJson } from '@/modules/email';
import { EmailActiveItem } from './EmailActiveItem';
import { EmailTemplateType } from '@/modules/emailtemplate';
import { FileEntityCollectionJson, FileEntityJson } from '@/modules/file';
import { Actions } from '../store';
import { store as EmailStore, Actions as EmailAction } from '@/modules/email';
import { getEnumByName } from '@/util';

const maxFileSize = parseInt(process.env.VUE_APP_UPLOAD_MAX_FILE_SIZE, 10) || 10485760;

export default abstract class EmailBase<T> extends FormBase<EmailActiveItem<T>> {
  private readonly maxFileSizeInKB = maxFileSize / 1024;
  private readonly maxFileSizeInKBPermitted = 7 * 1024;

  /**
   * Returns or sets the currently active item.
   */
  public activeItem: EmailActiveItem<T> | null = null;

  public getActiveItem(): EmailActiveItem<T> | null
  {
    return this.activeItem;
  }

  /**
   * Returns file attachment collection of the active item.
   */
  public get formData(): FormData<FileEntityCollectionJson> | null {
    return this.activeItem ? asFormData(this.activeItem.email.fileAttachmentCollection) : null;
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: EmailActiveItem<T>): boolean {
    return false;
  }

  private createFileEntity(): NewEntity<FileEntityJson> {
    return createEntity<FileEntityJson>({
      uuid: '',
      fileName: '',
    });
  }

  private getDescriptionWithSize(item: FileEntityJson): string {
    if (item && item.fileName) {
      // The Math.round rounds to 2 digits after ','. We Get the size in bytes, so we have to convert it
      return this.$root.$t('module.email.fileform.file') + ': ' + item.fileName + ' (' + this.roundToTwoDigits(item.size) + ' KB)';
    }
    return this.$root.$t('module.email.fileform.uploadRequest') as string;
  }

  private isAttachmentToLarge(): boolean {
    return this.maxFileSizeInKBPermitted < this.getCompleteFileSize();
  }

  private getCompleteFileSize() {
    const activeItem = this.activeItem;
    let aggregated_size = 0;
    if (activeItem) {
      activeItem.email.fileAttachmentCollection.fileEntities.forEach(element => {
        if (element && element.size) {
          aggregated_size += element.size;
        }
      });
    }
    return this.roundToTwoDigits(aggregated_size);
  }

  private roundToTwoDigits(value: number) {
    return Math.round((value / 1024) * 100) / 100;
  }

  private removeFileEntity(fileEntity: FileEntityJson): void {
    if (this.activeItem) {
      EmailStore[EmailAction.DELETE_FILE]({
        email: asFormData(this.activeItem.email),
        fileEntity,
      }).then(item => {
        const activeItem = this.getActiveItem();
        if (activeItem) {
          activeItem.email.fileAttachmentCollection = item;
        }
        this.changeActiveItem(activeItem ? activeItem : null);
      });
    }
  }

  private uploadFileAttachment(file: globalThis.File): void {
    if (file.size < maxFileSize) {
      if (this.activeItem) {
        EmailStore[EmailAction.UPLOAD_FILE]({
          email: asFormData(this.activeItem.email),
          file,
        }).then(item => {
          const activeItem = this.getActiveItem();
          if (activeItem) {
            activeItem.email.fileAttachmentCollection = item;
          }
          this.changeActiveItem(activeItem ? activeItem : null);
        });
      }
    }
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected saveItem(item: EmailActiveItem<T>): Promise<void> {
    if (item) {
      EmailStore[EmailAction.SEND_EMAIL]({
        email: asFormData(item.email),
      })
        .then(itemRes => {
          this.onClose();
          this.$success(this.$root.$t('module.email.send.ok') as string);
        })
        .catch(err => {
          this.$error(this.$root.$t('module.email.send.failed') as string);
        });
    }
    return Promise.resolve();
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: EmailActiveItem<T>): void {
    this.$_.assign(item.email, Object.getPrototypeOf(item.email));
  }

  /**
   * Whether the given item is considered new.
   */
  protected isNew(item: EmailActiveItem<T>): boolean {
    return false;
  }
}
