import Vue from 'vue';

import i18n from './i18n';

import Vuetify, { colors, VCalendar } from 'vuetify/lib';
// import Vuetify from 'vuetify';
// import 'vuetify/dist/vuetify.min.css';

import {
  mdiLogout,
  mdiAccount,
  mdiLock,
  mdiEye,
  mdiEyeOff,
  mdiHome,
  mdiCalendar,
  mdiTranslate,
  mdiReceipt,
  mdiDatabase,
  mdiAccountCircle,
  mdiContactMail,
  mdiBank,
  mdiAccountGroup,
  mdiAccountArrowRight,
  mdiAccountArrowLeftOutline,
  mdiAccountTie,
  mdiAccountKey,
  mdiSettings,
  mdiRefresh,
  mdiPlus,
  mdiPencil,
  mdiDelete,
  mdiDomain,
  mdiCashMultiple,
  mdiMinus,
  mdiAccountGroupOutline,
  mdiFileDocument,
  mdiFileMultiple,
  mdiFilter,
  mdiCheck,
  mdiClose,
  mdiPaperclip,
  mdiFileDocumentBoxOutline,
  mdiFileDocumentBoxMultipleOutline,
  mdiFileDocumentBoxPlusOutline,
  mdiCreditCardMultipleOutline,
  mdiCreditCardOutline,
  mdiCreditCardPlusOutline,
  mdiFileDocumentEditOutline,
  mdiArrowRight,
  mdiOfficeBuilding,
  mdiEmailEdit,
  mdiCodeBraces,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatStrikethrough,
  mdiFormatAlignLeft,
  mdiFormatAlignCenter,
  mdiFormatAlignRight,
  mdiFormatAlignJustify,
  mdiFormatSubscript,
  mdiFormatSuperscript,
  mdiFormatClear,
  mdiFormatListNumbered,
  mdiFormatListBulleted,
  mdiFormatQuoteClose,
  mdiFormatIndentIncrease,
  mdiFormatIndentDecrease,
  mdiFormatColorText,
  mdiFormatColorFill,
  mdiColorHelper,
  mdiLink,
  mdiImageOutline,
  mdiEmailSend,
  mdiArrowSplitHorizontal,
  mdiClockOutline,
  mdiArrowUpThick,
  mdiArrowDownThick,
  mdiDownload,
  mdiCommentEye,
  mdiAlarmCheck,
  mdiEmailSendOutline,
  mdiStar,
  mdiBookOpenOutline,
} from '@mdi/js';

// Vuetify
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      // custom icons
      logout: mdiLogout,
      loginEmail: mdiAccount,
      loginPassword: mdiLock,
      show: mdiEye,
      visibility: mdiEye,
      visibilityOff: mdiEyeOff,
      refresh: mdiRefresh,
      add: mdiPlus,
      edit: mdiPencil,
      delete: mdiDelete,
      remove: mdiMinus,
      datePicker: mdiCalendar,
      timePicker: mdiClockOutline,
      filter: mdiFilter,
      confirm: mdiCheck,
      attachment: mdiPaperclip,
      checkedIcon: mdiCheck,
      download: mdiDownload,
      commentEye: mdiCommentEye,

      // module icons
      calendar: mdiCalendar,
      documenttranslation: mdiFileDocument,
      onsitetranslation: mdiFileDocument,
      videotranslation: mdiFileDocument,
      language: mdiTranslate,
      invoice: mdiFileDocumentBoxMultipleOutline,
      businessunit: mdiOfficeBuilding,
      costcenter: mdiDomain,
      emailtemplate: mdiEmailEdit,
      user: mdiAccountCircle,
      users: mdiAccountGroup,
      translator: mdiAccountArrowRight,
      customer: mdiAccountArrowLeftOutline,
      customergroup: mdiAccountGroupOutline,
      officeworker: mdiAccountTie,
      admin: mdiAccountKey,
      settings: mdiSettings,
      systemconfiguration: mdiSettings,
      report: mdiFileDocumentBoxMultipleOutline,
      credit: mdiCreditCardMultipleOutline,
      externaldocument: mdiFileDocumentEditOutline,
      task: mdiAlarmCheck,
      starmoneyimport: mdiStar,
      vatconfiguration: mdiSettings,

      costconfiguration: mdiCashMultiple,
      contact: mdiContactMail,
      bankaccountcollection: mdiBank,
      bankaccounttransaction: mdiBank,
      cashaccounttransaction: mdiCashMultiple,
      cashbook: mdiBookOpenOutline,

      dashboard: mdiHome,
      contracts: mdiFileMultiple,
      masterData: mdiDatabase,
      accounting: mdiReceipt,
      userContact: mdiContactMail,
      userChangePassword: mdiLock,
      invoiceDetail: mdiFileDocumentBoxOutline,
      customerlanguagegroup: mdiTranslate,
      creditDetail: mdiCreditCardOutline,

      createInvoice: mdiFileDocumentBoxPlusOutline,
      createCredit: mdiCreditCardPlusOutline,
      sendEmail: mdiEmailSend,
      sendEmailOutline: mdiEmailSendOutline,
      contractCancel: mdiClose,
      contractFulfill: mdiCheck,
      navigate: mdiArrowRight,
      emailTemplatePlaceholder: mdiCodeBraces,
      positionDragHandle: mdiArrowSplitHorizontal,
      positionMoveUp: mdiArrowUpThick,
      positionMoveDown: mdiArrowDownThick,
      cancelInvoice: mdiClose,


      // quill editor
      quillBold: mdiFormatBold,
      quillItalic: mdiFormatItalic,
      quillUnderline: mdiFormatUnderline,
      quillStrike: mdiFormatStrikethrough,
      quillAlignDefault: mdiFormatAlignLeft,
      quillAlignCenter: mdiFormatAlignCenter,
      quillAlignRight: mdiFormatAlignRight,
      quillAlignJustify: mdiFormatAlignJustify,
      quillScriptSub: mdiFormatSubscript,
      quillScriptSuper: mdiFormatSuperscript,
      quillListOrdered: mdiFormatListNumbered,
      quillListBullet: mdiFormatListBulleted,
      quillClean: mdiFormatClear,
      quillBlockquote: mdiFormatQuoteClose,
      quillIndentIndent: mdiFormatIndentIncrease,
      quillIndentOutdent: mdiFormatIndentDecrease,
      quillColorColor: mdiFormatColorText,
      quillColorBackground: mdiFormatColorFill,
      quillColorHelper: mdiColorHelper,
      quillLink: mdiLink,
      quillImage: mdiImageOutline,
    },
  },
  i18n,
  lang: {
    t: (key: string, ...params: any[]) => i18n.t(key, params),
  },
  theme: {
    // dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: process.env.VUE_APP_THEME_PRIMARY_COLOR || '#080000',
        secondary: process.env.VUE_APP_THEME_SECONDARY_COLOR || '#FABD2C',
        accent: process.env.VUE_APP_THEME_ACCENT_COLOR || '#F7A934',
        calendar_document_translation_contract_color: process.env.VUE_APP_CALENDAR_DOCUMENT_TRANSLATION_CONTRACT || '#2196F3',
        calendar_on_site_translation_contract_color: process.env.VUE_APP_CALENDAR_ON_SITE_TRANSLATION_CONTRACT || '#FF9800',
        calendar_video_translation_contract_color: process.env.VUE_APP_CALENDAR_VIDEO_TRANSLATION_CONTRACT || '#8BC34A',
        calendar_invoice_color: process.env.VUE_APP_CALENDAR_INVOICE || '#8BC34A',
        calendar_task_color: process.env.VUE_APP_CALENDAR_TASK || '#5fcfd7',
        delete: colors.red.darken4,
      },
    },
  },
});

// override the format time of the calendar
(VCalendar as typeof Vue).mixin({
  created(this: any) {
    this.formatTime = function(this: Vue, withTime: VueCalendarTimestamp, ampm: boolean): string {
      const opts: Intl.DateTimeFormatOptions = {
        ...this.$i18n.getDateTimeFormat(this.$i18n.locale).eventTime,
      };

      if (ampm === false) {
        opts.hour12 = false;
      }

      const date = new Date();
      if (withTime.hasDay) {
        date.setFullYear(withTime.year);
        date.setMonth(withTime.month - 1);
        date.setDate(withTime.day);
      }
      date.setHours(withTime.hour);
      date.setMinutes(withTime.minute);
      date.setSeconds(0);

      const str = new Intl.DateTimeFormat(this.$i18n.locale, opts).format(date);

      return opts.hour12 === false && str.startsWith('0') ? str.substr(1) : str;
    };
  },
});
