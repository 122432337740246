
































































































import { Component } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { extend } from 'vee-validate';
import { EntityStoreActions, Form as FormBase, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity, FormData, asFormData, WritableEntity } from '@/base';
import { getDuplicates } from '@/util';
import { CustomerJson } from '../json';
import store, { Actions } from '../store';
// tslint:disable-next-line:max-line-length
import { store as costConfigurationStore, getCycleTypeDescription as getCostConfigurationCycleTypeDescription, CostConfigurationCycleTypeJson } from '@/modules/costconfiguration';
import {
  CustomerCostConfigurationCycleJson,
  CustomerCostConfigurationJson,
  getCycleDescription as getCostConfigurationCycleDescription,
} from '@/modules/costconfiguration/customer';
import { CustomerLanguageGroupJson, CustomerLanguageGroupsJson } from '@/modules/customer/customerlanguagegroup';
import { UserPermission } from '../../user';

/**
 * The type of the active form item.
 */
interface ActiveItem {
  customer: CustomerJson;
  costConfiguration: FormData<CustomerCostConfigurationJson>;
}

@Component<CostConfiguration>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  methods: {
    getCostConfigurationCycleTypeDescription,
    getCostConfigurationCycleDescription,
  },
})
export default class CostConfiguration extends FormBase<ActiveItem> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.CUSTOMER_WRITE);
  }

  /**
   * Returns the currently active cost configuration item.
   */
  public activeItem: ActiveItem | null = null;

  /**
   * Returns the cost configuration of the active item.
   */
  public get activeCostConfiguration(): FormData<CustomerCostConfigurationJson> | null {
    return this.activeItem ? this.activeItem.costConfiguration : null;
  }

  /**
   * The list of language groups.
   */
  public customerLanguageGroups: CustomerLanguageGroupsJson;

  /**
   * The store of cost configuration cycle types.
   */
  private readonly costConfigurationCycleTypeStore = costConfigurationStore.cycleType;

  private getWarningTitle(item: CustomerCostConfigurationCycleJson): string | null
  {
    if (this.isDuplicate(item))
    {
      return this.$t('costConfiguration.costConfigurationCycles.customerCostConfigurationCycleDuplicates') as string;
    }
    return null;
  }

  private removeEntity(entity: NewEntity<CustomerCostConfigurationCycleJson> | WritableEntity<CustomerCostConfigurationCycleJson>): void
  {
    if (this.activeCostConfiguration)
    {
      this.$_.pull(this.activeCostConfiguration.costConfigurationCycles, entity);
    }
    this.updateDuplicates();
  }

  private isDuplicate(item: CustomerCostConfigurationCycleJson): boolean
  {
    return this.currentDuplicates.filter(id => item.id === id).length > 0;
  }

  private hasDuplicates(): boolean {
    return this.currentDuplicates.length > 0;
  }

  private currentDuplicates: number[] = [];

  private getCurrentDuplicates(): number[] {
    return this.currentDuplicates;
  }

  private getDuplicateKeys(customerGroups: NewEntity<CustomerLanguageGroupJson>[], costConfigurationCycleTypes: NewEntity<CostConfigurationCycleTypeJson>[]): string[] {
    const res: string[] = [];
    customerGroups.forEach(group => costConfigurationCycleTypes.forEach(type => res.push(`${group.id}_${type.id}`)));
    return res;
  }

  private updateDuplicates(): void
  {
    if (this.activeCostConfiguration && this.activeCostConfiguration.costConfigurationCycles)
    {
      const duplicates = getDuplicates(this.activeCostConfiguration.costConfigurationCycles, c =>
        c && c.customerLanguageGroups && c.costConfigurationCycleTypes ? this.getDuplicateKeys(c.customerLanguageGroups, c.costConfigurationCycleTypes) : null,
      );

      this.currentDuplicates = duplicates;
    }
    else {
      this.currentDuplicates = [];
    }
  }

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: ActiveItem | null): void {
    const activeCustomer = store[EntityStoreGetters.ACTIVE_ITEM];
    const activeItem = this.activeItem;

    // set the customer active
    store[EntityStoreMutations.ACTIVE_ITEM](item ? item.customer : null);
    this.activeItem = item;

    if (activeCustomer && (!item || item.customer !== activeCustomer.$raw)) {
      activeCustomer.$revoke();
    }

    if (activeItem && (!item || item.costConfiguration !== activeItem.costConfiguration)) {
      activeItem.costConfiguration.$revoke();
    }
  }

  private createCostConfigurationCycle(): NewEntity<CustomerCostConfigurationCycleJson> {
    return createEntity<CustomerCostConfigurationCycleJson>({
      firstCycleDuration: 0,
      firstCycleCostNet: 0,
      followingCycleDuration: 0,
      followingCycleCostNet: 0,
      costConfigurationCycleTypes: [],
      customerLanguageGroups: [],
    });
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: ActiveItem): boolean {
    return item.costConfiguration.$isDirty();
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void {
    this.$router.push({ name: 'customer' });
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: ActiveItem): Promise<void> {
    const result = await store[Actions.SAVE_COST_CONFIGURATION](item);
    this.changeActiveItem({
      customer: item.customer,
      costConfiguration: asFormData(result, false),
    });
    this.$success(this.$root.$t('form.save.success').toString());
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: ActiveItem): void {
    item.costConfiguration.$reset();
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const proceed = (customer: CustomerJson, item: CustomerCostConfigurationJson | NewEntity<CustomerCostConfigurationJson>): Promise<void> => {
      return this.open(() => ({
        customer,
        costConfiguration: asFormData<CustomerCostConfigurationJson>(item),
      })).then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    };

    const customerId = parseInt(to.params.id, 10);


    Promise.all([store[EntityStoreActions.READ_ONE]({ id: customerId, initial: true }), store[Actions.READ_LANGUAGE_GROUPS]({ customer: customerId })])
      .then(([customer, customerLanguageGroups]) => {
        this.customerLanguageGroups = customerLanguageGroups;
        // read this separately to handle the 404 error
        return store[Actions.READ_COST_CONFIGURATION]({ customer })
          .then(item => proceed(customer, item))
          .catch(error => {
            // if not found, create a new one
            if (error.statusCode === 404) {
              return proceed(
                customer,
                createEntity<CustomerCostConfigurationJson>({
                  fixedRateNet: 0,
                  costConfigurationCycles: [],
                }),
              );
            }
            return Promise.reject(error);
          });
      })
      .catch((err) => next(from.fullPath === '/' && !from.name ? { name: 'customer', replace: true } : { path: from.fullPath, replace: true} ));
  }
}
