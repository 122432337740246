import { EntityPagingStore, EntityPagingStoreState, NewEntityProperties, createEntityPagingStore, commitEntity, EntityId, FormData, getId, asRawData } from '@/base';
import { api } from '@/plugins';
import { DocumentTranslationContractJson } from './json';
import { ReceiverOption, ContractState } from '../json';
import { TranslatorJson } from '@/modules/translator';
import { ContactJson } from '@/modules/contact';
import { EmailTemplateType, EmailTemplateJson } from '@/modules/emailtemplate';
import { EmailJson } from '@/modules/email';
import { FileEntityCollectionJson, FileEntityJson } from '@/modules/file';
import { store as languageStore, Actions as languageActions } from '@/modules/language';

export enum Actions {
  ASSIGN_TRANSLATOR = 'dispatchAssignTranslator',
  SET_STATE = 'dispatchSetState',
  READ_FILE_ATTACHMENT_COLLECTION_ORIGINAL = 'dispatchReadFileAttachmentCollectionOriginal',
  READ_FILE_ATTACHMENT_COLLECTION_TRANSLATED = 'dispatchReadFileAttachmentCollectionTranslated',
  UPLOAD_ORIGINAL_FILE = 'dispatchUploadOriginalFile',
  DELETE_ORIGINAL_FILE = 'dispatchDeleteOriginalFile',
  DOWNLOAD_ORIGINAL_FILE = 'dispatchDownloadOriginalFile',
  UPLOAD_TRANSLATED_FILE = 'dispatchUploadTranslatedFile',
  DELETE_TRANSLATED_FILE = 'dispatchDeleteTranslatedFile',
  DOWNLOAD_TRANSLATED_FILE = 'dispatchDownloadTranslatedFile',
  READ_INVOICE_CONTACT = 'dispatchReadInvoiceContact',
  SAVE_INVOICE_CONTACT = 'dispatchSaveInvoiceContact',
  READ_CREDIT_CONTACT = 'dispatchReadCreditContact',
  SAVE_CREDIT_CONTACT = 'dispatchSaveCreditContact',
  READ_EMAIL = 'dispatchReadEmail',
}

export enum Mutations {
  INVOICE_CONTACT = 'commitInvoiceContact',
  CREDIT_CONTACT = 'commitCreditContact',
  FILE_ATTACHMENT_COLLECTION_ORIGINAL = 'commitFileAttachmentCollectionOriginal',
  FILE_ATTACHMENT_COLLECTION_TRANSLATED = 'commitFileAttachmentCollectionTranslated',
}

export interface State extends EntityPagingStoreState<DocumentTranslationContractJson> {
  /**
   * The list of credit contacts per contract id.
   */
  invoiceContacts: _.NumericDictionary<ContactJson>;
  /**
   * The list of credit contacts per contract id.
   */
  creditContacts: _.NumericDictionary<ContactJson>;

  fileEntityCollectionsOriginal: _.NumericDictionary<FileEntityCollectionJson>;

  fileEntityCollectionsTranslated: _.NumericDictionary<FileEntityCollectionJson>;
}

export interface Store extends EntityPagingStore<DocumentTranslationContractJson> {
  /**
   * Assigns the given translator to the given contract.
   */
  [Actions.ASSIGN_TRANSLATOR](payload: { contract: DocumentTranslationContractJson; translator: TranslatorJson }): Promise<DocumentTranslationContractJson>;
  /**
   * Sets the contract to the given state.
   */
  [Actions.SET_STATE](payload: { contract: DocumentTranslationContractJson; state: ContractState }): Promise<DocumentTranslationContractJson>;

  [Actions.READ_FILE_ATTACHMENT_COLLECTION_ORIGINAL](
    payload:
      | EntityId<DocumentTranslationContractJson>
      | {
          documentTranslationContractJson: EntityId<DocumentTranslationContractJson>;
        },
  ): Promise<FileEntityCollectionJson>;

  [Actions.READ_FILE_ATTACHMENT_COLLECTION_TRANSLATED](
    payload:
      | EntityId<DocumentTranslationContractJson>
      | {
          documentTranslationContractJson: EntityId<DocumentTranslationContractJson>;
        },
  ): Promise<FileEntityCollectionJson>;

  [Actions.UPLOAD_ORIGINAL_FILE](payload: { documentTranslationContractJson: FormData<DocumentTranslationContractJson>; file: File }): Promise<FileEntityCollectionJson>;
  [Actions.UPLOAD_TRANSLATED_FILE](payload: { documentTranslationContractJson: FormData<DocumentTranslationContractJson>; file: File }): Promise<FileEntityCollectionJson>;

  [Actions.DELETE_ORIGINAL_FILE](payload: {
    documentTranslationContractJson: EntityId<DocumentTranslationContractJson>;
    fileEntity: FileEntityJson;
  }): Promise<FileEntityCollectionJson>;
  [Actions.DELETE_TRANSLATED_FILE](payload: {
    documentTranslationContractJson: EntityId<DocumentTranslationContractJson>;
    fileEntity: FileEntityJson;
  }): Promise<FileEntityCollectionJson>;

  [Actions.DOWNLOAD_ORIGINAL_FILE](payload: { documentTranslationContractJson: EntityId<DocumentTranslationContractJson>; fileEntity: FileEntityJson }): Promise<void>;
  [Actions.DOWNLOAD_TRANSLATED_FILE](payload: { documentTranslationContractJson: EntityId<DocumentTranslationContractJson>; fileEntity: FileEntityJson }): Promise<void>;

  [Actions.READ_INVOICE_CONTACT](
    payload:
      | EntityId<DocumentTranslationContractJson>
      | {
          contract: EntityId<DocumentTranslationContractJson>;
        },
  ): Promise<ContactJson>;
  [Actions.SAVE_INVOICE_CONTACT](payload: { contract: EntityId<DocumentTranslationContractJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
  [Actions.READ_CREDIT_CONTACT](
    payload:
      | EntityId<DocumentTranslationContractJson>
      | {
          contract: EntityId<DocumentTranslationContractJson>;
        },
  ): Promise<ContactJson>;
  [Actions.SAVE_CREDIT_CONTACT](payload: { contract: EntityId<DocumentTranslationContractJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
  [Actions.READ_EMAIL](payload: { contract: EntityId<DocumentTranslationContractJson>; emailTemplate: EntityId<EmailTemplateJson>; isTranslator: boolean }): Promise<EmailJson>;
}

function createItem(): NewEntityProperties<DocumentTranslationContractJson> {
  return {
    businessUnit: null,
    completeUntil: null,
    contractDate: new Date(),
    customerLanguage: null,
    translatorLanguage: null,
    invoiceReceiverOption: ReceiverOption.CONTACT_ADDRESS,
    creditReceiverOption: ReceiverOption.CONTACT_ADDRESS,
    linesOfOriginalFile: null,
    linesOfTranslatedFile: null,
    customer: null,
    contractComment: null,
  };
}

const apiBaseUrl = 'documenttranslationcontract';

export default createEntityPagingStore<DocumentTranslationContractJson, State, Store>(
  'documenttranslation',
  apiBaseUrl,
  createItem,
  {
    invoiceContacts: {},
    creditContacts: {},
    fileEntityCollectionsOriginal: {},
    fileEntityCollectionsTranslated: {},
  },
  moduleBuilder => {
    const setFileAttachmentCollectionOriginal = moduleBuilder.commit(
      (
        state,
        {
          documentTranslationContractJson,
          fileEntityCollection,
        }: {
          documentTranslationContractJson: EntityId<DocumentTranslationContractJson>;
          fileEntityCollection: FileEntityCollectionJson;
        },
      ) => {
        state.fileEntityCollectionsOriginal[getId(documentTranslationContractJson)] = fileEntityCollection;
      },
      Mutations.FILE_ATTACHMENT_COLLECTION_ORIGINAL,
    );

    const setFileAttachmentCollectionTranslated = moduleBuilder.commit(
      (
        state,
        {
          documentTranslationContractJson,
          fileEntityCollection,
        }: {
          documentTranslationContractJson: EntityId<DocumentTranslationContractJson>;
          fileEntityCollection: FileEntityCollectionJson;
        },
      ) => {
        state.fileEntityCollectionsTranslated[getId(documentTranslationContractJson)] = fileEntityCollection;
      },
      Mutations.FILE_ATTACHMENT_COLLECTION_TRANSLATED,
    );

    const setInvoiceContact = moduleBuilder.commit((state, { contract, contact }: { contract: EntityId<DocumentTranslationContractJson>; contact: ContactJson }) => {
      state.invoiceContacts[getId(contract)] = contact;
    }, Mutations.INVOICE_CONTACT);

    const setCreditContact = moduleBuilder.commit((state, { contract, contact }: { contract: EntityId<DocumentTranslationContractJson>; contact: ContactJson }) => {
      state.creditContacts[getId(contract)] = contact;
    }, Mutations.CREDIT_CONTACT);

    function generateReadContactAction(action: Actions.READ_INVOICE_CONTACT | Actions.READ_CREDIT_CONTACT, type: 'invoice' | 'credit', mutation: typeof setInvoiceContact) {
      return moduleBuilder.dispatch(
        async (
          { state },
          payload:
            | EntityId<DocumentTranslationContractJson>
            | {
                contract: EntityId<DocumentTranslationContractJson>;
              },
        ) => {
          if (typeof payload === 'number' || !('contract' in payload)) {
            payload = {
              contract: payload,
            };
          }

          const { contract } = payload;
          const contractId = getId(contract);
          const contacts = state[`${type}Contacts` as 'invoiceContacts' | 'creditContacts'];

          const contact = await api.get<ContactJson>(`${apiBaseUrl}/${contractId}/${type}contact`);
          mutation({ contract, contact });

          return contacts[contractId];
        },
        action,
      );
    }

    function generateSaveContactAction(action: Actions.SAVE_INVOICE_CONTACT | Actions.SAVE_CREDIT_CONTACT, type: 'invoice' | 'credit', mutation: typeof setInvoiceContact) {
      return moduleBuilder.dispatch(async ({ state }, { contract, contact }: { contract: EntityId<DocumentTranslationContractJson>; contact: FormData<ContactJson> }) => {
        const contractId = getId(contract);
        const serverResult = await api.put<ContactJson>(`${apiBaseUrl}/${contractId}/${type}contact`, contact);
        const committed = contact.$commit(serverResult);
        mutation({ contract, contact: committed.$raw });
        return committed;
      }, action);
    }

    return {
      [Actions.ASSIGN_TRANSLATOR]: moduleBuilder.dispatch(async ({ state }, { contract, translator }: Parameters<Store[Actions.ASSIGN_TRANSLATOR]>[0]) => {
        const result = await api.put<DocumentTranslationContractJson>(`${apiBaseUrl}/${contract.id}/assigntranslator`, translator);

        const committed = commitEntity(contract, result);

        state.items.set(committed);

        return committed;
      }, Actions.ASSIGN_TRANSLATOR),

      [Actions.SET_STATE]: moduleBuilder.dispatch(async ({ state }, { contract, state: contractState }: Parameters<Store[Actions.SET_STATE]>[0]) => {
        const result = await api.put<DocumentTranslationContractJson>(`${apiBaseUrl}/${contract.id}/setstate`, contractState);

        const committed = commitEntity(contract, result);

        state.items.set(committed);

        return committed;
      }, Actions.SET_STATE),

      [Actions.READ_FILE_ATTACHMENT_COLLECTION_ORIGINAL]: moduleBuilder.dispatch(
        async (
          { state },
          payload:
            | EntityId<DocumentTranslationContractJson>
            | {
                documentTranslationContractJson: EntityId<DocumentTranslationContractJson>;
              },
        ) => {
          if (typeof payload === 'number' || !('documentTranslationContractJson' in payload)) {
            payload = {
              documentTranslationContractJson: payload,
            };
          }
          const { documentTranslationContractJson } = payload;
          const documentTranslationContractJsonId = getId(documentTranslationContractJson);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${documentTranslationContractJsonId}/fileattachmentcollectionoriginal`);
          setFileAttachmentCollectionOriginal({
            documentTranslationContractJson,
            fileEntityCollection,
          });

          return state.fileEntityCollectionsOriginal[documentTranslationContractJsonId];
        },
        Actions.READ_FILE_ATTACHMENT_COLLECTION_ORIGINAL,
      ),
      [Actions.READ_FILE_ATTACHMENT_COLLECTION_TRANSLATED]: moduleBuilder.dispatch(
        async (
          { state },
          payload:
            | EntityId<DocumentTranslationContractJson>
            | {
                documentTranslationContractJson: EntityId<DocumentTranslationContractJson>;
              },
        ) => {
          if (typeof payload === 'number' || !('documentTranslationContractJson' in payload)) {
            payload = {
              documentTranslationContractJson: payload,
            };
          }
          const { documentTranslationContractJson } = payload;
          const documentTranslationContractJsonId = getId(documentTranslationContractJson);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${documentTranslationContractJsonId}/fileattachmentcollectiontranslated`);
          setFileAttachmentCollectionTranslated({
            documentTranslationContractJson,
            fileEntityCollection,
          });

          return state.fileEntityCollectionsTranslated[documentTranslationContractJsonId];
        },
        Actions.READ_FILE_ATTACHMENT_COLLECTION_TRANSLATED,
      ),
      [Actions.UPLOAD_ORIGINAL_FILE]: moduleBuilder.dispatch(async ({ state }, { documentTranslationContractJson, file }: Parameters<Store[Actions.UPLOAD_ORIGINAL_FILE]>[0]) => {
        const result = await api.upload<FileEntityCollectionJson>({
          data: {
            'user-file': file,
          },
          method: 'POST',
          url: `${apiBaseUrl}/${documentTranslationContractJson.id}/fileoriginal`,
        });
        state.fileEntityCollectionsOriginal[getId(documentTranslationContractJson)] = result;
        return result;
      }, Actions.UPLOAD_ORIGINAL_FILE),

      [Actions.DELETE_ORIGINAL_FILE]: moduleBuilder.dispatch(
        async ({ state }, { documentTranslationContractJson, fileEntity }: Parameters<Store[Actions.DELETE_ORIGINAL_FILE]>[0]) => {
          const documentTranslationContractJsonId = getId(documentTranslationContractJson);
          await api.delete(`${apiBaseUrl}/${documentTranslationContractJsonId}/fileoriginal/${fileEntity.id}`);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${documentTranslationContractJsonId}/fileattachmentcollectionoriginal`);
          setFileAttachmentCollectionOriginal({
            documentTranslationContractJson,
            fileEntityCollection,
          });
          return state.fileEntityCollectionsOriginal[documentTranslationContractJsonId];
        },
        Actions.DELETE_ORIGINAL_FILE,
      ),

      [Actions.DELETE_TRANSLATED_FILE]: moduleBuilder.dispatch(
        async ({ state }, { documentTranslationContractJson, fileEntity }: Parameters<Store[Actions.DELETE_TRANSLATED_FILE]>[0]) => {
          const documentTranslationContractJsonId = getId(documentTranslationContractJson);
          await api.delete(`${apiBaseUrl}/${documentTranslationContractJsonId}/filetranslated/${fileEntity.id}`);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${documentTranslationContractJsonId}/fileattachmentcollectiontranslated`);
          setFileAttachmentCollectionTranslated({
            documentTranslationContractJson,
            fileEntityCollection,
          });
          return state.fileEntityCollectionsTranslated[documentTranslationContractJsonId];
        },
        Actions.DELETE_TRANSLATED_FILE,
      ),

      [Actions.UPLOAD_TRANSLATED_FILE]: moduleBuilder.dispatch(async ({ state }, { documentTranslationContractJson, file }: Parameters<Store[Actions.UPLOAD_ORIGINAL_FILE]>[0]) => {
        const result = await api.upload<FileEntityCollectionJson>({
          data: {
            'user-file': file,
          },
          method: 'POST',
          url: `${apiBaseUrl}/${documentTranslationContractJson.id}/filetranslated`,
        });
        state.fileEntityCollectionsTranslated[getId(documentTranslationContractJson)] = result;
        return result;
      }, Actions.UPLOAD_TRANSLATED_FILE),

      [Actions.READ_INVOICE_CONTACT]: generateReadContactAction(Actions.READ_INVOICE_CONTACT, 'invoice', setInvoiceContact),
      [Actions.SAVE_INVOICE_CONTACT]: generateSaveContactAction(Actions.SAVE_INVOICE_CONTACT, 'invoice', setInvoiceContact),

      [Actions.READ_CREDIT_CONTACT]: generateReadContactAction(Actions.READ_CREDIT_CONTACT, 'credit', setCreditContact),
      [Actions.SAVE_CREDIT_CONTACT]: generateSaveContactAction(Actions.SAVE_CREDIT_CONTACT, 'credit', setCreditContact),

      [Actions.READ_EMAIL]: moduleBuilder.dispatch((ctx, { contract, emailTemplate, isTranslator }: Parameters<Store[Actions.READ_EMAIL]>[0]) => {
        const emailPath = isTranslator ? 'email/translator' : 'email/customer';
        return api.get<EmailJson>(`${apiBaseUrl}/${getId(contract)}/${emailPath}/${getId(emailTemplate)}`);
      }, Actions.READ_EMAIL),

      [Actions.DOWNLOAD_ORIGINAL_FILE]: moduleBuilder.dispatch(
        async ({ state }, { documentTranslationContractJson, fileEntity }: Parameters<Store[Actions.DOWNLOAD_ORIGINAL_FILE]>[0]) => {
          const documentTranslationContractJsonId = getId(documentTranslationContractJson);
          return api.download({
            method: 'GET',
            url: `${apiBaseUrl}/${documentTranslationContractJsonId}/fileoriginal/${fileEntity.id}`,
          });
        },
        Actions.DOWNLOAD_ORIGINAL_FILE,
      ),

      [Actions.DOWNLOAD_TRANSLATED_FILE]: moduleBuilder.dispatch(
        async ({ state }, { documentTranslationContractJson, fileEntity }: Parameters<Store[Actions.DOWNLOAD_TRANSLATED_FILE]>[0]) => {
          const documentTranslationContractJsonId = getId(documentTranslationContractJson);
          return api.download({
            method: 'GET',
            url: `${apiBaseUrl}/${documentTranslationContractJsonId}/filetranslated/${fileEntity.id}`,
          });
        },
        Actions.DOWNLOAD_TRANSLATED_FILE,
      ),
    };
  },
);
