import { BaseEntity } from '@/base';
import { BankAccountJson } from '../bankaccount/json';
import { BusinessUnitJson } from '../businessunit';
import { InvoiceJson } from '../financialdocument/invoice';
import { CreditJson } from '../financialdocument/credit';
import { ExternalDocumentJson } from '../financialdocument/externaldocument';

export interface BankAccountTransactionJson extends BaseEntity
{
  btIdentity: number;
  btValue: number;
  readonly btValueAssigned: number;
  amountOfBalance: number;
  btUsage: number;
  transactionDate: Date;
  businessUnit: BusinessUnitJson;
  bankAccount: BankAccountJson;
  bankAccountTransactionInvoicePositions: BankAccountTransactionPositionJson<InvoiceJson>[];
  bankAccountTransactionCreditPositions: BankAccountTransactionPositionJson<CreditJson>[];
  bankAccountTransactionExternalDocumentPositions: BankAccountTransactionPositionJson<ExternalDocumentJson>[];
  bankAccountTransactionAssignmentState: BankAccountTransactionAssignmentState;
}

export interface BankAccountTransactionPositionJson<T extends DocumentValueHolder> extends BaseEntity
{
  assignedPriceNet: number;
  assignedPriceGross: number;
  entity: T | null;
}

export interface DocumentValueHolder extends BaseEntity
{
  businessUnit: BusinessUnitJson;
  documentValueNet: number;
  documentValueGross: number;
}

export enum BankAccountTransactionAssignmentState {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNMENT_INCOMPLETE_AUTOMATIC = 'ASSIGNMENT_INCOMPLETE_AUTOMATIC',
  ASSIGNMENT_INCOMPLETE_MANUAL = 'ASSIGNMENT_INCOMPLETE_MANUAL',
  ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED = 'ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED',
  ASSIGNMENT_COMPLETE_AUTOMATIC = 'ASSIGNMENT_COMPLETE_AUTOMATIC',
  ASSIGNMENT_COMPLETE_MANUAL = 'ASSIGNMENT_COMPLETE_MANUAL',
}
