






















































































































































import { extend } from 'vee-validate';
import { Component } from 'vue-property-decorator';
import { OnSiteTranslationContractJson, TravelOption } from '../json';
import ContractForm from '../../base/ContractForm';
import DateField from '@/components/DateField.vue';
import store from '../store';
import { FormData, NewEntity, asFormData } from '@/base';
import { Route, RawLocation } from 'vue-router';

@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created(): void {
      const me = this;
      extend('validateStartEndExistsForStart', {
        validate(value, args) {
          const valueIsNull = !value;
          const argIsNull = (args as any[])[0] === 'null';
          return (valueIsNull && argIsNull) || (!valueIsNull && !argIsNull);
        },
        message(field, values) {
          return me.$t(`validate.error.${values!._rule_}`, values).toString();
        },
      });
      extend('validateStartEndExistsForEnd', {
        validate(value, args) {
          const valueIsNull = !value;
          const argIsNull = (args as any[])[0] === 'null';
          return (valueIsNull && argIsNull) || (!valueIsNull && !argIsNull);
        },
        message(field, values) {
          return me.$t(`validate.error.${values!._rule_}`, values).toString();
        },
      });
      extend('validateStartBeforeEnd', {
        validate(value, args) {
          const valueIsNull = !value;
          const firstArg = (args as any[])[0];
          const argIsNull = firstArg === 'null';
          return valueIsNull || argIsNull || new Date(value).getTime() < new Date(firstArg).getTime();
        },
        message(field, values) {
          return me.$t(`validate.error.${values!._rule_}`, values).toString();
        },
      });
      extend('validateEndAfterStart', {
        validate(value, args) {
          const valueIsNull = !value;
          const firstArg = (args as any[])[0];
          const argIsNull = firstArg === 'null';
          return valueIsNull || argIsNull || new Date(value).getTime() > new Date(firstArg).getTime();
        },
        message(field, values) {
          return me.$t(`validate.error.${values!._rule_}`, values).toString();
        },
      });
  },
})
export default class Form extends ContractForm<OnSiteTranslationContractJson, typeof store>
{
  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'onsitetranslation',
  };

  public readonly TravelOption = TravelOption;

  protected setBeginDate(item: OnSiteTranslationContractJson | NewEntity<OnSiteTranslationContractJson>, date: Date, dateTime: Date): void
  {
    item.beginDateTime = dateTime;
  }

  protected prefillrealTranslationStart(item: OnSiteTranslationContractJson | NewEntity<OnSiteTranslationContractJson>, dateTime: any): void
  {
    if (!item.realTranslationStart ) {
      item.realTranslationStart = dateTime;
    }
  }

  protected prefilltravelTimeBackStart(item: OnSiteTranslationContractJson | NewEntity<OnSiteTranslationContractJson>, dateTime: any): void
  {
    if (!item.travelTimeBackStart ) {
      item.travelTimeBackStart = dateTime;
    }
  }
}
