import _ from 'lodash';
import { BaseEntity } from '@/base';

/**
 * A cache for entities to use in an EntityStore.
 */
export class EntityCache<T extends BaseEntity>
{
  /**
   * Returns the number of elements in the cache.
   */
  public get size(): number
  {
    return this.__getList().length;
  }

  /**
   * Returns the list of items in the cache.
   */
  public get list(): T[]
  {
    return this.__getList().slice();
  }

  /**
   * Removes all elements from the cache.
   */
  public clear(): void
  {
    this.__dict = {};
    this.__list = null;
  }

  /**
   * Deletes the entity with the given id from the cache.
   */
  public delete(id: number): boolean;
  /**
   * Deletes the given entity from the cache.
   */
  // tslint:disable-next-line:unified-signatures
  public delete(entity: T): boolean;

  public delete(idOrEntity: number | T): boolean
  {
    if (typeof idOrEntity === 'number')
    {
      if (idOrEntity in this.__dict)
      {
        delete this.__dict[idOrEntity];
        this.__list = null;
        return true;
      }
    }
    else
    {
      const id = _.findKey(this.__dict, t => t === idOrEntity);
      if (id != null)
      {
        delete this.__dict[parseInt(id, 10)];
        this.__list = null;
        return true;
      }
    }
    return false;
  }

  /**
   * Calls the given function on every element in the cache.
   */
  public forEach(callbackfn: (entity: T, id: number) => void, thisArg?: any): void
  {
    this.__getList().forEach(t => callbackfn.call(this, t, t.id));
  }

  /**
   * Returns the entity with the given id.
   */
  public get(id: number): T | undefined
  {
    return this.__dict[id];
  }

  /**
   * Checks whether an entity with the given id is present.
   */
  public has(id: number): boolean
  {
    return id in this.__dict;
  }

  /**
   * Adds or updates the given entities.
   */
  public set(...entities: T[]): void
  {
    entities.forEach(t => this.__dict[t.id] = t);
    this.__list = null;
  }

  private __getList(): T[]
  {
    if (!this.__list)
    {
      this.__list = _.values(this.__dict);
    }
    return this.__list;
  }

  private __list: T[] | null = null;
  private __dict: _.NumericDictionary<T> = {};
}
