








import { Component } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { EntityStoreActions, Form as FormBase, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity, FormData, asFormData } from '@/base';
import { CustomerJson } from '../json';
import store, { Actions } from '../store';
import { ContactJson, ContactForm } from '@/modules/contact';
import { UserPermission } from '@/modules/user';

/**
 * The type of the active form item.
 */
interface ActiveItem {
  customer: CustomerJson;
  contact: FormData<ContactJson>;
}

@Component<InvoiceContact>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created(): void {
    // HACK: Dirty is not evaluated if inner values change.
    // this.$watch('activeContact', () => this.$forceUpdate(), { deep: true });
  },
  components: {
    ContactForm,
  },
})
export default class InvoiceContact extends FormBase<ActiveItem> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.CUSTOMER_WRITE);
  }

  /**
   * Returns the currently active item.
   */
  public activeItem: ActiveItem | null = null;

  /**
   * Returns the invoice contact of the active item.
   */
  public get activeContact(): FormData<ContactJson> | null {
    return this.activeItem ? this.activeItem.contact : null;
  }

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: ActiveItem | null): void {
    const activeCustomer = store[EntityStoreGetters.ACTIVE_ITEM];
    const activeItem = this.activeItem;

    // set the customer active
    store[EntityStoreMutations.ACTIVE_ITEM](item ? item.customer : null);
    this.activeItem = item;

    if (activeCustomer && (!item || item.customer !== activeCustomer.$raw)) {
      activeCustomer.$revoke();
    }

    if (activeItem && (!item || item.contact !== activeItem.contact)) {
      activeItem.contact.$revoke();
    }
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: ActiveItem): boolean {
    return item.contact.$isDirty();
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void {
    this.$router.push({ name: 'customer' });
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: ActiveItem): Promise<void> {
    const result = await store[Actions.SAVE_INVOICE_CONTACT](item);
    this.changeActiveItem({
      customer: item.customer,
      contact: asFormData(result, false),
    });
    this.$success(this.$root.$t('form.save.success').toString());
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: ActiveItem): void {
    item.contact.$reset();
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const proceed = (customer: CustomerJson, item: ContactJson | NewEntity<ContactJson>): Promise<void> => {
      return this.open(() => ({
        customer,
        contact: asFormData<ContactJson>(item),
      })).then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    };

    const customerId = parseInt(to.params.id, 10);

    store[EntityStoreActions.READ_ONE]({ id: customerId, initial: true })
      .then(customer => {
        // read this separately to handle the 404 error
        return store[Actions.READ_INVOICE_CONTACT]({ customer })
          .then(item => proceed(customer, item))
          .catch(error => {
            // if not found, create a new one
            if (error.statusCode === 404) {
              return proceed(
                customer,
                createEntity<ContactJson>({
                  company: null,
                  firstName: null,
                  lastName: null,
                  street: null,
                  zipCode: null,
                  city: null,
                  country: null,
                  emails: [],
                  phoneNumbers: [],
                }),
              );
            }
            return Promise.reject(error);
          });
      })
      .catch(() => next(from.fullPath === '/' && !from.name ? { name: 'customer', replace: true } : { path: from.fullPath, replace: true }));
  }
}
