import { BaseEntity } from '@/base';
import { ContactJson } from '@/modules/contact';
import { CustomerJson } from '@/modules/customer';
import { BusinessUnitJson } from '@/modules/businessunit';
import { DocumentValueHolder } from '@/modules/cashaccounttransaction';

export enum InvoiceState
{
  CREATED = 'CREATED',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
}

export enum Vat
{
  ZERO = 'ZERO',
  REDUCED = 'REDUCED',
  FULL = 'FULL',
}

export interface InvoicePositionJson extends BaseEntity
{
  count: number;
  countUnit: string;
  description: string;
  unitPriceNet: number;
  unitPriceNetUnit: string;
  totalPriceNet: number;
  vat: Vat;
}

export interface InvoiceJson extends DocumentValueHolder
{
  readonly invoiceReference: string;
  readonly invoiceDate: Date;
  invoiceState: InvoiceState;
  receiverContact: ContactJson;
  senderContact: ContactJson;
  customer: CustomerJson;
  businessUnit: BusinessUnitJson;
  invoicePositions: InvoicePositionJson[];
  readonly fileNameInvoicePDF: string | null;
  readonly creatorFullNameInvoicePDF: string | null;
  customerAsText: string;
  operationTime: string;
  operationDate: string;
  operationLocation: string;
  language: string;
  reference: string;
  translatorAsText: string;
  notice: string;
  invoiceComment: string;
  explanation: string;
  isPaymentCompleted: boolean;
  readonly timeForPayment: Date;
  readonly documentValueNet: number;
  readonly documentValueGross: number;
  readonly toBeCanceledInvoice: InvoiceJson;
}
