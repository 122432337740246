








































import { Component } from 'vue-property-decorator';
import { EntityList, HeaderConfig, EntityStoreActions, EntityStoreGetters, ListAction } from '@/base';
import store, { Actions } from '../store';
import { ReportJson, ReportState, ReportTimeGroup } from '../json';
import { UserPermission } from '../../user';

const groupKeys: (keyof ReportJson)[] = [
  'isGroupedByLanguage',
  'isGroupedByBusinessUnit',
  'isGroupedByContractType',
  'isGroupedByContractNo',
  'isGroupedByContractState',
  'isGroupedByTranslator',
  'isGroupedByCustomer',
  'isGroupedByMoneyOrigin',
  'isGroupedByAssignmentState',
  'isGroupedByFinancialDocumentType',
  'isGroupedByInvoiceState',
  'isGroupedByInvoicePaymentState',
  'isGroupedByOperationLocation',
];

@Component<List>({
  filters: {},
  methods: {},
  created() {
    this.init();
  },
})
export default class List extends EntityList<ReportJson, typeof store> {

  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.REPORT_WRITE);
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'createDate', desc: true }];

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    reportState: null,
  };

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[] {
    return [
      {
        value: 'reportState',
        text: this.$t('list.header.reportState').toString(),
      },
      {
        value: 'fromDate',
        text: this.$t('list.header.fromDate').toString(),
      },
      {
        value: 'toDate',
        text: this.$t('list.header.toDate').toString(),
      },
      {
        value: 'createDate',
        text: this.$t('list.header.createDate').toString(),
      },
      {
        value: 'groupSummary',
        text: this.$t('list.header.groupSummary').toString(),
        sortable: false,
      },
      {
        value: 'filterSummary',
        text: this.$t('list.header.filterSummary').toString(),
        sortable: false,
      },
      {
        value: 'download',
        text: '',
        sortable: false,
      },
    ];
  }

  protected getDeleteConfirmationMessage(item: ReportJson): { title?: string; message: string } {
    const args = Object.assign({ fullName: item.reportState }, item);
    return {
      title: this.$t('list.delete.title', args).toString(),
      message: this.$t('list.delete.message', args).toString(),
    };
  }

  private getGroupSummary(item: ReportJson): string {
    const res: string[] = [];
    groupKeys.forEach(key => {
      if (item[key]) {
        res.push(this.$t(`list.groupSummary.${key}`).toString());
      }
    });
    return res.join(', ');
  }

  private getFilterSummary(item: ReportJson): string {
    const res: string[] = [];
    if (item.reportMoneyOrigin)
    {
      res.push(this.$t('list.filteredBy.reportMoneyOrigin.label') + ': ' + this.$t('enums.ReportMoneyOrigin.' + item.reportMoneyOrigin) as string);
    }

    if (item.bankAccountTransactionAssignmentState)
    {
      res.push(this.$t('list.filteredBy.bankAccountTransactionAssignmentState.label') + ': ' + this.$root.$t('module.bankaccounttransaction.enums.BankAccountTransactionAssignmentState.' + item.bankAccountTransactionAssignmentState) as string);
    }

    if (item.financialDocumentType)
    {
      res.push(this.$t('list.filteredBy.financialDocumentType.label') + ': ' + this.$t('enums.FinancialDocumentType.' + item.financialDocumentType) as string);
    }

    if (item.businessUnitName)
    {
      res.push(this.$t('list.filteredBy.businessUnit.label') + ': ' + item.businessUnitName);
    }

    if (item.languageName)
    {
      res.push(this.$t('list.filteredBy.language.label') + ': ' + item.languageName);
    }

    if (item.contractType)
    {
      res.push(this.$t('list.filteredBy.contractType.label') + ': ' + this.$t('enums.ContractType.' + item.contractType) as string);
    }

    if (item.contractNo)
    {
      res.push(this.$t('list.filteredBy.contractNo.label') + ': ' + item.contractNo);
    }

    if (item.contractState)
    {
      res.push(this.$t('list.filteredBy.contractState.label') + ': ' + this.$t('enums.ContractState.' + item.contractState) as string);
    }

    if (item.invoiceState)
    {
      res.push(this.$t('list.filteredBy.invoiceState.label') + ': ' + this.$root.$t('module.invoice.enums.InvoiceState.' + item.invoiceState) as string);
    }

    if (item.invoicePaymentState)
    {
      res.push(this.$t('list.filteredBy.invoicePaymentState.label') + ': ' + this.$t('enums.InvoicePaymentState.' + item.invoicePaymentState) as string);
    }

    if (item.customerName)
    {
      res.push(this.$t('list.filteredBy.customer.label') + ': ' + item.customerName);
    }

    if (item.translatorName)
    {
      res.push(this.$t('list.filteredBy.translator.label') + ': ' + item.translatorName);
    }

    if (item.operationLocation)
    {
      res.push(this.$t('list.filteredBy.operationLocation.label') + ': ' + item.operationLocation);
    }

    return res.join(', ');
  }

  private handleDownloadIconClick(item: ReportJson): void {
    if (item && item.reportState === 'CREATION_FINISHED') {
      store[Actions.DOWNLOAD_REPORT](item.id);
    }
  }

  private readonly ReportState = ReportState;

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<ReportJson>[] {
    if (this.hasWritePermission())
    {
      return [this.getActionDelete()];
    }
    return [];
  }
}
