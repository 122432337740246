import { RouteConfig } from 'vue-router';

import List from './views/List.vue';
import Form from './views/Form.vue';
import CostConfiguration from './views/CostConfiguration.vue';
import CreditContact from './views/CreditContact.vue';
import Email from './views/Email.vue';

export default [
  // list
  {
    name: 'translator',
    path: '',
    component: List,
  },
  // edit
  {
    path: ':id(new|\\d+)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // cost configuration
  {
    path: ':id(\\d+)/costconfiguration',
    component: CostConfiguration,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // credit contact
  {
    path: ':id(\\d+)/creditcontact',
    component: CreditContact,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // send e-mail
  {
    path: ':id(\\d+)/email/(translator|customer)/:emailTemplateId(\\d+)',
    component: Email,
    meta: {
      hideMenu: true,
      hideTab: true,
      isEmailRoute: true,
    },
  },
] as RouteConfig[];
