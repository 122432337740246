








import { Component } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { EntityStoreActions, Form as FormBase, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity, FormData, asFormData } from '@/base';
import { DocumentTranslationContractJson } from '../json';
import store, { Actions } from '../store';
import { ContactJson, ContactForm } from '@/modules/contact';
import { UserPermission } from '@/modules/user';

/**
 * The type of the active form item.
 */
interface ActiveItem {
  contract: DocumentTranslationContractJson;
  contact: FormData<ContactJson>;
}

@Component<Contact>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteUpdate(to, from, next): void {
    this.handleBeforeRouteEnter(to, from, next);
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created(): void {
    // HACK: Dirty is not evaluated if inner values change.
    // this.$watch('activeContact', () => this.$forceUpdate(), { deep: true });
  },
  components: {
    ContactForm,
  },
})
export default class Contact extends FormBase<ActiveItem> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.CONTRACT_WRITE);
  }

  /**
   * Returns the currently active item.
   */
  public activeItem: ActiveItem | null = null;

  /**
   * Returns the credit or invoice contact of the active item.
   */
  public get activeContact(): FormData<ContactJson> | null {
    return this.activeItem ? this.activeItem.contact : null;
  }

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: ActiveItem | null): void {
    const activeContract = store[EntityStoreGetters.ACTIVE_ITEM];
    const activeItem = this.activeItem;

    // set the contract active
    store[EntityStoreMutations.ACTIVE_ITEM](item ? item.contract : null);
    this.activeItem = item;

    if (activeContract && (!item || item.contract !== activeContract.$raw)) {
      activeContract.$revoke();
    }

    if (activeItem && (!item || item.contact !== activeItem.contact)) {
      activeItem.contact.$revoke();
    }
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: ActiveItem): boolean {
    return item.contact.$isDirty();
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void {
    this.$router.push({ name: 'documenttranslation' });
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: ActiveItem): Promise<void> {
    const result = await store[this.$route.params.type === 'credit' ? Actions.SAVE_CREDIT_CONTACT : Actions.SAVE_INVOICE_CONTACT](item);
    this.changeActiveItem({
      contract: item.contract,
      contact: asFormData(result, false),
    });
    this.$success(this.$root.$t('form.save.success').toString());
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: ActiveItem): void {
    item.contact.$reset();
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const proceed = (contract: DocumentTranslationContractJson, item: ContactJson | NewEntity<ContactJson>): Promise<void> => {
      return this.open(() => ({
        contract,
        contact: asFormData<ContactJson>(item),
      })).then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    };

    const contractId = parseInt(to.params.id, 10);

    store[EntityStoreActions.READ_ONE]({ id: contractId, initial: true })
      .then(contract => {
        let actions = Actions.READ_INVOICE_CONTACT;
        if (to.params.type === 'credit') {
          actions = Actions.READ_CREDIT_CONTACT;
        }

        // read this separately to handle the 404 error
        return store[actions]({ contract })
          .then(item => proceed(contract, item))
          .catch(error => {
            // if not found, create a new one
            if (error.statusCode === 404) {
              return proceed(
                contract,
                createEntity<ContactJson>({
                  company: null,
                  firstName: null,
                  lastName: null,
                  street: null,
                  zipCode: null,
                  city: null,
                  country: null,
                  emails: [],
                  phoneNumbers: [],
                }),
              );
            }
            return Promise.reject(error);
          });
      })
      .catch(() => next(from.fullPath === '/' && !from.name ? { name: 'documenttranslation', replace: true } : { path: from.fullPath, replace: true }));
  }
}
