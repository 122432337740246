import { compareFn, getObjectValueByPath } from 'vuetify/src/util/helpers';

/**
 * Better sort function for v-data-table custom-sort.
 */
export function sortItems<T = any>(items: T[], sortBy: string[], sortDesc: boolean[], locale: string, customSorters?: Record<string, compareFn>): T[]
{
  if (!sortBy || !sortBy.length)
  {
    return items;
  }

  return items.sort((a, b) =>
    {
      let res = 0;
      for (let i = 0; i < sortBy.length && res === 0; i++)
      {
        const sortKey = sortBy[i];
        const desc = sortDesc[i];

        if (customSorters && customSorters[sortKey])
        {
          res = customSorters[sortKey](desc ? b : a, desc ? a : b);
          continue;
        }

        let sortA = getObjectValueByPath(a, sortKey);
        let sortB = getObjectValueByPath(b, sortKey);

        if (desc)
        {
          [sortA, sortB] = [sortB, sortA];
        }

        res = compareValue(sortA, sortB, locale);
      }

      return res;
    });
}

/**
 * Default compare function.
 */
export function compareValue<T = any>(a: T, b: T, locale: string): number
{
  // check null values
  if (a == null)
  {
    if (b == null)
    {
      return 0;
    }
    return 1;
  }
  else if (b == null)
  {
    return -1;
  }
  else if (a instanceof Date && b instanceof Date)
  {
    return a.getTime() - b.getTime();
  }
  else if (typeof a === 'number' && typeof b === 'number')
  {
    return a - b;
  }
  else if (typeof a === 'boolean' && typeof b === 'boolean')
  {
    return +a - +b;
  }
  else
  {
    return String(a).localeCompare(String(b), locale, {
      ignorePunctuation: true,
      sensitivity: 'base',
      usage: 'sort',
    });
  }
}
