import _ from 'lodash';
import { SharedState } from '@/store';
import { getStoreBuilder, ModuleBuilder } from 'vuex-typex';
import api from '@/plugins/api';
import { UserPasswordChangeJson, UserJson } from './json';
import { ContactJson } from '../contact';
import { FormData, EntityStoreState, EntityStore, createEntityStore, NewEntityProperties } from '@/base';
import { ActionContext } from 'vuex';

// tslint:disable-next-line:no-empty-interface
export interface State
{
}

export interface Store
{
  /**
   * The current user store.
   */
  currentUser: CurrentUserStore;
}

export enum CurrentUserGetters
{
  CURRENT_USER = 'getCurrentUser',
}

export enum CurrentUserMutations
{
  CURRENT_USER = 'commitCurrentUser',
  CONTACT = 'commitContact',
}

export enum CurrentUserActions
{
  GET = 'dispatchGet',
  SAVE = 'dispatchSave',
  CHANGE_PASSWORD = 'dispatchChangePassword',
  READ_CONTACT = 'dispatchReadContact',
  SAVE_CONTACT = 'dispatchSaveContact',
}

export interface CurrentUserState
{
  /**
   * The current user JSON if loaded. Falls back to the user login data.
   */
  currentUser: UserJson | null;
  /**
   * The invoice or credit contact of the current user if the user type is TRANSLATOR or CUSTOMER and if loaded.
   */
  contact: ContactJson | null;
}

export interface CurrentUserStore
{
  /**
   * Returns the data of the current user.
   */
  [CurrentUserActions.GET](initial?: boolean): Promise<UserJson>;
  /**
   * Saves the basic data of the current user.
   */
  [CurrentUserActions.SAVE](payload: FormData<UserJson>): Promise<UserJson>;
  /**
   * Changes the password of the current user.
   */
  [CurrentUserActions.CHANGE_PASSWORD](payload: UserPasswordChangeJson): Promise<UserJson>;
  /**
   * Reads the invoice or credit contact of the current user if the user type os TRANSLATOR or CUSTOMER.
   */
  [CurrentUserActions.READ_CONTACT](initial?: boolean): Promise<ContactJson>;
  /**
   * Saves the invoice or credit contact of the current user if the user type os TRANSLATOR or CUSTOMER.
   */
  [CurrentUserActions.SAVE_CONTACT](payload: FormData<ContactJson>): Promise<ContactJson>;
}

/**
 * Injects a mutation that is used to reset loaded users if the current user changes.
 */
export function injectResetMutation<T extends UserJson, S extends EntityStoreState<T>>(mb: ModuleBuilder<S, any>): void
{
  // use the old email address as finder
  mb.commit((state, email: string) =>
    {
      state.allLoaded = false; // need to reload the list
      // remove cached item
      state.items.list.filter(t => t.email === email)
        .forEach(t => state.items.delete(t.id));
    }, 'resetUser');

  // TODO: other way round?
}

// main module
const moduleBuilder = getStoreBuilder<SharedState>().module<State>('user', {
});

// current user module
function createCurrentUserStore(): CurrentUserStore
{
  const builder = moduleBuilder.module<CurrentUserState>('currentuser', {
    currentUser: null,
    contact: null,
  });

  // const getCurrentUser = builder.read((state, unused, rootState) => state.currentUser || rootState.currentUserLogin, CurrentUserGetters.CURRENT_USER);
  const commitCurrentUser = builder.commit((state, user: UserJson | null) => state.currentUser = user, CurrentUserMutations.CURRENT_USER);

  const commitContact = builder.commit((state, contact: ContactJson) => state.contact = contact, CurrentUserMutations.CONTACT);

  return {
      [CurrentUserActions.GET]: builder.dispatch(async ({ state }, initial?: boolean) =>
      {
        if (!initial || !state.currentUser)
        {
          const result = await api.get<UserJson>('currentuser');
          commitCurrentUser(result);
        }

        return state.currentUser!;
      }, CurrentUserActions.GET),

    [CurrentUserActions.SAVE]: builder.dispatch(async (ctx, payload: FormData<UserJson>) =>
      {
        const fullCtx = ctx as ActionContext<CurrentUserState, SharedState>;
        const email = payload.$original.email;

        const result = await api.put<UserJson>('currentuser', payload);
        const committed = payload.$commit(result);
        commitCurrentUser(committed.$raw);

        // Inform user entity stores (can this be done better?)
        fullCtx.commit(`${_.camelCase(result.userType).toLowerCase()}/resetUser`, email, { root: true });

        return committed;
      }, CurrentUserActions.SAVE),

    [CurrentUserActions.CHANGE_PASSWORD]: builder.dispatch(async (ctx, payload: UserPasswordChangeJson) =>
      {
        const result = await api.post<UserJson>('currentuser/changepassword', payload);
        commitCurrentUser(result);
        return result;
      }, CurrentUserActions.CHANGE_PASSWORD),

    [CurrentUserActions.READ_CONTACT]: builder.dispatch(async ({ state }, initial?: boolean) =>
      {
        if (!initial || !state.contact)
        {
          const result = await api.get<ContactJson>('currentuser/contact');
          commitContact(result);
        }
        return state.contact!;
      }, CurrentUserActions.READ_CONTACT),

    [CurrentUserActions.SAVE_CONTACT]: builder.dispatch(async (ctx, payload: FormData<ContactJson>) =>
      {
        const result = await api.put<ContactJson>('currentuser/contact', payload);
        const committed = payload.$commit(result);
        commitContact(committed.$raw);
        return committed;
      }, CurrentUserActions.SAVE_CONTACT),
  };
}


export const store: Store = {
  currentUser: createCurrentUserStore(),
};

getStoreBuilder<SharedState>().registerModule('user');

export default store;

/*
* This has unknown behavior. When its not commented, it doesn'lt load the correct activeItem over at /user
* as its only implemented as an accident yet and not used, i've outcommented it.
* If we don't use it till final release we should throw this away.
* Setting TODO READ above
* commit where this gets added is r747
*/

// export interface StoreUser extends EntityStore<UserJson>{
// }
// export interface StateUser extends EntityStoreState<UserJson>{
// }
// function createItem(): NewEntityProperties<UserJson>
// {
//   return {
//   firstName: '',
//   lastName: '',
//   email: '',
//   userType: null,
//   isLocked: false,
//   };
// }
// const apiBaseUrl = 'user';
// export const userStore = createEntityStore<UserJson, StateUser, StoreUser>(
//   'user',
//   apiBaseUrl,
//   createItem,
//   // tslint:disable-next-line:no-empty
//   () => {});
