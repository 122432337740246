var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"list",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading ? 'secondary' : false,"loading-text":_vm.$root.$t('list.loading'),"custom-sort":_vm.sortItems,"must-sort":_vm.internalOptions.mustSort,"options":_vm.internalOptions,"hide-default-footer":!_vm.showFooter,"server-items-length":_vm.paginationInfo.hasPagination ? _vm.paginationInfo.totalItems : -1,"footer-props":{ disablePagination: !_vm.paginationInfo.hasPagination, showFirstLastPage: true, itemsPerPageOptions: [10, 20, 50, 100]},"show-select":_vm.selectionMode != 'NONE',"single-select":_vm.selectionMode == 'SINGLE'},on:{"update:options":function($event){_vm.internalOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.actions),function(action,index){return (!action.isVisible || action.isVisible(item))?_c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-sm-1 mr-1 pa-0",staticStyle:{"min-width":"0"},attrs:{"color":"primary","ripple":false,"small":"","text":true,"fab":false,"color":action.color === undefined ? 'primary' : action.color,"disabled":action.isDisabled && action.isDisabled(item)},on:{"click":function($event){return action.handler(item)}}},action.text ? on : undefined),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons."+_vm._s(action.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.text))])]):_vm._e()})}},(!_vm.isFormDisabled)?{key:"item.draggable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.xsOnly)?[_c('v-btn',{attrs:{"fab":"","x-small":"","disabled":_vm.items.indexOf(item) <= 0},on:{"click":function($event){$event.stopPropagation();return _vm.moveItemUp(item)}}},[_c('v-icon',[_vm._v("$vuetify.icons.positionMoveUp")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","disabled":_vm.items.indexOf(item) >= _vm.items.length - 1},on:{"click":function($event){$event.stopPropagation();return _vm.moveItemDown(item)}}},[_c('v-icon',[_vm._v("$vuetify.icons.positionMoveDown")])],1)]:_c('v-icon',{staticClass:"drag-handle",attrs:{"disabled":_vm.items.length < 2}},[_vm._v("$vuetify.icons.positionDragHandle")])]}}:null,{key:"item.operationDescription",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.getLabel('edit.operationDescription.label'),"single-line":"","disabled":_vm.isFormDisabled},model:{value:(item.operationDescription),callback:function ($$v) {_vm.$set(item, "operationDescription", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.operationDescription"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(value))])]}},{key:"item.operationDate",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('field',{attrs:{"field-type":"date","label":_vm.getLabel('edit.operationDate.label'),"disabled":_vm.isFormDisabled},model:{value:(item.operationDate),callback:function ($$v) {_vm.$set(item, "operationDate", $$v)},expression:"item.operationDate"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(_vm.$d(value, 'short')))])]}},{key:"item.operationReference",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.getLabel('edit.operationReference.label'),"single-line":"","disabled":_vm.isFormDisabled},model:{value:(item.operationReference),callback:function ($$v) {_vm.$set(item, "operationReference", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.operationReference"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(value))])]}},{key:"item.count",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('decimal-field',{attrs:{"label":_vm.getLabel('edit.count.label'),"single-line":"","nullable":false,"disabled":_vm.isFormDisabled,"allowNegative":true},on:{"change":function($event){return _vm.countOrUnitPriceChanged(item)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", _vm._n($$v))},expression:"item.count"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(_vm.$n(value, 'decimal')))])]}},{key:"item.countUnit",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.getLabel('edit.countUnit.label'),"single-line":"","disabled":_vm.isFormDisabled},model:{value:(item.countUnit),callback:function ($$v) {_vm.$set(item, "countUnit", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.countUnit"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(value))])]}},{key:"item.unitPriceNet",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('currency-field',{attrs:{"label":_vm.getLabel('edit.unitPriceNet.label'),"single-line":"","nullable":false,"disabled":_vm.isFormDisabled,"allowNegative":true},on:{"change":function($event){return _vm.countOrUnitPriceChanged(item)}},model:{value:(item.unitPriceNet),callback:function ($$v) {_vm.$set(item, "unitPriceNet", _vm._n($$v))},expression:"item.unitPriceNet"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(_vm.$n(value, 'currency')))])]}},{key:"item.unitPriceNetUnit",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.getLabel('edit.unitPriceNetUnit.label'),"single-line":"","disabled":_vm.isFormDisabled},model:{value:(item.unitPriceNetUnit),callback:function ($$v) {_vm.$set(item, "unitPriceNetUnit", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.unitPriceNetUnit"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(value))])]}},{key:"item.totalPriceNet",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('currency-field',{attrs:{"label":_vm.getLabel('edit.totalPriceNet.label'),"single-line":"","nullable":false,"disabled":_vm.isFormDisabled,"allowNegative":true},on:{"change":function($event){return _vm.totalPriceChanged(item)}},model:{value:(item.totalPriceNet),callback:function ($$v) {_vm.$set(item, "totalPriceNet", _vm._n($$v))},expression:"item.totalPriceNet"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(_vm.$n(value, 'currency')))])]}},{key:"item.vat",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('field',{attrs:{"field-type":"enum","enum-type":_vm.Vat,"item-text":function (v) { return _vm.$root.$t(("module.invoice.enums.Vat." + v)); },"label":_vm.getLabel('edit.vat.label'),"disabled":_vm.isFormDisabled},model:{value:(item.vat),callback:function ($$v) {_vm.$set(item, "vat", $$v)},expression:"item.vat"}})]},proxy:true}],null,true)},[_vm._v(_vm._s(_vm.$root.$t(("module.invoice.enums.Vat." + value))))])]}},{key:"item.vatRate",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(_vm.showVatRate())?_c('label',[_vm._v(_vm._s(_vm.$n(value, 'decimal')))]):_vm._e()]}},(!_vm.isFormDisabled)?{key:"body.append",fn:function(){return [_c('tr',{staticClass:"add-row"},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('td',{attrs:{"colspan":"2"}}):_vm._e(),_c('td',{staticClass:"pt-2 pt-sm-0",class:{ 'v-data-table__mobile-row': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-edit-dialog',{on:{"save":function($event){return _vm.addItemIf()},"cancel":function($event){_vm.newDescription = ''},"close":function($event){return _vm.addItemIf()},"open":function($event){_vm.newDescription = ''}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.getLabel('edit.operationReference.label'),"single-line":""},model:{value:(_vm.newDescription),callback:function ($$v) {_vm.newDescription=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"newDescription"}})]},proxy:true}],null,false,3734807809)},[_c('v-tooltip',{attrs:{"bottom":"","internal-activator":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-sm-1 mr-1 pa-0",staticStyle:{"min-width":"0"},attrs:{"color":"primary","ripple":false,"small":"","text":true,"fab":false}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.add")]),_vm._v(_vm._s(_vm.getLabel("edit.addCreditPosition.label")))],1)]}}],null,false,4176384741)},[_c('span',[_vm._v(_vm._s(_vm.getLabel("edit.addCreditPosition.label")))])])],1)],1),(!_vm.$vuetify.breakpoint.xsOnly)?_c('td',{attrs:{"colspan":"3"}}):_vm._e()])]},proxy:true}:null],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})}
var staticRenderFns = []

export { render, staticRenderFns }