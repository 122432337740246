









































import { Component, Prop } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { WritableEntity, EntityStoreActions, Form as FormBase, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity, FormData, asFormData } from '@/base';
import { ExternalDocumentJson } from '../json';
import store, { Actions } from '../store';
import { FileEntityCollectionJson, FileEntityJson } from '@/modules/file';
import ExternalDocument from '../ExternalDocument.vue';
import { UserPermission } from '@/modules/user';

const maxFileSize = parseInt(process.env.VUE_APP_UPLOAD_MAX_FILE_SIZE, 10) || 10485760;

/**
 * The type of the active form item.
 */
interface ActiveItem
{
  externalDocumentJson: ExternalDocumentJson;
  fileAttachmentCollection: FormData<FileEntityCollectionJson>;
}

@Component<FileAttachmentCollection>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created(): void
  {
    // HACK: Dirty is not evaluated if inner values change.
    // this.$watch('activeContact', () => this.$forceUpdate(), { deep: true });
  },
})
export default class FileAttachmentCollection extends FormBase<ActiveItem>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_WRITE);
  }

  private readonly maxFileSizeInKB = maxFileSize / 1024;

  /**
   * Returns file attachment collection of the active item.
   */
  public get formData(): FormData<FileEntityCollectionJson> | null
  {
    return this.activeItem ? this.activeItem.fileAttachmentCollection : null;
  }

  private createFileEntity(): NewEntity<FileEntityJson>
  {
    return createEntity<FileEntityJson>({
      uuid: '',
      fileName: '',
    });
  }

  private removeFileEntity(fileEntity: FileEntityJson): void
  {
    this.deleteFileAttachment(fileEntity);
  }

  private downloadFileEntity(fileEntity: FileEntityJson): void
  {
    this.downloadFileAttachment(fileEntity);
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: ActiveItem): Promise<void>
  {
      return Promise.resolve();
  }

  private getDescription(item: FileEntityJson): string
  {
    if (item && item.fileName) {
      return this.$t('fileform.file') + ': ' + item.fileName;
    }
    return this.$t('fileform.uploadRequest') as string;
  }

  /**
   * Returns the cost configuration of the active item.
   */
  public get activeFileAttachmentCollection(): FormData<FileEntityCollectionJson> | null
  {
    return this.activeItem ? this.activeItem.fileAttachmentCollection : null;
  }

  private uploadFileAttachment(file: globalThis.File): void
  {
    if (file.size < maxFileSize){
      if (this.activeItem)  {
        store[Actions.UPLOAD_FILE]({
          externalDocumentJson : asFormData(this.activeItem.externalDocumentJson),
          file,
        }).then(item => this.changeActiveItem(
          this.activeItem ? {
            externalDocumentJson: this.activeItem.externalDocumentJson,
            fileAttachmentCollection: asFormData(item),
            } : null));
      }
    }
  }

  private deleteFileAttachment(fileEntity: FileEntityJson): void
  {
      if (this.activeItem)  {
        store[Actions.DELETE_FILE]({
          externalDocumentJson : asFormData(this.activeItem.externalDocumentJson),
          fileEntity,
        }).then(item => this.changeActiveItem(
          this.activeItem ? {
            externalDocumentJson: this.activeItem.externalDocumentJson,
            fileAttachmentCollection: asFormData(item),
            } : null));
      }
  }
  private downloadFileAttachment(fileEntity: FileEntityJson): void
  {
    if (this.activeItem) {
      store[Actions.DOWNLOAD_FILE]({
          externalDocumentJson : asFormData(this.activeItem.externalDocumentJson),
          fileEntity,
        });
    }
  }

  /**
   * Returns the currently active item.
   */
  public activeItem: ActiveItem | null = null;

  /**
   * Returns the bank account collection of the active item.
   */
  public get activeContact(): FormData<FileEntityCollectionJson> | null
  {
    return this.activeItem ? this.activeItem.fileAttachmentCollection : null;
  }

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: ActiveItem | null): void
  {
    const activeBusinessUnit = store[EntityStoreGetters.ACTIVE_ITEM];
    const activeItem = this.activeItem;

    // set the ExternalDocument template
    store[EntityStoreMutations.ACTIVE_ITEM](item ? item.externalDocumentJson : null);
    this.activeItem = item;

    if (activeBusinessUnit && (!item || item.externalDocumentJson !== activeBusinessUnit.$raw))
    {
      activeBusinessUnit.$revoke();
    }

    if (activeItem && (!item || item.fileAttachmentCollection !== activeItem.fileAttachmentCollection))
    {
      activeItem.fileAttachmentCollection.$revoke();
    }
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: ActiveItem): boolean
  {
    if (item)
    {
    return item.fileAttachmentCollection.$isDirty();
    }
    return false;
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void
  {
    this.$router.push({ name: 'externaldocument' });
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: ActiveItem): void
  {
    item.fileAttachmentCollection.$reset();
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void)
  {
    const proceed = (externalDocumentJson: ExternalDocumentJson, item: FileEntityCollectionJson | NewEntity<FileEntityCollectionJson>): Promise<void> =>
    {
      return this.open(() => ({
        externalDocumentJson,
        fileAttachmentCollection: asFormData<FileEntityCollectionJson>(item),
      }))
        .then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    };

    const externalDocumentJsonId = parseInt(to.params.id, 10);

    store[EntityStoreActions.READ_ONE]({ id: externalDocumentJsonId, initial: true })
      .then(externalDocumentJson =>
        {
          // read this separately to handle the 404 error
          return store[Actions.READ_FILE_ATTACHMENT_COLLECTION]({ externalDocumentJson })
            .then(item => proceed(externalDocumentJson, item))
            .catch(error =>
            {
              // if not found, create a new one
              if (error.statusCode === 404)
              {
                return proceed(externalDocumentJson, createEntity<FileEntityCollectionJson>({
                    fileEntities: [],
                }));
              }
              return Promise.reject(error);
            });
        })
        .catch(() => next(from.fullPath === '/' && !from.name ? { name: 'externalDocumentJson', replace: true } : { path: from.fullPath, replace: true }));
  }
}
