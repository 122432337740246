import { RouteConfig } from 'vue-router';

import DownloadCashbook from './views/DownloadCashbookForm.vue';


export default [
  {
    name: 'cashbook',
    path: '',
    component: DownloadCashbook,
  },
] as RouteConfig[];
