





















import { Vue, Prop, Component, Watch, Emit } from 'vue-property-decorator';
import { ValidationResult, ProviderInstance } from 'vee-validate/dist/types/types';
import { VTextField, VSelect, VCheckbox, VAutocomplete, VFileInput } from 'vuetify/lib';
import DateField from './DateField.vue';
import DateTimeField from './DateTimeField.vue';
import EnumSelect from './EnumSelect.vue';
import CurrencyField from './CurrencyField.vue';
import DecimalField from './DecimalField.vue';
import EntitySelect from './EntitySelect.vue';
import QuillField from './QuillField';

/**
 * Specify the list of available fields here.
 * Every corresponding field must be added to components to make it available.
 */
export type FieldType = 'text' | 'select' | 'date' | 'datetime' | 'enum-select' | 'currency' | 'decimal' | 'checkbox' | 'autocomplete' | 'entityselect' | 'file-input' | 'quill';

/**
 * This is just a wrapper component for convenient markup for fields with validation.
 *
 * Specify the tag of the field. All attributes and listeners except "rules" will be passed through.
 */
@Component({
  inheritAttrs: false,
  components: {
    'field-text': VTextField,
    'field-select': VSelect,
    'field-date': DateField,
    'field-datetime': DateTimeField,
    'field-enum': EnumSelect,
    'field-currency': CurrencyField,
    'field-decimal': DecimalField,
    'field-checkbox': VCheckbox,
    'field-autocomplete': VAutocomplete,
    'field-entityselect': EntitySelect,
    'field-file-input': VFileInput,
    'field-quill': QuillField,
  },
})
export default class Field extends Vue
{
  public readonly $refs!: {
    validator: ProviderInstance;
  };

  // Pass model through
  @Prop()
  public readonly value: any;
  private model = this.value == null ? null : this.value;
  @Watch('model')
  @Emit('input')
  private onModelChange(): void
  {
    // just emit the event
  }
  @Watch('value')
  private onValueChange(value: any): void
  {
    this.model = value;
  }

  /**
   * The actual field type to use.
   */
  @Prop({ default: 'text' })
  public readonly fieldType: FieldType;

  /**
   * The validation rules for the field.
   */
  @Prop({ default: null })
  public readonly rules: string | Record<string, any> | null;

  /**
   * The label of the field. Will be passed through and is used here
   * to pass it to validation messages.
   */
  @Prop()
  public readonly label: string;

  /**
   * The alias to use for the validation field name.
   */
  @Prop()
  public readonly alias: string;

  /**
   * An id for the validation provider for cross field validations.
   */
  @Prop()
  public readonly vid: string;

  /**
   * Validates the field.
   */
  public validate(silent?: boolean): Promise<ValidationResult>
  {
    if (silent)
    {
      return this.$refs.validator.validateSilent();
    }
    return this.$refs.validator.validate();
  }

  /**
   * Validates the field and returns whether it is valid.
   */
  public async isValid(silent?: boolean): Promise<boolean>
  {
    return (await this.validate(silent)).valid;
  }

  /**
   * Resets the validation state.
   */
  public resetValidation(): void
  {
    this.$refs.validator.reset();
  }
}

