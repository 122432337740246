












import { Component } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { EntityStoreActions, Form as FormBase, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity, FormData, asFormData } from '@/base';
import store, { CurrentUserActions } from '../store';
import { ContactJson, ContactForm } from '@/modules/contact';

@Component<Contact>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created(): void
  {
    // HACK: Dirty is not evaluated if inner values change.
    // this.$watch('activeContact', () => this.$forceUpdate(), { deep: true });
  },
  components: {
    ContactForm,
  },
})
export default class Contact extends FormBase<FormData<ContactJson>>
{
  /**
   * Returns the currently active item.
   */
  public activeItem: FormData<ContactJson> | null = null;

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: FormData<ContactJson> | null): void
  {
    const activeItem = this.activeItem;
    this.activeItem = item;

    if (activeItem && (!item || item !== activeItem))
    {
      activeItem.$revoke();
    }
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: FormData<ContactJson>): boolean
  {
    return item.$isDirty();
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: FormData<ContactJson>): Promise<void>
  {
    const result = await store.currentUser[CurrentUserActions.SAVE_CONTACT](item);
    this.changeActiveItem(asFormData(result, false));
    this.$success(this.$root.$t('form.save.success').toString());
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: FormData<ContactJson>): void
  {
    item.$reset();
  }

  /**
   * Handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void)
  {
    store.currentUser[CurrentUserActions.READ_CONTACT](true)
      .then(contact => this.open(() => asFormData(contact)))
      .then(
        handled => next(handled ? undefined : { path: from.fullPath, replace: true }),
        () => next(from.fullPath === '/' && !from.name ? { name: 'user', replace: true } : { path: from.fullPath, replace: true }));
  }
}
