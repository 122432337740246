









































import { Component } from 'vue-property-decorator';
import { VideoTranslationContractJson, CommunicationSystem } from '../json';
import ContractForm from '../../base/ContractForm';
import DateField from '@/components/DateField.vue';
import store from '../store';
import { FormData, NewEntity } from '@/base';

@Component<Form>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends ContractForm<VideoTranslationContractJson, typeof store>
{
  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'videotranslation',
  };

  public readonly CommunicationSystem = CommunicationSystem;

  protected setBeginDate(item: VideoTranslationContractJson | NewEntity<VideoTranslationContractJson>, date: Date, dateTime: Date): void
  {
    item.beginDateTime = dateTime;
  }
}
