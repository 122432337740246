import { getStoreBuilder } from 'vuex-typex';
import { EntityStore, EntityStoreState, NewEntityProperties, createEntityStore } from '@/base';
import { SharedState } from '@/store';
import { CostConfigurationCycleTypeJson } from './json';

// tslint:disable-next-line:no-empty-interface
export interface State
{
}

export interface Store
{
  cycleType: CycleTypeStore;
}

export interface CycleTypeState extends EntityStoreState<CostConfigurationCycleTypeJson>
{
}

export interface CycleTypeStore extends EntityStore<CostConfigurationCycleTypeJson>
{
}

function createCycleTypeItem(): NewEntityProperties<CostConfigurationCycleTypeJson>
{
  return {
    costType: '',
    costUnit: '',
  };
}

// main module
const moduleBuilder = getStoreBuilder<SharedState>().module<State>('costconfiguration', {});

// main store
const store: Store = {
  cycleType: createEntityStore<CostConfigurationCycleTypeJson, CycleTypeState, CycleTypeStore>(
    'cycletype',
    'costconfigurationcycletype',
    createCycleTypeItem,
    {},
    undefined,
    moduleBuilder),
};

getStoreBuilder<SharedState>().registerModule('costconfiguration');

export default store;
