import { RouteConfig } from 'vue-router';

import List from './views/List.vue';
import Form from './views/Form.vue';
import Email from './views/Email.vue';

import { store, Actions } from './store';

export default [
  // list
  {
    name: 'credit',
    path: '',
    component: List,
  },
  // edit
  {
    path: ':id(\\d+)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // redirects from contracts
  {
    path: 'documenttranslationcontract/:documentTranslationContractId(\\d+)',
    beforeEnter(to, from, next) {
      store[Actions.READ_DOCUMENT_TRANSLATION_CONTRACT](parseInt(to.params.documentTranslationContractId, 10)).then(
        credits => {
          if (credits.length === 1) {
            next(`/credit/${credits[0].id}`);
          } else {
            next(`/credit/?contractNo=${to.query.contractNo}`);
          }
        },
        () => next(false),
      );
    },
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // redirects from contracts
  {
    path: 'onsitetranslationcontract/:onSiteTranslationContractId(\\d+)',
    beforeEnter(to, from, next) {
      store[Actions.READ_ON_SITE_TRANSLATION_CONTRACT](parseInt(to.params.onSiteTranslationContractId, 10)).then(
        invoices => {
          if (invoices.length === 1) {
            next(`/credit/${invoices[0].id}`);
          } else {
            next(`/credit/?contractNo=${to.query.contractNo}`);
          }
        },
        () => next(false),
      );
    },
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // send e-mail
  {
    path: ':id(\\d+)/email/(translator|customer)/:emailTemplateId(\\d+)',
    component: Email,
    meta: {
      hideMenu: true,
      hideTab: true,
      isEmailRoute: true,
    },
  },
] as RouteConfig[];
