import { BaseEntity } from '@/base';
import { UserJson } from '../user';

export interface StarMoneyImportJson extends BaseEntity
{
    importState: StarMoneyImportState;
    fileName: string;
    user: UserJson;
}

export enum StarMoneyImportState
{
    READY_FOR_IMPORT = 'READY_FOR_IMPORT',
    ERROR_WRONG_FILE_NAME_FORMAT = 'ERROR_WRONG_FILE_NAME_FORMAT',
    ERROR_WHILE_PROCESSING = 'ERROR_WHILE_PROCESSING',
    IMPORT_SUCCESSFUL = 'IMPORT_SUCCESSFUL',
}
