import api from '@/plugins/api';
import { OfficeWorkerJson } from './json';
import { UserType, injectResetMutation } from '../user';
import { EntityStore, createEntityStore, EntityStoreState, NewEntityProperties, FormData } from '@/base';

export enum Actions
{
  SEND_ACTIVATION_EMAIL = 'dispatchSemdActivationEmail',
}

export interface State extends EntityStoreState<OfficeWorkerJson>
{
}

export interface Store extends EntityStore<OfficeWorkerJson>
{
  [Actions.SEND_ACTIVATION_EMAIL](officeworker: FormData<OfficeWorkerJson>): Promise<OfficeWorkerJson>;
}

function createItem(): NewEntityProperties<OfficeWorkerJson>
{
  return {
    userType: UserType.OFFICE_WORKER,
    lastName: '',
    firstName: '',
    email: '',
    businessUnits: [],
    isLocked: false,
    userPermissions: [],
  };
}

const baseUrl = 'officeworkers';

export const store = createEntityStore<OfficeWorkerJson, State, Store>(
  'officeworker',
  baseUrl,
  createItem,
  {},
  moduleBuilder =>
    {
      injectResetMutation(moduleBuilder);
      return {
        [Actions.SEND_ACTIVATION_EMAIL]: moduleBuilder.dispatch(async ({ state }, officeworker: FormData<OfficeWorkerJson>) =>
        {
          const result = await api.post<OfficeWorkerJson>(`${baseUrl}/activationemail`, officeworker.$raw);
          return officeworker.$commit(result);
        }, Actions.SEND_ACTIVATION_EMAIL),
      };
    });
