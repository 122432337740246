




import { Component } from 'vue-property-decorator';
import { EntityList, HeaderConfig } from '@/base';
import store from '../store';
import { LanguageJson } from '../json';
import { UserPermission } from '@/modules/user';

@Component<List>({
  created()
  {
    this.init();
  },
})
export default class List extends EntityList<LanguageJson, typeof store>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.LANGUAGE_WRITE);
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'name' }];

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'isoCode',
        text: this.$t('list.header.isoCode').toString(),
      },
      {
        value: 'name',
        text: this.$t('list.header.name').toString(),
      },
    ];
  }
}
