import { Vue } from 'vue-property-decorator';
import { compareFn } from 'vuetify/src/util/helpers';
import { sortItems } from '@/util';
import { UserPermission } from '@/modules/user';
import { hasPermission as hasPermissionBase } from '@/json';

import './List.scss';

export interface ListAction<T>
{
  /**
   * The handler to call on button click.
   */
  handler: (item: T) => void;
  /**
   * A function that checks whether the action is visible.
   * If omitted, the action is always visible.
   */
  isVisible?: (item: T) => boolean;
  /**
   * A function that checks whether the action is disabled.
   * If omitted, the action is always enabled.
   */
  isDisabled?: (item: T) => boolean;
  /**
   * The name of a $vuetify.icons.<icon> icon.
   */
  icon: string;
  /**
   * The text label of the action.
   */
  text?: string;
  /**
   * The color of the button. Defaults to 'primary'.
   */
  color?: string;
}

/**
 * The possible selection modes of a list.
 */
export enum SelectionMode
{
  NONE = 'NONE',
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
}

/**
 * Interface for pagination information.
 */
export interface PaginationInfo
{
  /**
   * Whether pagination is used on the list.
   */
  hasPagination: boolean;
  /**
   * The total number of items if pagination is used.
   */
  totalItems: number;
}

export abstract class List<T> extends Vue
{

  protected hasWritePermission(): boolean {
    return true;
  }

  protected hasPermission(userPermission: UserPermission): boolean {
    return hasPermissionBase(userPermission);
  }

  /**
   * Returns the columns of the table (excluding actions).
   */
  protected abstract getColumns(): VueDataTableHeaderConfig[];

  /**
   * Returns the items of the list.
   */
  public abstract get items(): T[];

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<T>[]
  {
    return [];
  }

  /**
   * Property for template.
   */
  private get actions(): ListAction<T>[]
  {
    return this.getActions() || [];
  }

  /**
   * Whether the grid is loading.
   */
  public loading = false;

  /**
   * The selection mode of the list.
   */
  public selectionMode: SelectionMode = SelectionMode.NONE;

  /**
   * The selected items.
   */
  public selectedItems: T[] = [];

  /**
   * False to allow no sorting.
   */
  public get mustSort(): boolean
  {
    return this.internalOptions.mustSort;
  }
  public set mustSort(value: boolean)
  {
    this.internalOptions.mustSort = value;
  }

  /**
   * True to allow multiple columns to be sorted.
   */
  public get multiSort(): boolean
  {
    return this.internalOptions.multiSort;
  }
  public set multiSort(value: boolean)
  {
    this.internalOptions.multiSort = value;
  }

  /**
   * The number of items per page. Has no effect if paging is disabled.
   */
  public get itemsPerPage(): number
  {
    return this.internalOptions.itemsPerPage;
  }
  public set itemsPerPage(value: number)
  {
    this.internalOptions.itemsPerPage = value;
  }

  /**
   * The page number to display (0-based). Has no effect if paging is disabled.
   */
  public get page(): number
  {
    return this.paginationInfo.hasPagination ? this.internalOptions.page - 1 : 0;
  }
  public set page(value: number)
  {
    const { hasPagination, totalItems } = this.paginationInfo;
    if (hasPagination)
    {
      this.internalOptions.page = Math.max(0, Math.min(value, Math.ceil(totalItems / this.internalOptions.itemsPerPage) - 1)) + 1;
    }
  }

  /**
   * Whether the store uses remote paging and sorting.
   */
  protected get paginationInfo(): PaginationInfo
  {
    return {
      hasPagination: false,
      totalItems: -1,
    };
  }

  /**
   * Whether to show the footer on the list.
   */
  protected get showFooter(): boolean
  {
    return this.__showFooter == null ? this.paginationInfo.hasPagination : this.__showFooter;
  }
  protected set showFooter(value: boolean)
  {
    this.__showFooter = value;
  }
  private __showFooter?: boolean;

  /**
   * The current sort state. Override for initial sorting.
   */
  public get sortState(): SortInfo[]
  {
    return this.internalOptions.sortBy.map((property, i) => ({ property, desc: this.internalOptions.sortDesc[i] }));
  }
  public set sortState(value)
  {
    this.internalOptions.sortBy = value.map(({ property }) => property);
    this.internalOptions.sortDesc = value.map(({ desc }) => !!desc);
  }

  /**
   * The internal options of the data table.
   */
  protected internalOptions: VueDataTableOptions = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: -1,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: [],
    sortDesc: [],
  };

  /**
   * The headers of the table.
   */
  public get headers(): VueDataTableHeaderConfig[]
  {
    return this.getHeaders();
  }

  /**
   * Returns the headers of the list.
   */
  protected getHeaders(): VueDataTableHeaderConfig[]
  {
    const actions = this.getActions() || [];
    const columns = this.getColumns() || [];

    if (actions.length > 0)
    {
      return [{
        value: 'actions',
        text: '',
        width: actions.length * 20 + 32 + 4/*extra*/,
        sortable: false,
      } as VueDataTableHeaderConfig].concat(columns);
    }

    return columns;
  }

  /**
   * A better sort function for data tables.
   */
  public sortItems(items: T[], sortBy: string[], sortDesc: boolean[], locale: string, customSorters?: Record<string, compareFn>): T[]
  {
    return sortItems(items, sortBy, sortDesc, locale, customSorters);
  }
}

export default List;
