import { EmailTemplateJson } from './json';
import { EntityStore, createEntityStore, EntityStoreState, NewEntityProperties, EntityId, getId, FormData} from '@/base';
import { FileEntityCollectionJson, FileEntityJson } from '../file';
import api from '@/plugins/api';


export enum Actions
{
  READ_FILE_ATTACHMENT_COLLECTION = 'dispatchReadFileAttachmentCollection',
  UPLOAD_FILE = 'dispatchUploadFile',
  DELETE_FILE = 'deleteUploadFile',
  DOWNLOAD_FILE = 'dispatchDownloadFile',
}

export enum Mutations
{
  FILE_ATTACHMENT_COLLECTION = 'commitFileAttachmentCollection',
}

export interface State extends EntityStoreState<EmailTemplateJson>
{
  /**
   * The list of file entity collections per email template id.
   */
  fileEntityCollections: _.NumericDictionary<FileEntityCollectionJson>;
}

export interface Store extends EntityStore<EmailTemplateJson>
{
  [Actions.READ_FILE_ATTACHMENT_COLLECTION](
    payload: EntityId<EmailTemplateJson> | { emailTemplate: EntityId<EmailTemplateJson>; }): Promise<FileEntityCollectionJson>;
  [Actions.UPLOAD_FILE](
    payload: {emailTemplate: FormData<EmailTemplateJson>, file: File }): Promise<FileEntityCollectionJson>;
  [Actions.DELETE_FILE](
    payload: {emailTemplate: EntityId<EmailTemplateJson>, fileEntity: FileEntityJson }): Promise<FileEntityCollectionJson>;
  [Actions.DOWNLOAD_FILE](payload: { emailTemplate: EntityId<EmailTemplateJson>; fileEntity: FileEntityJson }): Promise<void>;
}

function createItem(): NewEntityProperties<EmailTemplateJson>
{
  return {
    name: '',
    emailTemplateType: null,
    businessUnit: null,
    subject: '',
    body: '',
  };
}

const apiBaseUrl = 'emailtemplates';

export default createEntityStore<EmailTemplateJson, State, Store>(
  'emailtemplate',
  apiBaseUrl,
  createItem,
  {
    fileEntityCollections: {},
  },
  moduleBuilder =>
  {

    const setFileAttachmentCollection = moduleBuilder.commit(
      (state, { emailTemplate, fileEntityCollection }: { emailTemplate: EntityId<EmailTemplateJson>; fileEntityCollection: FileEntityCollectionJson }) =>
      {
        state.fileEntityCollections[getId(emailTemplate)] = fileEntityCollection;
      }, Mutations.FILE_ATTACHMENT_COLLECTION);

    return {
      [Actions.READ_FILE_ATTACHMENT_COLLECTION]: moduleBuilder.dispatch(
        async ({ state }, payload: EntityId<EmailTemplateJson> | { emailTemplate: EntityId<EmailTemplateJson>; }) =>
        {
          if (typeof payload === 'number' || !('emailTemplate' in payload))
          {
            payload = {
              emailTemplate: payload,
            };
          }

          const { emailTemplate } = payload;
          const emailTemplateId = getId(emailTemplate);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${emailTemplateId}/fileattachmentcollection`);
          setFileAttachmentCollection({ emailTemplate, fileEntityCollection });

          return state.fileEntityCollections[emailTemplateId];
        }, Actions.READ_FILE_ATTACHMENT_COLLECTION),
      [Actions.UPLOAD_FILE]: moduleBuilder.dispatch(async ({ state }, { emailTemplate, file }: Parameters<Store[Actions.UPLOAD_FILE]>[0]) =>
        {
          const result = await api.upload<FileEntityCollectionJson>({
            data: {
              'user-file': file,
            },
            method: 'POST',
            url: `${apiBaseUrl}/${emailTemplate.id}/file`,
          });
          state.fileEntityCollections[getId(emailTemplate)] = result;
          return result;
        }, Actions.UPLOAD_FILE),
      [Actions.DELETE_FILE]: moduleBuilder.dispatch(async ({ state }, { emailTemplate, fileEntity }: Parameters<Store[Actions.DELETE_FILE]>[0]) =>
        {
          const emailTemplateId = getId(emailTemplate);
          await api.delete(`${apiBaseUrl}/${emailTemplateId}/file/${fileEntity.id}`);
          const fileEntityCollection = await api.get<FileEntityCollectionJson>(`${apiBaseUrl}/${emailTemplateId}/fileattachmentcollection`);
          setFileAttachmentCollection({ emailTemplate, fileEntityCollection });
          return state.fileEntityCollections[emailTemplateId];
      }, Actions.DELETE_FILE),
      [Actions.DOWNLOAD_FILE]: moduleBuilder.dispatch(
        async ({ state }, { emailTemplate, fileEntity }: Parameters<Store[Actions.DOWNLOAD_FILE]>[0]) => {
          const emailTemplateId = getId(emailTemplate);
          return api.download({
            method: 'GET',
            url: `${apiBaseUrl}/${emailTemplateId}/file/${fileEntity.id}`,
          });
        },
        Actions.DOWNLOAD_FILE,
      ),
    };
  },
);
