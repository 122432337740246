


































































































































































































































































































import { Component } from 'vue-property-decorator';
import { ReportJson, ReportState, ReportTimeGroup, ReportMoneyOrigin, FinancialDocumentType, InvoicePaymentState } from '../json';
import { EntityForm as FormBase, EntityStoreGetters, EntityStoreActions } from '@/base';
import store from '../store';
import { store as businessUnitStore } from '@/modules/businessunit';
import { store as languageStore, getLanguageItemComparator } from '@/modules/language';
import { store as customerStore, CustomerJson } from '@/modules/customer';
import { store as translatorStore } from '@/modules/translator';
import { InvoiceState } from '@/modules/financialdocument/invoice';
import { ContractState, ContractType } from '@/modules/contract';
import { BankAccountTransactionAssignmentState } from '@/modules/bankaccounttransaction';
import { getFullName, UserPermission } from '@/modules/user';

@Component<Form>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  methods: {
    getFullName,
    getLanguageItemComparator,
  },
})
export default class Form extends FormBase<ReportJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.REPORT_WRITE);
  }

  protected readonly store = store;

  protected readonly businessUnitStore = businessUnitStore;
  protected readonly languageStore = languageStore;
  protected readonly customerStore = customerStore;
  protected readonly translatorStore = translatorStore;

  private readonly InvoiceState = InvoiceState;
  private readonly InvoicePaymentState = InvoicePaymentState;
  private readonly ContractState = ContractState;
  private readonly ContractType = ContractType;
  private readonly ReportMoneyOrigin = ReportMoneyOrigin;
  private readonly BankAccountTransactionAssignmentState = BankAccountTransactionAssignmentState;
  private readonly FinancialDocumentType = FinancialDocumentType;

  protected readonly closeRoute = {
    name: 'report',
  };

  /*
   * Overwrite function
   * called after successfull save.
   */
  protected afterSave() {
    this.changeActiveItem(null);
    this.$router.push(this.closeRoute);
  }

  private readonly ReportState = ReportState;
  private readonly ReportTimeGroup = ReportTimeGroup;
}
