








import { Component } from 'vue-property-decorator';
import { HeaderConfig, EntityList } from '@/base';
import store from '../store';
import { EmailTemplateJson } from '../json';
import { UserPermission } from '@/modules/user';

@Component<List>({
  created()
  {
    this.init();
  },
})
export default class List extends EntityList<EmailTemplateJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.EMAIL_TEMPLATE_WRITE);
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'name' }];

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'name',
        text: this.$t('list.header.name').toString(),
      },
      {
        value: 'emailTemplateType',
        text: this.$t('list.header.emailTemplateType').toString(),
      },
      {
        value: 'businessUnit.name',
        text: this.$t('list.header.businessUnit').toString(),
      },
    ];
  }

  protected getDeleteConfirmationMessage(item: EmailTemplateJson): { title?: string; message: string }
  {
    return {
      title: this.$t('list.delete.title', item).toString(),
      message: this.$t('list.delete.message', item).toString(),
    };
  }
}
