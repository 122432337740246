import { TranslatorCostConfigurationCycleJson } from './json';

export function getCycleDescription(item: TranslatorCostConfigurationCycleJson, fallbackToId?: boolean): string
{
  if (item.costConfigurationCycleTypes && item.customerGroups)
  {
    const customerLanguageGroupsDesc = item.customerGroups.map(group => group.name).sort().join(', ');
    const cycleDesc = item.costConfigurationCycleTypes.map(type => `${type.costType}: ${item.firstCycleDuration}/${item.followingCycleDuration} (${type.costUnit})`).sort().join(', ');
    return `${customerLanguageGroupsDesc} - ${cycleDesc}`;
  }

  return fallbackToId ? `(ID: ${item.id})` : '';
}
