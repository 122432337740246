import { RouteConfig } from 'vue-router';

import List from './views/List.vue';
import Form from './views/Form.vue';
import FileAttachmentCollectionOriginal from './views/FileAttachmentCollectionOriginal.vue';
import FileAttachmentCollectionTranslated from './views/FileAttachmentCollectionTranslated.vue';
import Contact from './views/Contact.vue';
import Email from './views/Email.vue';

export default [
  // list
  {
    name: 'documenttranslation',
    path: '',
    component: List,
  },
  // edit
  {
    path: ':id(new|\\d+)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // file upload original
  {
    path: ':id(\\d+)/fileattachmentcollectionoriginal',
    component: FileAttachmentCollectionOriginal,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // file upload translated
  {
    path: ':id(\\d+)/fileattachmentcollectiontranslated',
    component: FileAttachmentCollectionTranslated,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // invoice or credit contact
  {
    path: ':id(\\d+)/:type(invoice|credit)contact',
    component: Contact,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // invoice
  {
    path: ':id(\\d+)/invoice',
    redirect: to => `/invoice/documenttranslationcontract/${to.params.id}`,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // credit
  {
    path: ':id(\\d+)/credit',
    redirect: to => `/credit/documenttranslationcontract/${to.params.id}`,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // send e-mail
  {
    path: ':id(\\d+)/email/(translator|customer)/:emailTemplateId(\\d+)',
    component: Email,
    meta: {
      hideMenu: true,
      hideTab: true,
      isEmailRoute: true,
    },
  },
] as RouteConfig[];
