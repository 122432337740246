




import { Component, Prop } from 'vue-property-decorator';
import Sortable from 'sortablejs';
import { List as ListBase, ListAction, HeaderConfig, createEntity, NewEntity } from '@/base';
import { BankAccountTransactionPositionJson } from '../json';
import BankAccountTransactionPositionList from '../base/BankAccountTransactionPositionList.vue';
import { InvoiceJson, store as invoiceStore } from '../../financialdocument/invoice';

@Component<PositionListInvoice>({
  props: {
    items: {
      required: true,
    },
  },
  mounted()
  {
    this.sortable = new Sortable(this.$el.querySelector('tbody')!, {
      draggable: 'tr:not(.add-row)',
      handle: '.drag-handle:not(.v-icon--disabled)',
    });
  },
  destroyed()
  {
    if (this.sortable)
    {
      this.sortable.destroy();
    }
  },
})
export default class PositionListInvoice extends BankAccountTransactionPositionList<InvoiceJson>
{
  public readonly items: NewEntity<BankAccountTransactionPositionJson<InvoiceJson>>[];

  private entityStore = invoiceStore;

  protected getFinancialDocumentLabel(): string
  {
    return this.getLabel('header.invoice');
  }

  protected getFullName(item: InvoiceJson): string
  {
    return item !== null ? item.id + ' - ' + item.invoiceReference : '';
  }

  /**
   * Adds a new item if the newDescription model has a value.
   */
  protected addItemIf(): void
  {
    if (this.newEntity)
    {
      this.items.push(createEntity({
        assignedPriceNet: this.newEntity.documentValueNet,
        assignedPriceGross: this.newEntity.documentValueGross,
        entity: this.newEntity,
      }));
      this.newEntity = null;
    }
  }
}
