import api from '@/plugins/api';
import { CreditJson, CreditState } from './json';
import { EntityPagingStoreState, EntityPagingStore, EntityStoreActions, createEntityPagingStore, EntityId, getId, commitEntity, FormData } from '@/base';
import { DocumentTranslationContractJson } from '@/modules/contract/documenttranslation';
import { OnSiteTranslationContractJson } from '@/modules/contract/onsitetranslation';
import { VideoTranslationContractJson } from '@/modules/contract/videotranslation';
import { EmailTemplateJson } from '@/modules/emailtemplate';
import { EmailJson } from '@/modules/email';

export enum Actions {
  READ_DOCUMENT_TRANSLATION_CONTRACT = 'dispatchReadDocumentTranslationContract',
  CREATE_DOCUMENT_TRANSLATION_CONTRACT = 'dispatchCreateDocumentTranslationContract',
  READ_ON_SITE_TRANSLATION_CONTRACT = 'dispatchReadOnSiteTranslationContract',
  CREATE_ON_SITE_TRANSLATION_CONTRACT = 'dispatchCreateOnSiteTranslationContract',
  READ_VIDEO_TRANSLATION_CONTRACT = 'dispatchReadVideoTranslationContract',
  CREATE_VIDEO_TRANSLATION_CONTRACT = 'dispatchCreateVideoTranslationContract',
  CREATE_CREDIT_PDF = 'dispatchCreateCreditPdf',
  DOWNLOAD_CREDIT_PDF = 'dispatchDownloadCreditPdf',
  DOWNLOAD_CREDIT_PDF_ZIP_CHECK = 'dispatchDownloadCreditPdfZipCheck',
  DOWNLOAD_CREDIT_PDF_ZIP = 'dispatchDownloadCreditPdfZip',
  SET_STATE = 'dispatchSetState',
  READ_EMAIL = 'dispatchReadEmail',
  CANCEL_CREDIT = 'dispatchCancelCredit',
}

export interface State extends EntityPagingStoreState<CreditJson> {}

export interface Store extends EntityPagingStore<CreditJson> {
  // hide actions
  [EntityStoreActions.CREATE_OR_UPDATE]: never;
  [EntityStoreActions.DELETE]: never;
  /**
   * Reads the credit for a document translation contract.
   */
  [Actions.READ_DOCUMENT_TRANSLATION_CONTRACT](contract: EntityId<DocumentTranslationContractJson>): Promise<CreditJson[]>;
  /**
   * Creates an credit for document translation contracts.
   */
  [Actions.CREATE_DOCUMENT_TRANSLATION_CONTRACT](contracts: DocumentTranslationContractJson[]): Promise<CreditJson>;
  /**
   * Reads the credit for a onsite translation contract.
   */
  [Actions.READ_ON_SITE_TRANSLATION_CONTRACT](contract: EntityId<OnSiteTranslationContractJson>): Promise<CreditJson[]>;
  /**
   * Creates an credit for onsite translation contracts.
   */
  [Actions.CREATE_ON_SITE_TRANSLATION_CONTRACT](contracts: OnSiteTranslationContractJson[]): Promise<CreditJson>;
  /**
   * Reads the credit for a video translation contract.
   */
  [Actions.READ_VIDEO_TRANSLATION_CONTRACT](contract: EntityId<VideoTranslationContractJson>): Promise<CreditJson[]>;
  /**
   * Creates an credit for video translation contracts.
   */
  [Actions.CREATE_VIDEO_TRANSLATION_CONTRACT](contracts: VideoTranslationContractJson[]): Promise<CreditJson>;
  /**
   * Creates an credit PDF.
   */
  [Actions.CREATE_CREDIT_PDF](credit: FormData<CreditJson>): Promise<CreditJson>;
  /**
   * Downloads a created credit PDF.
   */
  [Actions.DOWNLOAD_CREDIT_PDF](credit: EntityId<CreditJson>): Promise<void>;
  /**
   * Returns CreditJsons, which has no PDF-File.
   */
  [Actions.DOWNLOAD_CREDIT_PDF_ZIP_CHECK](credits: CreditJson[]): Promise<CreditJson[]>;
  /**
   * Downloads created credit PDF-Files as ZIP archive.
   */
  [Actions.DOWNLOAD_CREDIT_PDF_ZIP](credits: CreditJson[]): Promise<void>;
  /**
   * Sets the credit to the given state.
   */
  [Actions.SET_STATE](payload: { credit: CreditJson; state: CreditState }): Promise<CreditJson>;

  [Actions.CANCEL_CREDIT](payload: { credit: CreditJson; }): Promise<CreditJson>;

  [Actions.READ_EMAIL](payload: { credit: EntityId<CreditJson>; emailTemplate: EntityId<EmailTemplateJson>; isTranslator: boolean }): Promise<EmailJson>;
}

const creditBaseUrl = 'credits';

export const store = createEntityPagingStore<CreditJson, State, Store>(
  'credit',
  creditBaseUrl,
  () => {
    throw new Error('Creating credits is not supported.');
  },
  {},
  moduleBuilder => {
    return {
      [Actions.READ_DOCUMENT_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contract: EntityId<DocumentTranslationContractJson>) => {
        const result = await api.get<CreditJson[]>(`${creditBaseUrl}/documenttranslationcontract/${getId(contract)}`);

        state.items.set(...result);

        return result;
      }, Actions.READ_DOCUMENT_TRANSLATION_CONTRACT),

      [Actions.CREATE_DOCUMENT_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contracts: DocumentTranslationContractJson[]) => {
        const result = await api.post<CreditJson>(`${creditBaseUrl}/documenttranslationcontract`, contracts);

        state.items.set(result);

        return result;
      }, Actions.CREATE_DOCUMENT_TRANSLATION_CONTRACT),
      [Actions.READ_ON_SITE_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contract: EntityId<OnSiteTranslationContractJson>) => {
        const result = await api.get<CreditJson[]>(`${creditBaseUrl}/onsitetranslationcontract/${getId(contract)}`);

        state.items.set(...result);

        return result;
      }, Actions.READ_ON_SITE_TRANSLATION_CONTRACT),

      [Actions.CREATE_ON_SITE_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contracts: OnSiteTranslationContractJson[]) => {
        const result = await api.post<CreditJson>(`${creditBaseUrl}/onsitetranslationcontract`, contracts);

        state.items.set(result);

        return result;
      }, Actions.CREATE_ON_SITE_TRANSLATION_CONTRACT),
      [Actions.READ_VIDEO_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contract: EntityId<VideoTranslationContractJson>) => {
        const result = await api.get<CreditJson[]>(`${creditBaseUrl}/videotranslationcontract/${getId(contract)}`);

        state.items.set(...result);

        return result;
      }, Actions.READ_VIDEO_TRANSLATION_CONTRACT),

      [Actions.CREATE_VIDEO_TRANSLATION_CONTRACT]: moduleBuilder.dispatch(async ({ state }, contracts: VideoTranslationContractJson[]) => {
        const result = await api.post<CreditJson>(`${creditBaseUrl}/videotranslationcontract`, contracts);

        state.items.set(result);

        return result;
      }, Actions.CREATE_VIDEO_TRANSLATION_CONTRACT),

      [Actions.CREATE_CREDIT_PDF]: moduleBuilder.dispatch(async ({ state }, credit: FormData<CreditJson>) => {
        const result = await api.post<CreditJson>(`${creditBaseUrl}/${credit.id}/creditpdf`);
        return credit.$commit(result);
      }, Actions.CREATE_CREDIT_PDF),

      [Actions.DOWNLOAD_CREDIT_PDF]: moduleBuilder.dispatch(({ state }, credit: EntityId<CreditJson>) => {
        return api.download({
          method: 'GET',
          url: `${creditBaseUrl}/${getId(credit)}/creditpdf`,
          headers: {
            Accept: 'application/pdf',
          },
        });
      }, Actions.DOWNLOAD_CREDIT_PDF),
      [Actions.DOWNLOAD_CREDIT_PDF_ZIP_CHECK]: moduleBuilder.dispatch(async ({ state }, credits: CreditJson[]) => {
        const result = await api.post<CreditJson[]>(`${creditBaseUrl}/creditpdfzipcheck`, credits);
        return result;
      }, Actions.DOWNLOAD_CREDIT_PDF_ZIP_CHECK),
      [Actions.DOWNLOAD_CREDIT_PDF_ZIP]: moduleBuilder.dispatch(({ state }, credits: CreditJson[]) => {
        return api.download({
          data: credits,
          method: 'POST',
          url: `${creditBaseUrl}/creditpdfzip`,
          headers: {
            Accept: 'application/pdf',
          },
        });
      }, Actions.DOWNLOAD_CREDIT_PDF_ZIP),
      [Actions.CANCEL_CREDIT]: moduleBuilder.dispatch(async ({ state }, { credit }: Parameters<Store[Actions.CANCEL_CREDIT]>[0]) => {
        const result = await api.put<CreditJson>(`${creditBaseUrl}/${credit.id}/cancelcredit`);

        const committed = commitEntity(credit, result);

        state.items.set(result);

        return committed;
      }, Actions.CANCEL_CREDIT),
      [Actions.SET_STATE]: moduleBuilder.dispatch(async ({ state }, { credit, state: creditState }: Parameters<Store[Actions.SET_STATE]>[0]) => {
        const result = await api.put<CreditJson>(`${creditBaseUrl}/${credit.id}/setstate`, creditState);

        const committed = commitEntity(credit, result);

        state.items.set(committed);

        return committed;
      }, Actions.SET_STATE),
      [Actions.READ_EMAIL]: moduleBuilder.dispatch((ctx, { credit, emailTemplate, isTranslator }: Parameters<Store[Actions.READ_EMAIL]>[0]) => {
        const emailPath = isTranslator ? 'email/translator' : 'email/customer';
        return api.get<EmailJson>(`${creditBaseUrl}/${getId(credit)}/${emailPath}/${getId(emailTemplate)}`);
      }, Actions.READ_EMAIL),
    };
  },
);

export default store;
