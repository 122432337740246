

























































































































































import { Component } from 'vue-property-decorator';
import { EntityList, HeaderConfig, EntityStoreActions, EntityStoreGetters, ListAction } from '@/base';
import { store, Actions } from '../store';
import { CashAccountTransactionJson, CashAccountTransactionAssignmentState } from '../json';
import { store as businessUnitStore } from '@/modules/businessunit';
import { store as cashbookStore, Actions as cashbookActions } from '@/modules/cashbook';
import PositionList from '../../financialdocument/invoice/components/PositionList.vue';
import { store as invoiceStore, InvoiceJson } from '@/modules/financialdocument/invoice';
import { store as creditStore, CreditJson } from '@/modules/financialdocument/credit';
import { store as externalDocumentStore, ExternalDocumentJson } from '@/modules/financialdocument/externaldocument';
import { UserPermission } from '@/modules/user';

@Component<List>({
  created()
  {
    this.init();
  },
})
export default class List extends EntityList<CashAccountTransactionJson, typeof store>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_WRITE);
  }
  protected hasDeletePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_DELETE);
  }

  private readonly businessUnitStore = businessUnitStore;
  private readonly invoiceStore = invoiceStore;
  private readonly creditStore = creditStore;
  private readonly externalDocumentStore = externalDocumentStore;

  private showUsageDialog = false;

  private contentDialog = '';

  private handleIconClick(subject: string): void {
    this.contentDialog = subject;
  }

  private isAssignmentComplete(value: CashAccountTransactionAssignmentState): boolean
  {
    // tslint:disable-next-line:max-line-length
    return value && (value === CashAccountTransactionAssignmentState.ASSIGNMENT_COMPLETE_MANUAL || value === CashAccountTransactionAssignmentState.ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED);
  }

  private isAssignmentIncomplete(value: CashAccountTransactionAssignmentState): boolean
  {
    return value && value === CashAccountTransactionAssignmentState.ASSIGNMENT_INCOMPLETE_MANUAL;
  }

  private isAssignmentNone(value: CashAccountTransactionAssignmentState): boolean
  {
    return value && (value === CashAccountTransactionAssignmentState.NOT_ASSIGNED);
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'transactionDate', desc: true }];

   /**
    * Values of filter form.
    */
  protected readonly filters = {
    businessUnitId: null as number | null,
    ctValueFrom: null as number | null,
    ctValueTo: null as number | null,
    transactionDateFrom: null as Date | null,
    transactionDateUntil: null as Date | null,
    ctUsage: null as string | null,
    bankAccount: null as string | null,
    invoiceId: null as number | null,
    creditId: null as number | null,
    externalDocumentId: null as number | null,
  };

  private canAcceptIncompleteAssigned(state: CashAccountTransactionAssignmentState): boolean {
    return state === CashAccountTransactionAssignmentState.ASSIGNMENT_INCOMPLETE_MANUAL;
  }


  private async acceptIncompleteAssignment(item: CashAccountTransactionJson): Promise<void> {
    await store[Actions.ACCEPT_INCOMPLETE_ASSIGNMENT]({
        cashAccountTransaction: item,
      });
  }

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<CashAccountTransactionJson>[] {
    const actions = [ this.getListActionEdit() ];
    if (this.hasDeletePermission()) {
      actions.push(this.getListActionDelete());

    }
    return actions;
  }

  private getListActionEdit(): ListAction<CashAccountTransactionJson> {
    return {
        icon: 'edit',
        handler: item => this.editItem(item),
        text: this.$root.$t('edit.label').toString(),
      };
  }

  private getListActionDelete(): ListAction<CashAccountTransactionJson> {
    return {
        icon: 'delete',
        handler: item => this.deleteItem(item),
        text: this.$root.$t('delete.label').toString(),
        color: 'delete',
      };
  }

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'businessUnit.name',
        text: this.$t('list.header.businessUnit').toString(),
        remoteSort: desc => [{ property: 'businessUnit.id', desc }],
        filterProperty: 'businessUnitId',
      },
      {
        value: 'transactionDate',
        text: this.$t('list.header.transactionDate').toString(),
        filterProperty: ['transactionDateFrom', 'transactionDateUntil'],
      },
      {
        value: 'ctValue',
        text: this.$t('list.header.ctValue').toString(),
        filterProperty: ['ctValueFrom', 'ctValueTo'],
      },
      {
        value: 'ctValueAssigned',
        text: this.$t('list.header.ctValueAssigned').toString(),
      },
      {
        value: 'cashAccountTransactionAssignmentState',
        text: this.$t('list.header.cashAccountTransactionAssignmentState').toString(),
        sortable: false,
        filterProperty: ['cashAccountTransactionAssignmentState', 'invoiceId', 'creditId', 'externalDocumentId'],
      },
      {
        value: 'ctUsage',
        text: this.$t('list.header.ctUsage').toString(),
        sortable: false,
        filterProperty: ['ctUsage'],
      },
    ];
  }

  private getFullNameCredit(item: CreditJson): string
  {
    return item ? item.id + ' - ' + item.creditReference : '';
  }

  private getFullNameInvoice(item: InvoiceJson): string
  {
    return item ? item.id + ' - ' + item.invoiceReference : '';
  }

  private getFullNameExternalDocument(item: ExternalDocumentJson): string
  {
    return item ? item.id + ' - ' + item.documentReference : '';
  }

  private contentCurrentBalance = 'ts<BR>ts';

  private showCurrentBalanceDialog = false;

  private showCurrentBalance(): void
  {
    this.showCurrentBalanceDialog = true;
    cashbookStore[cashbookActions.GET_CURRENT_BALANCES]({})
    .then((results) => {
      this.contentCurrentBalance = '';
      results.forEach(res => this.contentCurrentBalance += res.businessUnit.name + ': ' + this.$n(res.currentBalance, 'currency') + '<BR>');
    })
    .catch((err) => this.contentCurrentBalance = err);
  }

  private closeCurrentBalance(): void
  {
    this.contentCurrentBalance = '';
    this.showCurrentBalanceDialog = false;
  }
}
