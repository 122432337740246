




























import { Component } from 'vue-property-decorator';
import { store } from '../store';
import { VatConfigurationJson, Vat } from '../json';
import { sortByFullName, getFullName } from '@/modules/user';
import { HeaderConfig, EntityList, ListAction } from '@/base';

@Component<List>({
  created()
  {
    this.init();
  },
  methods: {
    getFullName,
  },
})
export default class List extends EntityList<VatConfigurationJson, typeof store>
{
  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'validFrom', desc: true  }];

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    vat: null as Vat | null,
  };

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'validFrom',
        text: this.$t('list.header.validFrom').toString(),
      },
      {
        value: 'vat',
        text: this.$t('list.header.vat').toString(),
      },
      {
        value: 'vatRate',
        text: this.$t('list.header.vatRate').toString(),
      },
    ];
  }

  protected getDeleteConfirmationMessage(item: VatConfigurationJson): { title?: string; message: string }
  {
    const args = Object.assign({ item });
    return {
      title: this.$t('list.delete.title', args).toString(),
      message: this.$t('list.delete.message', args).toString(),
    };
  }

  private readonly Vat = Vat;
}
