








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { Route } from 'vue-router';
import { Actions, store, Getters } from '../store';
import { UserLoginRequest } from '@/json';
import DialogContent from '@/components/DialogContent.vue';
import { InteractionSetting, InteractionModeFactory } from 'vee-validate/dist/types/modes';

@Component<Login>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.isLoggedIn ? next(to.query.redirectTo as string || '/dashboard') : next());
  },
  beforeRouteUpdate(to, from, next): void
  {
    this.__updateRedirectProperties(to);
    next();
  },
  created()
  {
    this.init();
  },
  activated()
  {
    this.init();
  },
  deactivated()
  {
    this.__resetMessages(true);
  },
  destroyed()
  {
    this.__resetMessages(true);
  },
  components: {
    DialogContent,
  },
})
export default class Login extends Vue
{
  /**
   * The form data.
   */
  public loginData: UserLoginRequest = {
    email: '',
    password: '',
  };

  /**
   * Whether to display the password.
   */
  public showPassword = false;

  /**
   * Property to disable the form while submitting.
   */
  private isSubmitting = false;

  /**
   * Whether to show the hint for active caps lock.
   */
  private showCapslockHint = false;

  public get isLoggedIn(): boolean
  {
    return store[Getters.IS_LOGGED_IN];
  }

  private init(): void
  {
    this.loginData.email = this.loginData.password = '';
    this.__updateRedirectProperties(this.$route);
  }

  private onMouseMove(e: MouseEvent): void
  {
    this.showCapslockHint = e.getModifierState('CapsLock');
  }

  private onPasswordKeydown(e: KeyboardEvent): void
  {
    this.showCapslockHint = e.getModifierState('CapsLock');
  }

  public submit(): void
  {
    this.__doRequest(() =>
      {
        return store[Actions.LOGIN](this.loginData)
          .then(() => this.$router.push(this.__redirectTo || '/dashboard'))
          .catch(error =>
          {
            // reset password
            this.loginData.password = '';
            // set error
            this.loginErrorAlert = this.$error(error.statusCode === 401 ? this.$t('login.error') as string : error.message, 0, false);

            this.$nextTick(() => (this.$refs.passwordField as HTMLElement).focus());
          });
      });
  }

  /**
   * Sends a password request e-mail.
   */
  public requestPassword(): void
  {
    this.__doRequest(() =>
      {
        return store[Actions.REQUEST_PASSWORD]({
          email: this.loginData.email,
        })
          .then(result =>
            {
              this.passwordRequestSuccessAlert = this.$success(this.$t('login.requestPassword.success', {
                email: result.data.email,
                expires: this.$d(result.data.passwordRequestExpirationTime, 'dateTime'),
              }).toString(), 0);
            })
          .catch(error =>
            {
              this.loginErrorAlert = this.$error(error.message, 0, false);
            });
      });
  }

  /**
   * Validates the email field. Checks if it is non empty.
   */
  private async validateEmail(): Promise<boolean>
  {
    const result = await (this.$refs.emailValidation as InstanceType<typeof ValidationProvider>).validate();
    return result.valid;
  }

  /**
   * Returns the interaction mode for email validation.
   */
  private getEmailValidationMode(...params: Parameters<InteractionModeFactory>): InteractionSetting
  {
    // mixture of passive and eager
    return {
      on: params[0].errors.length ? ['input', 'change'] : [],
    };
  }

  /**
   * Does a server request and handles the validation.
   */
  private __doRequest<T = unknown>(fn: () => Promise<T>): Promise<T | undefined>
  {
    return this.validateEmail()
      .then(valid =>
      {
        if (valid)
        {
          try
          {
            (this.$refs.passwordField as HTMLElement).blur();
          }
          catch (e)
          {
            // ignore
          }

          this.isSubmitting = true;
          this.__resetMessages(this.$route.query.ref !== 'expired');

          // remember input
          (this.$refs.form as HTMLFormElement).submit();

          return fn()
            .finally(() => this.isSubmitting = false);
        }
      });
  }

  /**
   * Updates the redirect info from the given route.
   */
  private __updateRedirectProperties(route: Route): void
  {
    if (this.refAlert)
    {
      this.refAlert();
    }

    switch (route.query.ref)
    {
      case 'expired':
        this.refAlert = this.$warning(this.$t('login.ref.expired') as string, 0, false);
        break;
      case 'reset':
        this.refAlert = this.$success(this.$t('login.ref.reset') as string, 0, false);
        break;
      default:
        this.refAlert = null;
        break;
    }

    this.__redirectTo = route.query.redirectTo as string || null;
  }

  /**
   * Removes the alert messages.
   */
  private __resetMessages(includeRef?: boolean): void
  {
    if (this.loginErrorAlert)
    {
      this.loginErrorAlert();
      this.loginErrorAlert = null;
    }

    if (this.passwordRequestSuccessAlert)
    {
      this.passwordRequestSuccessAlert();
      this.passwordRequestSuccessAlert = null;
    }

    if (includeRef && this.refAlert)
    {
      this.refAlert();
      this.refAlert = null;
    }
  }

  /**
   * The route to redirect after login. Defaults to dashboard.
   */
  private __redirectTo: string | null = null;

  /**
   * The login error remove function if any.
   */
  private loginErrorAlert: (() => void) | null = null;

  /**
   * The success message remove function if password request was successful.
   */
  private passwordRequestSuccessAlert: (() => void) | null = null;

  /**
   * The remove function of the refAlert for being on the login page.
   */
  private refAlert: (() => void) | null = null;
}
