import { UserJson } from './json';
import { TranslatorJson } from '../translator/json';
import i18n from '@/plugins/i18n';

export function getFullName<T extends UserJson>(item: T, fallbackToId?: boolean): string
{
  let fullName: string = '';
  if (item.firstName || item.lastName) {
    fullName = `${item.id} - ${[item.lastName, item.firstName].join(', ')}`;
  } else if (fallbackToId) {
    fullName = `(ID: ${item.id})`;
  }
  if (item.hasOwnProperty('offeredServices')) {
    const translator = item as unknown as TranslatorJson;
    if (translator.offeredServices && translator.offeredServices.length > 0) {
      const offeredServices = translator.offeredServices ? translator.offeredServices.map(s => {
        return i18n.t(`module.translator.list.offeredServices.${s.typeOfService}`).toString();
      }) : [];
      fullName = fullName + ` (${offeredServices.sort().join(', ')})`;
    }
  }
  return  fullName;
}

export function sortByFullName<T extends UserJson>(item1: T, item2: T, locale: string, caseSensitive?: boolean): number
{
  const name1 = getFullName(item1);
  const name2 = getFullName(item2);

  return name1.localeCompare(name2, locale, {
    ignorePunctuation: true,
    sensitivity: caseSensitive ? 'variant' : 'base',
    usage: 'sort',
  });
}
