






























import { Component } from 'vue-property-decorator';
import { EntityList, ListAction, HeaderConfig, EntityStoreGetters, EntityStoreActions, SelectionMode } from '@/base';
import { getEnumFields } from '@/util';
import { ContractState, InvoiceFilter, CreditFilter } from '../../json';
import { OnSiteTranslationContractJson } from '../json';
import { TranslatorJson, store as translatorStore } from '@/modules/translator';
import { getFullName, UserJson, UserType } from '@/modules/user';
import store, { Actions } from '../store';
import { store as mainStore, Getters } from '@/store';
import { store as invoiceStore, Actions as InvoiceActions } from '@/modules/financialdocument/invoice';
import { store as creditStore, Actions as CreditActions } from '@/modules/financialdocument/credit';

import { EmailTemplateJson, EmailTemplateType, store as emailTemplateStore } from '../../../emailtemplate';
import ContractList from '../../base/ContractList.vue';

const emailTemplateTypes: ReadonlyArray<EmailTemplateType> = getEnumFields(EmailTemplateType);

@Component<List>({
  filters: {
    fullName(item: UserJson | null, fallbackToId?: boolean): string
    {
      return item ? getFullName(item, fallbackToId) : '';
    },
  },
  created()
  {
    this.init();
  },
  methods: {
    getFullName,
  },
})
export default class List extends ContractList<OnSiteTranslationContractJson, typeof store>
{
  /**
   * The store to the items.
   */
  protected readonly store = store;
  public sortState = [{ property: 'contractNo' }];
  public selectionMode = SelectionMode.MULTI;

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    contractNo: '',
    customerId: null as number | null,
    translatorId: null as number | null,
    businessUnitId: null as number | null,
    contractState: null as ContractState | null,
    contractComment: null as string | null,
    contractDateFrom: null as Date | null,
    contractDateUntil: null as Date | null,
    beginDateTimeFrom: null as Date | null,
    beginDateTimeUntil: null as Date | null,
    operationLocationFilter: null as string | null,
    translatorLanguageId: null as number | null,
    invoiceFilter: null as InvoiceFilter | null,
    creditFilter: null as CreditFilter | null,
    invoiceId: null as number | null,
    creditId: null as number | null,
  };

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    const contractNo: HeaderConfig = {
        value: 'contractNo',
        text: this.$t('list.header.contractNo').toString(),
      };

    const contractState: HeaderConfig =  {
        value: 'contractState',
        text: this.$t('list.header.contractState').toString(),
        filterProperty: ['contractState', 'invoiceFilter', 'creditFilter'],
      };

    const businessUnit: HeaderConfig = {
        value: 'businessUnit.name',
        text: this.$t('list.header.businessUnit').toString(),
        remoteSort: desc => [{ property: 'businessUnit.id', desc }],
        filterProperty: 'businessUnitId',
      };

    const customer: HeaderConfig = {
        value: 'customer',
        text: this.$t('list.header.customer').toString(),
        remoteSort: desc => [
          { property: 'customer.lastName', desc },
          { property: 'customer.firstName', desc },
        ],
        filterProperty: 'customerId',
      };

    const translator: HeaderConfig = {
        value: 'translator',
        text: this.$t('list.header.translator').toString(),
        remoteSort: desc => [
          { property: 'translator.lastName', desc },
          { property: 'translator.firstName', desc },
        ],
        filterProperty: 'translatorId',
      };

    const translatorLanguage: HeaderConfig = {
        value: 'translatorLanguage.name',
        text: this.$t('list.header.translatorLanguage').toString(),
        filterProperty: 'translatorLanguageId',
      };

    const contractDate: HeaderConfig = {
        value: 'contractDate',
        text: this.$t('list.header.contractDate').toString(),
        filterProperty: ['contractDateFrom', 'contractDateUntil'],
      };

    const beginDateTime: HeaderConfig = {
        value: 'beginDateTime',
        text: this.$t('list.header.beginDateTime').toString(),
        filterProperty: ['beginDateTimeFrom', 'beginDateTimeUntil'],
      };

    const contractComment: HeaderConfig = {
      value: 'contractComment',
      text: this.$t('list.header.contractComment').toString(),
      filterProperty: ['contractComment'],
    };

    const operationContact: HeaderConfig = {
      value: 'operationContact.city',
      text: this.$t('list.header.operationContact').toString(),
      filterProperty: ['operationContactFilter'],
    };

    if (this.isAllowForAdminsAndOfficeWorkers())
    {
      return [contractNo, contractState, businessUnit, customer, translator, translatorLanguage, contractDate, beginDateTime, operationContact, contractComment];
    }
    return [contractNo, contractState, businessUnit, customer, translator, translatorLanguage, contractDate, operationContact];
  }

  /**
   * Creates an invoice or the selected contracts.
   */
  protected async createInvoice(): Promise<void>
  {
      const invoice = await invoiceStore[InvoiceActions.CREATE_ON_SITE_TRANSLATION_CONTRACT]({data: this.selectedItems, module: this.$t('') as string });
  }

  /**
   * Creates Credit for the selected contracts.
   */
  protected async createCredit(): Promise<void>
  {
    const credit = await creditStore[CreditActions.CREATE_ON_SITE_TRANSLATION_CONTRACT](this.selectedItems);
  }

  protected async setNewState(state: ContractState): Promise<void> {
    for (const item of this.selectedItems)
    {
      await store[Actions.SET_STATE]({
        contract: item,
        state,
      });
    }
  }

  private async assignTranslator(item: OnSiteTranslationContractJson, translator: TranslatorJson): Promise<void>
  {
    await store[Actions.ASSIGN_TRANSLATOR]({
      contract: item,
      translator,
    });
  }

  private async confirmTranslator(item: OnSiteTranslationContractJson): Promise<void>
  {
    await store[Actions.SET_STATE]({
      contract: item,
      state: ContractState.TRANSLATOR_CONFIRMED,
    });
  }

  private async fulfillContract(item: OnSiteTranslationContractJson): Promise<void>
  {
    await store[Actions.SET_STATE]({
      contract: item,
      state: ContractState.CONTRACT_FULFILLED,
    });
  }

  private async cancelContract(item: OnSiteTranslationContractJson): Promise<void>
  {
    const msg = await this.$msg(
      this.$t('list.edit.contractState.confirmCancel.title', item).toString(),
      this.$t('list.edit.contractState.confirmCancel.message', item).toString(),
      'no',
      {
        id: 'yes',
        color: 'delete',
        text: this.$t('list.edit.contractState.confirmCancel.yes.label', item).toString(),
      },
    );

    if (msg === 'yes')
    {
      await store[Actions.SET_STATE]({
        contract: item,
        state: ContractState.CANCELED,
      });
    }
  }

  private readonly InvoiceFilter = InvoiceFilter;

  private readonly CreditFilter = CreditFilter;

  protected getEmailTemplateType(): EmailTemplateType
  {
    return EmailTemplateType.CONTRACT_ON_SITE;
  }
}
