import { ContactEmailJson, ContactPhoneNumberJson, ContactJson } from './json';
import i18n from '@/plugins/i18n';

export function getDescription(item: ContactJson, fallbackToId?: boolean): string
{
  const company = item.company;
  const name = item.lastName
    ? item.firstName ? `${item.lastName}, ${item.firstName}` : item.lastName
    : (item.firstName || '');
  const street = item.street;
  const city = item.city
    ? item.zipCode ? `${item.zipCode} ${item.city}` : item.city
    : (item.zipCode || '');

  const address = city
    ? street ? `${street}, ${city}` : city
    : (street || '');

  const email = item.emails && item.emails.length > 0 ? getEmailDescription(item.emails[0]) : '';
  const phoneNumber = item.phoneNumbers && item.phoneNumbers.length > 0 ? getPhoneNumberDescription(item.phoneNumbers[0]) : '';

  const digitalContact = email || phoneNumber;

  if (company)
  {
    if (name)
    {
      if (address)
      {
        return `${company}: ${name}: ${address}`;
      }

      return `${company}: ${name}`;
    }

    if (address)
    {
      return `${company}: ${address}`;
    }

    if (digitalContact)
    {
      return `${company}: ${digitalContact}`;
    }

    return company;
  }

  if (name)
  {
    if (address)
    {
      return `${name}: ${address}`;
    }

    if (digitalContact)
    {
      return `${name}: ${digitalContact}`;
    }

    return name;
  }

  if (address)
  {
    return address;
  }

  if (digitalContact)
  {
    return digitalContact;
  }

  return fallbackToId ? `(ID: ${item.id})` : '';
}

export function getEmailDescription(item: ContactEmailJson, fallbackToId?: boolean): string
{
  if (item.email)
  {
    if (item.emailType)
    {
      return `${item.email} (${i18n.t(`module.contact.enums.EmailType.${item.emailType}`)})`;
    }
    return item.email;
  }

  return fallbackToId ? `(ID: ${item.id})` : '';
}

export function getPhoneNumberDescription(item: ContactPhoneNumberJson, fallbackToId?: boolean): string
{
  if (item.phoneNumber)
  {
    if (item.phoneNumberType)
    {
      return `${item.phoneNumber} (${i18n.t(`module.contact.enums.PhoneNumberType.${item.phoneNumberType}`)})`;
    }
    return item.phoneNumber;
  }

  return fallbackToId ? `(ID: ${item.id})` : '';
}
