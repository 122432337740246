import { RouteConfig } from 'vue-router';
import List from './views/List.vue';
import Form from './views/Form.vue';
import LanguageGroups from './views/LanguageGroups.vue';
import CostConfiguration from './views/CostConfiguration.vue';
import InvoiceContact from './views/InvoiceContact.vue';
import Email from './views/Email.vue';

export default [
  // list
  {
    name: 'customer',
    path: '',
    component: List,
  },
  // edit
  {
    path: ':id(new|\\d+)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // language groups
  {
    path: ':id(\\d+)/languagegroups',
    component: LanguageGroups,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // cost configuration
  {
    path: ':id(\\d+)/costconfiguration',
    component: CostConfiguration,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // invoice contact
  {
    path: ':id(\\d+)/invoicecontact',
    component: InvoiceContact,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // send e-mail
  {
    path: ':id(\\d+)/email/(translator|customer)/:emailTemplateId(\\d+)',
    component: Email,
    meta: {
      hideMenu: true,
      hideTab: true,
      isEmailRoute: true,
    },
  },
] as RouteConfig[];
