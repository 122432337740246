import { RouteConfig } from 'vue-router';

import List from './views/List.vue';
import Form from './views/Form.vue';
import FileAttachmentCollection from './views/FileAttachmentCollection.vue';

export default [
  // list
  {
    name: 'emailtemplate',
    path: '',
    component: List,
  },
  // edit
  {
    path: ':id(new|\\d+)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // bank account collection
  {
    path: ':id(\\d+)/fileattachmentcollection',
    component: FileAttachmentCollection,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
] as RouteConfig[];
