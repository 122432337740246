import { BaseEntity } from '@/base';

export enum EmailType
{
  PRIVATE = 'PRIVATE',
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
}

export enum PhoneNumberType
{
  PRIVATE = 'PRIVATE',
  BUSINESS = 'BUSINESS',
  PRIVATE_MOBILE = 'PRIVATE_MOBILE',
  BUSINESS_MOBILE = 'BUSINESS_MOBILE',
  FAX = 'FAX',
  OTHER = 'OTHER',
}

export interface ContactEmailJson extends BaseEntity
{
  email: string;
  emailType: EmailType;
}

export interface ContactPhoneNumberJson extends BaseEntity
{
  phoneNumber: string;
  phoneNumberType: PhoneNumberType;
}

export interface ContactJson extends BaseEntity
{
  company: string | null;
  firstName: string | null;
  lastName: string | null;
  street: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
  emails: ContactEmailJson[];
  phoneNumbers: ContactPhoneNumberJson[];
}
