import { EntityStore, EntityStoreActions, EntityStoreGetters, EntityStoreMutations } from './entitystore';
import { BaseEntity, NewEntity } from './entity';
import { FormData, asFormData } from './formdata';
import { RawLocation, Route } from 'vue-router';
import Form from './Form';

export abstract class EntityForm<T extends BaseEntity, S extends EntityStore<T>> extends Form<FormData<T>> {
  /**
   * The store to the items.
   */
  protected abstract readonly store: S;

  /**
   * Returns the route back after closing the form.
   */
  protected readonly closeRoute: RawLocation = {
    path: '..',
    append: true,
  };

  /**
   * Returns the currently active item.
   */
  public get activeItem(): EntityStore<T>[EntityStoreGetters.ACTIVE_ITEM] {
    return this.store ? this.store[EntityStoreGetters.ACTIVE_ITEM] : null;
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: FormData<T>): boolean {
    return item.$isDirty();
  }

  /**
   * Changes the active item.
   */
  protected changeActiveItem(item: FormData<T> | null): void {
    const activeItem = this.activeItem;
    this.store[EntityStoreMutations.ACTIVE_ITEM](item);
    if (activeItem && activeItem !== item) {
      activeItem.$revoke();
    }
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: FormData<T>): Promise<void> {
    const result = await this.store[EntityStoreActions.CREATE_OR_UPDATE](item);
    this.onSave(result);
  }

  /**
   * Resets the form.
   */
  protected resetItem(item: FormData<T>): void {
    item.$reset();
  }

  /**
   * Whether the given item is considered new.
   */
  protected isNew(item: FormData<T>): boolean {
    return item.$isNew();
  }

  /**
   * Called after saving changes of the given item.
   */
  protected onSave(item: T): void {
    if (this.$te('form.save.success')) {
      this.$success(this.$t('form.save.success', item).toString());
    } else {
      this.$success(this.$root.$t('form.save.success').toString());
    }

    // replace the url with the id for new items.
    if (this.$route.params.id === 'new') {
      this.$router.replace({
        path: this.$route.fullPath.replace('/new', `/${item.id}`),
        replace: true,
      });
    }
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void {
    this.$router.push(this.closeRoute);
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  protected handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const proceed = (item: T | NewEntity<T>): Promise<void> => {
      this.updateNewItem(item);
      return this.open(() => asFormData<T>(item)).then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    };

    if (to.params.id === 'new') {
      proceed(this.store.createEntity());
    } else {
      this.store[EntityStoreActions.READ_ONE]({ id: parseInt(to.params.id, 10), initial: true })
        .then(item => proceed(item))
        .catch(() => {
          const location = this.closeRoute;
          // if the item does not exist, redirect to list (after refresh, mostly for new items)
          next(
            from.fullPath === '/' && !from.name
              ? typeof location === 'string'
                ? { path: location, replace: true }
                : Object.assign({}, location, { replace: true })
              : { path: from.fullPath, replace: true },
          );
        });
    }
  }

  // tslint:disable-next-line:no-empty
  protected updateNewItem(item: T | NewEntity<T>) {

  }
}

export default EntityForm;
