















import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { getEnumFields } from '@/util';

// Although we should only use string enumerations whose values are the same as keys
// we cannot constrain what is passed to enum-type inside the template.
// So consider all types of enums and return the correct value which may be
// either another string than the key or a number.

@Component({
  inheritAttrs: false,
})
export default class EnumSelect<T extends Enum<T>> extends Vue
{
  /**
   * The (initial) value of the field.
   */
  @Prop({ default: null })
  public readonly value: T[keyof T] | null;

  /**
   * A function that returns the description/text of an enum field.
   */
  @Prop()
  public readonly itemText: (key: keyof T) => string;

  /**
   * The type of the enum.
   */
  @Prop({ required: true })
  public readonly enumType: T;

  /**
   * A function to filter the values.
   */
  @Prop()
  public readonly filter: (key: keyof T) => boolean;

  /**
   * The items of the select box.
   */
  public get items(): ({ key: keyof T, value: T[keyof T] })[]
  {
    if (this.enumType)
    {
      // Wrap it into an object to trigger calls to item-text
      return getEnumFields(this.enumType)
        .filter(key => !this.filter || this.filter(key))
        .map(key => ({ key, value: this.enumType[key] }));
    }

    return [];
  }

  /**
   * The value of the field.
   */
  private model: T[keyof T] | null = this.value;

  /**
   * Returns the description of an item.
   */
  public getDescription<K extends keyof T>(v: { key: K, value: T[K] })
  {
    const { key } = v;
    return this.itemText ? this.itemText(key) : key;
  }

  /**
   * Called when the model property changes.
   */
  @Watch('model')
  @Emit('input')
  private __onModelChange(value: T[keyof T]): void
  {
    // just emit the value to sync the bound model
  }

  /**
   * Called when the value changed from outside.
   */
  @Watch('value')
  private __onValueChange(value: any): void
  {
    // undefined as null
    if (value == null)
    {
      value = null;
    }

    // ignore circular updates
    if (value === this.model)
    {
      return;
    }

    this.model = value;
  }
}

