











































































































































































import { Component } from 'vue-property-decorator';
import { EntityList, HeaderConfig, EntityStoreActions, EntityStoreGetters, ListAction } from '@/base';
import { store, Actions } from '../store';
import { BankAccountTransactionJson, BankAccountTransactionAssignmentState } from '../json';
import { store as businessUnitStore } from '@/modules/businessunit';
import { store as invoiceStore, InvoiceJson } from '@/modules/financialdocument/invoice';
import { store as creditStore, CreditJson } from '@/modules/financialdocument/credit';
import { store as externalDocumentStore, ExternalDocumentJson } from '@/modules/financialdocument/externaldocument';


@Component<List>({
  filters: {
  },
  methods: {
  },
  created()
  {
    this.init();
  },
})
export default class List extends EntityList<BankAccountTransactionJson, typeof store>
{
  private readonly businessUnitStore = businessUnitStore;
  private readonly invoiceStore = invoiceStore;
  private readonly creditStore = creditStore;
  private readonly externalDocumentStore = externalDocumentStore;

  private showUsageDialog = false;

  private contentDialog = '';

  private handleIconClick(subject: string): void {
    this.contentDialog = subject;
  }

  private isAssignmentComplete(value: BankAccountTransactionAssignmentState): boolean
  {
    // tslint:disable-next-line:max-line-length
    return value && (value === BankAccountTransactionAssignmentState.ASSIGNMENT_COMPLETE_AUTOMATIC || value === BankAccountTransactionAssignmentState.ASSIGNMENT_COMPLETE_MANUAL || value === BankAccountTransactionAssignmentState.ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED);
  }

  private isAssignmentIncomplete(value: BankAccountTransactionAssignmentState): boolean
  {
    // tslint:disable-next-line:max-line-length
    return value && (value === BankAccountTransactionAssignmentState.ASSIGNMENT_INCOMPLETE_AUTOMATIC || value === BankAccountTransactionAssignmentState.ASSIGNMENT_INCOMPLETE_MANUAL);
  }

  private isAssignmentNone(value: BankAccountTransactionAssignmentState): boolean
  {
    return value && (value === BankAccountTransactionAssignmentState.NOT_ASSIGNED);
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'transactionDate', desc: true }];

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    businessUnitId: null as number | null,
    btValueFrom: null as number | null,
    btValueTo: null as number | null,
    transactionDateFrom: null as Date | null,
    transactionDateUntil: null as Date | null,
    btUsage: null as string | null,
    bankAccount: null as string | null,
    invoiceId: null as number | null,
    creditId: null as number | null,
    externalDocumentId: null as number | null,
  };

  private canAcceptIncompleteAssigned(state: BankAccountTransactionAssignmentState): boolean {
    return state === BankAccountTransactionAssignmentState.ASSIGNMENT_INCOMPLETE_AUTOMATIC || state === BankAccountTransactionAssignmentState.ASSIGNMENT_INCOMPLETE_MANUAL;
  }


  private async acceptIncompleteAssignment(item: BankAccountTransactionJson): Promise<void> {
    await store[Actions.ACCEPT_INCOMPLETE_ASSIGNMENT]({
        bankAccountTransaction: item,
      });
  }

  protected getActions(): ListAction<BankAccountTransactionJson>[]
  {
    return [
      {
        icon: 'edit',
        handler: item => this.editItem(item),
        text: this.$root.$t('edit.label').toString(),
      },
    ];
  }

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'businessUnit.name',
        text: this.$t('list.header.businessUnit').toString(),
        remoteSort: desc => [{ property: 'businessUnit.id', desc }],
        filterProperty: 'businessUnitId',
      },
      {
        value: 'bankAccount',
        text: this.$t('list.header.bankAccount').toString(),
        filterProperty: ['bankAccount'],
      },
      {
        value: 'btIdentity',
        text: this.$t('list.header.btIdentity').toString(),
        filterProperty: ['btIdentity'],
      },
      {
        value: 'transactionDate',
        text: this.$t('list.header.transactionDate').toString(),
        filterProperty: ['transactionDateFrom', 'transactionDateUntil'],
      },
      {
        value: 'amountOfBalance',
        text: this.$t('list.header.amountOfBalance').toString(),
      },
      {
        value: 'btValue',
        text: this.$t('list.header.btValue').toString(),
        filterProperty: ['btValueFrom', 'btValueTo'],
      },
      {
        value: 'btValueAssigned',
        text: this.$t('list.header.btValueAssigned').toString(),
      },
      {
        value: 'bankAccountTransactionAssignmentState',
        text: this.$t('list.header.bankAccountTransactionAssignmentState').toString(),
        sortable: false,
        filterProperty: ['bankAccountTransactionAssignmentState', 'invoiceId', 'creditId', 'externalDocumentId'],
      },
      {
        value: 'btUsage',
        text: this.$t('list.header.btUsage').toString(),
        sortable: false,
        filterProperty: ['btUsage'],
      },
    ];
  }

  private getFullNameCredit(item: CreditJson): string
  {
    return item ? item.id + ' - ' + item.creditReference : '';
  }

  private getFullNameInvoice(item: InvoiceJson): string
  {
    return item ? item.id + ' - ' + item.invoiceReference : '';
  }

  private getFullNameExternalDocument(item: ExternalDocumentJson): string
  {
    return item ? item.id + ' - ' + item.documentReference : '';
  }

  private readonly BankAccountTransactionAssignmentState = BankAccountTransactionAssignmentState;
}
