// import globally registered components here.

import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import Field from './Field.vue';
import DateField from './DateField.vue';
import DateTimeField from './DateTimeField.vue';
import EnumSelect from './EnumSelect.vue';
import EntitySelect from './EntitySelect.vue';
import { EntitySelectItemComparator } from './EntitySelectItemComparator';
import { EntitySelectCustomFilter } from './EntitySelectCustomFilter';
import DecimalField from './DecimalField.vue';
import CurrencyField from './CurrencyField.vue';
import FormItemGroup from './FormItemGroup.vue';
import QuillField from './QuillField';

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('field', Field);
Vue.component('date-field', DateField);
Vue.component('datetime-field', DateTimeField);
Vue.component('enum-select', EnumSelect);
Vue.component('entity-select', EntitySelect);
Vue.component('form-item-group', FormItemGroup);

Vue.component('decimal-field', DecimalField);
Vue.component('currency-field', CurrencyField);

Vue.component('quill-field', QuillField);
