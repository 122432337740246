/**
 * Returns the specified date in the YYYY-MM-DD notation.
 * This is needed for several vuetify components that handle dates like this (datepicker, calendar, ...).
 */
export function getDateString(date: Date): string
{
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

/**
 * Checks whether the given date only contains date information without time.
 *
 * That is, the time is 00:00:00GMT, because that's what the server does with date values.
 */
export function isDateOnly(date: Date): boolean
{
  return date.getUTCHours() === 0 && date.getUTCMinutes() === 0 && date.getUTCSeconds() === 0 && date.getUTCMilliseconds() === 0;
}

/**
 * The first day of week. 0 = Sunday, 1 = Monday, ... 6 = Saturday.
 */
export const firstDayOfWeek = parseInt(process.env.VUE_APP_FIRST_DAY_OF_WEEK || '0', 10) % 7;
