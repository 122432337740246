




import { Component } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { Form as FormBase, EntityStoreActions, EntityStoreGetters, EntityStoreMutations, createEntity, NewEntity, asFormData, FormData } from '@/base';
import { DocumentTranslationContractJson } from '../json';
import { EmailJson } from '@/modules/email';
import { EmailTemplateType } from '@/modules/emailtemplate';
import { FileEntityCollectionJson, FileEntityJson } from '@/modules/file';
import store, { Actions } from '../store';
import { store as EmailStore, Actions as EmailAction } from '@/modules/email';
import { getEnumByName } from '@/util';
import EmailBase from '../../../email/views/EmailBase.vue';
import { EmailActiveItem } from '../../../email/views/EmailActiveItem';

@Component<Email>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
})
export default class Email extends EmailBase<DocumentTranslationContractJson> {
  /**
   * Returns file attachment collection of the active item.
   */
  public get formData(): FormData<FileEntityCollectionJson> | null {
    return this.activeItem ? asFormData(this.activeItem.email.fileAttachmentCollection) : null;
  }

  /**
   * Changes the active item.
   */
  protected changeActiveItem(item: EmailActiveItem<DocumentTranslationContractJson> | null): void {
    const activeContract = store[EntityStoreGetters.ACTIVE_ITEM];
    const activeItem = this.activeItem;

    // set the contract active
    store[EntityStoreMutations.ACTIVE_ITEM](item ? item.entity : null);

    if (item) {
      // Keep the original values in prototype.
      item = {
        entity: item.entity,
        email: item.email,
      };
    }

    this.activeItem = item;

    if (activeContract && (!item || item.entity !== activeContract.$raw)) {
      activeContract.$revoke();
    }
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void {
    this.$router.push({ name: 'documenttranslation' });
  }

  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void): void {
    const contractId = parseInt(to.params.id, 10);
    const emailTemplateId = parseInt(to.params.emailTemplateId, 10);

    const isInvalidUrl = isNaN(contractId) || isNaN(emailTemplateId);
    const isTranslator = to.path.includes('translator');
    const promise = isInvalidUrl
      ? Promise.reject()
      : Promise.all([
          store[EntityStoreActions.READ_ONE]({ id: contractId, initial: true }),
          store[Actions.READ_EMAIL]({ contract: contractId, emailTemplate: emailTemplateId, isTranslator }),
        ]);

    promise
      .then(([contract, email]) => this.open(() => ({ entity: contract, email })))
      .then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }))
      .catch(() => next(from.fullPath === '/' && !from.name ? { name: 'documenttranslation', replace: true } : { path: from.fullPath, replace: true }));
  }
}
