import { BaseEntity } from '@/base';
import { BusinessUnitJson } from '../businessunit';

export enum EmailTemplateType {
  CONTRACT_DOCUMENT = 'CONTRACT_DOCUMENT',
  CONTRACT_ON_SITE = 'CONTRACT_ON_SITE',
  CONTRACT_VIDEO = 'CONTRACT_VIDEO',
  TRANSLATOR = 'TRANSLATOR',
  CUSTOMER = 'CUSTOMER',
  INVOICE = 'INVOICE',
  CREDIT = 'CREDIT',
}

export enum EmailTemplatePlaceholder {
  ADDRESS_NAME = 'ADDRESS_NAME',
  ADDRESS = 'ADDRESS',
  BUSINESS_UNIT_NAME = 'BUSINESS_UNIT_NAME',
  COMMENT = 'COMMENT',
  COMPLETE_UNTIL = 'COMPLETE_UNTIL',
  CONTRACT_NO = 'CONTRACT_NO',
  COST_CENTER_NAME = 'COST_CENTER_NAME',
  CREDIT_ACCOUNTING_PERIOD_FROM = 'CREDIT_ACCOUNTING_PERIOD_FROM',
  CREDIT_ACCOUNTING_PERIOD_UNTIL = 'CREDIT_ACCOUNTING_PERIOD_UNTIL',
  CREDIT_BANK_ACCOUNT_HOLDER = 'CREDIT_BANK_ACCOUNT_HOLDER',
  CREDIT_BANK_NAME = 'CREDIT_BANK_NAME',
  CREDIT_BIC = 'CREDIT_BIC',
  CREDIT_DATE = 'CREDIT_DATE',
  CREDIT_DOCUMENT_VALUE_NET = 'CREDIT_DOCUMENT_VALUE_NET',
  CREDIT_IBAN = 'CREDIT_IBAN',
  CREDIT_NO = 'CREDIT_NO',
  CREDIT_REFERENCE = 'CREDIT_REFERENCE',
  CUSTOMER_EMAIL = 'CUSTOMER_EMAIL',
  CUSTOMER_FIRSTNAME = 'CUSTOMER_FIRSTNAME',
  CUSTOMER_GROUP = 'CUSTOMER_GROUP',
  CUSTOMER_LASTNAME = 'CUSTOMER_LASTNAME',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  CUSTOMER_TAXID = 'CUSTOMER_TAXID',
  CUSTOMER_TRAVEL_OPTION = 'CUSTOMER_TRAVEL_OPTION',
  DATE = 'DATE',
  INVOICE_DATE = 'INVOICE_DATE',
  INVOICE_DOCUMENT_VALUE_GROSS = 'INVOICE_DOCUMENT_VALUE_GROSS',
  INVOICE_DOCUMENT_VALUE_NET = 'INVOICE_DOCUMENT_VALUE_NET',
  INVOICE_EXPLANATION = 'INVOICE_EXPLANATION',
  INVOICE_LANGUAGE = 'INVOICE_LANGUAGE',
  INVOICE_NO = 'INVOICE_NO',
  INVOICE_NOTICE = 'INVOICE_NOTICE',
  INVOICE_OPERATION_LOCATION = 'INVOICE_OPERATION_LOCATION',
  INVOICE_OPERATION_TIME = 'INVOICE_OPERATION_TIME',
  INVOICE_REFERENCE = 'INVOICE_REFERENCE',
  INVOICE_STATE = 'INVOICE_STATE',
  LINES_OF_ORIGINAL_DOCUMENT = 'LINES_OF_ORIGINAL_DOCUMENT',
  LINES_OF_TRANSLATED_DOCUMENT = 'LINES_OF_TRANSLATED_DOCUMENT',
  PLANNED_TRANSLATION_DURATION = 'PLANNED_TRANSLATION_DURATION',
  REAL_TRANSLATION_DURATION = 'REAL_TRANSLATION_DURATION',
  RECEIVER_CONTACT_CITY = 'RECEIVER_CONTACT_CITY',
  RECEIVER_CONTACT_COMPANY = 'RECEIVER_CONTACT_COMPANY',
  RECEIVER_CONTACT_COUNTRY = 'RECEIVER_CONTACT_COUNTRY',
  RECEIVER_CONTACT_FIRST_NAME = 'RECEIVER_CONTACT_FIRST_NAME',
  RECEIVER_CONTACT_LAST_NAME = 'RECEIVER_CONTACT_LAST_NAME',
  RECEIVER_CONTACT_STREET = 'RECEIVER_CONTACT_STREET',
  RECEIVER_CONTACT_ZIPCODE = 'RECEIVER_CONTACT_ZIPCODE',
  SENDER_CONTACT_CITY = 'SENDER_CONTACT_CITY',
  SENDER_CONTACT_COMPANY = 'SENDER_CONTACT_COMPANY',
  SENDER_CONTACT_COUNTRY = 'SENDER_CONTACT_COUNTRY',
  SENDER_CONTACT_FIRST_NAME = 'SENDER_CONTACT_FIRST_NAME',
  SENDER_CONTACT_LAST_NAME = 'SENDER_CONTACT_LAST_NAME',
  SENDER_CONTACT_STREET = 'SENDER_CONTACT_STREET',
  SENDER_CONTACT_ZIPCODE = 'SENDER_CONTACT_ZIPCODE',
  TRANSLATOR_ADDRESS_NAME = 'TRANSLATOR_ADDRESS_NAME',
  TRANSLATOR_ADDRESS = 'TRANSLATOR_ADDRESS',
  TRANSLATOR_BANKACCOUNTHOLDER = 'TRANSLATOR_BANKACCOUNTHOLDER',
  TRANSLATOR_BANKNAME = 'TRANSLATOR_BANKNAME',
  TRANSLATOR_BIC = 'TRANSLATOR_BIC',
  TRANSLATOR_EMAIL = 'TRANSLATOR_EMAIL',
  TRANSLATOR_FIRSTNAME = 'TRANSLATOR_FIRSTNAME',
  TRANSLATOR_IBAN = 'TRANSLATOR_IBAN',
  TRANSLATOR_LASTNAME = 'TRANSLATOR_LASTNAME',
  TRANSLATOR_NAME = 'TRANSLATOR_NAME',
  TRANSLATOR_TAXID = 'TRANSLATOR_TAXID',
  TRANSLATOR_TRANSLATORCOMMENT = 'TRANSLATOR_TRANSLATORCOMMENT',
  TRANSLATOR_TRAVEL_OPTION = 'TRANSLATOR_TRAVEL_OPTION',
  TRAVEL_DISTANCE = 'TRAVEL_DISTANCE',
  TRAVEL_TICKET_COST = 'TRAVEL_TICKET_COST',
  TRAVEL_TIME_BACK = 'TRAVEL_TIME_BACK',
  TRAVEL_TIME_TO_LOCATION = 'TRAVEL_TIME_TO_LOCATION',
}

export interface EmailTemplateJson extends BaseEntity {
  name: string;
  emailTemplateType: EmailTemplateType;
  businessUnit: BusinessUnitJson | null;
  subject: string;
  body: string;
}
