




















































































































































import { Component, Prop } from 'vue-property-decorator';
import Sortable from 'sortablejs';
import { List as ListBase, ListAction, HeaderConfig, createEntity, NewEntity } from '@/base';
import store, { Actions } from '../store';
import { CreditPositionJson } from '../json';
import { Vat } from '../../invoice';

@Component<PositionList>({
  props: {
    items: {
      required: true,
    },
  },
  mounted()
  {
    this.sortable = new Sortable(this.$el.querySelector('tbody')!, {
      draggable: 'tr:not(.add-row)',
      handle: '.drag-handle:not(.v-icon--disabled)',
      onEnd: e => this.moveItem(e.oldIndex!, e.newIndex!),
    });
  },
  destroyed()
  {
    if (this.sortable)
    {
      this.sortable.destroy();
    }
  },
})
export default class PositionList extends ListBase<NewEntity<CreditPositionJson>>
{
  /**
   * Returns the items of the list.
   */
  // @Prop({ required: true }) // Has a bug on inheritance and sets required to true for ListBase. So use above method.
  public readonly items: NewEntity<CreditPositionJson>[];

  @Prop({ required: false })
  public isFormDisabled: false;

  @Prop({ required: false })
  public isDirty: true;

  public showVatRate(): boolean
  {
    return !this.isDirty;
  }

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<NewEntity<CreditPositionJson>>[]
  {
    return [
      {
        icon: 'delete',
        isDisabled: item => this.isFormDisabled,
        handler: item => this.deleteItem(item),
        text: this.$root.$t('delete.label').toString(),
        color: 'delete',
      },
    ];
  }

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'draggable',
        text: '',
        sortable: false,
        width: 56,
      },
      {
        value: 'operationReference',
        text: this.getLabel('header.operationReference'),
        sortable: false,
      },
      {
        value: 'operationDate',
        text: this.getLabel('header.operationDate'),
        sortable: false,
      },
      {
        value: 'operationDescription',
        text: this.getLabel('header.operationDescription'),
        sortable: false,
      },
      {
        value: 'count',
        text: this.getLabel('header.count'),
        sortable: false,
        align: 'end',
      },
      {
        value: 'countUnit',
        text: this.getLabel('header.countUnit'),
        sortable: false,
        align: 'end',
      },
      {
        value: 'unitPriceNet',
        text: this.getLabel('header.unitPriceNet'),
        sortable: false,
        align: 'end',
      },
      {
        value: 'unitPriceNetUnit',
        text: this.getLabel('header.unitPriceNetUnit'),
        sortable: false,
        align: 'end',
      },
      {
        value: 'totalPriceNet',
        text: this.getLabel('header.totalPriceNet'),
        sortable: false,
        align: 'end',
      },
      {
        value: 'vat',
        text: this.getLabel('header.vat'),
        sortable: false,
        align: 'end',
      },
            {
        value: 'vatRate',
        text: this.getLabel('header.vatRate'),
        sortable: false,
        align: 'end',
      },
    ];
  }


  /**
   * Used as model for the new item dialog.
   */
  private newDescription: string = '';

  private countOrUnitPriceChanged(item: CreditPositionJson): void {
    item.totalPriceNet = item.count * item.unitPriceNet;
  }

  private totalPriceChanged(item: CreditPositionJson): void {
    item.count = 1;
    item.unitPriceNet = item.totalPriceNet;
  }


  /**
   * Adds a new item if the newDescription model has a value.
   */
  private addItemIf(): void
  {
    let newOperationDate: Date | null = new Date();
    if (this.items && this.items.length > 1)
    {
      newOperationDate = this.items[this.items.length - 1].operationDate;
    }
    if (this.newDescription)
    {
      this.items.push(createEntity({
        operationReference: this.newDescription,
        operationDate: newOperationDate,
        operationDescription: '',
        count: 0,
        countUnit: '',
        unitPriceNet: 0,
        unitPriceNetUnit: '€',
        totalPriceNet: 0,
        vat: Vat.FULL,
      }));
      this.newDescription = '';
    }
  }

  private deleteItem(item: NewEntity<CreditPositionJson>): void
  {
    this.$_.pull(this.items, item);
  }

  /**
   * Called at the end of dragging and moves the element from the old index to the new index.
   */
  private moveItem(oldIndex: number, newIndex: number): void
  {
    if (oldIndex !== newIndex && oldIndex >= 0 && oldIndex < this.items.length && newIndex >= 0 && newIndex < this.items.length)
    {
      this.items.splice(newIndex, 0, ...this.items.splice(oldIndex, 1));
    }
  }

  private moveItemUp(item: NewEntity<CreditPositionJson>): void
  {
    const index = this.items.indexOf(item);
    this.moveItem(index, index - 1);
  }

  private moveItemDown(item: NewEntity<CreditPositionJson>): void
  {
    const index = this.items.indexOf(item);
    this.moveItem(index, index + 1);
  }

  private getLabel(key: string): string
  {
    key = `positionList.${key}`;

    if (this.$te(key))
    {
      return this.$t(key).toString();
    }
    return this.$root.$t(`module.credit.${key}`).toString();
  }

  private sortable: Sortable;

   private readonly Vat = Vat;
}
