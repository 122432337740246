






































import { Component, Vue } from 'vue-property-decorator';
import { CashBookJson } from '../json';
import { store as businessUnitStore } from '@/modules/businessunit';
import { EntityForm as FormBase, asFormData, NewEntityProperties, FormData } from '@/base';
import { store, Actions } from '../store';
import { Route, RawLocation } from 'vue-router';
import CashBook from '../CashBook.vue';
import { UserPermission } from '@/modules/user';


@Component<DownloadCashbookForm>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void
  {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class DownloadCashbookForm extends FormBase<CashBookJson, typeof store>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_READ);
  }

  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'cashbook',
  };

  private readonly businessUnitStore = businessUnitStore;

  protected handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void): void {
    const item = this.createItem();

    this.open(() => asFormData(item));
  }

  private createItem(): CashBookJson
  {
    return {
      id: 0,
      fromDate: null,
      toDate: null,
      businessUnit: null,
      createDate: new Date(),
      modifyDate: new Date(),
    };
  }

  protected async saveItem(cashBookJson: FormData<CashBookJson>): Promise<void> {
    const result = await this.store[Actions.CREATE_CASHBOOK]({cashBookJson});
  }

  public async handleUnsavedChanges(): Promise<boolean> {
    return true;
  }
}
