import _ from 'lodash';
import { TranslatorJson } from './json';
import { UserType, injectResetMutation } from '../user';
import { EntityPagingStore, EntityPagingStoreState, createEntityPagingStore, FormData, NewEntityProperties, EntityId, getId } from '@/base';
import api from '@/plugins/api';
import { TranslatorCostConfigurationJson } from '../costconfiguration/translator';
import { ContactJson } from '../contact';
import { CancelToken } from 'axios';
import { EmailTemplateJson } from '../emailtemplate';
import { EmailJson } from '../email';

export interface State extends EntityPagingStoreState<TranslatorJson> {
  /**
   * The list of cost configurations per translator id.
   */
  costConfigurations: _.NumericDictionary<TranslatorCostConfigurationJson>;
  /**
   * The list of credit contacts per translator id.
   */
  creditContacts: _.NumericDictionary<ContactJson>;
}

export enum Actions {
  READ_COST_CONFIGURATION = 'dispatchReadCostConfiguration',
  SAVE_COST_CONFIGURATION = 'dispatchSaveCostConfiguration',
  READ_CREDIT_CONTACT = 'dispatchReadCreditContact',
  SAVE_CREDIT_CONTACT = 'dispatchSaveCreditContact',
  SEND_ACTIVATION_EMAIL = 'dispatchSendActivationEmail',
  READ_EMAIL = 'dispatchReadEmail',
}

export enum Mutations {
  COST_CONFIGURATION = 'commitCostConfiguration',
  CREDIT_CONTACT = 'commitCreditContact',
}

export interface Store extends EntityPagingStore<TranslatorJson> {
  [Actions.READ_COST_CONFIGURATION](payload: EntityId<TranslatorJson> | { translator: EntityId<TranslatorJson>}): Promise<TranslatorCostConfigurationJson>;
  [Actions.SAVE_COST_CONFIGURATION](payload: {
    translator: EntityId<TranslatorJson>;
    costConfiguration: FormData<TranslatorCostConfigurationJson>;
  }): Promise<TranslatorCostConfigurationJson>;
  [Actions.READ_CREDIT_CONTACT](payload: EntityId<TranslatorJson> | { translator: EntityId<TranslatorJson>}): Promise<ContactJson>;
  [Actions.SAVE_CREDIT_CONTACT](payload: { translator: EntityId<TranslatorJson>; contact: FormData<ContactJson> }): Promise<ContactJson>;
  [Actions.SEND_ACTIVATION_EMAIL](translator: FormData<TranslatorJson>): Promise<TranslatorJson>;

  [Actions.READ_EMAIL](payload: { translator: EntityId<TranslatorJson>; emailTemplate: EntityId<EmailTemplateJson>; isTranslator: boolean }): Promise<EmailJson>;
}

const apiBaseUrl = 'translators';

function createItem(): NewEntityProperties<TranslatorJson> {
  return {
    userType: UserType.TRANSLATOR,
    lastName: '',
    firstName: '',
    email: '',
    taxId: '',
    vatExempt: false,
    translatorComment: '',
    isWebDavEnabled: true,
    mainPhoneNumber: '',
    costCenter: null,
    isLocked: false,
    languages: [],
    bankAccountHolder: '',
    bankName: '',
    IBAN: '',
    BIC: '',
    userPermissions: [],
    offeredServices: [],
  };
}

export const store = createEntityPagingStore<TranslatorJson, State, Store>(
  'translator',
  apiBaseUrl,
  createItem,
  {
    costConfigurations: {},
    creditContacts: {},
  },
  moduleBuilder => {
    injectResetMutation(moduleBuilder);

    const setCostConfiguration = moduleBuilder.commit(
      (state, { translator, costConfiguration }: { translator: EntityId<TranslatorJson>; costConfiguration: TranslatorCostConfigurationJson }) => {
        state.costConfigurations[getId(translator)] = costConfiguration;
      },
      Mutations.COST_CONFIGURATION,
    );

    const setCreditContact = moduleBuilder.commit((state, { translator, contact }: { translator: EntityId<TranslatorJson>; contact: ContactJson }) => {
      state.creditContacts[getId(translator)] = contact;
    }, Mutations.CREDIT_CONTACT);

    return {
      [Actions.READ_COST_CONFIGURATION]: moduleBuilder.dispatch(
        async ({ state }, payload: EntityId<TranslatorJson> | { translator: EntityId<TranslatorJson> }) => {
          if (typeof payload === 'number' || !('translator' in payload)) {
            payload = {
              translator: payload,
            };
          }

          const { translator } = payload;
          const translatorId = getId(translator);

          const costConfiguration = await api.get<TranslatorCostConfigurationJson>(`${apiBaseUrl}/${translatorId}/costconfiguration`);
          setCostConfiguration({ translator, costConfiguration });

          return state.costConfigurations[translatorId];
        },
        Actions.READ_COST_CONFIGURATION,
      ),

      [Actions.SAVE_COST_CONFIGURATION]: moduleBuilder.dispatch(
        async (ctx, { translator, costConfiguration }: { translator: EntityId<TranslatorJson>; costConfiguration: FormData<TranslatorCostConfigurationJson> }) => {
          const translatorId = getId(translator);
          const serverResult = await api.put<TranslatorCostConfigurationJson>(`${apiBaseUrl}/${translatorId}/costconfiguration`, costConfiguration);
          const committed = costConfiguration.$commit(serverResult);
          setCostConfiguration({ translator, costConfiguration: committed.$raw });
          return committed;
        },
        Actions.SAVE_COST_CONFIGURATION,
      ),

      [Actions.READ_CREDIT_CONTACT]: moduleBuilder.dispatch(async ({ state }, payload: EntityId<TranslatorJson> | { translator: EntityId<TranslatorJson> }) => {
        if (typeof payload === 'number' || !('translator' in payload)) {
          payload = {
            translator: payload,
          };
        }

        const { translator } = payload;
        const translatorId = getId(translator);

        const contact = await api.get<ContactJson>(`${apiBaseUrl}/${translatorId}/creditcontact`);
        setCreditContact({ translator, contact });

        return state.creditContacts[translatorId];
      }, Actions.READ_CREDIT_CONTACT),

      [Actions.SAVE_CREDIT_CONTACT]: moduleBuilder.dispatch(async (ctx, { translator, contact }: { translator: EntityId<TranslatorJson>; contact: FormData<ContactJson> }) => {
        const translatorId = getId(translator);
        const serverResult = await api.put<ContactJson>(`${apiBaseUrl}/${translatorId}/creditcontact`, contact);
        const committed = contact.$commit(serverResult);
        setCreditContact({ translator, contact: committed.$raw });
        return committed;
      }, Actions.SAVE_CREDIT_CONTACT),

      [Actions.SEND_ACTIVATION_EMAIL]: moduleBuilder.dispatch(async ({ state }, translator: FormData<TranslatorJson>) => {
        const result = await api.post<TranslatorJson>(`${apiBaseUrl}/activationemail`, translator.$raw);
        return translator.$commit(result);
      }, Actions.SEND_ACTIVATION_EMAIL),
      [Actions.READ_EMAIL]: moduleBuilder.dispatch((ctx, { translator, emailTemplate, isTranslator }: Parameters<Store[Actions.READ_EMAIL]>[0]) => {
        const emailPath = isTranslator ? 'email/translator' : 'email/customer';
        return api.get<EmailJson>(`${apiBaseUrl}/${getId(translator)}/${emailPath}/${getId(emailTemplate)}`);
      }, Actions.READ_EMAIL),
    };
  },
);

export default store;
