import { EntitySelectItemComparator } from '@/components/EntitySelectItemComparator';
import { LanguageJson } from '.';

export function getLanguageItemComparator(): LanguageItemComparator {
    return new LanguageItemComparator();
}

class LanguageItemComparator implements EntitySelectItemComparator<LanguageJson> {
    public sort(item1: LanguageJson, item2: LanguageJson): number {
      return item1.name.localeCompare(item2.name);
    }
}
