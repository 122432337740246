import { RawLocation } from 'vue-router';
import { ValidationObserver } from 'vee-validate';
import { EntityStoreGetters, EntityStoreMutations, EntityStoreActions, EntityStore, BaseEntity } from '@/base';
import { List, ListAction, PaginationInfo } from './List';
import { isPagingStore } from './entitystore';
import { EntityPagingStoreActions, EntityPagingStoreGetters } from './entitypagingstore';

export interface HeaderConfig extends VueDataTableHeaderConfig
{
  /**
   * If sorted with remote pagination, return sort infos if different from 'value'.
   */
  remoteSort?: (desc: boolean) => SortInfo[];
  /**
   * The property of 'filters' that indicates that the column is filtered.
   */
  filterProperty?: string | string[];
}

export abstract class EntityList<T extends BaseEntity, S extends EntityStore<T>> extends List<T>
{
  /**
   * The store to the items.
   */
  protected abstract readonly store: S;

  // initial sort state
  public sortState = [{ property: 'id' }];
  public readonly mustSort = true;

  /**
   * The filter state. Contains the filter parameters or is null if filtering is not supported.
   */
  protected readonly filters: _.Dictionary<string | number | boolean | Date | null> | null = null;

  /**
   * Checks if filtering is possible.
   */
  private get canFilter(): boolean
  {
    return this.filters != null;
  }

  /**
   * Checks whether the given property ('value' option of a header) is filtered.
   */
  protected isFiltered(property: string): boolean
  {
    if (this.currentFilters != null)
    {
      const prop = this.currentFilters[property];
      return prop != null && prop !== '';
    }
    return false;
  }

  protected isAddButtonAvailable(): boolean {
    return this.hasWritePermission();
  }

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<T>[]
  {
    if (this.hasWritePermission())
    {
      return [this.getActionEdit(), this.getActionDelete()];
    }
    return [this.getActionEdit()];
  }

  protected getActionEdit(): ListAction<T>
  {
    return {
      icon: 'edit',
      handler: item => this.editItem(item),
      isVisible: item => item.id > 0,
      text: this.$root.$t('edit.label').toString(),
    };
  }

  protected getActionDelete(): ListAction<T>
  {
    return {
      icon: 'delete',
      handler: item => this.deleteItem(item),
      isVisible: item => item.id > 0,
      text: this.$root.$t('delete.label').toString(),
      color: 'delete',
    };
  }

  /**
   * Returns the message for the delete confirmation dialog for the given item.
   */
  protected getDeleteConfirmationMessage(item: T): { title?: string; message: string }
  {
    return {
      title: this.$t('list.delete.title', item).toString(),
      message: this.$t('list.delete.message', item).toString(),
    };
  }

  /**
   * Returns the route to the editing form for the given item or a new item.
   */
  protected toForm(item?: T): RawLocation
  {
    return {
      path: item ? `${item.id}` : 'new',
      append: true,
    };
  }

  /**
   * Called after deleting the given item.
   */
  protected onDelete(item: T): void
  {
    this.$success(this.$root.$t('list.delete.success').toString());
  }

  /**
   * The headers of the table.
   */
  protected getHeaders(): VueDataTableHeaderConfig[]
  {
    const idColumn = this.getIdColumn();
    const columns = super.getHeaders();
    const hasActionColumn = columns.length > 0 && columns[0].value === 'actions';

    if (hasActionColumn)
    {
      return [columns[0]].concat(idColumn ? [idColumn] : []).concat(this.$_.tail(columns));
    }

    return (idColumn ? [idColumn] : []).concat(columns);
  }

  /**
   * Returns the items of the list.
   */
  public get items(): T[]
  {
    if (isPagingStore(this.store))
    {
      return this.store[EntityPagingStoreGetters.PAGE].content;
    }
    return this.store[EntityStoreGetters.ITEMS];
  }

  /**
   * Whether the store uses remote paging and sorting.
   */
  protected get paginationInfo(): PaginationInfo
  {
    if (isPagingStore(this.store))
    {
      return {
        hasPagination: true,
        totalItems: this.store[EntityPagingStoreGetters.PAGE].totalElements,
      };
    }
    else
    {
      return {
        hasPagination: false,
        totalItems: -1,
      };
    }
  }

  /**
   * Filters the list.
   */
  protected async filter(): Promise<void>
  {
    const isValid = await (this.$refs.filterValidator as InstanceType<typeof ValidationObserver>).validate();
    if (isValid)
    {
      this.isFilterExpanded = null;
      this.__loadData(false, true);
    }
  }

  /**
   * Loads the items from the server.
   */
  public refresh(): Promise<void>
  {
    return this.__loadData();
  }

  /**
   * Initializes the data of the list.
   */
  public init(): void
  {
    if (this.itemsPerPage < 0 && isPagingStore(this.store))
    {
      this.itemsPerPage = 10;
    }

    this.__loadData(true)
      .then(() =>
        {
          if (isPagingStore(this.store))
          {
            this.$watch('internalOptions', () => this.__loadData());

            this.$store.subscribeAction({
              after: action =>
              {
                if (action.type === `${this.store.namespace}/${EntityStoreActions.CREATE_OR_UPDATE}` || action.type === `${this.store.namespace}/${EntityStoreActions.DELETE}`)
                {
                  this.selectedItems = [];
                  this.__loadData(true);
                }
              },
            });
          }
        });
  }

  /**
   * Call when the component is activated.
   */
  protected activate(): void
  {
    this.__loadData(true);
  }

  /**
   * Adds a new item to the list and starts the editing of the item.
   */
  public addItem(): void
  {
    this.$router.push(this.toForm());
  }

  /**
   * Starts the editing of the given item.
   */
  public editItem(item: T): void
  {
    // force read from server
    this.store[EntityStoreActions.READ_ONE](item.id)
      .then(res => this.$router.push(this.toForm(res)));
  }

  /**
   * Deletes the given item.
   * @param item The item to delete.
   * @param force True to skip confirmation.
   */
  public async deleteItem(item: T, force?: boolean): Promise<void>
  {
    if (force)
    {
      await this.store[EntityStoreActions.DELETE](item);

      // remove active item if it is the currently deleted
      const activeItem = this.store[EntityStoreGetters.ACTIVE_ITEM];
      if (activeItem && activeItem.id === item.id)
      {
        this.store[EntityStoreMutations.ACTIVE_ITEM](null);
      }

      this.onDelete(item);
    }
    else
    {
      const { title, message } = this.getDeleteConfirmationMessage(item);
      const result = await this.$msg(
        title || this.$root.$t('list.delete.title').toString(),
        message,
        'cancel',
        {
          id: 'ok',
          text: this.$root.$t('delete.label').toString(),
          color: 'delete',
        },
      );

      if (result === 'ok')
      {
        await this.deleteItem(item, true);
      }
    }
  }

  /**
   * Returns the id column config. Return null to exclude the id column.
   */
  protected getIdColumn(): VueDataTableHeaderConfig | null
  {
    return {
      value: 'id',
      text: this.$root.$t('list.header.id').toString(),
      width: 80,
    };
  }

  /**
   * Loads the data.
   */
  private __loadData(initial?: boolean, forceFilter?: boolean): Promise<void>
  {
    this.loading = true;

    let promise: Promise<any>;

    if (isPagingStore(this.store))
    {
      const { sortState, page, itemsPerPage } = this;
      const headers = this.$_.keyBy(this.headers as HeaderConfig[], h => h.value);

      const filter = forceFilter ? this.$_.clone(this.filters) : this.currentFilters;

      promise = this.store[EntityPagingStoreActions.READ_PAGE]({
        page,
        pageSize: itemsPerPage,
        sort: (sortState as SortInfo[]).reduce((result, { property, desc }) =>
          {
            const h = headers[property];

            if (h && h.remoteSort)
            {
              result.push(...h.remoteSort(!!desc));
            }
            else
            {
              result.push({ property, desc });
            }

            return result;
          }, [] as SortInfo[]),
          filter: filter || undefined,
          initial,
      })
        .then(() => this.currentFilters = filter);
    }
    else
    {
      promise = this.store[EntityStoreActions.READ_ALL](initial);
    }

    return promise.finally(() => this.loading = false);
  }

  /**
   * A clone of the filters of the current page.
   */
  protected currentFilters: _.Dictionary<string | number | boolean | Date | null> | null = null;

  /**
   * The expanded state of the filter panel.
   */
  private isFilterExpanded: number | null = null;
}

export default EntityList;
