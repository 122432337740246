











import axios from 'axios';
import { Component, Vue, Prop } from 'vue-property-decorator';
import api, { IAxiosError } from '../plugins/api';
import { store, Getters, Alert, Mutations } from '../store';

@Component<Alerts>({
  created()
  {
    this.init();
  },
  destroyed()
  {
    this.teardown();
  },
})
export default class Alerts extends Vue
{
  private getMsg(alert: Alert): string {
      return alert.module ? alert.module + ': ' + alert.text : alert.text;
  }

  public get alerts(): typeof store[Getters.ALERTS]
  {
    return store[Getters.ALERTS];
  }

  private init(): void
  {
    this.__requestInterceptor = api.interceptors.request
    .use(r => (this.__resetAjaxError(true), r), err => this.__handleError(err));
    this.__responseInterceptor = api.interceptors.response.use(undefined, err => this.__handleError(err));
  }

  private teardown(): void
  {
    if (this.__requestInterceptor)
    {
      api.interceptors.request.eject(this.__requestInterceptor);
    }
    if (this.__responseInterceptor)
    {
      api.interceptors.response.eject(this.__responseInterceptor);
    }

    this.__resetAjaxError();
  }

  private __handleError(error: IAxiosError): any
  {
    if (axios.isCancel(error))
    {
      return;
    }

    if (!error.config || error.config.$isRaw !== true)
    {
      let msg: string;
      if (error.response && error.response.data && typeof error.response.data.message === 'string')
      {
        const message: string = error.response.data.message;
        let args: any[] = error.response.data.args;
        const key = `error.server.${message}`;

        // Translate also the arguments if possible
        if (args) {
          args = args.map(arg => {
            const argPath = `error.server.${arg}`;
            const argMappingPath = `error.server.MAPPING.${arg}`;
            if (this.$root.$te(argMappingPath)) {
              const newPath = this.$root.$t(argMappingPath).toString();
              return this.$root.$t(newPath).toString();
            } else if (this.$te(argPath)) {
              return this.$t(argPath).toString();
            } else if (this.$root.$te(argPath)) {
              return this.$root.$t(argPath).toString();
            }
            return arg;
          });
        }
        if (this.$te(key))
        {
          msg = this.$t(key, args).toString();
        }
        else if (this.$root.$te(key))
        {
          msg = this.$root.$t(key, args).toString();
        }
        else
        {
          msg = message;
        }
      }
      else
      {
        msg = error.message;
      }

      this.__resetAjaxError();
      const module = error.config.params && error.config.params.module ? error.config.params.module : null;
      this.ajaxErrorAlert = this.$error(msg , 0, true, module);
      // prevent hiding error when redirecting, e.g. from form to list because item is not found
      this.__timeout = setTimeout(() => this.__timeout = null, 10);
    }

    return Promise.reject(error);
  }

  private __resetAjaxError(ifTimedOut?: boolean)
  {
    if (this.ajaxErrorAlert && (!ifTimedOut || this.__timeout == null))
    {
      this.ajaxErrorAlert();
      this.ajaxErrorAlert = null;
    }
  }

  /**
   * Removes the message from the store if the user clicks the close icon.
   */
  private removeMessage(msg: Alert): void
  {
    store[Mutations.REMOVE_ALERT](msg);
  }

  private ajaxErrorAlert: (() => void) | null;
  private __requestInterceptor = 0;
  private __responseInterceptor = 0;
  private __timeout: number | null;
}
