




































































































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { EntityForm } from '@/base';
import { InvoiceJson, InvoiceState } from '../json';
import store, { Actions } from '../store';
import { ContactForm } from '@/modules/contact';
import PositionList from '../components/PositionList.vue';
import { getDescription as getContactDescription, ContactJson } from '@/modules/contact';
import { store as mainStore, Getters } from '@/store';
import { UserType, UserPermission } from '../../../user';
import { VatConfigurationJson } from '@/modules/vatconfiguration';
import { store as vatStore, Actions as vatActions } from '@/modules/vatconfiguration/store';

@Component<Form>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  filters: {
    contact: (item: ContactJson | null, fallbackToId?: boolean): string => {
      const desc = item ? getContactDescription(item) : null;
      if (desc) {
        return `(${desc})`;
      }
      return '';
    },
  },
  components: {
    ContactForm,
    PositionList,
  },
})
export default class Form extends EntityForm<InvoiceJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_WRITE);
  }
  @Watch('activeItem.invoiceDate')
  private getVatConfigurations(invoiceDate: any | null): void {
    if (invoiceDate) {
      vatStore[vatActions.READ_VAT_CONFIGURATIONS_VALID_FROM](invoiceDate).then(vatConfig => {
        this.vatConfigurations = vatConfig;
      });
    }
  }

  private vatConfigurations: VatConfigurationJson[] = [];

  get totalNet(): number {
    let totalNet = 0;
    if (this.activeItem) {
      this.activeItem.invoicePositions.forEach(pos => totalNet += pos.totalPriceNet || 0);
    }
    return totalNet;
  }

  private totalGross: number = 0;

  // Helper to watch two properties with one handler -> updateTotalGross()
  get invoicePositionsAndVatConfigurations(): any {
    return `${(this.activeItem && this.activeItem.invoicePositions) ? JSON.stringify(this.activeItem.invoicePositions) : ''}|${JSON.stringify(this.vatConfigurations)}`;
  }

  @Watch('invoicePositionsAndVatConfigurations', {deep: true})
  private updateTotalGross(invoicePositionsAndVatConfigurations: string): void {
    const [invoicePositions, vatConfigurations] = invoicePositionsAndVatConfigurations.split('|').map((el: string) => JSON.parse(el));
    let totalGross = 0;

    if (invoicePositions && vatConfigurations.length) {
      invoicePositions.forEach((pos: any) => {
        if (pos.totalPriceNet) {
          const vatConfig = vatConfigurations.find((vatConf: any) => vatConf.vat === pos.vat);
          totalGross += pos.totalPriceNet * (1 + (vatConfig ? vatConfig.vatRate : 0));
        }
        });
    }
    this.totalGross = totalGross;
  }

  get vat(): number {
    return this.totalGross - this.totalNet;
  }

  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'invoice',
  };

  private downloadInvoicePdf(): void {
    const activeItem = this.activeItem;
    if (activeItem && activeItem.fileNameInvoicePDF) {
      store[Actions.DOWNLOAD_INVOICE_PDF](activeItem.id);
    }
  }

  private createPDF(): void {
    if (this.activeItem) {
      store[Actions.CREATE_INVOICE_PDF](this.activeItem);
    }
  }

  protected isAllowForAdminsAndOfficeWorkers(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.OFFICE_WORKER || currentUserType === UserType.ADMIN;
  }

  protected isFormDisabled(): boolean {
    return !this.hasWritePermission() || !this.activeItem || !(this.activeItem.invoiceState === InvoiceState.CREATED) || !this.isAllowForAdminsAndOfficeWorkers();
  }

  protected isDirty(): boolean {
      const activeItem = this.activeItem;
      if (activeItem && (this.isNew(activeItem) || this.hasUnsavedChanges(activeItem))) {
        return true;
      }
      return false;
  }
}
