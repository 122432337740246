import { BaseEntity } from '@/base';
import { ContactJson } from '@/modules/contact';
import { TranslatorJson } from '@/modules/translator';
import { BusinessUnitJson } from '@/modules/businessunit';
import { DocumentValueHolder } from '@/modules/cashaccounttransaction';

export enum CreditState {
  CREATED = 'CREATED',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
}

export enum Vat {
  ZERO = 'ZERO',
  REDUCED = 'REDUCED',
  FULL = 'FULL',
}

export interface CreditPositionJson extends BaseEntity {
  count: number;
  countUnit: string;
  operationReference: string;
  operationDate: Date;
  operationDescription: string;
  unitPriceNet: number;
  unitPriceNetUnit: string;
  totalPriceNet: number;
  vat: Vat;
}

export interface CreditJson extends DocumentValueHolder {
  readonly creditReference: string;
  readonly creditDate: Date;
  creditState: CreditState;
  receiverContact: ContactJson;
  senderContact: ContactJson;
  translator: TranslatorJson;
  businessUnit: BusinessUnitJson;
  creditPositions: CreditPositionJson[];
  readonly fileNameCreditPDF: string | null;
  readonly creatorFullNameCreditPDF: string | null;
  taxId: string;
  accountingPeriodFrom: Date;
  accountingPeriodUntil: Date;
  bankAccountHolder: string;
  bankName: string;
  IBAN: string;
  BIC: string;
  isPaymentCompleted: string;
  readonly documentValueNet: number;
  readonly documentValueGross: number;
  readonly toBeCanceledInvoice: CreditJson;
}
