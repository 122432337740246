









import { Component, Vue } from 'vue-property-decorator';
import api from '../plugins/api';

@Component<AjaxLoader>({
  created()
  {
    this.init();
  },
  destroyed()
  {
    this.teardown();
  },
})
export default class AjaxLoader extends Vue
{
  public isActive = 0;

  private init(): void
  {
    this.__requestInterceptor = api.interceptors.request.use(req => (this.isActive++, req));
    this.__responseInterceptor = api.interceptors.response.use(resp => (this.isActive--, resp), err => (this.isActive--, Promise.reject(err)));
  }

  private teardown(): void
  {
    if (this.__requestInterceptor)
    {
      api.interceptors.request.eject(this.__requestInterceptor);
    }
    if (this.__responseInterceptor)
    {
      api.interceptors.response.eject(this.__responseInterceptor);
    }
  }

  private __requestInterceptor = 0;
  private __responseInterceptor = 0;
}
