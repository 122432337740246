








































import { Component } from 'vue-property-decorator';
import { DocumentTranslationContractJson } from '../json';
import ContractForm from '../../base/ContractForm';
import store from '../store';
import { FormData, NewEntity } from '@/base';

@Component<Form>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
})
export default class Form extends ContractForm<DocumentTranslationContractJson, typeof store> {
  protected readonly store = store;

  protected readonly closeRoute = {
    name: 'documenttranslation',
  };

  protected setBeginDate(item: DocumentTranslationContractJson | NewEntity<DocumentTranslationContractJson>, date: Date, dateTime: Date): void
  {
    item.completeUntil = date;
  }
}
