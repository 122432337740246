import { RouteConfig } from 'vue-router';

import List from './views/List.vue';
import Form from './views/Form.vue';

export default [
  // list
  {
    name: 'language',
    path: '',
    component: List,
  },
  // edit
  {
    path: ':id(new|\\d+)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
] as RouteConfig[];
