






















































































































import { Component } from 'vue-property-decorator';
import { EntityList, HeaderConfig, EntityStoreActions, EntityStoreGetters, ListAction } from '@/base';
import store from '../store';
import { TranslatorJson, TranslatorOfferedService } from '../json';
import { sortByFullName, getFullName, UserPermission } from '@/modules/user';
import { store as costCenterStore } from '@/modules/costcenter';
import { store as languageStore, LanguageJson, getLanguageItemComparator } from '@/modules/language';
import { EmailTemplateJson, EmailTemplateType, store as emailTemplateStore } from '@/modules/emailtemplate';
import { store as translatorStore } from '@/modules/translator';

@Component<List>({
  filters: {
    fullName: (item: TranslatorJson | null, fallbackToId?: boolean): string => {
      return item ? getFullName(item, fallbackToId) : '';
    },
  },
  methods: {
    getFullName,
    getLanguageItemComparator,
  },
  created() {
    this.init();
  },
})
export default class List extends EntityList<TranslatorJson, typeof store> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.TRANSLATOR_WRITE);
  }

  private showCommentDialog = false;

  private contentDialog = '';

  private handleIconClick(subject: string): void {
    this.contentDialog = subject;
  }

  /**
   * The store to the items.
   */
  protected readonly store = store;

  public sortState = [{ property: 'lastName, firstName' }];

  get offeredServiceItems(): any[] {
    const offeredServiceItems: any[] = [];
    Object.values(TranslatorOfferedService).forEach(s => {
      offeredServiceItems.push({
        typeOfService: s,
      });
    });

    return offeredServiceItems;
  }

  /**
   * Values of filter form.
   */
  protected readonly filters = {
    firstName: '',
    lastName: '',
    email: '',
    mainPhoneNumber: '',
    costCenterId: null as number | null,
    languageID: null as number | null,
    offeredTypeOfService: null as string | null,
    translatorComment: '',
    translatorContactLocation: '',
  };

  /**
   * Returns the cost centers for filtering.
   */
  private readonly costCenterStore = costCenterStore;

  /**
   * Returns the languages for filtering.
   */
  private readonly languageStore = languageStore;

  /**
   * Returns the translators for filtering.
   */
  private readonly translatorStore = translatorStore;

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[] {
    return [
      {
        value: 'lastName',
        text: this.$t('list.header.name').toString(),
        sort: (a, b) => sortByFullName(a, b, this.$i18n.locale),
        filterProperty: ['firstName', 'lastName'],
      },
      {
        value: 'email',
        text: this.$t('list.header.email').toString(),
      },
      {
        value: 'mainPhoneNumber',
        text: this.$t('list.header.mainPhoneNumber').toString(),
      },
      {
        value: 'costCenter.name',
        text: this.$t('list.header.costCenter').toString(),
        filterProperty: 'costCenterId',
      },
      {
        value: 'languages',
        text: this.$t('list.header.languages').toString(),
        filterProperty: 'languageId',
      },
      {
        value: 'translatorComment',
        text: this.$t('list.header.translatorComment').toString(),
        filterProperty: 'translatorComment',
      },
      {
        value: 'creditContact.city',
        text: this.$t('list.header.translatorContactLocation').toString(),
        filterProperty: ['translatorContactLocation'],
      },
    ];
  }

  protected getDeleteConfirmationMessage(item: TranslatorJson): { title?: string; message: string } {
    const args = Object.assign({ fullName: getFullName(item, true) }, item);
    return {
      title: this.$t('list.delete.title', args).toString(),
      message: this.$t('list.delete.message', args).toString(),
    };
  }

  protected getActions(): ListAction<TranslatorJson>[] {
    if (this.hasWritePermission()) {
      return [
        {
          icon: 'edit',
          handler: item => this.editItem(item),
          text: this.$root.$t('edit.label').toString(),
        },
        {
          icon: 'delete',
          handler: item => this.deleteItem(item),
          isVisible: item => item.id > 0,
          text: this.$root.$t('delete.label').toString(),
          color: 'delete',
        },
        {
          icon: 'sendEmail',
          handler: item => this.showSendEmailDialog(item, true),
          // isVisible: item => this.isAllowForAdminsAndOfficeWorkers(),
          text: this.$t('list.actions.sendEmailToTranslator').toString(),
        },
      ];
    }
    return [
      {
        icon: 'edit',
        handler: item => this.editItem(item),
        text: this.$root.$t('edit.label').toString(),
      },
    ];

  }

  private getLanguagesSummary(languages: LanguageJson[]): string {
    const res: string[] = [];
    languages.forEach((language: { name: string; }) => {
        res.push(language.name);
    });
    return res.join(', ');
  }

  // E-mails

  /**
   * Variable used to display the options in the email dialog.
   */
  protected emailTemplatesForTranslator: {
    translator: TranslatorJson;
    emailTemplates: EmailTemplateJson[];
    isTranslator: boolean;
  } | null = null;

  protected showSendEmailDialog(item: TranslatorJson, isTranslator: boolean): void {
    emailTemplateStore[EntityStoreActions.READ_ALL](true);
    const res = emailTemplateStore[EntityStoreGetters.ITEMS].filter(t => t.emailTemplateType === EmailTemplateType.TRANSLATOR);

    this.emailTemplatesForTranslator = {
      translator: item,
      emailTemplates: res,
      isTranslator,
    };
  }

  protected sendEmail(item: TranslatorJson, emailTemplate: EmailTemplateJson, isTranslator: boolean): void {
    const emailPath = isTranslator ? 'email/translator' : 'email/customer';

    // forward to route
    this.$router
      .push({
        path: `${item.id}/${emailPath}/${emailTemplate.id}`,
        append: true,
      })
      // close dialog
      .then(() => null);
  }
}
