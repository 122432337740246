
import { Component, Prop } from 'vue-property-decorator';
import Sortable from 'sortablejs';
import { List as ListBase, ListAction, HeaderConfig, createEntity, NewEntity, EntityStore } from '@/base';
// import store, { Actions } from '../store';
import { BankAccountTransactionPositionJson, BankAccountTransactionJson, DocumentValueHolder } from '../json';
import { UserPermission } from '@/modules/user';

export default abstract class BankAccountTransactionPositionList<T extends DocumentValueHolder> extends ListBase<NewEntity<BankAccountTransactionPositionJson<T>>>
{
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.ACCOUNTING_WRITE);
  }

  /**
   * Returns the items of the list.
   */
  // @Prop({ required: true }) // Has a bug on inheritance and sets required to true for ListBase. So use above method.
  public readonly items: NewEntity<BankAccountTransactionPositionJson<T>>[];

  @Prop({ required: false })
  public isFormDisabled: false;

  /**
   * Returns the list of actions to display in the table.
   */
  protected getActions(): ListAction<NewEntity<BankAccountTransactionPositionJson<T>>>[]
  {
    if (this.hasWritePermission())
    {
      return [
        {
          icon: 'delete',
          isDisabled: item => this.isFormDisabled,
          handler: item => this.deleteItem(item),
          text: this.$root.$t('delete.label').toString(),
          color: 'delete',
        },
      ];
    }
    return [];
  }

  /**
   * Returns the columns of the table (excluding actions and id).
   */
  protected getColumns(): HeaderConfig[]
  {
    return [
      {
        value: 'entity',
        text: this.getFinancialDocumentLabel(),
        sortable: false,
        align: 'end',
      },
      {
        value: 'entity.businessUnit',
        text: this.getLabel('header.businessUnit'),
        sortable: false,
        align: 'end',
      },
      {
        value: 'assignedPriceNet',
        text: this.getLabel('header.assignedPriceNet'),
        sortable: false,
        align: 'end',
      },
      {
        value: 'assignedPriceGross',
        text: this.getLabel('header.assignedPriceGross'),
        sortable: false,
        align: 'end',
      },
    ];
  }

  protected abstract getFinancialDocumentLabel(): string;

  protected abstract getFullName(item: T): string;

  /**
   * Used as model for the new item dialog.
   */
  protected newEntity: T | null = null;

  /**
   * Adds a new item if the newEntity model has a value.
   */
  protected abstract addItemIf(): void;


  private deleteItem(item: NewEntity<BankAccountTransactionPositionJson<T>>): void
  {
    this.$_.pull(this.items, item);
  }

  protected getLabel(key: string): string
  {
    key = `positionList.${key}`;

    if (this.$te(key))
    {
      return this.$t(key).toString();
    }

    return this.$root.$t(`module.bankAccountTransaction.${key}`).toString();
  }

  protected sortable: Sortable;

}
