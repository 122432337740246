import { LanguageJson } from './json';
import { EntityStore, createEntityStore, EntityStoreState, NewEntityProperties, EntityId } from '@/base';
import { api } from '@/plugins';

export enum Actions {
  GET_DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS = 'dispatchDefaultLanguageForContracts',
}
export interface State extends EntityStoreState<LanguageJson>
{
}

export interface Store extends EntityStore<LanguageJson>
{
  /**
   * Get Default Language set by SystemConfigurationKey.DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS
   */
  [Actions.GET_DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS](): Promise<LanguageJson>;
}


function createItem(): NewEntityProperties<LanguageJson>
{
  return {
    isoCode: '',
    name: '',
  };
}

const apiBaseUrl = 'languages';

export default createEntityStore<LanguageJson, State, Store>('language', apiBaseUrl , createItem, {}, moduleBuilder => {
  return {
    [Actions.GET_DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS]: moduleBuilder.dispatch(async () => {
      return await api.get<LanguageJson>(`${apiBaseUrl}/getdefaultlanguageforcontracts`);
    }, Actions.GET_DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS),
  };
});
