import { BaseEntity } from '@/base';

export interface UserJson extends BaseEntity
{
  firstName: string;
  lastName: string;
  email: string;
  userType: UserType;
  isLocked: boolean;
  userPermissions: UserPermission[];
}

export enum UserType
{
  CUSTOMER = 'CUSTOMER',
  TRANSLATOR = 'TRANSLATOR',
  OFFICE_WORKER = 'OFFICE_WORKER',
  ADMIN = 'ADMIN',
  ACCOUNTANCY_FIRM = 'ACCOUNTANCY_FIRM',
}

export enum UserPermission
{
  APPOINTMENT_READ = 'APPOINTMENT_READ',
  APPOINTMENT_WRITE = 'APPOINTMENT_WRITE',
  CONTRACT_READ = 'CONTRACT_READ',
  CONTRACT_WRITE = 'CONTRACT_WRITE',
  ACCOUNTING_READ = 'ACCOUNTING_READ',
  ACCOUNTING_WRITE = 'ACCOUNTING_WRITE',
  ACCOUNTING_DELETE = 'ACCOUNTING_DELETE',
  MASTER_DATA_READ = 'MASTER_DATA_READ',
  MASTER_DATA_WRITE = 'MASTER_DATA_WRITE',
  LANGUAGE_READ = 'LANGUAGE_READ',
  LANGUAGE_WRITE = 'LANGUAGE_WRITE',
  EMAIL_TEMPLATE_READ = 'EMAIL_TEMPLATE_READ',
  EMAIL_TEMPLATE_WRITE = 'EMAIL_TEMPLATE_WRITE',
  TRANSLATOR_READ = 'TRANSLATOR_READ',
  TRANSLATOR_WRITE = 'TRANSLATOR_WRITE',
  CUSTOMER_READ = 'CUSTOMER_READ',
  CUSTOMER_WRITE = 'CUSTOMER_WRITE',
  REPORT_READ = 'REPORT_READ',
  REPORT_WRITE = 'REPORT_WRITE',
}

export interface UserPasswordChangeJson
{
  passwordOld: string;
  passwordNew: string;
}
