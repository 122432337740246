import { BusinessUnitJson } from '../businessunit';
import { CustomerJson } from '../customer';
import { TranslatorJson } from '../translator';
import { BaseEntity } from '@/base';
import { LanguageJson } from '../language';

export enum ContractState
{
  CREATED = 'CREATED',
  TRANSLATOR_ASSIGNED = 'TRANSLATOR_ASSIGNED',
  TRANSLATOR_CONFIRMED = 'TRANSLATOR_CONFIRMED',
  CONTRACT_FULFILLED = 'CONTRACT_FULFILLED',
  CANCELED = 'CANCELED',
}

export enum ContractType
{
  ON_SITE_TRANSLATION = 'ON_SITE_TRANSLATION',
  DOCUMENT_TRANSLATION = 'DOCUMENT_TRANSLATION',
  VIDEO_TRANSLATION = 'VIDEO_TRANSLATION',
}

export enum ReceiverOption
{
  CONTACT_ADDRESS = 'CONTACT_ADDRESS',
  COST_CENTER = 'COST_CENTER',
  ALTERNATIVE_ADDRESS = 'ALTERNATIVE_ADDRESS',
}

export enum InvoiceFilter
{
  INVOICE_CREATED = 'INVOICE_CREATED',
  INVOICE_NOT_CREATED = 'INVOICE_NOT_CREATED',
}

export enum CreditFilter
{
  CREDIT_CREATED = 'CREDIT_CREATED',
  CREDIT_NOT_CREATED = 'CREDIT_NOT_CREATED',
}

export interface ContractJson extends BaseEntity
{
  businessUnit: BusinessUnitJson;
  readonly contractNo: number;
  contractDate: Date;
  readonly contractState: ContractState;
  customerLanguage: LanguageJson;
  translatorLanguage: LanguageJson;
  invoiceReceiverOption: ReceiverOption;
  creditReceiverOption: ReceiverOption;
  readonly contractType: ContractType;
  contractComment: string;

  customer: CustomerJson;
  readonly translator: TranslatorJson | null;
  readonly isInvoiceCreated: false;
  readonly isCreditCreated: false;
}
