import { UserJson } from '@/modules/user';
import { CostCenterJson } from '../costcenter';
import { LanguageJson } from '../language';
import { ContactJson } from '../contact';

// tslint:disable-next-line:no-empty-interface
export interface TranslatorJson extends UserJson
{
  taxId: string;
  vatExempt: boolean;
  translatorComment: string;
  mainPhoneNumber: string;
  isWebDavEnabled: boolean;
  costCenter: CostCenterJson | null;
  languages: LanguageJson[] | null;
  bankAccountHolder: string;
  bankName: string;
  IBAN: string;
  BIC: string;
  readonly contact: ContactJson | null;
  offeredServices: TranslatorOfferedServiceJson[] | null;
}

export interface TranslatorOfferedServiceJson {
  typeOfService: TranslatorOfferedService | null;
}

export enum TranslatorOfferedService
{
  INTERPRETER = 'INTERPRETER',
  TRANSLATOR = 'TRANSLATOR',
}
