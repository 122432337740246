









































import { Component, Vue } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { extend } from 'vee-validate';
import { EntityStoreActions, Form as FormBase, EntityStoreGetters, EntityStoreMutations, createEntity, BaseEntity, ArrayFormData, FormData, asFormData, NewEntity } from '@/base';
import { getDuplicates } from '@/util';
import { CustomerJson } from '../json';
import store, { Actions } from '../store';
import { CustomerLanguageGroupJson, CustomerLanguageGroupsJson, getCustomerLanguageGroupDescription } from '@/modules/customer/customerlanguagegroup';
import { store as languageStore, LanguageJson, getLanguageItemComparator } from '@/modules/language';
import { UserPermission } from '../../user';

/**
 * The type of the active form item.
 */
interface ActiveItem {
  customer: CustomerJson;
  languageGroups: FormData<CustomerLanguageGroupsJson>;
}

@Component<LanguageGroups>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  beforeRouteLeave(to, from, next): void {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created(): void {
    const me = this;
    extend('customerLanguageGroupsNameDuplicates', {
      validate(value, args) {
        if (me.activeItem) {
          const cycleId = parseInt((args as any[])[0], 10);
          const duplicates = getDuplicates(me.activeItem.languageGroups.customerLanguageGroups, g => g.name || null);
          return !me.$_.includes(duplicates, cycleId);
        }
        return true;
      },
      message(field, values) {
        return me.$t(`languageGroups.name.error.${values!._rule_}`, values).toString();
      },
    });
    extend('customerLanguageGroupsLanguagesDuplicates', {
      validate(value, args) {
        if (me.activeItem) {
          const cycleId = parseInt((args as any[])[0], 10);
          // tslint:disable-next-line:max-line-length
          const duplicates = getDuplicates(me.activeItem.languageGroups.customerLanguageGroups, g => (g.languages && g.languages.length > 0 ? (g.languages as BaseEntity[]).map(l => `${l.id}`) : null));
          return !me.$_.includes(duplicates, cycleId);
        }
        return true;
      },
      message(field, values) {
        return me.$t(`languageGroups.languages.error.${values!._rule_}`, values).toString();
      },
    });
  },
  methods: {
    getCustomerLanguageGroupDescription,
    getLanguageItemComparator,
  },
})
export default class LanguageGroups extends FormBase<ActiveItem> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.CUSTOMER_WRITE);
  }

  /**
   * The currently active items with the corresponding customer.
   */
  public activeItem: ActiveItem | null = null;

  /**
   * The store of available languages.
   */
  private readonly languageStore = languageStore;

  /**
   * Changes the active item on any of the given actions.
   */
  protected changeActiveItem(item: ActiveItem | null): void {
    const activeCustomer = store[EntityStoreGetters.ACTIVE_ITEM];
    const activeItem = this.activeItem;

    // set the customer active
    store[EntityStoreMutations.ACTIVE_ITEM](item ? item.customer : null);
    this.activeItem = item;

    if (activeCustomer && (!item || item.customer !== activeCustomer.$raw)) {
      activeCustomer.$revoke();
    }

    if (activeItem && (!item || item.languageGroups !== activeItem.languageGroups)) {
      activeItem.languageGroups.$revoke();
    }
  }

  private createLanguageGroup(): NewEntity<CustomerLanguageGroupJson> {
    return createEntity<CustomerLanguageGroupJson>({
      name: '',
      languages: [],
    });
  }

  /**
   * Checks if the given item has unsaved changes.
   */
  protected hasUnsavedChanges(item: ActiveItem): boolean {
    return item.languageGroups.$isDirty();
  }

  /**
   * Called when closing the form.
   */
  protected onClose(): void {
    this.$router.push({ name: 'customer' });
  }

  /**
   * Saves the changes of the given item on the server.
   */
  protected async saveItem(item: ActiveItem): Promise<void> {
    const result = await store[Actions.SAVE_LANGUAGE_GROUPS](item);
    this.changeActiveItem({
      customer: item.customer,
      languageGroups: asFormData(result, false),
    });
    this.$success(this.$root.$t('form.save.success').toString());
  }

  /**
   * Resets the given item.
   */
  protected resetItem(item: ActiveItem): void {
    item.languageGroups.$reset();
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    const proceed = (customer: CustomerJson, languageGroups: CustomerLanguageGroupsJson): Promise<void> => {
      return this.open(() => ({
        customer,
        languageGroups: asFormData(languageGroups),
      })).then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
    };

    const customerId = parseInt(to.params.id, 10);

    Promise.all([store[EntityStoreActions.READ_ONE]({ id: customerId, initial: true }), store[Actions.READ_LANGUAGE_GROUPS]({ customer: customerId })])
      .then(([customer, languageGroups]) => proceed(customer, languageGroups))
      .catch(() => next(from.fullPath === '/' && !from.name ? { name: 'customer', replace: true } : { path: from.fullPath, replace: true }));
  }
}
