import { BaseEntity } from '@/base';

export interface VatConfigurationJson extends BaseEntity
{
    validFrom: Date;
    vatRate: number;
    vat: Vat;
}

export enum Vat
{
    ZERO = 'ZERO',
    REDUCED = 'REDUCED',
    FULL = 'FULL',
}
