import { CustomerGroupJson } from './json';
import { EntityStore, createEntityStore, EntityStoreState, NewEntityProperties } from '@/base';

export interface State extends EntityStoreState<CustomerGroupJson>
{
}

export interface Store extends EntityStore<CustomerGroupJson>
{
}

function createItem(): NewEntityProperties<CustomerGroupJson>
{
  return {
    name: '',
  };
}

export default createEntityStore<CustomerGroupJson, State, Store>('customergroup', 'customergroups', createItem, {});
