import { ContractJson, ReceiverOption, ContractState } from '../json';
import { EntityStore, EntityForm, NewEntity, asFormData } from '@/base';
import { getFullName, UserType, UserPermission } from '@/modules/user';
import { store as customerStore } from '@/modules/customer';
import { store as businessUnitStore } from '@/modules/businessunit';
import { store as languageStore, Actions, LanguageJson, getLanguageItemComparator } from '@/modules/language';
import { store as mainStore, Getters } from '@/store';
import { Route, RawLocation } from 'vue-router';

export default abstract class ContractForm<T extends ContractJson, S extends EntityStore<T>> extends EntityForm<T, S> {
  protected hasWritePermission(): boolean {
    return this.hasPermission(UserPermission.CONTRACT_WRITE);
  }

  // stores for entityselect fields
  private readonly businessUnitStore = businessUnitStore;
  private readonly customerStore = customerStore;
  private readonly languageStore = languageStore;
  private readonly getLanguageItemComparator = getLanguageItemComparator;

  // pass methods through
  public readonly getFullName = getFullName;
  public readonly ReceiverOption = ReceiverOption;

  protected isAllowForAdminsAndOfficeWorkers(): boolean {
    const currentUserType = mainStore[Getters.CURRENT_USER_LOGIN]!.userType;
    return currentUserType === UserType.OFFICE_WORKER || currentUserType === UserType.ADMIN;
  }
  private isContractStateFulFilled(): boolean {
    const activeItem = this.activeItem as ContractJson;
    return activeItem.contractState === ContractState.CONTRACT_FULFILLED;
  }

  private isContractStateCanceled(): boolean {
    const activeItem = this.activeItem as ContractJson;
    return activeItem.contractState === ContractState.CANCELED;
  }

  protected isFormDisabled(): boolean {
    // Disable Form, if contract is fulfilled or canceled
    return !this.isAllowForAdminsAndOfficeWorkers() || this.isContractStateFulFilled() || this.isContractStateCanceled() || !this.hasWritePermission();
  }

  protected comingFrom = {};

  /**
   * Called when closing the form.
   */
  protected onClose(): void {
    this.$router.push(this.comingFrom);
  }

  protected createCustomer()
  {
    this.$router.push('/customer/new?backTo=' + this.$route.fullPath);
  }

  public handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    languageStore[Actions.GET_DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS]().then(
      item => {
        this.defaultLanguage = item;
        this.handleBeforeRouteEnterWithLanguage(to, from, next, item);
      },
    ).catch(
      item => {
        this.defaultLanguage  = null;
        this.handleBeforeRouteEnterWithLanguage(to, from, next, null);
      },
    );
  }

  private defaultLanguage: LanguageJson | null;

  protected updateNewItem(item: T | NewEntity<T>) {
    const contract = item as ContractJson;
    if (contract && this.defaultLanguage && contract.id < 0) {
      contract.customerLanguage = this.defaultLanguage;
    }
  }

  public handleBeforeRouteEnterWithLanguage(to: Route, from: Route, next: (to?: RawLocation | false) => void, language: LanguageJson | null) {
    if (to.query.timestamp) {
      const proceed = (item: T | NewEntity<T>): Promise<void> => {
        this.updateNewItem(item);
        const newBeginDateTimeVueCalendarTimestamp = JSON.parse(to.query.timestamp as string);
        const newBeginDateTime = new Date();
        newBeginDateTime.setFullYear(newBeginDateTimeVueCalendarTimestamp.year);
        newBeginDateTime.setMonth(newBeginDateTimeVueCalendarTimestamp.month - 1);
        newBeginDateTime.setDate(newBeginDateTimeVueCalendarTimestamp.day);
        newBeginDateTime.setHours(newBeginDateTimeVueCalendarTimestamp.hour);
        newBeginDateTime.setMinutes(this.roundMinutes(newBeginDateTimeVueCalendarTimestamp.minute));
        newBeginDateTime.setSeconds(0);

        const newBeginDate = new Date();
        newBeginDate.setFullYear(newBeginDateTimeVueCalendarTimestamp.year);
        newBeginDate.setMonth(newBeginDateTimeVueCalendarTimestamp.month - 1);
        newBeginDate.setDate(newBeginDateTimeVueCalendarTimestamp.day);
        newBeginDate.setHours(0);
        newBeginDate.setMinutes(0);
        newBeginDate.setSeconds(0);

        this.setBeginDate(item, newBeginDate, newBeginDateTime);

        return this.open(() => asFormData<T>(item)).then(handled => next(handled ? undefined : { path: from.fullPath, replace: true }));
      };

      proceed(this.store.createEntity());
    } else {
      super.handleBeforeRouteEnter(to, from, next);
    }
    this.setCloseRouteDynamically(from);
  }

  private setCloseRouteDynamically(from: Route) {
    if (from.name) {
      this.comingFrom = {
        name: from.name,
      };
    } else {
      this.comingFrom = {
        path: '..',
        append: true,
      };
    }
  }

  private roundMinutes(minutes: number): number {
    if (minutes && minutes >= 0 && minutes < 15) {
      return 0;
    }
    if (minutes && minutes >= 15 && minutes < 30) {
      return 15;
    }
    if (minutes && minutes >= 30 && minutes < 45) {
      return 30;
    }
    if (minutes && minutes >= 45 && minutes < 60) {
      return 45;
    }
    return minutes;
  }

  protected abstract setBeginDate(item: T | NewEntity<T>, date: Date, dateTime: Date): void;
}
