import { RouteConfig } from 'vue-router';

import List from './views/List.vue';
import Form from './views/Form.vue';
import FileAttachmentCollection from './views/FileAttachmentCollection.vue';
import Contact from './views/Contact.vue';
import Email from './views/Email.vue';

export default [
  // list
  {
    name: 'onsitetranslation',
    path: '',
    component: List,
  },
  // edit
  {
    path: ':id(new|\\d+)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // file upload original
  {
    path: ':id(\\d+)/fileattachmentcollection',
    component: FileAttachmentCollection,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // new with time
  {
    path: 'new?timestamp=(.*)',
    component: Form,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // invoice, credit or operation contact
  {
    path: ':id(\\d+)/:type(invoice|credit|operation)contact',
    component: Contact,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // invoice
  {
    path: ':id(\\d+)/invoice',
    redirect: to => `/invoice/onsitetranslationcontract/${to.params.id}`,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // credit
  {
    path: ':id(\\d+)/credit',
    redirect: to => `/credit/onsitetranslationcontract/${to.params.id}`,
    meta: {
      hideMenu: true,
      hideTab: true,
    },
  },
  // send e-mail
  {
    path: ':id(\\d+)/email/(translator|customer)/:emailTemplateId(\\d+)',
    component: Email,
    meta: {
      hideMenu: true,
      hideTab: true,
      isEmailRoute: true,
    },
  },
] as RouteConfig[];
