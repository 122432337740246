import Vue from 'vue';
import Router, { RouteConfig, RawLocation, Route } from 'vue-router';
import Login from './views/Login.vue';

import { store, Getters as StoreGetters, Actions as StoreActions } from './store';
import api, { IAxiosError } from './plugins/api';
import i18n from './plugins/i18n';
// it is import to have correct import order: UserType, UserPermission is needed as import in other modules
import { routes as user, UserType, UserPermission } from '@/modules/user';
import { routes as documenttranslation } from '@/modules/contract/documenttranslation';
import { routes as onsitetranslation } from '@/modules/contract/onsitetranslation';
import { routes as videotranslation } from '@/modules/contract/videotranslation';
import { routes as customer } from '@/modules/customer';
import { routes as customergroup } from '@/modules/customer/customergroup';
import { routes as translator } from '@/modules/translator';
import { routes as officeworker } from '@/modules/officeworker';
import { routes as admin } from '@/modules/admin';
import { routes as businessunit } from '@/modules/businessunit';
import { routes as language } from '@/modules/language';
import { routes as systemconfiguration } from '@/modules/systemconfiguration';
import { routes as report } from '@/modules/report';
import { routes as bankaccounttransaction } from '@/modules/bankaccounttransaction';
import { routes as cashaccounttransaction } from '@/modules/cashaccounttransaction';
import { routes as invoice } from '@/modules/financialdocument/invoice';
import { routes as credit } from '@/modules/financialdocument/credit';
import { routes as externaldocument } from '@/modules/financialdocument/externaldocument';
import { routes as costcenter } from '@/modules/costcenter';
import { routes as emailtemplate } from '@/modules/emailtemplate';
import { routes as task } from '@/modules/task';
import { routes as starmoneyimport } from '@/modules/starmoneyimport';
import { routes as cashbook } from '@/modules/cashbook';
import { routes as vatconfiguration } from '@/modules/vatconfiguration';


Vue.use(Router);

// See "masterdata" for menu only items.

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: Login,
    meta: {
      noModule: true, // informs the module plugin to ignore this path as module name
      anonymous: true, // this allows the navigation without being logged in
      hideMenu: true, // this hides the route from the main menu
    },
    beforeEnter: (to, from, next) => store[StoreGetters.IS_LOGGED_IN] ? next(to.query.redirectTo as string || '/dashboard') : next(),
  },
  // Password reset link
  {
    path: '/resetpassword/:rid',
    meta: {
      noModule: true,
      anonymous: true,
      hideMenu: true,
    },
    component: () => import(/* webpackChunkName: "resetpassword" */'./views/ResetPassword.vue'),
  },
  // Password reset link
  {
    path: '/activation/:rid',
    meta: {
      noModule: true,
      anonymous: true,
      hideMenu: true,
    },
    component: () => import(/* webpackChunkName: "activation" */'./views/ResetPassword.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      noModule: true,
      title: 'menu.dashboard',
    },
    component: () => import(/* webpackChunkName: "dashboard" */'./views/Dashboard.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    meta: {
      module: 'calendar',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.TRANSLATOR],
      userPermission: [UserPermission.APPOINTMENT_READ],
    },
    component: () => import(/* webpackChunkName: "calendar" */'./modules/calendar/Calendar.vue'),
  },
  {
    path: '/task',
    children: task,
    meta: {
      module: 'task',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.APPOINTMENT_READ],
    },
    component: () => import(/* webpackChunkName: "task" */ './modules/task/Task.vue'),
  },
  {
    // This is a menu only item. Use it to group other modules. Redirect it to any sub module.
    path: '/appointment',
    redirect: '/calendar',
    meta: {
      icon: 'calendar',
      title: 'menu.appointment', // key of title locale
      noMenuLink: true,
      noModule: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.TRANSLATOR],
      userPermission: [UserPermission.APPOINTMENT_READ],
    },
    // children for menu
    children: [
      {
        path: '/calendar',
        redirect: '/calendar',
        meta: {
          module: 'calendar',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.TRANSLATOR],
          userPermission: [UserPermission.APPOINTMENT_READ],
        },
      },
      {
        path: '/task',
        redirect: '/task',
        meta: {
          module: 'task',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
          userPermission: [UserPermission.APPOINTMENT_READ],
        },
      },
    ],
  },
  {
    path: '/documenttranslation',
    children: documenttranslation,
    meta: {
      module: 'documenttranslation',
      hideMenu: true,
      hideDashboard: !getVueAppDocumentTranslationContractEnabledState(),
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.TRANSLATOR],
      userPermission: [UserPermission.CONTRACT_READ],
    },
    component: () => import(/* webpackChunkName: "documenttranslation" */'./modules/contract/documenttranslation/DocumentTranslation.vue'),
  },
  {
    path: '/onsitetranslation',
    children: onsitetranslation,
    meta: {
      module: 'onsitetranslation',
      hideMenu: true,
      hideDashboard: !getVueAppOnSiteTranslationContractEnabledState(),
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.TRANSLATOR],
      userPermission: [UserPermission.CONTRACT_READ],
    },
    component: () => import(/* webpackChunkName: "onsitetranslation" */'./modules/contract/onsitetranslation/OnSiteTranslation.vue'),
  },
  {
    path: '/videotranslation',
    children: videotranslation,
    meta: {
      module: 'videotranslation',
      hideMenu: true,
      // We have to revert this value because hide it if its not enabled
      hideDashboard: !getVueAppVideoTranslationContractEnabledState(),
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.TRANSLATOR],
      userPermission: [UserPermission.CONTRACT_READ],
    },
    component: () => import(/* webpackChunkName: "videotranslation" */'./modules/contract/videotranslation/VideoTranslation.vue'),
  },
  {
    path: '/contract',
    redirect: '/documenttranslation',
    meta: {
      icon: 'contracts',
      title: 'menu.contracts', // key of title locale
      noMenuLink: true,
      noModule: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.TRANSLATOR],
      userPermission: [UserPermission.CONTRACT_READ],
    },
    children: getRouterConfigContract(),
  },
  {
    path: '/starmoneyimport',
    children: starmoneyimport,
    meta: {
      module: 'starmoneyimport',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
      userPermission: [UserPermission.ACCOUNTING_READ],
    },
    component: () => import(/* webpackChunkName: "starmoneyimport" */ './modules/starmoneyimport/StarMoneyImport.vue'),
  },
  {
    path: '/bankaccounttransaction',
    children: bankaccounttransaction,
    meta: {
      module: 'bankaccounttransaction',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
      userPermission: [UserPermission.ACCOUNTING_READ],
    },
    component: () => import(/* webpackChunkName: "bankaccounttransaction" */ './modules/bankaccounttransaction/BankAccountTransaction.vue'),
  },
  {
    path: '/cashaccounttransaction',
    children: cashaccounttransaction,
    meta: {
      module: 'cashaccounttransaction',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
      userPermission: [UserPermission.ACCOUNTING_READ],
    },
    component: () => import(/* webpackChunkName: "cashaccounttransaction" */ './modules/cashaccounttransaction/CashAccountTransaction.vue'),
  },
  {
    path: '/cashbook',
    children: cashbook,
    meta: {
      module: 'cashbook',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
      userPermission: [UserPermission.ACCOUNTING_READ],
    },
    component: () => import(/* webpackChunkName: "starmoneyimport" */ './modules/cashbook/CashBook.vue'),
  },
  {
    path: '/invoice',
    children: invoice,
    meta: {
      module: 'invoice',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.ACCOUNTANCY_FIRM],
      userPermission: [UserPermission.ACCOUNTING_READ],
    },
    component: () => import(/* webpackChunkName: "invoice" */ './modules/financialdocument/invoice/Invoice.vue'),
  },
  {
    path: '/credit',
    children: credit,
    meta: {
      module: 'credit',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.TRANSLATOR, UserType.ACCOUNTANCY_FIRM],
      userPermission: [UserPermission.ACCOUNTING_READ],
    },
    component: () => import(/* webpackChunkName: "credit" */ './modules/financialdocument/credit/Credit.vue'),
  },
  {
    path: '/externaldocument',
    children: externaldocument,
    meta: {
      module: 'externaldocument',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
      userPermission: [UserPermission.ACCOUNTING_READ],
    },
    component: () => import(/* webpackChunkName: "externaldocument" */ './modules/financialdocument/externaldocument/ExternalDocument.vue'),
  },
  {
    path: '/accounting',
    redirect: '/invoice',
    meta: {
      icon: 'accounting',
      title: 'menu.accounting',
      noMenuLink: true,
      hideDashboard: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.TRANSLATOR, UserType.ACCOUNTANCY_FIRM],
      userPermission: [UserPermission.ACCOUNTING_READ],
    },
    children: [
      {
        path: '/starmoneyimport',
        redirect: '/starmoneyimport',
        meta: {
          module: 'starmoneyimport',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
          userPermission: [UserPermission.ACCOUNTING_READ],
        },
      },
      {
        path: '/bankaccounttransaction',
        redirect: '/bankaccounttransaction',
        meta: {
          module: 'bankaccounttransaction',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
          userPermission: [UserPermission.ACCOUNTING_READ],
        },
      },
      {
        path: '/cashaccounttransaction',
        redirect: '/cashaccounttransaction',
        meta: {
          module: 'cashaccounttransaction',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
          userPermission: [UserPermission.ACCOUNTING_READ],
        },
      },
      {
        path: '/cashbook',
        redirect: '/cashbook',
        meta: {
          module: 'cashbook',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
          userPermission: [UserPermission.ACCOUNTING_READ],
        },
      },
      {
        path: '/invoice',
        redirect: '/invoice',
        meta: {
          module: 'invoice',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.CUSTOMER, UserType.ACCOUNTANCY_FIRM],
          userPermission: [UserPermission.ACCOUNTING_READ],
        },
      },
      {
        path: '/credit',
        redirect: '/credit',
        meta: {
          module: 'credit',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.TRANSLATOR, UserType.ACCOUNTANCY_FIRM],
          userPermission: [UserPermission.ACCOUNTING_READ],
        },
      },
      {
        path: '/externaldocument',
        redirect: '/externaldocument',
        meta: {
          module: 'externaldocument',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER, UserType.ACCOUNTANCY_FIRM],
          userPermission: [UserPermission.ACCOUNTING_READ],
        },
      },
    ],
  },
  {
    path: '/businessunit',
    children: businessunit,
    meta: {
      module: 'businessunit',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.MASTER_DATA_READ],
    },
    component: () => import(/* webpackChunkName: "businessunit" */ './modules/businessunit/BusinessUnit.vue'),
  },
  {
    path: '/costcenter',
    children: costcenter,
    meta: {
      module: 'costcenter',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.MASTER_DATA_READ],
    },
    component: () => import(/* webpackChunkName: "costcenter" */ './modules/costcenter/CostCenter.vue'),
  },
  {
    path: '/customergroup',
    children: customergroup,
    meta: {
      module: 'customergroup',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.MASTER_DATA_READ],
    },
    component: () => import(/* webpackChunkName: "customergroup" */ './modules/customer/customergroup/CustomerGroup.vue'),
  },
  {
    path: '/language',
    children: language,
    meta: {
      module: 'language',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.LANGUAGE_READ],
    },
    component: () => import(/* webpackChunkName: "language" */'./modules/language/Language.vue'),
  },
  {
    path: '/emailtemplate',
    children: emailtemplate,
    meta: {
      module: 'emailtemplate',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.EMAIL_TEMPLATE_READ],
    },
    component: () => import(/* webpackChunkName: "emailtemplate" */'./modules/emailtemplate/EmailTemplate.vue'),
  },
  {
    // This is a menu only item. Use it to group other modules. Redirect it to any sub module.
    path: '/masterdata',
    redirect: '/businessunit',
    meta: {
      icon: 'masterData',
      title: 'menu.masterData', // key of title locale
      noMenuLink: true,
      noModule: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.MASTER_DATA_READ, UserPermission.LANGUAGE_READ, UserPermission.EMAIL_TEMPLATE_READ],
    },
    // children for menu
    children: [
      {
        // Sub modules linked to sub menus must have this structure to work properly.
        // The module itself must be defined before(!) this item. This is just a redirect.
        path: '/businessunit',
        redirect: '/businessunit',
        meta: {
          module: 'businessunit',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
          userPermission: [UserPermission.MASTER_DATA_READ],
        },
      },
      {
        path: '/costcenter',
        redirect: '/costcenter',
        meta: {
          module: 'costcenter',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
          userPermission: [UserPermission.MASTER_DATA_READ],
        },
      },
      {
        path: '/customergroup',
        redirect: '/customergroup',
        meta: {
          module: 'customergroup',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
          userPermission: [UserPermission.MASTER_DATA_READ],
        },
      },
      {
        path: '/language',
        redirect: '/language',
        meta: {
          module: 'language',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
          userPermission: [UserPermission.LANGUAGE_READ],
        },
      },
      {
        path: '/emailtemplate',
        redirect: '/emailtemplate',
        meta: {
          module: 'emailtemplate',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
          userPermission: [UserPermission.EMAIL_TEMPLATE_READ],
        },
      },
    ],
  },
  {
    path: '/translator',
    children: translator,
    meta: {
      module: 'translator',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.TRANSLATOR_READ],
    },
    component: () => import(/* webpackChunkName: "translator" */ './modules/translator/Translator.vue'),
  },
  {
    path: '/customer',
    children: customer,
    meta: {
      module: 'customer',
      hideMenu: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.CUSTOMER_READ],
    },
    component: () => import(/* webpackChunkName: "customer" */ './modules/customer/Customer.vue'),
  },
  {
    path: '/officeworker',
    children: officeworker,
    meta: {
      module: 'officeworker',
      hideMenu: true,
      userType: [UserType.ADMIN],
    },
    component: () => import(/* webpackChunkName: "officeworker" */ './modules/officeworker/OfficeWorker.vue'),
  },
  {
    path: '/admin',
    children: admin,
    meta: {
      module: 'admin',
      hideMenu: true,
      userType: [UserType.ADMIN],
    },
    component: () => import(/* webpackChunkName: "admin" */ './modules/admin/Admin.vue'),
  },
  {
    path: '/users',
    redirect: '/translator',
    meta: {
      icon: 'users',
      title: 'menu.users', // key of title locale
      noMenuLink: true,
      noModule: true,
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.TRANSLATOR_READ],
    },
    // children for menu
    children: [
      {
        path: '/translator',
        redirect: '/translator',
        meta: {
          module: 'translator',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
          userPermission: [UserPermission.TRANSLATOR_READ],
        },
      },
      {
        path: '/customer',
        redirect: '/customer',
        meta: {
          module: 'customer',
          userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
          userPermission: [UserPermission.CUSTOMER_READ],
        },
      },
      {
        path: '/officeworker',
        redirect: '/officeworker',
        meta: {
          module: 'officeworker',
          userType: UserType.ADMIN,
        },
      },
      {
        path: '/admin',
        redirect: '/admin',
        meta: {
          module: 'admin',
          userType: UserType.ADMIN,
        },
      },
    ],
  },
  {
    path: '/user',
    children: user,
    meta: {
      hideMenu: true,
      module: 'user', // specifies the module name for $module resolution
    },
    // route level code-splitting
    // this generates a separate chunk (user.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ './modules/user/User.vue'),
  },
  // {
  //   path: '/settings',
  //   name: 'settings',
  //   // component: () => import(/* webpackChunkName: "settings" */ './modules/settings/Settings.vue'),
  // },
  {
    path: '/systemconfiguration',
    children: systemconfiguration,
    meta: {
      module: 'systemconfiguration',
      hideMenu: true,
      userType: UserType.ADMIN,
    },
    component: () => import(/* webpackChunkName: "systemconfiguration" */ './modules/systemconfiguration/SystemConfiguration.vue'),
  },
  {
    path: '/vatconfiguration',
    children: vatconfiguration,
    meta: {
      module: 'vatconfiguration',
      hideMenu: true,
      userType: UserType.ADMIN,
    },
    component: () => import(/* webpackChunkName: "vatconfiguration" */ './modules/vatconfiguration/VatConfiguration.vue'),
  },
  {
    // This is a menu only item. Use it to group other modules. Redirect it to any sub module.
    path: '/settings',
    redirect: '/systemconfiguration',
    meta: {
      icon: 'systemconfiguration',
      title: 'menu.settings', // key of title locale
      noMenuLink: true,
      noModule: true,
      userType: [UserType.ADMIN],
    },
    // children for menu
    children: [
      {
        path: '/systemconfiguration',
        redirect: '/systemconfiguration',
        meta: {
          module: 'systemconfiguration',
          userType: [UserType.ADMIN],
        },
      },
      {
        path: '/vatconfiguration',
        redirect: '/vatconfiguration',
        meta: {
          module: 'vatconfiguration',
          userType: [UserType.ADMIN],
        },
      },
    ],
  },
  {
    path: '/report',
    children: report,
    meta: {
      module: 'report',
      userType: [UserType.ADMIN, UserType.OFFICE_WORKER],
      userPermission: [UserPermission.REPORT_READ],
    },
    component: () => import(/* webpackChunkName: "report" */ './modules/report/Report.vue'),
  },
];

function getVueAppDocumentTranslationContractEnabledState(): boolean{
  if (process.env.VUE_APP_DOCUMENT_TRANSLATION_CONTRACT_ENABLED === 'true')
  {
    return true;
  }
  return false;
}

function getVueAppOnSiteTranslationContractEnabledState(): boolean{
  if (process.env.VUE_APP_ON_SITE_TRANSLATION_CONTRACT_ENABLED === 'true')
  {
    return true;
  }
  return false;
}

function getVueAppVideoTranslationContractEnabledState(): boolean{
  if (process.env.VUE_APP_VIDEO_TRANSLATION_CONTRACT_ENABLED === 'true')
  {
    return true;
  }
  return false;
}

function getRouterConfigContract(): RouteConfig[]
{
  const res: RouteConfig[] = [];
  if (getVueAppDocumentTranslationContractEnabledState())
  {
    res.push({
      path: '/documenttranslation',
      redirect: '/documenttranslation',
      meta: {
        module: 'documenttranslation',
      },
    });
  }

  if (getVueAppOnSiteTranslationContractEnabledState())
  {
    res.push({
      path: '/onsitetranslation',
      redirect: '/onsitetranslation',
      meta: {
        module: 'onsitetranslation',
      },
    });
  }

  if (getVueAppVideoTranslationContractEnabledState())
  {
    res.push({
      path: '/videotranslation',
      redirect: '/videotranslation',
      meta: {
        module: 'videotranslation',
      },
    });
  }

  return res;
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function getTitle(route: Route): string
{
  const meta = route.meta || {};

  if (meta.title)
  {
    return i18n.t(meta.title).toString();
  }

  if (meta.module)
  {
    return i18n.t(`module.${meta.module}.title`).toString();
  }

  const name = meta.name || route.name;
  const currentModule = getModule(route);

  // main module
  if (currentModule === name)
  {
    return i18n.t(`module.${name}.title`).toString();
  }

  if (!name)
  {
    return i18n.t(`module.${currentModule}.title`).toString();
  }

  return i18n.t(`module.${currentModule}.${name}.title`).toString();
}

export function getModule(route: Route): string | undefined
{
  const firstRoute = route.matched.find(m => !!((m.meta ? (m.meta.module || m.meta.name) : null) || m.name));

  if (firstRoute && !(firstRoute.meta && firstRoute.meta.noModule === true))
  {
    return firstRoute.meta ? (firstRoute.meta.module || firstRoute.meta.name) || firstRoute.name : firstRoute.name;
  }

  return undefined;
}

function logoutAndGetRedirectToLoginOptions(targetRoute: Route): RawLocation
{
  const isAlreadyOnLoginWithRedirect = router.currentRoute === targetRoute && targetRoute.query.ref === 'expired' && typeof targetRoute.query.redirectTo === 'string';

  store[StoreActions.LOGOUT]();

  return {
    path: '/',
    replace: true,
    query: {
      ref: 'expired',
      redirectTo: isAlreadyOnLoginWithRedirect ? targetRoute.query.redirectTo as string : targetRoute.fullPath,
    },
  };
}

/**
 * Performs the logout action and redirects to the login page.
 * @param targetRoute The target route to get redirected after successful login. If omitted the current route is used.
 */
export function logoutAndRedirectToLogin(targetRoute?: Route): void
{
  router.replace(logoutAndGetRedirectToLoginOptions(targetRoute || router.currentRoute));
}

// redirect to login page if not logged in and not on root
router.beforeEach((to, from, next) =>
  {
    if (store[StoreGetters.IS_LOGGED_IN] || (to.meta && to.meta.anonymous === true))
    {
      next();
    }
    else
    {
      // update route
      next(logoutAndGetRedirectToLoginOptions(to));
      // reload browser to clear instance
      self.location.reload();
    }
  });
// update document title
router.afterEach((to) =>
  {
    const meta = to.meta || {};
    const moduleTitle = meta.noModule === true ? null : getTitle(to);
    const appTitle = i18n.t('title', { company: process.env.VUE_APP_COMPANY });
    document.title = moduleTitle ? `${moduleTitle} - ${appTitle}` : appTitle.toString();
  });

api.interceptors.response.use(undefined, (error: IAxiosError) => {
  if (error.statusCode === 401 && !(error.config && error.config.url && error.config.url.endsWith('/login')))
  {
    logoutAndRedirectToLogin();
  }

  return Promise.reject(error);
});

export default router;
